import React from 'react';

const AnnouncementBanner = ({ 
  title, 
  text, 
  variant = 'default',
  buttons = [],
}) => {
  const variantStyles = {
    default: {
      background: 'bg-blue-50',
      title: 'text-blue-800',
      text: 'text-blue-700',
      disabled: 'bg-blue-300',
      button: 'bg-blue-600 text-white hover:bg-blue-700',
      secondaryButton: 'bg-gray-200 text-gray-700 hover:bg-gray-300'
    },
    warning: {
      background: 'bg-yellow-50',
      title: 'text-yellow-800',
      text: 'text-yellow-700',
      disabled: 'bg-yellow-200',
      button: 'bg-yellow-600 text-white hover:bg-yellow-700',
      secondaryButton: 'bg-gray-200 text-gray-700 hover:bg-gray-300'
    },
    error: {
      background: 'bg-red-50',
      title: 'text-red-800',
      text: 'text-red-700',
      disabled: 'bg-red-300',
      button: 'bg-red-600 text-white hover:bg-red-700',
      secondaryButton: 'bg-gray-200 text-gray-700 hover:bg-gray-300'
    },
    success: {
      background: 'bg-green-50',
      title: 'text-green-800',
      text: 'text-green-700',
      disabled: 'bg-green-300',
      button: 'bg-green-600 text-white hover:bg-green-700',
      secondaryButton: 'bg-gray-200 text-gray-700 hover:bg-gray-300'
    }
  };

  const styles = variantStyles[variant];

  return (
    <div className={`${styles.background} py-4`}>
      <div className="container mx-auto px-4">
        <div className="max-w-4xl mx-auto">
          <div className="flex flex-col sm:flex-row items-center justify-between gap-4">
            <div className="text-center sm:text-left">
              {title && (
                <h2 className={`text-lg font-semibold mb-1 ${styles.title}`}>
                  {title}
                </h2>
              )}
              {text && (
                <p className={`text-sm ${styles.text}`}>
                  {text}
                </p>
              )}
            </div>
            
            {buttons.length > 0 && (
              <div className="flex gap-3">
                {buttons.map((button, index) => (
                  <button
                    key={index}
                    onClick={button.onClick}
                    disabled={button.disabled}
                    className={`px-4 py-2 rounded-md text-sm font-medium transition-colors ${
                      button.disabled ? `${styles.disabled} cursor-not-allowed` : 
                      button.className || (index === 0 ? styles.button : styles.secondaryButton)
                    }`}
                  >
                    {button.label}
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnnouncementBanner;
