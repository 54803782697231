import React, { createContext, useState, useEffect, useContext } from 'react';
import { fetchLatestUserInfo } from '../api/axios';
import { useAuth } from './AuthContext';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const { isAuthenticated, user: authUser } = useAuth();

  useEffect(() => {
    if (isAuthenticated && authUser) {
      setUser(authUser);
    } else {
      setUser(null);
    }
  }, [isAuthenticated, authUser]);

  const updateUser = async () => {
    setLoading(true);
    try {
      if (isAuthenticated) {
        const response = await fetchLatestUserInfo();
        setUser(response.data);
      } else {
        setUser(null);
      }
    } catch (error) {
      console.error('Failed to update user info:', error);
      if (error.response && error.response.status === 401) {
        // Handle unauthorized error (e.g., clear token and redirect to login)
        localStorage.removeItem('token');
        setUser(null);
        // You might want to use a navigation function here to redirect to login
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <UserContext.Provider value={{ user, setUser, loading, updateUser }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
