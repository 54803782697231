import React from 'react';
import './Loading.css';

const Loading = () => {
  return (
    <div className="loading-container">
      <div className="loading-content">
        <div className="loading-spinner"></div>
        <h2>Loading Digital Medals</h2>
        <p>Connecting to your trophy case...</p>
      </div>
    </div>
  );
};

export default Loading;
