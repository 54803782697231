// src/components/Page.js
import React from 'react';
import { Helmet } from 'react-helmet-async';

const appName = "Digital Medals";

const Page = ({ title, description, keywords, ogImage, children }) => {
  const defaultDescription = "Track and showcase your professional achievements, skills, and career growth";
  const defaultKeywords = "digital medals, professional achievements, career tracking, skills development";
  const defaultOgImage = "/images/logo512.png";

  const pageTitle = title ? `${title} | ${appName}` : appName;
  const pageDescription = description || defaultDescription;
  const pageKeywords = keywords || defaultKeywords;
  const pageImage = ogImage || defaultOgImage;

  return (
    <>
      <Helmet>
        {/* Only update title and meta tags if they're provided or different from defaults */}
        <title>{pageTitle}</title>
        
        {/* SEO Meta Tags */}
        <meta name="description" content={pageDescription} data-rh="true" />
        <meta name="keywords" content={pageKeywords} data-rh="true" />
        
        {/* OpenGraph Meta Tags */}
        <meta property="og:title" content={pageTitle} data-rh="true" />
        <meta property="og:description" content={pageDescription} data-rh="true" />
        <meta property="og:image" content={pageImage} data-rh="true" />
        
        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" data-rh="true" />
        <meta name="twitter:title" content={pageTitle} data-rh="true" />
        <meta name="twitter:description" content={pageDescription} data-rh="true" />
        <meta name="twitter:image" content={pageImage} data-rh="true" />
      </Helmet>
      {children}
    </>
  );
};

export default Page;
