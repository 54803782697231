import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FaGraduationCap, FaEdit, FaTrash, FaPlus } from 'react-icons/fa';
import CreatableSelect from 'react-select/creatable';
import { fetchCertifications, createCertification, updateCertification, deleteCertification, fetchInstitutions, createInstitution } from '../../api/axios';
import Button from '../Common/Button';
import { format, parseISO } from 'date-fns';
import { Badge } from '../Common/Badge';

// For form inputs (YYYY-MM-DD)
const formatDateForInput = (dateString) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  return date instanceof Date && !isNaN(date.getTime())
    ? date.toISOString().split('T')[0]
    : '';
};

// For user-friendly display
const formatDateForDisplay = (dateString) => {
  if (!dateString) return '';
  try {
    return format(parseISO(dateString), 'MMMM d, yyyy');
  } catch (error) {
    console.error('Error formatting date:', error);
    return '';
  }
};

const CertificationItem = ({ certification, isEditing, editData, onEdit, onSave, onCancel, onDelete, setEditData, institutions }) => {
  const { t } = useTranslation();

  if (isEditing) {
    return (
      <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6 mb-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              {t('certifications.name')}
            </label>
            <input
              type="text"
              value={editData.name}
              onChange={(e) => setEditData({ ...editData, name: e.target.value })}
              className="w-full p-2 border border-gray-300 rounded-md"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              {t('certifications.institution')}
            </label>
            <CreatableSelect
              isClearable
              options={institutions.map(inst => ({ value: inst.id, label: inst.name }))}
              value={editData.institution_id ? { 
                value: editData.institution_id, 
                label: editData.institution_name 
              } : null}
              onChange={(newValue) => {
                if (newValue && newValue.__isNew__) {
                  createInstitution({ name: newValue.label }).then(newInst => {
                    setEditData({ ...editData, institution_id: newInst.id, institution_name: newInst.name });
                  });
                } else {
                  setEditData({ 
                    ...editData, 
                    institution_id: newValue ? newValue.value : null, 
                    institution_name: newValue ? newValue.label : '' 
                  });
                }
              }}
              className="w-full"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              {t('certifications.dateOfCompletion')}
            </label>
            <input
              type="date"
              value={formatDateForInput(editData.date_of_completion)}
              onChange={(e) => setEditData({ ...editData, date_of_completion: e.target.value })}
              className="w-full p-2 border border-gray-300 rounded-md"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              {t('certifications.expirationDate')}
            </label>
            <input
              type="date"
              value={formatDateForInput(editData.expiration_date)}
              onChange={(e) => setEditData({ ...editData, expiration_date: e.target.value })}
              className="w-full p-2 border border-gray-300 rounded-md"
            />
          </div>
          <div className="md:col-span-2">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              {t('certifications.description')}
            </label>
            <textarea
              value={editData.description || ''}
              onChange={(e) => setEditData({ ...editData, description: e.target.value })}
              className="w-full p-2 border border-gray-300 rounded-md"
              rows="3"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              {t('certifications.credentialNumber')}
            </label>
            <input
              type="text"
              value={editData.credential_number || ''}
              onChange={(e) => setEditData({ ...editData, credential_number: e.target.value })}
              className="w-full p-2 border border-gray-300 rounded-md"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              {t('certifications.verificationUrl')}
            </label>
            <input
              type="url"
              value={editData.verification_url || ''}
              onChange={(e) => setEditData({ ...editData, verification_url: e.target.value })}
              className="w-full p-2 border border-gray-300 rounded-md"
            />
          </div>
        </div>
        <div className="flex justify-end space-x-2 mt-4">

          <Button onClick={() => onDelete(certification.id)} color="red" icon={FaTrash} className="w-full sm:w-auto">
            {t('common.delete')}
          </Button>
          <Button onClick={onCancel} color="blue" icon={FaTrash} className="w-full sm:w-auto">
            {t('common.cancel')}
          </Button>
          <Button onClick={onSave} color="green" icon={FaPlus} className="w-full sm:w-auto">
            {t('common.save')}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6 mb-4">
      <div className="flex flex-col sm:flex-row sm:justify-between sm:items-start mb-4">
        <div className="mb-2 sm:mb-0">
          <h3 className="text-xl font-bold text-gray-900">{certification.name}</h3>
          {certification.institution && (
            <div className="flex items-center text-gray-600">
              <FaGraduationCap className="mr-2" />
              <p>{certification.institution.name}</p>
            </div>
          )}
        </div>
        <div className="flex space-x-2">
          <Button onClick={() => onEdit(certification)} color="blue" icon={FaEdit} className="w-full sm:w-auto">
            {t('common.edit')}
          </Button>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <p className="text-sm font-medium text-gray-500">{t('certifications.dateOfCompletion')}</p>
          {certification.date_of_completion ? (
            <p>{formatDateForDisplay(certification.date_of_completion)}</p>
          ) : (
            <Badge color="blue">{t('certifications.inProgress')}</Badge>
          )}
        </div>
        {certification.expiration_date && (
          <div>
            <p className="text-sm font-medium text-gray-500">{t('certifications.expirationDate')}</p>
            <p>{formatDateForDisplay(certification.expiration_date)}</p>
          </div>
        )}
        {certification.description && (
          <div className="md:col-span-2">
            <p className="text-sm font-medium text-gray-500">{t('certifications.description')}</p>
            <p>{certification.description}</p>
          </div>
        )}
        {certification.credential_number && (
          <div>
            <p className="text-sm font-medium text-gray-500">{t('certifications.credentialNumber')}</p>
            <div className="flex items-center gap-2">
              <p>{certification.credential_number}</p>
              {certification.verification_url && (
                <a 
                  href={certification.verification_url} 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="text-sm text-blue-600 hover:underline"
                >
                  ({t('certifications.verify')})
                </a>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const CertificationsSection = ({ 
  user, 
  certifications, 
  setCertifications, 
  onDeleteCertification 
}) => {
  const { t } = useTranslation();
  const [isAddingCertification, setIsAddingCertification] = useState(false);
  const [editMode, setEditMode] = useState(null);
  const [editData, setEditData] = useState({});
  const [institutions, setInstitutions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const [certsData, instsData] = await Promise.all([
        fetchCertifications(),
        fetchInstitutions()
      ]);
      setCertifications(certsData);
      setInstitutions(instsData);
    };
    fetchData();
  }, [setCertifications]);

  const handleAddCertification = () => {
    setIsAddingCertification(true);
    setEditData({
      title: '',
      institution: null,
      date_of_completion: '',
      expiration_date: '',
      description: '',
      credential_number: '',
      verification_url: ''
    });
  };

  const handleSaveNewCertification = async () => {
    try {
      const newCertificationData = {
        name: editData.name,
        institution_id: editData.institution_id,
        date_of_completion: editData.date_of_completion,
        expiration_date: editData.expiration_date,
        description: editData.description,
        credential_number: editData.credential_number,
        verification_url: editData.verification_url
      };
      const newCertification = await createCertification(newCertificationData);
      if (newCertification && newCertification.id) {
        setCertifications([...certifications, newCertification]);
        setIsAddingCertification(false);
        setEditData({});
      } else {
        console.error('New certification does not have an id:', newCertification);
      }
    } catch (error) {
      console.error('Error saving new certification:', error);
    }
  };

  const handleCancelNewCertification = () => {
    setIsAddingCertification(false);
    setEditData({});
  };

  const handleEditCertification = (cert) => {
    setEditMode(cert.id);
    setEditData({
      ...cert,
      // Map the nested institution data correctly
      institution_id: cert.institution?.id,
      institution_name: cert.institution?.name,
      date_of_completion: formatDateForInput(cert.date_of_completion),
      expiration_date: formatDateForInput(cert.expiration_date),
    });
  };

  const handleSaveCertification = async () => {
    try {
        const certificationData = {
            id: editData.id,
            name: editData.name,
            institution_id: editData.institution_id,
            date_of_completion: editData.date_of_completion,
            expiration_date: editData.expiration_date,
            description: editData.description,
            credential_number: editData.credential_number,
            verification_url: editData.verification_url
        };

        const updatedCertification = await updateCertification(certificationData);
        
        if (updatedCertification) {
            setCertifications(prevCertifications => 
                prevCertifications.map(cert => 
                    cert.id === editData.id ? {
                        ...cert,
                        ...updatedCertification,
                        institution: {
                            id: updatedCertification.institution_id,
                            name: editData.institution_name
                        }
                    } : cert
                )
            );
            setEditMode(null);
            setEditData({});
        } else {
            throw new Error('Failed to update certification');
        }
    } catch (error) {
        console.error('Error saving certification:', error);
    }
};

  const handleCancelCertification = () => {
    setEditMode(null);
    setEditData({});
  };

  const handleDelete = (certificationId) => {
    onDeleteCertification(certificationId);
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold text-gray-800">{t('certifications.title')}</h2>
        <Button onClick={handleAddCertification} color="blue" icon={FaPlus} className="w-full sm:w-auto">
          {t('certifications.addNew')}
        </Button>
      </div>
      <div className="space-y-6">
        {isAddingCertification && (
          <CertificationItem
            isEditing={true}
            editData={editData}
            onSave={handleSaveNewCertification}
            onCancel={handleCancelNewCertification}
            setEditData={setEditData}
            institutions={institutions}
          />
        )}
        {certifications.map((cert) => (
          cert && cert.id ? (
            <CertificationItem
              key={cert.id}
              certification={cert}
              isEditing={editMode === cert.id}
              editData={editData}
              onEdit={() => handleEditCertification(cert)}
              onSave={handleSaveCertification}
              onCancel={handleCancelCertification}
              onDelete={handleDelete}
              setEditData={setEditData}
              institutions={institutions}
            />
          ) : null
        ))}
        {certifications.length === 0 && !isAddingCertification && (
          <p className="text-gray-500 italic">{t('certifications.noCertifications')}</p>
        )}
      </div>
    </div>
  );
};

export default CertificationsSection;
