import instance from "../../axios";
import { toast } from 'react-toastify';
import { t } from 'i18next';

export const fetchGoals = async () => {
    const response = await instance.get('/goals');
    return response.data.data;
};

export const createGoal = async (goal) => {
    const response = await instance.post('/goals/store', goal);
    if (response.data.data) {
        toast.success(t('goals.created'));
        return response.data.data;
    }
    return response.data.data;
};

export const updateGoal = async (goal) => {
    const response = await instance.put(`/goals/update/${goal.id}`, goal);
    if (response.data.data) {
        toast.success(t('goals.updated'));
        return response.data.data;
    }
    return response.data.data;
};

export const deleteGoal = async (id) => {
    const response = await instance.delete(`/goals/destroy/${id}`);
    if (response.status === 200) {
        toast.success(t('goals.deleted'));
    } else {
        toast.error(t('goals.deleteError'));
    }
    return response.data.data;
};