import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const LessonsLearned = ({ initialData, onNext, onBack, medalData }) => {
  const { t } = useTranslation();
  const [lessons, setLessons] = useState(initialData.lessons || []);
  const [newLesson, setNewLesson] = useState('');

  const addLesson = () => {
    if (newLesson.trim()) {
      setLessons([...lessons, newLesson]);
      setNewLesson('');
    }
  };

  const deleteLesson = (index) => {
    setLessons(lessons.filter((_, i) => i !== index));
  };

  const handleNext = () => {
    onNext({ lessons });
  };

  return (
    <div className="w-full min-h-screen flex items-start justify-center p-4">
      <div className="w-full max-w-2xl bg-white">
        <div className="flex justify-between items-center mb-8 p-4">    
          <button
            onClick={() => onBack({ lessons })}
            className="bg-gray-500 text-white py-2 px-4 rounded-md hover:bg-gray-600 transition duration-300"
          >
            {t('medals.back')}
          </button>
          <div className="hidden md:block w-3/4 bg-white text-center">
            <p><strong>{medalData.title}</strong></p>
            <p>
            <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
            {medalData.categoryName || ''}
            </span>
            </p>
          </div>
          <button
            onClick={handleNext}
            className={`py-2 px-4 rounded-md transition duration-300 ${
              lessons.length === 0
                ? 'bg-yellow-500 hover:bg-yellow-600 text-black'
                : 'bg-blue-500 hover:bg-blue-600 text-white'
            }`}
          >
            {lessons.length === 0 ? t('common.skip') : t('common.next')}
          </button>
        </div>
        <div className="text-center mb-6">
          <h2 className="text-4xl font-bold text-gray-900 mb-2">{t('lessonsLearned.addLessonsLearned')}</h2>
          <p className="text-sm text-gray-700 rounded-lg p-4 border border-blue-300 bg-blue-100">
            {t('lessonsLearned.lessonsLearnedDescription')}
          </p>
        </div>
        <div className="space-y-4">
          <textarea
            value={newLesson}
            onChange={(e) => setNewLesson(e.target.value)}
            placeholder={t('lessonsLearned.lessonLearnedPlaceholder')}
            className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          ></textarea>
          <button
            onClick={addLesson}
            disabled={!newLesson.trim()}
            className={`w-full py-3 rounded-lg transition duration-300 ${
              newLesson.trim() ? 'bg-blue-500 hover:bg-blue-600 text-white' : 'bg-gray-300 text-gray-500 cursor-not-allowed'
            }`}
          >
            {t('lessonsLearned.addLesson')}
          </button>
          
          <div className="list-none pl-0 space-y-4">
            {lessons.map((lesson, index) => (
              <div key={index} className="bg-blue-100 text-blue-800 rounded-lg p-4 text-sm font-semibold flex justify-between items-center">
                <span>{lesson}</span>
                <span onClick={() => deleteLesson(index)} className="bg-white rounded px-1 text-xs uppercase font-bold text-red-500 border border-red-500">
                  {t('medals.delete')}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LessonsLearned;
