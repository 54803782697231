import React from 'react';

function StatCard({ title, value, change, subtext, className }) {
  return (
    <div className={`p-4 rounded-lg shadow ${className}`}>
      <h3 className="text-lg font-semibold text-white mb-2">{title}</h3>
      <p className="text-3xl font-bold text-white">{value != null ? value.toString() : 'N/A'}</p>
      {change && <p className="text-sm text-white mt-1">{change}</p>}
      {subtext && <p className="text-sm text-white mt-1">{subtext}</p>}
    </div>
  );
}

export default StatCard;
