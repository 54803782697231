import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const QuillWrapper = forwardRef(({ value, onChange, modules, formats, className, style }, ref) => {
  const quillRef = useRef(null);

  useImperativeHandle(ref, () => ({
    getEditor: () => quillRef.current?.getEditor(),
  }));

  useEffect(() => {
    const editor = quillRef.current?.getEditor();
    if (editor) {
      const resizeObserver = new ResizeObserver(() => {
        // Avoid updating contents directly to prevent duplication
        editor.root.style.height = 'auto';
        editor.root.style.height = editor.root.scrollHeight + 'px';
      });

      resizeObserver.observe(editor.container);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, []);

  return (
    <ReactQuill
      ref={quillRef}
      value={value}
      onChange={onChange}
      modules={modules}
      formats={formats}
      className={className}
      style={{ ...style, resize: 'both', overflow: 'auto' }}
      theme="snow"
    />
  );
});

QuillWrapper.displayName = 'QuillWrapper';

export default QuillWrapper;
