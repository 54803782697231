import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Add this function to get the user language
const getUserLanguage = () => {
  const storedLanguage = localStorage.getItem('userLanguage');
  if (storedLanguage && ['en', 'es', 'fr'].includes(storedLanguage)) {
    return storedLanguage;
  }
  const browserLanguage = navigator.language.split('-')[0];
  return ['en', 'es', 'fr'].includes(browserLanguage) ? browserLanguage : 'en';
};

export const setUserLanguage = (language) => {
  if (['en', 'es', 'fr'].includes(language)) {
    localStorage.setItem('userLanguage', language);
    i18n.changeLanguage(language);
  }
};

export const initI18n = () => {
  const userLanguage = getUserLanguage();

  i18n
    .use(initReactI18next)
    .init({
      resources: {
        en: {
          translation: {
            "settings": {
              "nav": {
                "general": "General",
                "security": "Security",
                "profile": "Profile",
                "employments": "Employment",
                "social": "Social",
                "goals": "Goals",
                "projects": "Projects",
                "certifications": "Certifications"
              },
              "title": "Settings",
              "profile": "Profile",
              "employments": "Employment",
              "social": "Social",
              "goals": "Goals",
              "projects": "Projects",
              "certifications": "Certifications",
              "general": "General",
              "security": "Security",
              "fullName": "Full Name",
              "emailAddress": "Email Address",
              "timezone": "Timezone",
              "language": "Language",
              "saveChanges": "Save Changes",
              "newPassword": "New Password",
              "confirmNewPassword": "Confirm New Password",
              "updatePassword": "Update Password",
              "passwordRequirements": "Password must be at least 8 characters long, include 1 uppercase letter, 1 symbol, and 1 number",
              "passwordsDoNotMatch": "Passwords do not match",
              "passwordMinLength": "Minimum 8 characters",
              "passwordLowercase": "Contains lowercase letter",
              "passwordUppercase": "Contains uppercase letter",
              "passwordNumbers": "Contains number",
              "passwordSpecialChars": "Contains special character",
              "profile": "Profile",
              "headline": "Headline",
              "description": "Description",
              "profileUpdateSuccess": "Profile updated successfully",
              "profileUpdateFailed": "Failed to update profile",
              "employments": "Employments",
              "dateLocale": "en-US",
              "social": "Social",
              "socialLinks": "Social Links",
              "addNewSocialLink": "Add New Social Link",
              "updateSocialLink": "Update Social Link",
              "deleteSocialLink": "Delete Social Link",
              "socialLinkUpdated": "Social link updated successfully",
              "socialLinkDeleted": "Social link deleted successfully",
              "socialLinkAdded": "Social link added successfully",
              "errorUpdatingSocialLink": "Error updating social link",
              "errorDeletingSocialLink": "Error deleting social link",
              "errorAddingSocialLink": "Error adding social link",
              "headlinePlaceholder": "Enter your headline",
              "descriptionPlaceholder": "Enter your description",
              "skills": "Skills",
              "technology": "Technology",
              "username": "Username",
              "usernamePlaceholder": "Enter your username",
              "passwordSuccess": "Password updated successfully",
              "passwordError": "Failed to update password",
              "generalSuccess": "General settings updated successfully",
              "generalError": "Failed to update general settings",
              "profileSuccess": "Profile updated successfully",
              "profileError": "Failed to update profile",
              "emailVerified": "Email verified",
              "resendVerification": "Resend verification email",
              "verificationEmailSent": "Verification email has been sent",
              "goals": "Goals",
              "projects": "Projects"
            },
            "languages": {
              "english": "English",
              "spanish": "Spanish",
              "french": "French"
            },
            "Language": "Language",
            "English": "English",
            "Spanish": "Spanish",
            "French": "French",
            "Save changes": "Save changes",
            "Save": "Save",
            "Cancel": "Cancel",
            "Name not set": "Name not set",
            "Skills": "Skills",
            "Technologies": "Technologies",
            "About Me": "About Me",
            "Find me on": "Find me on",
            "Experience": "Experience",
            "Error": "Error",
            "skills": {
              "loading": "Loading skills...",
              "noSkills": "No skills available.",
              "title": "Skills That Set You Apart",
              "addSkills": "Add Your Skills",
              "searchOrAdd": "Search or add a skill...",
              "yourSkillArsenal": "Your Skill Arsenal",
              "sortAZ": "A-Z",
              "sortProficiency": "Proficiency",
              "updateSuccess": "Skill updated successfully",
              "updateUnexpectedFailure": "Unexpected failure updating skill",
              "updateFailure": "Failed to update skill",
              "removeSuccess": "Skill removed successfully",
              "removeFailure": "Failed to remove skill",
              "createSuccess": "Skill created successfully",
              "createUnexpectedFailure": "Unexpected failure creating skill",
              "createFailure": "Failed to create skill",
              "clickOnSkillToEdit": "Click on a skill to edit it"
            },
            "technologies": {
              "word": "Technologies",
              "loading": "Loading technologies...",
              "noTechnologies": "No technologies available.",
              "title": "Technology That Powers You",
              "addTechnologies": "Add Your Technologies",
              "searchOrAdd": "Search or add a technology...",
              "yourTechnologyToolkit": "Your Technology Toolkit",
              "sortAZ": "A-Z",
              "sortProficiency": "Proficiency",
              "updateSuccess": "Technology updated successfully",
              "updateUnexpectedFailure": "Unexpected failure updating technology",
              "updateFailure": "Failed to update technology",
              "removeSuccess": "Technology removed successfully",
              "removeFailure": "Failed to remove technology",
              "createSuccess": "Technology created successfully",
              "createUnexpectedFailure": "Unexpected failure creating technology",
              "createFailure": "Failed to create technology",
              "clickOnTechnologyToEdit": "Click on a technology to edit it"
            },
            "Digital Medals": "Digital Medals",
            "Dashboard": "Dashboard",
            "Profile": "Profile",
            "Settings": "Settings",
            "Users": "Users",
            "View notifications": "View notifications",
            "Notifications": "Notifications",
            "New message": "New message",
            "John Doe sent you a message": "John Doe sent you a message",
            "New task assigned": "New task assigned",
            "You have been assigned a new task": "You have been assigned a new task",
            "Your Profile": "Your Profile",
            "Sign out": "Sign out",
            "Login": "Sign in",
            "Register": "Sign up",
            "All rights reserved": "All rights reserved",
            "loading": {
              "userData": "Loading user data..."
            },
            "dashboard": {
              "title": "Dashboard",
              "welcome": "Welcome, {{name}}!",
              "viewProfile": "View Profile ",
              "medals": "Medals",
              "skills": "Skills",
              "manageSkills": "Manage skills",
              "technologies": "Technologies",
              "manageTechnologies": "Manage technologies",
              "employments": "Employments",
              "manageEmployments": "Manage employments",
              "manageMedals": "Manage medals",
              "projects": "Projects",
              "manageProjects": "Manage projects",
              "projectsSkills": "Project Skills",
              "manageProjectsSkills": "Manage project skills",
              "projectsTechnologies": "Project Technologies",
              "manageProjectsTechnologies": "Manage project technologies",
              "latestMedals": "Latest Medals",
              "topSkills": "Top Skills",
              "topTechnologies": "Top Technologies",
              "lastLogin": "Last login",
              "noMedals": "No medals available.",
              "addMedals": "Add Medals",
              "noSkills": "No skills available.",
              "noTechnologies": "No technologies available.",
              "addSkills": "Add Skills",
              "addTechnologies": "Add Technologies",
              "checklistTitle": "Setup Checklist",
              "progressTitle": "Track Your Progress",
              "addSkillsChecklist": "Add your professional skills",
              "addTechnologiesChecklist": "Add your technical skills",
              "addMedalsChecklist": "Add your achievements",
              "addEmploymentsChecklist": "Add your work history"
            },
            "employments": {
              "title": "Employment History",
              "addNew": "Add New Employment",
              "edit": "Edit Employment",
              "delete": "Delete Employment",
              "employer": "Employer",
              "position": "Position",
              "startDate": "Start Date",
              "endDate": "End Date",
              "currentPosition": "Current Position",
              "description": "Description",
              "save": "Save Employment",
              "cancel": "Cancel",
              "deleteConfirmation": "Are you sure you want to delete this employment?",
              "noEmployments": "No employment history added yet.",
              "present": "Present",
              "searchOrAddEmployer": "Search or add an employer...",
              "searchOrAddTitle": "Search or add a title...",
              "updateSuccess": "Employment updated successfully",
              "updateUnexpectedFailure": "Unexpected failure updating employment",
              "updateFailure": "Failed to update employment",
              "addUnexpectedFailure": "Unexpected failure adding employment",
              "deleteSuccess": "Employment deleted successfully",
              "addSuccess": "Employment added successfully",
              "createEmployerSuccess": "Employer created successfully",
              "createEmployerUnexpectedFormat": "Unexpected response format when creating employer",
              "createEmployerFailure": "Failed to create employer",
              "createTitleSuccess": "Title created successfully",
              "createTitleUnexpectedFailure": "Unexpected failure creating title",
              "createTitleFailure": "Failed to create title",
              "deleteConfirmTitle": "Delete Employment",
              "deleteConfirmMessage": "Are you sure you want to delete this employment record? This action cannot be undone."
            },
            "userInfo": {
              "fetchError": "Error fetching latest user info",
              "passwordUpdateSuccess": "User password updated successfully",
              "passwordUpdateError": "Error updating user password",
              "nameUpdateSuccess": "User name updated successfully",
              "nameUpdateError": "Error saving user name",
              "profileUpdateSuccess": "User profile updated successfully",
              "profileUpdateError": "Error saving user profile",
              "headlineUpdateSuccess": "User headline updated successfully",
              "headlineUpdateError": "Error saving user headline",
              "descriptionUpdateSuccess": "User description updated successfully",
              "descriptionUpdateError": "Error saving user description"
            },
            "socialLinks": {
              "deleteSuccess": "Social link deleted successfully",
              "deleteError": "Error deleting social link",
              "addSuccess": "Social link added successfully",
              "addError": "Failed to add social link",
              "updateSuccess": "Social link updated successfully",
              "updateError": "Failed to update social link",
              "deleteConfirmTitle": "Delete Social Link",
              "deleteConfirmMessage": "Are you sure you want to delete this social link?"
            },
            "profile": {
              "nameNotSet": "Name not set",
              "aboutMe": "About Me",
              "findMeOn": "Find me on",
              "experience": "Experience",
              "addNewLink": "Add New Link",
              "doneEditing": "Done Editing",
              "addEmployment": "Add Employment",
              "urlPlaceholder": "Enter URL",
              "add": "Add",
              "editSocialLinks": "Edit Social Links",
              "noSocialLinks": "No social links added yet.",
              "noEmployments": "No employments added yet.",
              "joined": "Joined",
              "usernameNotSet": "Username not set",
              "publicProfile": "Public Profile",
              "noDescription": "No description provided",
              "noSkills": "No skills added yet",
              "noTechnologies": "No technologies added yet",
              "platform": "Platform",
              "url": "URL",
              "cancel": "Cancel",
              "delete": "Delete",
              "edit": "Edit",
              "selectPlatform": "Select Platform",
              "socialLinks": "Social Links",
              "updateSuccess": "Social link updated successfully",
              "updateError": "Failed to update social link",
              "deleteSuccess": "Social link deleted successfully",
              "deleteError": "Failed to delete social link",
              "addSuccess": "Social link added successfully",
              "addError": "Failed to add social link",
              "selectNewImage": "Select New Image",
              "selectImage": "Select Image",
              "editProfilePicture": "Edit Profile Picture",
              "cropImage": "Crop Image",
              "save": "Save",
              "takePhoto": "Take Photo"
            },
            "User not found": "User not found",
            "Not available": "Not available",
            "Invalid Date": "Invalid Date",
            "Back to Home": "Back to Home",
            "Headline not set": "Headline not set",
            "Present": "Present",
            "Showcasing Achievements in the Digital Realm": "Showcasing Achievements in the Digital Realm",
            "About": "About",
            "Find": "Find",
            "on": "on",
            "login": {
              "title": "Sign In",
              "emailLabel": "Email Address",
              "passwordLabel": "Password",
              "rememberMe": "Remember me",
              "forgotPassword": "Forgot password?",
              "signIn": "Sign In",
              "notAMember": "Not a member?",
              "signUp": "Sign up now",
              "invalidEmail": "Invalid email address",
              "passwordMinLength": "Password must be at least 8 characters long",
              "loginSuccessful": "Login successful",
              "loginFailed": "Login failed",
              "loginSuccessfulLog": "Login successful",
              "loginFailedLog": "Login failed",
              "orContinueWith": "Or sign in with",
              "orRegisterWith": "Or register with",
              "loginWithGoogle": "Google",
              "registerWithGoogle": "Google",
              "googleLoginFailed": "Failed to authenticate with Google",
              "googleLoginSuccessful": "Successfully authenticated with Google",
              "processingLogin": "Processing your login...",
              "googleLoginRedirect": "Redirecting to Google for authentication...",
              "googleLoginError": "An error occurred during Google authentication. Please try again.",
              "googleLoginCallbackError": "An error occurred during the callback from Google. Please try again.",
              "googleLoginCallbackSuccessful": "Successfully authenticated with Google",
              "googleLoginCallbackFailed": "Failed to authenticate with Google",
              "googleLoginCallbackProcessing": "Processing your login with Google...",
              "loginWithLinkedIn": "LinkedIn",
              "registerWithLinkedIn": "LinkedIn",
            },
            "register": {
              "createAccount": "Create an account",
              "name": "Name",
              "namePlaceholder": "John Doe",
              "email": "Email",
              "emailPlaceholder": "john@example.com",
              "password": "Password",
              "passwordPlaceholder": "********",
              "confirmPassword": "Confirm Password",
              "confirmPasswordPlaceholder": "********",
              "register": "Register",
              "alreadyHaveAccount": "Already have an account?",
              "signIn": "Sign in",
              "registrationSuccessful": "Registration successful",
              "registrationFailed": "Registration failed. Please try again.",
              "nameMinLength": "Name must be at least 2 characters",
              "invalidEmail": "Invalid email address",
              "passwordMinLength": "Password must be at least 8 characters",
              "passwordsDontMatch": "Passwords don't match"
            },
            "forgotPassword": {
              "title": "Forgot your password?",
              "instruction": "Enter your email address and we'll send you a link to reset your password.",
              "emailLabel": "Email address",
              "emailPlaceholder": "Email address",
              "sending": "Sending...",
              "sendResetLink": "Send reset link",
              "emailSent": "Password reset email sent. Please check your inbox.",
              "error": "An error occurred. Please try again.",
              "rememberPassword": "Remember your password?",
              "signIn": "Sign in"
            },
            "home": {
              "welcomeTo": "Welcome to",
              "elevatingAchievements": "Elevating Achievements in the Digital Arena",
              "boostCareer": "Boost Your Career Today!",
              "unlockPotential": "Start using our app and unlock your potential",
              "getStarted": "Get Started",
              "trackThings": "Keep track of all the things worth tracking.",
              "improvePerformance": "Improve your performance reviews",
              "improveResume": "Improve your resume",
              "improveYourself": "Improve yourself.",
              "login": "LOGIN",
              "register": "REGISTER →",
              "whatAreDigitalMedals": "What are Digital Medals",
              "whatAre": "What Are",
              "whatAreThey": "What Are They",
              "whyUseThem": "Why Use Them",
              "whatIsIt": "A Digital Medal is a personal, living record of your professional accomplishments, skills, and feedback.",
              "whatIsItUsedFor": "What a Digital Medal Is Used For and Why",
              "why": "Why ",
              "you": "You ",
              "whyYou": "Why You ",
              "keep": "Keep ",
              "should": "Should ",
              "shouldKeep": "Should Keep ",
              "whyYouShouldKeep": "Why You Should Keep ",
              "whyUseDigitalMedals": "Why use Digital Medals ",
              "whatIsItUsedForWhy": "It's a tool designed to capture both small and significant achievements as they happen. ",
              "whyItsImportant": "Whether it's a project you completed, feedback from a manager, or a skill you mastered, a Digital Medal keeps everything in one place, allowing you to showcase your professional growth over time. Keep all your Accomplishments, Feedback, Skills, and humble brags and more in one place.",
              "whyItsGoodToUse": "Digital Medals serve as a motivational tool, reminding you of your progress and building confidence when you feel stuck or overwhelmed.",
              "whyItsImportant3": "It helps align your work with long-term career goals, showing how current accomplishments contribute to future success.",

              "jobInterviews": "Job Interviews",
              "jobInterviewsText": "When applying for new roles, you can draw on specific examples from your Digital Medals to highlight relevant experience, improving your chances of success.",
              "performanceReviews": "Performance Reviews",
              "performanceReviewsText": "During evaluations, you need to show concrete examples of your contributions. A Digital Medal allows you to easily pull together accomplishments, proving your value to the organization",
              "resume": "Resume",
              "resumeText": "When applying for new roles, you can draw on specific examples from your Digital Medals to highlight relevant experience, improving your chances of success.",
              "careerProgression": "Career Progression",
              "careerProgressionText": "By tracking your accomplishments in relation to your company's career ladder, you can make a stronger case for promotions or raises.",
              "accurateReflection": "Accurate Reflection",
              "accurateReflectionText": "Over time, it's easy to forget many smaller contributions you've made. Keeping a Digital Medal ensures that no effort is lost or overlooked when it comes time for reviews or job applications.",
              "visibility": "Visibility",
              "visibilityText": "Sharing your Digital Medal (or elements from it) with your manager helps ensure your work is recognized, even if it's not always immediately visible.",
              "careerStrategy": "Career Strategy",
              "careerStrategyText": "It helps align your work with long-term career goals, showing how current accomplishments contribute to future aspirations.",
              "whatAreDigitalMed": "What Are Digital Medals",
              "digitalMedalTitle": "Digital Medal",
              "digitalMedalSubtitle": "A structured, personal record where you track your professional achievements, projects, feedback, and career goals.",
              "digitalMedalDescription": "It serves as a tool to help you present a comprehensive overview of your contributions and growth, particularly useful during performance reviews, interviews, or when you're negotiating promotions and raises. Tie that with your resume to make a strong case for your next promotion.",
              "digitalMedalIs": "What a Digital Medal Is:",
              "digitalMedalIncludes": "What a Digital Medal Includes:",
              "accomplishments": "Accomplishments",
              "accomplishmentsText": "A list of your key professional achievements, often backed by data (metrics, percentages, etc.) to quantify your success.",
              "projects": "Projects",
              "projectsText": "An overview of the projects you've worked on, your role in each, responsibilities, challenges, and the impact your work had.",
              "feedback": "Feedback",
              "feedbackText": "Collect feedback from colleagues, managers, and clients—both positive and constructive. Track who gave you the feedback and what they said.",
              "skillsDevelopment": "Skills Development",
              "skillsDevelopmentText": "Any new skills or certifications you've acquired, and how these have been applied to your work.",
              "technologyDevelopment": "Technology Development",
              "technologyDevelopmentText": "Any new technologies, languages, frameworks, or libraries you've learned, and how these have been applied to your work.",
              "futureGoals": "Future Goals",
              "futureGoalsText": "A forward-looking section that sets out your professional goals for the next review period or the future in general, helping you track progress."
            },
            "proficiency": {
              "beginner": "Beginner",
              "intermediate": "Intermediate",
              "advanced": "Advanced",
              "expert": "Expert"
            },
            "password": {
              "email": "Email Address",
              "resetSuccess": "Password reset successful!",
              "resetFailed": "Failed to reset password",
              "resetTitle": "Reset Password",
              "newPassword": "New Password",
              "confirmPassword": "Confirm New Password",
              "resetButton": "Reset Password",
              "redirectingToLogin": "Password reset successful! Redirecting to login...",
              "resetPassword": "Reset Password",
              "backTo": "Back to",
              "signIn": "Sign In",
              "invalidResetLink": "Invalid password reset link",
              "tryAgain": "Try again"
            },
            "email": {
              "resendVerificationSuccess": "Verification email has been sent successfully",
              "resendVerificationFailed": "Failed to send verification email",
              "verificationFailed": "Email verification failed",
              "verificationSuccess": "Email verified successfully"
            },
            "registration": {
              "failed": "Registration failed. Please try again"
            },
            "goals": {
              "title": "Goals",
              "description": "Description",
              "objective": "Objective",
              "actionPlan": "Action Plan",
              "relevance": "Relevance",
              "targetDate": "Target Date",
              "progress": "Progress",
              "priority": "Priority",
              "successCriteria": "Success Criteria",
              "addNew": "Add New Goal",
              "edit": "Edit",
              "delete": "Delete",
              "save": "Save",
              "cancel": "Cancel",
              "noGoals": "No goals added yet.",
              
              // Progress options
              "notStarted": "Not Started",
              "inProgress": "In Progress",
              "completed": "Completed",
              
              // Priority levels
              "low": "Low",
              "medium": "Medium",
              "high": "High",
              
              // Form placeholders
              "descriptionPlaceholder": "Enter goal description",
              "objectivePlaceholder": "Enter goal objective",
              "actionPlanPlaceholder": "Enter action plan",
              "relevancePlaceholder": "Enter goal relevance",
              "successCriteriaPlaceholder": "Enter success criteria",
              
              // Messages
              "deleteConfirmTitle": "Delete Goal",
              "deleteConfirmMessage": "Are you sure you want to delete this goal?",
              "deleteConfirmation": "Are you sure you want to delete this goal?",
              "createSuccess": "Goal created successfully",
              "updateSuccess": "Goal updated successfully",
              "deleteSuccess": "Goal deleted successfully",
              
              // Toast messages
              "created": "Goal created successfully",
              "updated": "Goal updated successfully",
              "deleted": "Goal deleted successfully",
              "createError": "Failed to create goal",
              "updateError": "Failed to update goal",
              "deleteError": "Failed to delete goal"
            },
            "certifications": {
              "title": "Certifications & Degrees",
              "addNew": "Add New Certification",
              "name": "Certification/Degree Name",
              "institution": "Issuing Institution",
              "completionDate": "Date of Completion",
              "expirationDate": "Expiration Date",
              "description": "Description",
              "credentialId": "Credential ID",
              "verificationUrl": "Verification URL",
              "verify": "Verify",
              "noCertifications": "No certifications or degrees added yet.",
              "dateOfCompletion": "Date of Completion",
              "inProgress": "In Progress",
              "deleteConfirmTitle": "Delete Certification",
              "deleteConfirmMessage": "Are you sure you want to delete this certification? This action cannot be undone.",
              "deleteSuccess": "Certification deleted successfully",
              "deleteError": "Failed to delete certification",
              "created": "Certification created successfully",
              "updated": "Certification updated successfully",
              "deleted": "Certification deleted successfully",
              "createError": "Failed to create certification",
              "updateError": "Failed to update certification",
              "credentialNumber": "Credential Number"
            },
            "medals": {
              "information": "Medal Information",
              "description": "Medals are essential for tracking your growth and accomplishments. It allows you to document successes, roles, and challenges faced, giving you a clear picture of your contributions. This not only helps with performance reviews and career development but also builds confidence by reflecting on the progress you've made and the value you bring to every project.",
              "title": "Title",
              "titlePlaceholder": "Enter title",
              "startDate": "Start Date",
              "endDate": "End Date",
              "category": "Category",
              "selectCategory": "Select Category",
              "associateGoal": "Associate Goal",
              "optional": "(optional)",
              "objective": "Objective",
              "goalSelectionHelp": "Click on any goal to select/deselect. You can select multiple goals.",
              "createSuccess": "Medal created successfully",
              "createError": "Failed to create medal",
              "updateSuccess": "Medal updated successfully",
              "updateError": "Failed to update medal",
              "deleteSuccess": "Medal deleted successfully",
              "deleteError": "Failed to delete medal",
              "basicInfo": "Basic Info",
              "projects": "Projects",
              "lessonsLearned": "Lessons Learned",
              "feedback": "Add Feedback",
              "feedbackDescription": "Including feedback is vital for growth. It highlights areas where you've excelled and provides insight into where you can improve. Constructive feedback helps refine your skills, while positive feedback reinforces your strengths. Documenting both creates a well-rounded view of your performance and shows your commitment to continuous improvement.",
              "feedbackPositive": "Positive Feedback",
              "feedbackConstructive": "Constructive Feedback",
              "feedbackGivenBy": "Given By",
              "feedbackGivenByPlaceholder": "Given By (or leave blank for yourself)",
              "feedbackGivenByDefault": "Myself",
              "add": "Add",
              "delete": "Delete",
              "notAvailable": "N/A",
              
              // Project related
              "projectName": "Project Name",
              "projectRole": "Role",
              "projectResponsibilities": "Responsibilities",
              "projectChallenges": "Challenges",
              "projectSkills": "Skills",
              "projectTechnologies": "Technologies",
              
              // Accomplishment related
              "accomplishmentTitle": "Accomplishment",
              "accomplishmentDescription": "Description",
              
              // Feedback related
              
              // Lessons learned
             
              
              // Review
              "reviewTitle": "Review Your Medal",
              "saveAndFinish": "Save and Finish",
              "goBack": "Go Back",
              "reviewAndSave": "Review and Save",
              
              // Messages
              "savingMedal": "Saving medal...",
              "medalSaved": "Medal saved successfully",
              "errorSaving": "Error saving medal",
              "confirmNavigation": "Are you sure you want to leave? Your changes will be lost.",
              "back": "Back",
              "skip": "Skip",
              "next": "Next",
             
              
              "noGoalsYet": "No goals yet. Create one!",
              "createNew": "Create New"
            },
            "accomplishments": {
              "word" : "Accomplishments",
              "addAccomplishments": "Add Accomplishments",
              "description": "Success is a journey, not a destination. It's about celebrating milestones and learning from the challenges along the way. Acknowledging hard work and dedication paves the path to future accomplishments.",
              "placeholder": "Accomplishment",
              "add": "Add",
              "title": "Accomplishments",
              "addAccomplishment": "Add Accomplishment",
              "noAccomplishments": "No accomplishments added. Add them!"
            },
            "common": {
              "back": "Back",
              "next": "Next",
              "skip": "Skip",
              "cancel": "Cancel",
              "save": "Save",
              "delete": "Delete",
              "edit": "Edit",
              "add": "Add",
            },
            "skillsAndTech": {
              "title": "Add Skills and Technologies",
              "description": "Select the skills and technologies you've used or improved during this achievement. This helps showcase your technical growth and expertise.",
              "skills": "Skills",
              "technologies": "Technologies",
              "searchOrAdd": "Search or add...",
              "proficiencyLevels": {
                "beginner": "Beginner",
                "intermediate": "Intermediate",
                "advanced": "Advanced",
                "expert": "Expert"
              }
            },
            "projects": {
              "addProjects": "Add Projects",
              "description": "Recognizing the project itself is key to understanding the full scope of success. Each project highlights the roles, responsibilities, and challenges faced along the way. By documenting these, we are making each achievement more meaningful and paving the way for future growth.",
              "projectName": "Project Name",
              "role": "Role",
              "challengesFaced": "Challenges Faced",
              "addProject": "Add",
              "deleteProject": "Delete",
              "back": "Back",
              "skip": "Skip",
              "next": "Next",
              "details": {
                "role": "Role",
                "responsibilities": "Responsibilities",
                "challenges": "Challenges Faced",
                "skills": "Skills",
                "technologies": "Technologies"
              },
              "title": "Projects",
              "addNew": "Add New Project",
              "edit": "Edit Project",
              "delete": "Delete Project",
              "skills": "Skills",
              "technologies": "Technologies",
              "responsibilities": "Responsibilities",
              "challenges": "Challenges",
              "achievements": "Achievements",
              "name": "Name",
              "selectExisting": "Select Existing Projects",
              "selectExistingPlaceholder": "Choose from your existing projects",
              "orAddNew": "Or Add a New Project",
              "deleteConfirmMessage": "Are you sure you want to delete this project?",
              "deleteConfirmTitle": "Delete Project",
              "namePlaceholder": "Enter project name",
              "rolePlaceholder": "Enter your role",
              "responsibilitiesPlaceholder": "Enter your responsibilities",
              "challengesPlaceholder": "Enter challenges faced",
              "skillsPlaceholder": "Search or select skills",
              "technologiesPlaceholder": "Search or select technologies",
              "startDate": "Start Date",
              "endDate": "End Date",
              "noProjects": "No projects added. What did you do? Please make sure to add them.",
              
            },
            "reviewAndSave": {
              "title": "Review and Save",
              "description": "Reviewing is a crucial step in showcasing your achievements. It ensures that the information is accurate, organized, and reflective of your growth. Sharing it not only highlights your successes but also demonstrates professionalism, attention to detail, and a commitment to personal and career development. It's a powerful tool for building credibility and advancing your career.",
              "back": "Back",
              "save": "Save",
              "medalInfo": {
                "title": "Medal Information",
                "timeFrame": "Time Frame",
                "category": "Category",
                "associatedGoals": "Associated Goals",
                "due": "Due"
              }
            },
            "lessonsLearned": {
              "title": "Lessons Learned",
              "noLessons": "No lessons learned? Really? Surely, you must have learned something.",
              "addLessonsLearned": "Add Lessons Learned",
              "addLesson": "Add Lesson",
              "lessonsLearnedDescription": "Every accomplishment comes with a set of lessons learned. It is important to reflect on these lessons and use them to improve future performance. Lessons learned can help identify strengths, weaknesses, opportunities, and threats, which can be used to develop strategies for future success.",
              "lessonLearnedPlaceholder": "Lesson Learned",
              "lessonsTitle": "Lessons Learned",
              "lessonsDescription": "What did you learn?",
            },
            "feedback": {
              "title": "Feedback",
              "noFeedback": "No feedback added. I'm sure somebody said something. Add them!",
              "positive": "Positive",
              "negative": "Negative",
              "givenBy": "Given By"
            }
          }
        },
        es: {
          translation: {
            "settings": {
              "nav": {
                "general": "General",
                "security": "Seguridad",
                "profile": "Perfil",
                "employments": "Empleos",
                "social": "Social",
                "goals": "Objetivos",
                "projects": "Proyectos",
                "certifications": "Certificaciones"
              },
              "title": "Configuración",
              "general": "General",
              "security": "Seguridad",
              "fullName": "Nombre Completo",
              "emailAddress": "Correo Electrónico",
              "timezone": "Zona Horaria",
              "language": "Idioma",
              "saveChanges": "Guardar Cambios",
              "newPassword": "Nueva Contraseña",
              "confirmNewPassword": "Confirmar Nueva Contraseña",
              "updatePassword": "Actualizar Contraseña",
              "passwordRequirements": "La contraseña debe tener al menos 8 caracteres, incluir 1 letra mayúscula, 1 símbolo y 1 número",
              "passwordsDoNotMatch": "Las contraseñas no coinciden",
              "passwordMinLength": "Mínimo 8 caracteres",
              "passwordLowercase": "Contiene letra minúscula",
              "passwordUppercase": "Contiene letra mayúscula",
              "passwordNumbers": "Contiene número",
              "passwordSpecialChars": "Contiene carácter especial",
              "profile": "Perfil",
              "headline": "Titular",
              "description": "Descripción",
              "profileUpdateSuccess": "Perfil actualizado exitosamente",
              "profileUpdateFailed": "Error al actualizar el perfil",
              "employments": "Empleos",
              "dateLocale": "es-ES",
              "social": "Social",
              "socialLinks": "Enlaces Sociales",
              "addNewSocialLink": "Agregar Nuevo Enlace Social",
              "updateSocialLink": "Actualizar Enlace Social",
              "deleteSocialLink": "Eliminar Enlace Social",
              "socialLinkUpdated": "Enlace social actualizado exitosamente",
              "socialLinkDeleted": "Enlace social eliminado exitosamente",
              "socialLinkAdded": "Enlace social agregado exitosamente",
              "errorUpdatingSocialLink": "Error al actualizar enlace social",
              "errorDeletingSocialLink": "Error al eliminar enlace social",
              "errorAddingSocialLink": "Error al agregar enlace social",
              "headlinePlaceholder": "Ingrese su titular",
              "descriptionPlaceholder": "Ingrese su descripción",
              "skills": "Habilidades",
              "technology": "Tecnología",
              "username": "Nombre de usuario",
              "usernamePlaceholder": "Ingrese su nombre de usuario",
              "passwordSuccess": "Contraseña actualizada exitosamente",
              "passwordError": "Error al actualizar la contraseña",
              "generalSuccess": "Configuración general actualizada exitosamente",
              "generalError": "Error al actualizar la configuración general",
              "profileSuccess": "Perfil actualizado exitosamente",
              "profileError": "Error al actualizar el perfil",
              "emailVerified": "Correo electrónico verificado",
              "resendVerification": "Reenviar correo de verificación",
              "verificationEmailSent": "Correo de verificación enviado",
              "goals": "Objetivos"
            },
            "languages": {
              "english": "Inglés",
              "spanish": "Español",
              "french": "Francés"
            },
            "Language": "Idioma",
            "English": "Inglés",
            "Spanish": "Español",
            "French": "Francés",
            "Save changes": "Guardar cambios",
            "Save": "Guardar",
            "Cancel": "Cancelar",
            "Name not set": "Nombre no establecido",
            "Skills": "Habilidades",
            "Technologies": "Tecnologías",
            "About Me": "Sobre Mí",
            "Find me on": "Encuéntrame en",
            "Experience": "Experiencia",
            "Error": "Error",
            "skills": {
              "loading": "Cargando habilidades...",
              "noSkills": "No hay habilidades disponibles.",
              "title": "Habilidades Que Te Distinguen",
              "addSkills": "Agregar Tus Habilidades",
              "searchOrAdd": "Buscar o agregar una habilidad...",
              "yourSkillArsenal": "Tu Arsenal de Habilidades",
              "sortAZ": "A-Z",
              "sortProficiency": "Nivel de Competencia",
              "updateSuccess": "Habilidad actualizada exitosamente",
              "updateUnexpectedFailure": "Fallo inesperado al actualizar habilidad",
              "updateFailure": "Error al actualizar habilidad",
              "removeSuccess": "Habilidad eliminada exitosamente",
              "removeFailure": "Error al eliminar habilidad",
              "createSuccess": "Habilidad creada exitosamente",
              "createUnexpectedFailure": "Fallo inesperado al crear habilidad",
              "createFailure": "Error al crear habilidad",
              "clickOnSkillToEdit": "Haz clic en una habilidad para editarla"
            },
            "technologies": {
              "word": "Tecnologías",
              "loading": "Cargando tecnologías...",
              "noTechnologies": "No hay tecnologías disponibles.",
              "title": "Tecnología Que Te Impulsa",
              "addTechnologies": "Agregar Tus Tecnologías",
              "searchOrAdd": "Buscar o agregar una tecnología...",
              "yourTechnologyToolkit": "Tu Kit de Herramientas Tecnológicas",
              "sortAZ": "A-Z",
              "sortProficiency": "Nivel de Competencia",
              "updateSuccess": "Tecnología actualizada exitosamente",
              "updateUnexpectedFailure": "Fallo inesperado al actualizar tecnología",
              "updateFailure": "Error al actualizar tecnología",
              "removeSuccess": "Tecnología eliminada exitosamente",
              "removeFailure": "Error al eliminar tecnología",
              "createSuccess": "Tecnología creada exitosamente",
              "createUnexpectedFailure": "Fallo inesperado al crear tecnología",
              "createFailure": "Error al crear tecnología",
              "clickOnTechnologyToEdit": "Haz clic en una tecnología para editarla"
            },
            "Digital Medals": "Medallas Digitales",
            "Dashboard": "Panel de Control",
            "Profile": "Perfil",
            "Settings": "Configuración",
            "Users": "Usuarios",
            "View notifications": "Ver notificaciones",
            "Notifications": "Notificaciones",
            "New message": "Nuevo mensaje",
            "John Doe sent you a message": "John Doe te envió un mensaje",
            "New task assigned": "Nueva tarea asignada",
            "You have been assigned a new task": "Se te ha asignado una nueva tarea",
            "Your Profile": "Tu Perfil",
            "Sign out": "Cerrar sesión",
            "Login": "Iniciar sesión",
            "Register": "Registrarse",
            "All rights reserved": "Todos los derechos reservados",
            "loading": {
              "userData": "Cargando datos del usuario..."
            },
            "dashboard": {
              "title": "Panel de Control",
              "welcome": "¡Bienvenido, {{name}}!",
              "viewProfile": "Ver Perfil",
              "medals": "Medallas",
              "skills": "Habilidades",
              "manageSkills": "Gestionar habilidades",
              "technologies": "Tecnologías",
              "manageTechnologies": "Gestionar tecnologías",
              "employments": "Empleos",
              "manageEmployments": "Gestionar empleos",
              "manageMedals": "Gestionar medallas",
              "projects": "Proyectos",
              "manageProjects": "Gestionar proyectos",
              "projectsSkills": "Habilidades del Proyecto",
              "manageProjectsSkills": "Gestionar habilidades del proyecto",
              "projectsTechnologies": "Tecnologías del Proyecto",
              "manageProjectsTechnologies": "Gestionar tecnologías del proyecto",
              "latestMedals": "Últimas Medallas",
              "topSkills": "Principales Habilidades",
              "topTechnologies": "Principales Tecnologías",
              "lastLogin": "Último inicio de sesión",
              "noMedals": "No hay medallas disponibles.",
              "addMedals": "Agregar Medallas",
              "noSkills": "No hay habilidades disponibles.",
              "noTechnologies": "No hay tecnologías disponibles.",
              "addSkills": "Agregar Habilidades",
              "addTechnologies": "Agregar Tecnologías",
              "checklistTitle": "Lista de Configuración",
              "progressTitle": "Seguimiento de Tu Progreso",
              "addSkillsChecklist": "Agregar tus habilidades profesionales",
              "addTechnologiesChecklist": "Agregar tus habilidades técnicas",
              "addMedalsChecklist": "Agregar tus logros",
              "addEmploymentsChecklist": "Agregar tu historial laboral"
            },
            "employments": {
              "title": "Historial Laboral",
              "addNew": "Agregar Nuevo Empleo",
              "edit": "Editar Empleo",
              "delete": "Eliminar Empleo",
              "employer": "Empleador",
              "position": "Cargo",
              "startDate": "Fecha de Inicio",
              "endDate": "Fecha de Finalización",
              "currentPosition": "Cargo Actual",
              "description": "Descripción",
              "save": "Guardar Empleo",
              "cancel": "Cancelar",
              "deleteConfirmation": "¿Estás seguro de que deseas eliminar este empleo?",
              "noEmployments": "Aún no se ha agregado historial laboral.",
              "present": "Presente",
              "searchOrAddEmployer": "Buscar o agregar empleador...",
              "searchOrAddTitle": "Buscar o agregar título...",
              "updateSuccess": "Empleo actualizado exitosamente",
              "updateUnexpectedFailure": "Fallo inesperado al actualizar empleo",
              "updateFailure": "Error al actualizar empleo",
              "addUnexpectedFailure": "Fallo inesperado al agregar empleo",
              "deleteSuccess": "Empleo eliminado exitosamente",
              "addSuccess": "Empleo agregado exitosamente",
              "createEmployerSuccess": "Empleador creado exitosamente",
              "createEmployerUnexpectedFormat": "Formato de respuesta inesperado al crear empleador",
              "createEmployerFailure": "Error al crear empleador",
              "createTitleSuccess": "Título creado exitosamente",
              "createTitleUnexpectedFailure": "Fallo inesperado al crear título",
              "createTitleFailure": "Error al crear título",
              "deleteConfirmTitle": "Eliminar Empleo",
              "deleteConfirmMessage": "¿Estás seguro de que deseas eliminar este registro de empleo? Esta acción no se puede deshacer."
            },
            "userInfo": {
              "fetchError": "Error al obtener la última información del usuario",
              "passwordUpdateSuccess": "Contraseña del usuario actualizada exitosamente",
              "passwordUpdateError": "Error al actualizar la contraseña del usuario",
              "nameUpdateSuccess": "Nombre del usuario actualizado exitosamente",
              "nameUpdateError": "Error al guardar el nombre del usuario",
              "profileUpdateSuccess": "Perfil del usuario actualizado exitosamente",
              "profileUpdateError": "Error al guardar el perfil del usuario",
              "headlineUpdateSuccess": "Titular del usuario actualizado exitosamente",
              "headlineUpdateError": "Error al guardar el titular del usuario",
              "descriptionUpdateSuccess": "Descripción del usuario actualizada exitosamente",
              "descriptionUpdateError": "Error al guardar la descripción del usuario"
            },
            "login": {
              "title": "Iniciar Sesión",
              "emailLabel": "Correo Electrónico",
              "passwordLabel": "Contraseña",
              "rememberMe": "Recordarme",
              "forgotPassword": "¿Olvidaste tu contraseña?",
              "signIn": "Iniciar Sesión",
              "notAMember": "¿No eres miembro?",
              "signUp": "Regístrate ahora",
              "invalidEmail": "Correo electrónico inválido",
              "passwordMinLength": "La contraseña debe tener al menos 8 caracteres",
              "loginSuccessful": "Inicio de sesión exitoso",
              "loginFailed": "Error al iniciar sesión",
              "orContinueWith": "O continúa con",
              "orRegisterWith": "O regístrate con",
              "loginWithGoogle": "Google",
              "registerWithGoogle": "Google",
              "googleLoginFailed": "Error al autenticar con Google",
              "googleLoginSuccessful": "Autenticación con Google exitosa",
              "processingLogin": "Procesando tu inicio de sesión...",
              "googleLoginRedirect": "Redirigiendo a Google para autenticación...",
              "googleLoginError": "Ocurrió un error durante la autenticación con Google. Por favor, intenta de nuevo.",
              "loginWithLinkedIn": "LinkedIn",
              "registerWithLinkedIn": "LinkedIn",
            },
            "register": {
              "createAccount": "Crear una cuenta",
              "name": "Name",
              "namePlaceholder": "John Doe",
              "email": "Email",
              "emailPlaceholder": "john@example.com",
              "password": "Password",
              "passwordPlaceholder": "********",
              "confirmPassword": "Confirm Password",
              "confirmPasswordPlaceholder": "********",
              "register": "Registrarse",
              "alreadyHaveAccount": "¿Ya tienes una cuenta?",
              "signIn": "Iniciar sesión",
              "registrationSuccessful": "Registro exitoso",
              "registrationFailed": "Registro fallido. Por favor, intenta de nuevo.",
              "nameMinLength": "El nombre debe tener al menos 2 caracteres",
              "invalidEmail": "Correo electrónico inválido",
              "passwordMinLength": "La contraseña debe tener al menos 8 caracteres",
              "passwordsDontMatch": "Las contraseñas no coinciden"
            },
            "forgotPassword": {
              "title": "¿Olvidaste tu contraseña?",
              "instruction": "Ingresa tu correo electrónico y te enviaremos un enlace para restablecer tu contraseña.",
              "emailLabel": "Correo electrónico",
              "emailPlaceholder": "Correo electrónico",
              "sending": "Enviando...",
              "sendResetLink": "Enviar enlace de restablecimiento",
              "emailSent": "Correo de restablecimiento de contraseña enviado. Por favor, revisa tu bandeja de entrada.",
              "error": "Ocurrió un error. Por favor, intenta de nuevo.",
              "rememberPassword": "¿Recuerdas tu contraseña?",
              "signIn": "Iniciar sesión"
            },
            "home": {
              "welcomeTo": "Bienvenido a",
              "elevatingAchievements": "Elevando Logros en el Arena Digital",
              "boostCareer": "¡Impulsa Tu Carrera Hoy!",
              "unlockPotential": "Comienza a usar nuestra aplicación y desbloquea tu potencial",
              "getStarted": "Comenzar",
              "trackThings": "Mantén un registro de todo lo que vale la pena seguir.",
              "improvePerformance": "Mejora tus evaluaciones de desempeño",
              "improveResume": "Mejora tu currículum",
              "improveYourself": "Mejórate a ti mismo.",
              "login": "INICIAR SESIÓN",
              "register": "REGISTRARSE →",
              "whatAreDigitalMedals": "Qué son las Medallas Digitales",
              "whatAre": "Qué Son",
              "whatAreThey": "Qué Son",
              "whyUseThem": "Por Qué Usarlas",
              "whatIsIt": "Una Medalla Digital es un registro personal y vivo de tus logros profesionales, habilidades y retroalimentación.",
              "whatIsItUsedFor": "Para Qué se Usa una Medalla Digital y Por Qué",
              "why": "Por Qué ",
              "you": "Tú ",
              "whyYou": "Por Qué Tú ",
              "keep": "Mantener ",
              "should": "Deberías ",
              "shouldKeep": "Deberías Mantener ",
              "whyYouShouldKeep": "Por Qué Deberías Mantener ",
              "whyUseDigitalMedals": "Por Qué Usar Medallas Digitales ",
              "whatIsItUsedForWhy": "Es una herramienta diseñada para capturar tanto logros pequeños como significativos mientras suceden. ",
              "whyItsImportant": "Ya sea un proyecto que completaste, retroalimentación de un gerente o una habilidad que dominaste, una Medalla Digital mantiene todo en un solo lugar, permitiéndote mostrar tu crecimiento profesional a lo largo del tiempo. Mantén todos tus Logros, Retroalimentación, Habilidades y más en un solo lugar.",
              "whyItsGoodToUse": "Las Medallas Digitales sirven como una herramienta motivacional, recordándote tu progreso y construyendo confianza cuando te sientes estancado o abrumado.",
              "whyItsImportant3": "Ayuda a alinear tu trabajo con objetivos profesionales a largo plazo, mostrando cómo los logros actuales contribuyen al éxito futuro.",
              "jobInterviews": "Entrevistas de Trabajo",
              "jobInterviewsText": "Al aplicar a nuevos roles, puedes extraer ejemplos específicos de tus Medallas Digitales para resaltar experiencia relevante, mejorando tus posibilidades de éxito.",
              "performanceReviews": "Evaluaciones de Desempeño",
              "performanceReviewsText": "Durante las evaluaciones, necesitas mostrar ejemplos concretos de tus contribuciones. Una Medalla Digital te permite reunir fácilmente los logros, demostrando tu valor para la organización",
              "resume": "Currículum",
              "resumeText": "Al aplicar a nuevos roles, puedes extraer ejemplos específicos de tus Medallas Digitales para resaltar experiencia relevante, mejorando tus posibilidades de éxito.",
              "careerProgression": "Progresión Profesional",
              "careerProgressionText": "Al rastrear tus logros en relación con la escala profesional de tu empresa, puedes presentar un caso más sólido para promociones o aumentos.",
              "accurateReflection": "Reflexión Precisa",
              "accurateReflectionText": "Con el tiempo, es fácil olvidar de nombreuses petites contributions que vous avez faites. Tenir une Médaille Numérique garantit qu'aucun effort n'est perdu ou négligé lors des évaluations ou des candidatures.",
              "visibility": "Visibilidad",
              "visibilityText": "Compartir tu Medalla Digital (o des éléments de celle-ci) avec votre manager aide à garantir que votre travail est reconnu, même s'il n'est pas toujours immédiatement visible.",
              "careerStrategy": "Estrategia Profesional",
              "careerStrategyText": "Cela aide à aligner votre travail sur des objectifs de carrière à long terme, montrant comment les réalisations actuelles contribuent aux aspirations futures.",
              "whatAreDigitalMed": "Qué Son les Médailles Numériques",
              "digitalMedalTitle": "Médaille Numérique",
              "digitalMedalSubtitle": "Un registre personnel structuré où vous suivez vos réalisations professionnelles, projets, retours et objectifs de carrière.",
              "digitalMedalDescription": "Cela sert d'outil pour vous aider à présenter une vue d'ensemble de vos contributions et de votre croissance, particulièrement utile lors des évaluations de performance, des entretiens ou lorsque vous négociez des promotions et des augmentations. Associez cela à votre CV pour présenter un argument solide pour votre prochaine promotion."
            },
            "proficiency": {
              "beginner": "Principiante",
              "intermediate": "Intermedio",
              "advanced": "Avanzado",
              "expert": "Experto"
            },
            "password": {
              "email": "Correo Electrónico",
              "resetSuccess": "¡Restablecimiento de contraseña exitoso!",
              "resetFailed": "Error al restablecer la contraseña",
              "resetTitle": "Restablecer Contraseña",
              "newPassword": "Nueva Contraseña",
              "confirmPassword": "Confirmar Nueva Contraseña",
              "resetButton": "Restablecer Contraseña",
              "redirectingToLogin": "¡Contraseña restablecida exitosamente! Redirigiendo al inicio de sesión...",
              "resetPassword": "Restablecer Contraseña",
              "backTo": "Volver a",
              "signIn": "Iniciar Sesión",
              "invalidResetLink": "Enlace de restablecimiento de contraseña inválido",
              "tryAgain": "Intentar de nuevo"
            },
            "email": {
              "resendVerificationSuccess": "Correo de verificación enviado exitosamente",
              "resendVerificationFailed": "Failed to send verification email",
              "verificationFailed": "Email verification failed",
              "verificationSuccess": "Email verified successfully"
            },
            "registration": {
              "failed": "Registration failed. Please try again"
            },
            "goals": {
              "title": "Objetivos",
              "description": "Descripción",
              "objective": "Objetivo",
              "actionPlan": "Plan de Acción",
              "relevance": "Relevancia",
              "targetDate": "Fecha Objetivo",
              "progress": "Progreso",
              "priority": "Prioridad",
              "successCriteria": "Criterios de Éxito",
              "addNew": "Agregar Nuevo Objetivo",
              "edit": "Editar",
              "delete": "Eliminar",
              "save": "Guardar",
              "cancel": "Cancelar",
              "noGoals": "Aún no se han agregado objetivos.",
              "notStarted": "No Iniciado",
              "inProgress": "En Progreso",
              "completed": "Completado",
              "low": "Baja",
              "medium": "Media",
              "high": "Alta",
              "descriptionPlaceholder": "Ingresa la descripción del objetivo",
              "objectivePlaceholder": "Ingresa el objetivo",
              "actionPlanPlaceholder": "Ingresa el plan de acción",
              "relevancePlaceholder": "Ingresa la relevancia del objetivo",
              "successCriteriaPlaceholder": "Ingresa los criterios de éxito",
              "deleteConfirmTitle": "Eliminar Objetivo",
              "deleteConfirmMessage": "¿Estás seguro de que deseas eliminar este objetivo?",
              "createSuccess": "Objetivo creado exitosamente",
              "updateSuccess": "Objetivo actualizado exitosamente",
              "deleteSuccess": "Objetivo eliminado exitosamente",
              "created": "Objetivo creado exitosamente",
              "updated": "Objetivo actualizado exitosamente",
              "deleted": "Objetivo eliminado exitosamente",
              "createError": "Error al crear objetivo",
              "updateError": "Error al actualizar objetivo",
              "deleteError": "Error al eliminar objetivo"
            },
            "certifications": {
              "title": "Certificaciones y Títulos",
              "addNew": "Agregar Nueva Certificación",
              "name": "Nombre de Certificación/Título",
              "institution": "Institución Emisora",
              "completionDate": "Fecha de Finalización",
              "expirationDate": "Fecha de Vencimiento",
              "description": "Descripción",
              "credentialId": "ID de Credencial",
              "verificationUrl": "URL de Verificación",
              "verify": "Verificar",
              "noCertifications": "Aún no se han agregado certificaciones o títulos.",
              "dateOfCompletion": "Fecha de Finalización",
              "inProgress": "En Progreso",
              "deleteConfirmTitle": "Eliminar Certificación",
              "deleteConfirmMessage": "¿Estás seguro de que deseas eliminar esta certificación? Esta acción no se puede deshacer.",
              "deleteSuccess": "Certificación eliminada exitosamente",
              "deleteError": "Error al eliminar certificación",
              "created": "Certificación creada exitosamente",
              "updated": "Certificación actualizada exitosamente",
              "deleted": "Certificación eliminada exitosamente",
              "createError": "Error al crear certificación",
              "updateError": "Error al actualizar certificación",
              "credentialNumber": "Número de Credencial"
            },
            "medals": {
              "information": "Información de la Medalla",
              "description": "Las medallas son esenciales para rastrear tu crecimiento y logros. Te permite documentar éxitos, roles y desafíos enfrentados, dándote una imagen clara de tus contribuciones. Esto no solo ayuda con las evaluaciones de desempeño y el desarrollo profesional, sino que también construye confianza al reflexionar sobre el progreso que has logrado y el valor que aportas a cada proyecto.",
              "title": "Título",
              "titlePlaceholder": "Ingresa título",
              "startDate": "Fecha de Inicio",
              "endDate": "Fecha de Finalización",
              "category": "Categoría",
              "selectCategory": "Seleccionar Categoría",
              "associateGoal": "Asociar Objetivo",
              "optional": "(opcional)",
              "objective": "Objetivo",
              "goalSelectionHelp": "Haz clic en cualquier objetivo para seleccionar/deseleccionar. Puedes seleccionar múltiples objetivos.",
              "createSuccess": "Medalla creada exitosamente",
              "createError": "Failed to create medal",
              "updateSuccess": "Medalla actualizada exitosamente",
              "updateError": "Failed to update medal",
              "deleteSuccess": "Medalla eliminada exitosamente",
              "deleteError": "Failed to delete medal",
              "basicInfo": "Información Básica",
              "projects": "Proyectos",
              "lessonsLearned": "Lecciones Aprendidas",
              "feedback": "Agregar Retroalimentación",
              "feedbackDescription": "Incluir retroalimentación es vital para el crecimiento. Destaca áreas donde has sobresalido y proporciona información sobre dónde puedes mejorar. La retroalimentación constructiva ayuda a refinar tus habilidades, mientras que la retroalimentación positiva refuerza tus fortalezas. Documentar ambas crea una visión integral de tu desempeño y muestra tu compromiso con la mejora continua.",
              "feedbackPositive": "Retroalimentación Positiva",
              "feedbackConstructive": "Retroalimentación Constructiva",
              "feedbackGivenBy": "Proporcionado Por",
              "feedbackGivenByPlaceholder": "Proporcionado Por (o deja en blanco para ti mismo)",
              "feedbackGivenByDefault": "Yo mismo",
              "add": "Agregar",
              "delete": "Eliminar",
              "notAvailable": "N/D",
              "projectName": "Nombre del Proyecto",
              "projectRole": "Rol",
              "projectResponsibilities": "Responsabilidades",
              "projectChallenges": "Desafíos",
              "projectSkills": "Habilidades",
              "projectTechnologies": "Tecnologías",
              "accomplishmentTitle": "Logro",
              "accomplishmentDescription": "Descripción",
              "reviewTitle": "Revisar Tu Medalla",
              "saveAndFinish": "Guardar y Finalizar",
              "goBack": "Volver",
              "reviewAndSave": "Revisar y Guardar",
              "savingMedal": "Guardando medalla...",
              "medalSaved": "Medalla guardada exitosamente",
              "errorSaving": "Error al guardar medalla",
              "confirmNavigation": "¿Estás seguro de que deseas salir? Tus cambios se perderán.",
              "back": "Atrás",
              "skip": "Omitir",
              "next": "Siguiente",
              "noGoalsYet": "No goals yet. Create one!",
              "createNew": "Crear Nuevo"
            },
            "accomplishments": {
              "word": "Logros",
              "addAccomplishments": "Agregar Logros",
              "description": "El éxito es un viaje, no un destino. Se trata de celebrar hitos y aprender de los desafíos en el camino. Reconocer el trabajo duro y la dedicación pavimenta el camino hacia futuros logros.",
              "placeholder": "Logro",
              "add": "Agregar",
              "title": "Logros",
              "addAccomplishment": "Agregar Logro",
              "noAccomplishments": "No hay logros agregados. ¡Agrégalos!"
            },
            "common": {
              "back": "Atrás",
              "next": "Siguiente",
              "skip": "Omitir",
              "cancel": "Cancelar",
              "save": "Guardar",
              "delete": "Eliminar",
              "edit": "Editar",
              "add": "Agregar"
            },
            "skillsAndTech": {
              "title": "Agregar Habilidades y Tecnologías",
              "description": "Selecciona las habilidades y tecnologías que has usado o mejorado durante este logro. Esto ayuda a mostrar tu crecimiento técnico y experiencia.",
              "skills": "Habilidades",
              "technologies": "Tecnologías",
              "searchOrAdd": "Buscar o agregar...",
              "proficiencyLevels": {
                "beginner": "Principiante",
                "intermediate": "Intermedio",
                "advanced": "Avanzado",
                "expert": "Experto"
              }
            },
            "projects": {
              "addProjects": "Agregar Proyectos",
              "description": "Reconocer el proyecto en sí es clave para entender el alcance completo del éxito. Cada proyecto destaca los roles, responsabilidades y desafíos enfrentados en el camino. Al documentar estos, hacemos que cada logro sea más significativo y pavimentamos el camino para el crecimiento futuro.",
              "projectName": "Nombre del Proyecto",
              "role": "Rol",
              "challengesFaced": "Desafíos Enfrentados",
              "addProject": "Agregar",
              "deleteProject": "Eliminar",
              "back": "Atrás",
              "skip": "Omitir",
              "next": "Siguiente",
              "details": {
                "role": "Rol",
                "responsibilities": "Responsabilidades",
                "challenges": "Desafíos Enfrentados",
                "skills": "Habilidades",
                "technologies": "Tecnologías"
              },
              "title": "Proyectos",
              "addNew": "Agregar Nuevo Proyecto",
              "edit": "Editar Proyecto",
              "delete": "Eliminar Proyecto",
              "skills": "Habilidades",
              "technologies": "Tecnologías",
              "responsibilities": "Responsabilidades",
              "challenges": "Desafíos",
              "achievements": "Logros",
              "name": "Nombre",
              "selectExisting": "Seleccionar Proyectos Existentes",
              "selectExistingPlaceholder": "Elige de tus proyectos existentes",
              "orAddNew": "O Agregar un Nuevo Proyecto",
              "deleteConfirmMessage": "¿Estás seguro de que deseas eliminar este proyecto?",
              "deleteConfirmTitle": "Eliminar Proyecto",
              "namePlaceholder": "Ingresa el nombre del proyecto",
              "rolePlaceholder": "Ingresa tu rol",
              "responsibilitiesPlaceholder": "Ingresa tus responsabilidades",
              "challengesPlaceholder": "Ingresa los desafíos enfrentados",
              "skillsPlaceholder": "Buscar o seleccionar habilidades",
              "technologiesPlaceholder": "Buscar o seleccionar tecnologías",
              "startDate": "Fecha de Inicio",
              "endDate": "Fecha de Finalización",
              "noProjects": "No hay proyectos agregados. ¿Qué hiciste? Por favor, asegúrate de agregarlos."
            },
            "reviewAndSave": {
              "title": "Revisar y Guardar",
              "description": "La revisión es un paso crucial para mostrar tus logros. Asegura que la información sea precisa, organizada y refleje tu crecimiento. Compartirla no solo destaca tus éxitos sino que también demuestra profesionalismo, atención al detalle y compromiso con el desarrollo personal y profesional. Es una herramienta poderosa para construir credibilidad y avanzar en tu carrera.",
              "back": "Atrás",
              "save": "Guardar",
              "medalInfo": {
                "title": "Información de la Medalla",
                "timeFrame": "Marco de Tiempo",
                "category": "Categoría"
              }
            },
            "lessonsLearned": {
              "title": "Lessons Learned",
              "noLessons": "No lessons learned? Really? Surely, you must have learned something.",
              "addLessonsLearned": "Add Lessons Learned",
              "addLesson": "Add Lesson",
              "lessonsLearnedDescription": "Every accomplishment comes with a set of lessons learned. It is important to reflect on these lessons and use them to improve future performance. Lessons learned can help identify strengths, weaknesses, opportunities, and threats, which can be used to develop strategies for future success.",
              "lessonLearnedPlaceholder": "Lesson Learned",
              "lessonsTitle": "Lessons Learned",
              "lessonsDescription": "What did you learn?",
            },
            "feedback": {
              "title": "Feedback",
              "noFeedback": "No feedback added. I'm sure somebody said something. Add them!",
              "positive": "Positive",
              "negative": "Negative",
              "givenBy": "Given By"
            }
          }
        },
        fr: {
          translation: {
            "settings": {
              "nav": {
                "general": "Général",
                "security": "Sécurité",
                "profile": "Profil",
                "employments": "Emplois",
                "social": "Social",
                "goals": "Objectifs",
                "projects": "Projets",
                "certifications": "Certifications"
              },
              "title": "Paramètres",
              "general": "Général",
              "security": "Sécurité",
              "fullName": "Nom Complet",
              "emailAddress": "Adresse E-mail",
              "timezone": "Fuseau Horaire",
              "language": "Langue",
              "saveChanges": "Enregistrer les Modifications",
              "newPassword": "Nouveau Mot de Passe",
              "confirmNewPassword": "Confirmer le Nouveau Mot de Passe",
              "updatePassword": "Mettre à Jour le Mot de Passe",
              "passwordRequirements": "Le mot de passe doit contenir au moins 8 caractères, 1 majuscule, 1 symbole et 1 chiffre",
              "passwordsDoNotMatch": "Les mots de passe ne correspondent pas",
              "passwordMinLength": "Minimum 8 caractères",
              "passwordLowercase": "Contient une lettre minuscule",
              "passwordUppercase": "Contient une lettre majuscule",
              "passwordNumbers": "Contient un chiffre",
              "passwordSpecialChars": "Contient un caractère spécial",
              "profile": "Profil",
              "headline": "Titre",
              "description": "Description",
              "profileUpdateSuccess": "Profil mis à jour avec succès",
              "profileUpdateFailed": "Échec de la mise à jour du profil",
              "employments": "Emplois",
              "dateLocale": "fr-FR",
              "social": "Social",
              "socialLinks": "Liens Sociaux",
              "addNewSocialLink": "Ajouter un Nouveau Lien Social",
              "updateSocialLink": "Mettre à Jour le Lien Social",
              "deleteSocialLink": "Supprimer le Lien Social",
              "socialLinkUpdated": "Lien social mis à jour avec succès",
              "socialLinkDeleted": "Lien social supprimé avec succès",
              "socialLinkAdded": "Lien social ajouté avec succès",
              "errorUpdatingSocialLink": "Erreur lors de la mise à jour du lien social",
              "errorDeletingSocialLink": "Erreur lors de la suppression du lien social",
              "errorAddingSocialLink": "Erreur lors de l'ajout du lien social",
              "headlinePlaceholder": "Entrez votre titre",
              "descriptionPlaceholder": "Entrez votre description",
              "skills": "Compétences",
              "technology": "Technologie",
              "username": "Nom d'utilisateur",
              "usernamePlaceholder": "Entrez votre nom d'utilisateur",
              "passwordSuccess": "Mot de passe mis à jour avec succès",
              "passwordError": "Échec de la mise à jour du mot de passe",
              "generalSuccess": "Paramètres généraux mis à jour avec succès",
              "generalError": "Échec de la mise à jour des paramètres généraux",
              "profileSuccess": "Profil mis à jour avec succès",
              "profileError": "Échec de la mise à jour du profil",
              "emailVerified": "E-mail vérifié",
              "resendVerification": "Renvoyer l'e-mail de vérification",
              "verificationEmailSent": "E-mail de vérification envoyé",
              "goals": "Objectifs"
            },
            "languages": {
              "english": "Anglais",
              "spanish": "Espagnol",
              "french": "Français"
            },
            "Language": "Langue",
            "English": "Anglais",
            "Spanish": "Espagnol",
            "French": "Français",
            "Save changes": "Enregistrer les modifications",
            "Save": "Enregistrer",
            "Cancel": "Annuler",
            "Name not set": "Nom non défini",
            "Skills": "Compétences",
            "Technologies": "Technologies",
            "About Me": "À Propos de Moi",
            "Find me on": "Retrouvez-moi sur",
            "Experience": "Expérience",
            "Error": "Erreur",
            "skills": {
              "loading": "Chargement des compétences...",
              "noSkills": "Aucune compétence disponible.",
              "title": "Compétences Qui Vous Distinguent",
              "addSkills": "Ajouter Vos Compétences",
              "searchOrAdd": "Rechercher ou ajouter une compétence...",
              "yourSkillArsenal": "Votre Arsenal de Compétences",
              "sortAZ": "A-Z",
              "sortProficiency": "Niveau de Maîtrise",
              "updateSuccess": "Compétence mise à jour avec succès",
              "updateUnexpectedFailure": "Échec inattendu de la mise à jour de la compétence",
              "updateFailure": "Échec de la mise à jour de la compétence",
              "removeSuccess": "Compétence supprimée avec succès",
              "removeFailure": "Échec de la suppression de la compétence",
              "createSuccess": "Compétence créée avec succès",
              "createUnexpectedFailure": "Échec inattendu de la création de la compétence",
              "createFailure": "Échec de la création de la compétence",
              "clickOnSkillToEdit": "Cliquez sur une compétence pour la modifier"
            },
            "technologies": {
              "word": "Technologies",
              "loading": "Chargement des technologies...",
              "noTechnologies": "Aucune technologie disponible.",
              "title": "Technologie Qui Vous Propulse",
              "addTechnologies": "Ajouter Vos Technologies",
              "searchOrAdd": "Rechercher ou ajouter une technologie...",
              "yourTechnologyToolkit": "Votre Boîte à Outils Technologique",
              "sortAZ": "A-Z",
              "sortProficiency": "Niveau de Maîtrise",
              "updateSuccess": "Technologie mise à jour avec succès",
              "updateUnexpectedFailure": "Échec inattendu de la mise à jour de la technologie",
              "updateFailure": "Échec de la mise à jour de la technologie",
              "removeSuccess": "Technologie supprimée avec succès",
              "removeFailure": "Échec de la suppression de la technologie",
              "createSuccess": "Technologie créée avec succès",
              "createUnexpectedFailure": "Échec inattendu de la création de la technologie",
              "createFailure": "Échec de la création de la technologie",
              "clickOnTechnologyToEdit": "Cliquez sur une technologie pour la modifier"
            },
            "Digital Medals": "Médailles Numériques",
            "Dashboard": "Tableau de Bord",
            "Profile": "Profil",
            "Settings": "Paramètres",
            "Users": "Utilisateurs",
            "View notifications": "Voir les notifications",
            "Notifications": "Notifications",
            "New message": "Nouveau message",
            "John Doe sent you a message": "John Doe vous a envoyé un message",
            "New task assigned": "Nouvelle tâche assignée",
            "You have been assigned a new task": "Une nouvelle tâche vous a été assignée",
            "Your Profile": "Votre Profil",
            "Sign out": "Déconnexion",
            "Login": "Connexion",
            "Register": "S'inscrire",
            "All rights reserved": "Tous droits réservés",
            "loading": {
              "userData": "Chargement des données utilisateur..."
            },
            "dashboard": {
              "title": "Tableau de Bord",
              "welcome": "Bienvenue, {{name}} !",
              "viewProfile": "Voir le Profil",
              "medals": "Médailles",
              "skills": "Compétences",
              "manageSkills": "Gérer les compétences",
              "technologies": "Technologies",
              "manageTechnologies": "Gérer les technologies",
              "employments": "Emplois",
              "manageEmployments": "Gérer les emplois",
              "manageMedals": "Gérer les médailles",
              "projects": "Projets",
              "manageProjects": "Gérer les projets",
              "projectsSkills": "Compétences du Projet",
              "manageProjectsSkills": "Gérer les compétences du projet",
              "projectsTechnologies": "Technologies du Projet",
              "manageProjectsTechnologies": "Gérer les technologies du projet",
              "latestMedals": "Dernières Médailles",
              "topSkills": "Meilleures Compétences",
              "topTechnologies": "Meilleures Technologies",
              "lastLogin": "Dernière connexion",
              "noMedals": "Aucune médaille disponible.",
              "addMedals": "Ajouter des Médailles",
              "noSkills": "Aucune compétence disponible.",
              "noTechnologies": "Aucune technologie disponible.",
              "addSkills": "Ajouter des Compétences",
              "addTechnologies": "Ajouter des Technologies",
              "checklistTitle": "Liste de Configuration",
              "progressTitle": "Suivez Votre Progression",
              "addSkillsChecklist": "Ajoutez vos compétences professionnelles",
              "addTechnologiesChecklist": "Ajoutez vos compétences techniques",
              "addMedalsChecklist": "Ajoutez vos réalisations",
              "addEmploymentsChecklist": "Ajoutez votre historique professionnel"
            },
            "employments": {
              "title": "Historique Professionnel",
              "addNew": "Ajouter un Nouvel Emploi",
              "edit": "Modifier l'Emploi",
              "delete": "Supprimer l'Emploi",
              "employer": "Employeur",
              "position": "Poste",
              "startDate": "Date de Début",
              "endDate": "Date de Fin",
              "currentPosition": "Poste Actuel",
              "description": "Description",
              "save": "Enregistrer l'Emploi",
              "cancel": "Annuler",
              "deleteConfirmation": "Êtes-vous sûr de vouloir supprimer cet emploi ?",
              "noEmployments": "Aucun historique professionnel ajouté pour le moment.",
              "present": "Présent",
              "searchOrAddEmployer": "Rechercher ou ajouter un employeur...",
              "searchOrAddTitle": "Rechercher ou ajouter un titre...",
              "updateSuccess": "Emploi mis à jour avec succès",
              "updateUnexpectedFailure": "Échec inattendu de la mise à jour de l'emploi",
              "updateFailure": "Échec de la mise à jour de l'emploi",
              "addUnexpectedFailure": "Échec inattendu de l'ajout de l'emploi",
              "deleteSuccess": "Emploi supprimé avec succès",
              "addSuccess": "Emploi ajouté avec succès",
              "createEmployerSuccess": "Employeur créé avec succès",
              "createEmployerUnexpectedFormat": "Format de réponse inattendu lors de la création de l'employeur",
              "createEmployerFailure": "Échec de la création de l'employeur",
              "createTitleSuccess": "Titre créé avec succès",
              "createTitleUnexpectedFailure": "Échec inattendu de la création du titre",
              "createTitleFailure": "Échec de la création du titre",
              "deleteConfirmTitle": "Supprimer l'Emploi",
              "deleteConfirmMessage": "Êtes-vous sûr de vouloir supprimer cet enregistrement d'emploi ? Cette action ne peut pas être annulée."
            },
            "userInfo": {
              "fetchError": "Erreur lors de la récupération des dernières informations utilisateur",
              "passwordUpdateSuccess": "Mot de passe utilisateur mis à jour avec succès",
              "passwordUpdateError": "Erreur lors de la mise à jour du mot de passe utilisateur",
              "nameUpdateSuccess": "Nom d'utilisateur mis à jour avec succès",
              "nameUpdateError": "Erreur lors de l'enregistrement du nom d'utilisateur",
              "profileUpdateSuccess": "Profil utilisateur mis à jour avec succès",
              "profileUpdateError": "Erreur lors de l'enregistrement du profil utilisateur",
              "headlineUpdateSuccess": "Titre utilisateur mis à jour avec succès",
              "headlineUpdateError": "Erreur lors de l'enregistrement du titre utilisateur",
              "descriptionUpdateSuccess": "Description utilisateur mise à jour avec succès",
              "descriptionUpdateError": "Erreur lors de l'enregistrement de la description utilisateur"
            },
            "login": {
              "title": "Connexion",
              "emailLabel": "Adresse E-mail",
              "passwordLabel": "Mot de Passe",
              "rememberMe": "Se souvenir de moi",
              "forgotPassword": "Mot de passe oublié ?",
              "signIn": "Se Connecter",
              "notAMember": "Pas encore membre ?",
              "signUp": "S'inscrire maintenant",
              "invalidEmail": "Adresse e-mail invalide",
              "passwordMinLength": "Le mot de passe doit contenir au moins 8 caractères",
              "loginSuccessful": "Connexion réussie",
              "loginFailed": "Échec de la connexion",
              "orContinueWith": "Ou continuer avec",
              "orRegisterWith": "Ou s'inscrire avec",
              "loginWithGoogle": "Google",
              "registerWithGoogle": "Google",
              "googleLoginFailed": "Échec de l'authentification avec Google",
              "googleLoginSuccessful": "Authentification avec Google réussie",
              "processingLogin": "Traitement de votre connexion...",
              "googleLoginRedirect": "Redirection vers Google pour l'authentification...",
              "googleLoginError": "Une erreur s'est produite lors de l'authentification avec Google. Veuillez réessayer.",
              "loginWithLinkedIn": "LinkedIn",
              "registerWithLinkedIn": "LinkedIn"
            },
            "register": {
              "createAccount": "Créer un compte",
              "name": "Nom",
              "namePlaceholder": "John Doe",
              "email": "E-mail",
              "emailPlaceholder": "john@exemple.com",
              "password": "Mot de passe",
              "passwordPlaceholder": "********",
              "confirmPassword": "Confirmer le mot de passe",
              "confirmPasswordPlaceholder": "********",
              "register": "S'inscrire",
              "alreadyHaveAccount": "Vous avez déjà un compte ?",
              "signIn": "Se connecter",
              "registrationSuccessful": "Inscription réussie",
              "registrationFailed": "Échec de l'inscription. Veuillez réessayer.",
              "nameMinLength": "Le nom doit contenir au moins 2 caractères",
              "invalidEmail": "Adresse e-mail invalide",
              "passwordMinLength": "Le mot de passe doit contenir au moins 8 caractères",
              "passwordsDontMatch": "Les mots de passe ne correspondent pas"
            },
            "forgotPassword": {
              "title": "Mot de passe oublié ?",
              "instruction": "Saisissez votre adresse e-mail et nous vous enverrons un lien pour réinitialiser votre mot de passe.",
              "emailLabel": "Adresse e-mail",
              "emailPlaceholder": "Adresse e-mail",
              "sending": "Envoi en cours...",
              "sendResetLink": "Envoyer le lien de réinitialisation",
              "emailSent": "E-mail de réinitialisation envoyé. Veuillez vérifier votre boîte de réception.",
              "error": "Une erreur s'est produite. Veuillez réessayer.",
              "rememberPassword": "Vous vous souvenez de votre mot de passe ?",
              "signIn": "Se connecter"
            },
            "home": {
              "welcomeTo": "Bienvenue sur",
              "elevatingAchievements": "Élever les Réalisations dans l'Arène Numérique",
              "boostCareer": "Boostez Votre Carrière Aujourd'hui !",
              "unlockPotential": "Commencez à utiliser notre application et libérez votre potentiel",
              "getStarted": "Commencer",
              "trackThings": "Gardez une trace de tout ce qui mérite d'être suivi.",
              "improvePerformance": "Améliorez vos évaluations de performance",
              "improveResume": "Améliorez votre CV",
              "improveYourself": "Améliorez-vous.",
              "login": "CONNEXION",
              "register": "S'INSCRIRE →",
              "whatAreDigitalMedals": "Que sont les Médailles Numériques",
              "whatAre": "Que Sont",
              "whatAreThey": "Que Sont-elles",
              "whyUseThem": "Pourquoi les Utiliser",
              "whatIsIt": "Une Médaille Numérique est un registre personnel et vivant de vos réalisations professionnelles, compétences et retours.",
              "whatIsItUsedFor": "À quoi sert une Médaille Numérique et Pourquoi",
              "why": "Pourquoi ",
              "you": "Vous ",
              "whyYou": "Pourquoi Vous ",
              "keep": "Garder ",
              "should": "Devriez ",
              "shouldKeep": "Devriez Garder ",
              "whyYouShouldKeep": "Pourquoi Vous Devriez Garder ",
              "whyUseDigitalMedals": "Pourquoi utiliser les Médailles Numériques ",
              "whatIsItUsedForWhy": "C'est un outil conçu pour capturer les réalisations, petites et importantes, au moment où elles se produisent. ",
              "whyItsImportant": "Qu'il s'agisse d'un projet terminé, d'un retour d'un manager ou d'une compétence maîtrisée, une Médaille Numérique conserve tout au même endroit, vous permettant de mettre en valeur votre croissance professionnelle au fil du temps. Gardez tous vos Accomplissements, Retours, Compétences et plus encore au même endroit.",
              "whyItsGoodToUse": "Les Médailles Numériques servent d'outil de motivation, vous rappelant vos progrès et renforçant votre confiance lorsque vous vous sentez bloqué ou dépassé.",
              "whyItsImportant3": "Cela aide à aligner votre travail sur des objectifs de carrière à long terme, montrant comment les réalisations actuelles contribuent au succès futur.",
              "jobInterviews": "Entretiens d'Embauche",
              "jobInterviewsText": "Lors de candidatures à de nouveaux postes, vous pouvez puiser dans des exemples spécifiques de vos Médailles Numériques pour mettre en valeur une expérience pertinente, améliorant vos chances de succès.",
              "performanceReviews": "Évaluations de Performance",
              "performanceReviewsText": "Lors des évaluations, vous devez montrer des exemples concrets de vos contributions. Une Médaille Numérique vous permet de rassembler facilement les réalisations, prouvant votre valeur pour l'organisation",
              "resume": "CV",
              "resumeText": "Lors de candidatures à de nouveaux postes, vous pouvez puiser dans des exemples spécifiques de vos Médailles Numériques pour mettre en valeur une expérience pertinente, améliorant vos chances de succès.",
              "careerProgression": "Progression de Carrière",
              "careerProgressionText": "En suivant vos réalisations par rapport à l'échelle de carrière de votre entreprise, vous pouvez présenter un argument plus solide pour les promotions ou les augmentations.",
              "accurateReflection": "Réflexion Précise",
              "accurateReflectionText": "Avec le temps, il est facile d'oublier de nombreuses petites contributions que vous avez faites. Tenir une Médaille Numérique garantit qu'aucun effort n'est perdu ou négligé lors des évaluations ou des candidatures.",
              "visibility": "Visibilité",
              "visibilityText": "Partager votre Médaille Numérique (ou des éléments de celle-ci) avec votre manager aide à garantir que votre travail est reconnu, même s'il n'est pas toujours immédiatement visible.",
              "careerStrategy": "Stratégie de Carrière",
              "careerStrategyText": "Cela aide à aligner votre travail sur des objectifs de carrière à long terme, montrant comment les réalisations actuelles contribuent aux aspirations futures.",
              "whatAreDigitalMed": "Que sont les Médailles Numériques",
              "digitalMedalTitle": "Médaille Numérique",
              "digitalMedalSubtitle": "Un registre personnel structuré où vous suivez vos réalisations professionnelles, projets, retours et objectifs de carrière.",
              "digitalMedalDescription": "Cela sert d'outil pour vous aider à présenter une vue d'ensemble de vos contributions et de votre croissance, particulièrement utile lors des évaluations de performance, des entretiens ou lorsque vous négociez des promotions et des augmentations. Associez cela à votre CV pour présenter un argument solide pour votre prochaine promotion."
            },
            "proficiency": {
              "beginner": "Débutant",
              "intermediate": "Intermédiaire",
              "advanced": "Avancé",
              "expert": "Expert"
            },
            "password": {
              "email": "E-mail",
              "resetSuccess": "Réinitialisation du mot de passe réussie !",
              "resetFailed": "Échec de la réinitialisation du mot de passe",
              "resetTitle": "Réinitialiser le Mot de Passe",
              "newPassword": "Nouveau Mot de Passe",
              "confirmPassword": "Confirmer le Nouveau Mot de Passe",
              "resetButton": "Réinitialiser le Mot de Passe",
              "redirectingToLogin": "Mot de passe réinitialisé avec succès ! Redirection vers la connexion...",
              "resetPassword": "Réinitialiser le Mot de Passe",
              "backTo": "Retour à",
              "signIn": "Connexion",
              "invalidResetLink": "Lien de réinitialisation de mot de passe invalide",
              "tryAgain": "Réessayer"
            },
            "email": {
              "resendVerificationSuccess": "E-mail de vérification envoyé avec succès",
              "resendVerificationFailed": "Échec de l'envoi de l'e-mail de vérification",
              "verificationFailed": "Échec de la vérification de l'e-mail",
              "verificationSuccess": "E-mail vérifié avec succès"
            },
            "registration": {
              "failed": "Échec de l'inscription. Veuillez réessayer"
            },
            "goals": {
              "title": "Objectifs",
              "description": "Description",
              "objective": "Objectif",
              "actionPlan": "Plan d'Action",
              "relevance": "Pertinence",
              "targetDate": "Date Cible",
              "progress": "Progression",
              "priority": "Priorité",
              "successCriteria": "Critères de Réussite",
              "addNew": "Ajouter un Nouvel Objectif",
              "edit": "Modifier",
              "delete": "Supprimer",
              "save": "Enregistrer",
              "cancel": "Annuler",
              "noGoals": "Aucun objectif n'a encore été ajouté.",
              "notStarted": "Non Commencé",
              "inProgress": "En Cours",
              "completed": "Terminé",
              "low": "Faible",
              "medium": "Moyenne",
              "high": "Haute",
              "descriptionPlaceholder": "Saisissez la description de l'objectif",
              "objectivePlaceholder": "Saisissez l'objectif",
              "actionPlanPlaceholder": "Saisissez le plan d'action",
              "relevancePlaceholder": "Saisissez la pertinence de l'objectif",
              "successCriteriaPlaceholder": "Saisissez les critères de réussite",
              "deleteConfirmTitle": "Supprimer l'Objectif",
              "deleteConfirmMessage": "Êtes-vous sûr de vouloir supprimer cet objectif ?",
              "createSuccess": "Objectif créé avec succès",
              "updateSuccess": "Objectif mis à jour avec succès",
              "deleteSuccess": "Objectif supprimé avec succès",
              "created": "Objectif créé avec succès",
              "updated": "Objectif mis à jour avec succès",
              "deleted": "Objectif supprimé avec succès",
              "createError": "Erreur lors de la création de l'objectif",
              "updateError": "Erreur lors de la mise à jour de l'objectif",
              "deleteError": "Erreur lors de la suppression de l'objectif"
            },
            "medals": {
              "information": "Information sur la Médaille",
              "description": "Les médailles sont essentielles pour suivre votre croissance et vos réalisations. Elles vous permettent de documenter les succès, les rôles et les défis relevés, vous donnant une image claire de vos contributions. Cela aide non seulement pour les évaluations de performance et le développement de carrière, mais renforce aussi la confiance en réfléchissant aux progrès accomplis et à la valeur que vous apportez à chaque projet.",
              "title": "Titre",
              "titlePlaceholder": "Saisissez le titre",
              "startDate": "Date de Début",
              "endDate": "Date de Fin",
              "category": "Catégorie",
              "selectCategory": "Sélectionner une Catégorie",
              "associateGoal": "Associer un Objectif",
              "optional": "(optionnel)",
              "objective": "Objectif",
              "goalSelectionHelp": "Cliquez sur n'importe quel objectif pour sélectionner/désélectionner. Vous pouvez sélectionner plusieurs objectifs.",
              "createSuccess": "Médaille créée avec succès",
              "createError": "Erreur lors de la création de la médaille",
              "updateSuccess": "Médaille mise à jour avec succès",
              "updateError": "Erreur lors de la mise à jour de la médaille",
              "deleteSuccess": "Médaille supprimée avec succès",
              "deleteError": "Erreur lors de la suppression de la médaille",
              "basicInfo": "Informations de Base",
              "projects": "Projets",
              "lessonsLearned": "Leçons Apprises",
              "feedback": "Ajouter un Retour",
              "feedbackDescription": "Inclure des retours est vital pour la croissance. Mettez en évidence les domaines où vous avez excellé et fournissez des informations sur les points à améliorer. Les retours constructifs aident à affiner vos compétences, tandis que les retours positifs renforcent vos points forts. Documenter les deux crée une vision globale de votre performance et montre votre engagement envers l'amélioration continue.",
              "feedbackPositive": "Retour Positif",
              "feedbackConstructive": "Retour Constructif",
              "feedbackGivenBy": "Fourni Par",
              "feedbackGivenByPlaceholder": "Fourni par (ou laissez vide pour vous-même)",
              "feedbackGivenByDefault": "Moi-même",
              "add": "Ajouter",
              "delete": "Supprimer",
              "notAvailable": "N/D"
            }
          }
        }
      },
      lng: userLanguage,
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false
      }
    });

  return i18n;
};


export default i18n;
