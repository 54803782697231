import instance from '../../axios';
import { toast } from 'react-toastify';
import i18n from '../../../i18n';

// Technologies functions
export const storeUserTechnology = (technologyId, proficiency) => {
    return instance.post('/technologies/user/store', { technology_id: technologyId, proficiency });
};
export const updateUserTechnology = (technologyId, proficiency) => {
    const response = instance.post(`/technologies/user/update/${technologyId}`, { proficiency });
    console.log("Update User Technology Response:", response);
    return response;
};
  
export const fetchUserTechnologies = () => instance.get('/technologies/user');
export const fetchCommonTechnologies = async () => {
    try {
      const response = await instance.get('/technologies/common');
      if (response.data && response.data.data) {
        return response.data.data;
      } else {
        throw new Error('Unexpected response structure');
      }
    } catch (error) {
      console.error("API: Error fetching common technologies:", error);
      throw error;
    }
  };
  
export const removeUserTechnology = (technologyId) => instance.delete(`/technologies/user/destroy/${technologyId}`);

export const createTechnology = async (technology) => {
    try {
      const response = await instance.post('/technologies/store', { name: technology });
      if (response.data && response.data.data) {
        toast.success(i18n.t('technologies.createSuccess'));
        return response.data.data;
      } else {
        toast.error(i18n.t('technologies.createUnexpectedFailure'));
        throw new Error('Unexpected response status');
      }
    } catch (error) {
      console.error("API: Error creating technology:", error);
      toast.error(i18n.t('technologies.createFailure'));
      throw error;
    }
  };
