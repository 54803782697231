import React, { useEffect } from 'react';
import { FaEnvelope, FaFilePdf, FaFileWord } from 'react-icons/fa';
import { format, isValid, parseISO } from 'date-fns';
import { getSocialIcon } from '../Common/SocialIcons';
import instance from '../../api/axios';
import { getResumeData } from '../../api/axios';
import Button from '../Common/Button';
const BasicResume = () => {
  const [resumeData, setResumeData] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    const fetchResumeData = async () => {
      try {
        const response = await getResumeData();
        setResumeData({
          ...response,
          name: response.user_information.name,
          headline: response.user_information.headline,
          description: response.user_information.description,
          email: response.user_information.email,
        });
      } catch (error) {
        console.error('Error fetching resume data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchResumeData();
  }, []);

  if (loading || !resumeData) {
    return <div>Loading...</div>;
  }

  const formatDate = (dateString) => {
    const date = parseISO(dateString);
    return isValid(date) ? format(date, 'MMMM yyyy') : '';
  };

  // Sort employments by date (most recent first)
  const sortedEmployments = resumeData.employments?.sort((a, b) => {
    const dateA = new Date(a.is_current ? Date.now() : a.end_date);
    const dateB = new Date(b.is_current ? Date.now() : b.end_date);
    return dateB - dateA;
  });

  const handlePdfDownload = () => {
    window.print();
  };

  const handleWordDownload = async () => {
    try {
      const response = await instance.post('/user/resume/download/word', 
        { userData: resumeData },
        { 
          responseType: 'blob',
          headers: {
            'Content-Type': 'application/json',
          }
        }
      );

      const blob = new Blob([response.data], { 
        type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' 
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${resumeData.name.replace(/\s+/g, '_')}_resume.docx`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading Word document:', error);
      alert('Failed to download Word document');
    }
  };
  

  return (
    <div id="resume-container" className="max-w-4xl mx-auto p-8 bg-white print:p-4">
      {/* Add Download Buttons - Will not show when printing */}
      <div className="flex justify-end gap-4 mb-4 print:hidden">
        <Button
          onClick={handlePdfDownload}
          color="red"
        >
          <FaFilePdf /> Print
        </Button>
        {/* <Button
          onClick={handleWordDownload}
          color="blue"
        >
          <FaFileWord /> Word
        </Button> */}
      </div>

      {/* Header Section with Two-Column Contact Info */}
      <header className="mb-8">
        <div className="text-center mb-4">
          <h1 className="text-3xl font-bold mb-2">{resumeData.name}</h1>
          <p className="text-lg text-gray-600">{resumeData.headline}</p>
        </div>
        
        <div className="max-w-2xl mx-auto grid grid-cols-2 gap-x-8 text-sm text-gray-600">
          {/* Left Column */}
          <div className="space-y-1">
            <div className="flex items-center gap-2">
              <FaEnvelope className="text-gray-500 w-4" />
              <span>{resumeData.email}</span>
            </div>
            {resumeData.social_links?.slice(0, Math.ceil(resumeData.social_links.length / 2)).map((link, index) => {
              const url = new URL(link.url);
              const displayUrl = `${url.hostname.replace('www.', '')}${url.pathname !== '/' ? url.pathname : ''}`;
              
              return (
                <div key={index} className="flex items-center gap-2">
                  {getSocialIcon(link.platform)}
                  <span>{displayUrl}</span>
                </div>
              );
            })}
          </div>

          {/* Right Column */}
          <div className="space-y-1">
            {resumeData.social_links?.slice(Math.ceil(resumeData.social_links.length / 2)).map((link, index) => {
              const url = new URL(link.url);
              const displayUrl = `${url.hostname.replace('www.', '')}${url.pathname !== '/' ? url.pathname : ''}`;
              
              return (
                <div key={index} className="flex items-center gap-2">
                  {getSocialIcon(link.platform)}
                  <span>{displayUrl}</span>
                </div>
              );
            })}
          </div>
        </div>
      </header>

      {/* Professional Summary */}
      <section className="mb-6">
        <h2 className="text-xl font-bold border-b-2 border-gray-300 mb-3">Professional Summary</h2>
        <p className="text-gray-700">{resumeData.description}</p>
      </section>

      {/* Professional Experience */}
      <section className="mb-6">
        <h2 className="text-xl font-bold border-b-2 border-gray-300 mb-3">Professional Experience</h2>
        {sortedEmployments?.map((employment, index) => (
          <div key={index} className="mb-4">
            <div className="flex justify-between items-start">
              <div>
                <h3 className="font-bold">{employment.position.name}</h3>
                <p className="text-gray-600">{employment.employer.name}</p>
              </div>
              <p className="text-sm text-gray-600">
                {formatDate(employment.start_date)} - {employment.is_current ? 'Present' : formatDate(employment.end_date)}
              </p>
            </div>
            <p className="mt-2 text-gray-700">{employment.description}</p>
          </div>
        ))}
      </section>

      {/* Skills Section */}
      <section className="mb-6">
        <h2 className="text-xl font-bold border-b-2 border-gray-300 mb-3">Skills</h2>
        <ul className="list-disc list-inside grid grid-cols-2 gap-2">
          {resumeData.skills?.map((skill, index) => (
            <li key={index} className="text-gray-700">
              {skill.skill_name}
            </li>
          ))}
        </ul>
      </section>

      {/* Technologies Section */}
      <section className="mb-6">
        <h2 className="text-xl font-bold border-b-2 border-gray-300 mb-3">Technologies</h2>
        <ul className="list-disc list-inside grid grid-cols-2 gap-2">
          {resumeData.technologies?.map((tech, index) => (
            <li key={index} className="text-gray-700">
              {tech.technology_name}
            </li>
          ))}
        </ul>
      </section>

      {/* Achievements Section */}
      <section className="mb-6">
        <h2 className="text-xl font-bold border-b-2 border-gray-300 mb-3">Achievements</h2>
        {resumeData.medals?.map((medal, index) => (
          <div key={index} className="mb-4">
            <div className="flex justify-between items-start">
              <div>
                <h3 className="font-bold">{medal.title}</h3>
                <p className="text-gray-600">{medal.category.category}</p>
              </div>
              <p className="text-sm text-gray-600">
                {formatDate(medal.startDate)} - {medal.endDate ? formatDate(medal.endDate) : 'Present'}
              </p>
            </div>
            {medal.accomplishments.length > 0 && (
              <div className="mt-2">
                <p className="font-semibold text-sm">Key Accomplishments:</p>
                <ul className="list-disc list-inside text-gray-700 text-sm ml-4">
                  {medal.accomplishments.map((accomplishment, idx) => (
                    <li key={idx}>{accomplishment.accomplishment}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        ))}
      </section>

      {/* Print Styles */}
      <style type="text/css" media="print">{`
        @page {
            margin: 0.5in;
        }
        /* Hide non-resume elements for printing */
        nav,
        footer,
        #header,   /* Adjust selector if needed */
        #sidebar,  /* Adjust selector if needed */
        .print\\:hidden {
            display: none !important;
        }
        /* Style the resume content for print */
        #resume-container {
            margin: 0 !important;
            padding: 0 !important;
            max-width: 100% !important;
            background: white !important;
        }
        /* Basic print optimizations */
        body {
            print-color-adjust: exact;
            -webkit-print-color-adjust: exact;
        }
        a {
            text-decoration: none;
            color: inherit;
        }
        .print-break-inside-avoid {
            break-inside: avoid;
        }
        `}</style>

    </div>
  );
};

export default BasicResume;
