import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { useUser } from '../../contexts/UserContext';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const SocialCallback = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { login } = useAuth();
    const { updateUser } = useUser();
    const { t } = useTranslation();

    useEffect(() => {
        const handleCallback = async () => {
            const token = searchParams.get('token');
            const error = searchParams.get('error');
            
            // Get user data from URL params
            const userId = searchParams.get('user[id]');
            const userName = searchParams.get('user[name]');
            const userEmail = searchParams.get('user[email]');

            if (error) {
                toast.error(decodeURIComponent(error));
                navigate('/login');
                return;
            }

            if (token && userId && userName && userEmail) {
                try {
                    // Create user object from URL params
                    const user = {
                        id: userId,
                        name: decodeURIComponent(userName),
                        email: decodeURIComponent(userEmail)
                    };

                    // Store token first
                    localStorage.setItem('token', token);
                    
                    // Wait for both operations to complete
                    await Promise.all([
                        login(token, user),
                        updateUser(user)
                    ]);
                    
                    // toast.success(t('login.googleLoginCallbackSuccessful'));
                    
                    // Use window.location.href instead of navigate
                    window.location.href = '/dashboard';
                } catch (error) {
                    console.error('Social login error:', error);
                    localStorage.removeItem('token');
                    // toast.error(t('login.googleLoginCallbackFailed'));
                    navigate('/login');
                }
            } else {
                // toast.error(t('login.googleLoginCallbackError'));
                navigate('/login');
            }
        };

        handleCallback();
    }, [searchParams, navigate, login, updateUser, t]);

    return (
        <div className="flex items-center justify-center min-h-screen">
            <div className="text-center">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900 mx-auto"></div>
                <p className="mt-4">{t('login.googleLoginCallbackProcessing')}</p>
            </div>
        </div>
    );
};

export default SocialCallback; 