import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const AddFeedback = ({ initialData, onNext, onBack, medalData }) => {
  const { t } = useTranslation();
  const [feedbacks, setFeedbacks] = useState(initialData.feedbacks || []);
  const [newFeedback, setNewFeedback] = useState({ positive: '', constructive: '', actionItems: '', givenBy: '' });

  const addFeedback = () => {
    if (newFeedback.positive.trim() || newFeedback.constructive.trim() || newFeedback.actionItems.trim()) {
      setFeedbacks([...feedbacks, newFeedback]);
      setNewFeedback({ positive: '', constructive: '', actionItems: '', givenBy: '' });
    }
  };

  const deleteFeedback = (index) => {
    setFeedbacks(feedbacks.filter((_, i) => i !== index));
  };

  const handleNext = () => {
    onNext({ feedbacks });
  };

  return (
    <div className="w-full min-h-screen flex items-start justify-center p-4">
      <div className="w-full max-w-2xl bg-white">
        <div className="flex justify-between items-center mb-8 p-4">
          <button
            onClick={() => onBack({ feedbacks })}
            className="bg-gray-500 text-white py-2 px-4 rounded-md hover:bg-gray-600 transition duration-300"
          >
            {t('common.back')}
          </button>
          <div className="hidden md:block w-3/4 bg-white text-center">
            <p><strong>{medalData.title}</strong></p>
            <p>
              <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                {medalData.categoryName || ''}
              </span>
            </p>
          </div>
          <button
            onClick={handleNext}
            className={`py-2 px-4 rounded-md transition duration-300 ${
              feedbacks.length === 0
                ? 'bg-yellow-500 hover:bg-yellow-600 text-black'
                : 'bg-blue-500 hover:bg-blue-600 text-white'
            }`}
          >
            {feedbacks.length === 0 ? t('common.skip') : t('common.next')}
          </button>
        </div>
      
        <div className="text-center mb-6">
          <h2 className="text-4xl font-bold text-gray-900 mb-2">{t('medals.feedback')}</h2>
          <p className="text-sm text-gray-700 rounded-lg p-4 border border-blue-300 bg-blue-100">
            {t('medals.feedbackDescription')}
          </p>
        </div>
        
        <div className="space-y-4">
          <textarea
            value={newFeedback.positive}
            onChange={(e) => setNewFeedback({ ...newFeedback, positive: e.target.value })}
            placeholder={t('medals.feedbackPositive')}
            className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          ></textarea>
          <textarea
            value={newFeedback.constructive}
            onChange={(e) => setNewFeedback({ ...newFeedback, constructive: e.target.value })}
            placeholder={t('medals.feedbackConstructive')}
            className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          ></textarea>
          <input
            type="text"
            value={newFeedback.givenBy}
            onChange={(e) => setNewFeedback({ ...newFeedback, givenBy: e.target.value })}
            placeholder={t('medals.feedbackGivenByPlaceholder')}
            className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <button
            onClick={addFeedback}
            disabled={!newFeedback.positive.trim() && !newFeedback.constructive.trim() && !newFeedback.actionItems.trim()}
            className={`w-full py-3 rounded-lg transition duration-300 ${
              newFeedback.positive.trim() || newFeedback.constructive.trim() || newFeedback.actionItems.trim()
                ? 'bg-blue-500 hover:bg-blue-600 text-white'
                : 'bg-gray-300 text-gray-500 cursor-not-allowed'
            }`}
          >
            {t('medals.add')}
          </button>
          <div className="list-none pl-0 space-y-4">
            {feedbacks.map((fb, index) => (
              <div key={index} className="bg-blue-100 text-blue-800 rounded-lg p-4 text-sm font-semibold flex justify-between items-center">
                <div>
                  <p><strong>{t('medals.feedbackPositive')}:</strong> {fb.positive}</p>
                  <p><strong>{t('medals.feedbackConstructive')}:</strong> {fb.constructive}</p>
                  <p><strong>{t('medals.feedbackGivenBy')}:</strong> {fb.givenBy || t('medals.feedbackGivenByDefault')}</p>
                </div>
                <span onClick={() => deleteFeedback(index)} className="bg-white rounded px-1 text-xs uppercase font-bold text-red-500 border border-red-500">
                  {t('medals.delete')}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddFeedback;
