import instance from "../../axios";
import { toast } from 'react-toastify';
import { t } from 'i18next';

export const fetchCertifications = async () => {
    const response = await instance.get('/certifications');
    return response.data.data;
};

export const createCertification = async (certification) => {
    try {
        const response = await instance.post('/certifications/store', certification);
        if (response.data) {
            toast.success(t('certifications.created'));
            return response.data.data;
        }
    } catch (error) {
        if (error.response?.status === 422) {
            // Handle validation errors
            const errorMessage = error.response.data.message;
            toast.error(errorMessage);
        } else {
            // Handle other errors
            const errorMessage = error.response?.data?.message || t('certifications.createError');
            toast.error(errorMessage);
        }
        throw error;
    }
};

export const updateCertification = async (certification) => {
    try {
        const response = await instance.put(`/certifications/update/${certification.id}`, certification);
        if (response.data) {
            toast.success(t('certifications.updated'));
            return response.data.data; // Return just the data portion
        }
    } catch (error) {
        if (error.response?.status === 422) {
            // Handle validation errors
            const errorMessage = error.response.data.message;
            toast.error(errorMessage);
        } else {
            // Handle other errors
            const errorMessage = error.response?.data?.message || t('certifications.updateError');
            toast.error(errorMessage);
        }
        throw error;
    }
};

export const deleteCertification = async (id) => {
    const response = await instance.delete(`/certifications/destroy/${id}`);
    if (response.data && response.data.success) {
        toast.success(t('certifications.deleted'));
        return response.data.data;
    } else {
        throw new Error(response.data.message);
    }
};
