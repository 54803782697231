import instance from '../../axios';
import { toast } from 'react-toastify';
import i18n from '../../../i18n';

// Social Links functions
export const deleteSocialLink = async (linkId) => {
  try {
    const response = await instance.delete(`/social/destroy/${linkId}`);
    if (response.status === 200) {
      toast.success(i18n.t('socialLinks.deleteSuccess'));
      return response.data;
    }
  } catch (error) {
    console.error('Error deleting social link:', error);
    if (error.response && error.response.data && error.response.data.errors) {
      const { errors } = error.response.data;
      if (errors.url) {
        toast.error(errors.url[0]);
      } else {
        toast.error(i18n.t('socialLinks.deleteError'));
      }
    } else {
      toast.error(i18n.t('socialLinks.deleteError'));
    }
    throw error;
  }
};

export const addSocialLink = async (socialLink) => {
  try {
    const response = await instance.post('/social/store', socialLink);
    if (response.status === 201) {
      toast.success(i18n.t('socialLinks.addSuccess'));
      return response.data.data;
    }
  } catch (error) {
    console.error('Error adding social link:', error);
    if (error.response && error.response.data && error.response.data.errors) {
      const { errors } = error.response.data;
      if (errors.url) {
        toast.error(errors.url[0]);
      } else {
        toast.error(i18n.t('socialLinks.addError'));
      }
    } else {
      toast.error(i18n.t('socialLinks.addError'));
    }
    throw error;
  }
};

export const updateSocialLink = async (linkId, updatedUrl) => {
  try {
    const response = await instance.put(`/social/update/${linkId}`, { url: updatedUrl });
    if (response.status === 200) {
      toast.success(i18n.t('socialLinks.updateSuccess'));
      return response.data;
    }
  } catch (error) {
    console.error('Error updating social link:', error);
    if (error.response && error.response.data && error.response.data.errors) {
      const { errors } = error.response.data;
      if (errors.url) {
        toast.error(errors.url[0]);
      } else {
        toast.error(i18n.t('socialLinks.updateError'));
      }
    } else {
      toast.error(i18n.t('socialLinks.updateError'));
    }
    throw error;
  }
};
