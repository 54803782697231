import React, { useState, useEffect } from 'react';
import axios from '../../api/axios';

const Users = () => {
	const [users, setUsers] = useState([]);

	useEffect(() => {
		const fetchUsers = async () => {
			try {
				const response = await axios.get('/users');
				setUsers(response.data.data);
			} catch (error) {
				console.error('Error fetching users:', error);
				}
		};

		fetchUsers();
	}, []);

	// Function to format the date
	const formatDate = (dateString) => {
		const options = { year: 'numeric', month: 'long', day: 'numeric' };
		return new Date(dateString).toLocaleDateString(undefined, options);
	};

	return (
		<div className="py-12">
			<div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
				<div className="bg-white overflow-hidden shadow-sm sm:rounded-lg">
					<div className="p-6 text-gray-900">
						<h2 className="font-semibold text-xl text-gray-800 leading-tight mb-4">Users</h2>
						<table className="min-w-full divide-y divide-gray-200">
							<thead className="bg-gray-50">
								<tr>
									<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
									<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
									<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Created At</th>
								</tr>
							</thead>
							<tbody className="bg-white divide-y divide-gray-200">
								{users.map((user) => (
									<tr key={user.id}>
										<td className="px-6 py-4 whitespace-nowrap">{user.name}</td>
										<td className="px-6 py-4 whitespace-nowrap">{user.email}</td>
										<td className="px-6 py-4 whitespace-nowrap">{formatDate(user.created_at)}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Users;
