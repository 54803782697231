import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { 
  FaPlus, 
  FaEllipsisH, 
  FaMedal, 
  FaEdit, 
  FaCode, 
  FaLaptopCode, 
  FaSlidersH,
  FaColumns,
  FaHistory,
  FaSignOutAlt,
  FaBars
} from 'react-icons/fa';
import { signOut } from '../../api/axios';
import { useUser } from '../../contexts/UserContext';
import { Menu, MenuButton } from '@headlessui/react'
import AnnouncementBanner from '../Common/AnnouncementBanner';
import instance from '../../api/axios';
import { toast } from 'react-toastify';
const Layout = ({ children, isAuthenticated, onLogout }) => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [openDropdown, setOpenDropdown] = useState(null);
  const userMenuRef = useRef(null);
  const notificationRef = useRef(null);
  const { clearUser, user } = useUser();  // Add user from UserContext
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [showEmailBanner, setShowEmailBanner] = useState(true);
  const [isResending, setIsResending] = useState(false);

  useLayoutEffect(() => {
    // Store original console.error
    const originalConsoleError = console.error;
    
    // Create new error handler
    const handleError = (...args) => {
      const errorMessage = args.join(' ');
      if (errorMessage.includes('ResizeObserver') || 
          errorMessage.includes('ResizeObserver loop completed with undelivered notifications.') ||
          errorMessage.includes('ResizeObserver loop limit exceeded')) {
        return;
      }
      originalConsoleError.apply(console, args);
    };

    // Replace console.error
    console.error = handleError;

    // Cleanup
    return () => {
      console.error = originalConsoleError;
    };
  }, []);

  const toggleDropdown = (dropdown) => {
    if (openDropdown === dropdown) {
      setOpenDropdown(null);
    } else {
      setOpenDropdown(dropdown);
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut();
      setOpenDropdown(null);
      onLogout(); // Call the onLogout function passed from App
      navigate('/'); // Navigate to the home page
    } catch (error) {
      console.error('Sign out failed', error);
    }
  };

  const handleLogout = () => {
    clearUser(); // Clear user data from context and localStorage
    onLogout(); // Existing logout logic
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (userMenuRef.current && !userMenuRef.current.contains(event.target) &&
          notificationRef.current && !notificationRef.current.contains(event.target)) {
        setOpenDropdown(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const closeDropdown = () => {
    setOpenDropdown(null);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleResendVerification = async () => {
    if (isResending) return;
    
    try {
      setIsResending(true);
      const response = await instance.post('/email/resend-verification-email', {
        email: user.email
      });
      
      if (response.status === 200) {
        toast.success(t('Verification email sent successfully'));
      }
    } catch (error) {
      console.error('Failed to resend verification email:', error);
      toast.error(t('Failed to send verification email'));
    }
    // Don't reset isResending to allow only one attempt
  };

  const renderEmailVerificationBanner = () => {
    if (!isAuthenticated || user?.email_verified_at || !showEmailBanner) {
      return null;
    }

    return (
      <AnnouncementBanner
        title={t('Email Verification Required')}
        text={t('Please verify your email address to access all features.')}
        variant="warning"
        buttons={[
          {
            label: isResending ? t('Verification Email Sent') : t('Resend Verification Email'),
            onClick: handleResendVerification,
            disabled: isResending
          },
          {
            label: t('Dismiss'),
            onClick: () => setShowEmailBanner(false)
          }
        ]}
      />
    );
  };

  // Add this new function to handle both navigation and menu closing
  const handleMenuItemClick = () => {
    // The Menu component from @headlessui/react will automatically close
    // when we click a Menu.Item, but we should also close the mobile menu if it's open
    setIsMobileMenuOpen(false);
  };

  return (
    <div className="min-h-screen flex flex-col bg-white">
      <nav className={`bg-white shadow-sm ${isMobileMenuOpen ? '' : 'md:sticky md:top-0 md:z-50'}`}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex-shrink-0 flex items-center">
              <img
                src="/images/logo192.png"
                alt="Digital Medals Logo"
                className="h-8 w-8 mr-2"
              />
              <Link to="/" className="ml-2 text-2xl font-bold text-gray-900 hover:text-blue-500 transition duration-150 ease-in-out">
                {t('Digital Medals')}
              </Link>
            </div>
            
            {/* Mobile menu button */}
            <div className="flex items-center sm:hidden">
              <button
                type="button"
                onClick={toggleMobileMenu}
                aria-expanded={isMobileMenuOpen}
                aria-controls="mobile-menu"
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
              >
                <span className="sr-only">{isMobileMenuOpen ? 'Close menu' : 'Open menu'}</span>
                <FaBars className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>

            {/* Desktop menu */}
            <div className="hidden sm:flex sm:items-center">
              {isAuthenticated ? (
                <>
                  <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                    <Link to="/dashboard" className={`${location.pathname === '/dashboard' ? 'border-blue-500 text-gray-900' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'} inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`}>
                      {t('Dashboard')}
                    </Link>
                    <Link to="/profile" className={`${location.pathname === '/profile' ? 'border-blue-500 text-gray-900' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'} inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`}>
                      {t('Profile')}
                    </Link>
                    <Link to="/settings" className={`${location.pathname === '/settings' ? 'border-blue-500 text-gray-900' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'} inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`}>
                      {t('Settings')}
                    </Link>
                    <Link to="/timeline" className={`${location.pathname === '/timeline' ? 'border-blue-500 text-gray-900' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'} inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`}>
                      {t('Timeline')}
                    </Link>
                    {location.pathname === '/medal-wizard' ? (
                      <span className="inline-flex items-center px-3 py-1 rounded border border-blue-500 text-blue-500 text-sm font-medium">
                        <FaEllipsisH className="text-1xl mr-9 ml-9"/>
                      </span>
                    ) : (
                      <Link to="/medal-wizard" className="inline-flex items-center px-3 py-1 rounded bg-blue-500 text-white text-sm font-medium hover:bg-blue-600 transition duration-150 ease-in-out">
                        <FaPlus className="text-1xl mr-1"/>
                        {t('Add Medal')}
                      </Link>
                    )}
                   
                  </div>
                  {/* <div className="relative ml-3" ref={notificationRef}>
                    <button 
                      onClick={() => toggleDropdown('notification')}
                      type="button" 
                      className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      <span className="sr-only">{t('View notifications')}</span>
                      <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                      </svg>
                    </button>
                    {openDropdown === 'notification' && (
                      <div className="origin-top-right absolute right-0 mt-2 w-80 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="notification-menu" tabIndex="-1">
                        <div className="px-4 py-2 text-sm text-gray-700 font-semibold">{t('Notifications')}</div>
                        <div className="divide-y divide-gray-100">
                          <button className="block px-4 py-3 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">
                            <p className="font-medium">{t('New message')}</p>
                            <p className="text-xs text-gray-500">{t('John Doe sent you a message')}</p>
                          </button>
                          <button className="block px-4 py-3 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">
                            <p className="font-medium">{t('New task assigned')}</p>
                            <p className="text-xs text-gray-500">{t('You have been assigned a new task')}</p>
                          </button>
                        </div>
                      </div>
                    )}
                  </div> */}
                  <Menu as="div" className="ml-3 relative">
                  <div>
                      <MenuButton className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                        <FaMedal aria-hidden="true" className=" h-5 w-5 text-gray-400" />
                      </MenuButton>
                    </div>

                    <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none">
                      <div className="px-4 py-3">
                        <p className="text-sm">{t('Signed in as')}</p>
                        <p className="truncate text-sm font-medium text-gray-900">
                          {user?.name || t('Guest')}  {/* Add fallback for when user is null */}
                        </p>
                      </div>
                      
                      <div className="py-1">
                        
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/dashboard"
                              className={`block px-4 py-2 text-sm text-gray-700 ${active ? 'bg-gray-100' : ''}`}
                              onClick={handleMenuItemClick}
                            >
                              <div className="flex items-center">
                                <FaColumns className="mr-2" />
                                {t('Dashboard')}
                              </div>
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/profile"
                              className={`block px-4 py-2 text-sm text-gray-700 ${active ? 'bg-gray-100' : ''}`}
                              onClick={handleMenuItemClick}
                            >
                              <div className="flex items-center">
                                <FaEdit className="mr-2" />
                                {t('Your Profile')}
                              </div>
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/settings"
                              className={`block px-4 py-2 text-sm text-gray-700 ${active ? 'bg-gray-100' : ''}`}
                              onClick={handleMenuItemClick}
                            >
                              <div className="flex items-center">
                                <FaSlidersH className="mr-2" />
                                {t('Settings')}
                              </div>
                            </Link>
                          )}
                        </Menu.Item>
                      </div>

                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/skills"
                              className={`block px-4 py-2 text-sm text-gray-700 ${active ? 'bg-gray-100' : ''}`}
                              onClick={handleMenuItemClick}
                            >
                              <div className="flex items-center">
                                <FaCode className="mr-2" />
                                {t('Skills')}
                              </div>
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/technologies"
                              className={`block px-4 py-2 text-sm text-gray-700 ${active ? 'bg-gray-100' : ''}`}
                              onClick={handleMenuItemClick}
                            >
                              <div className="flex items-center">
                                <FaLaptopCode className="mr-2" />
                                {t('Technologies')}
                              </div>
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/medal-wizard"
                              className={`block px-4 py-2 text-sm text-gray-700 ${active ? 'bg-gray-100' : ''}`}
                              onClick={handleMenuItemClick}
                            >
                              <div className="flex items-center">
                                <FaPlus className="mr-2" />
                                {t('Add Medal')}
                              </div>
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/timeline"
                              className={`block px-4 py-2 text-sm text-gray-700 ${active ? 'bg-gray-100' : ''}`}
                              onClick={handleMenuItemClick}
                            >
                              <div className="flex items-center">
                                <FaHistory className="mr-2" />
                                {t('Timeline')}
                              </div>
                            </Link>
                          )}
                        </Menu.Item>
                      </div>

                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              onClick={() => {
                                handleSignOut();
                                handleMenuItemClick();
                              }}
                              className={`block w-full text-left px-4 py-2 text-sm text-gray-700 ${active ? 'bg-gray-100' : ''}`}
                            >
                              <div className="flex items-center">
                                <FaSignOutAlt className="mr-2" />
                                {t('Sign out')}
                              </div>
                            </button>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Menu>
                </>
              ) : (
                <div className="flex space-x-4 items-center">
                  <Link to="/why-use-digital-medals" className="text-gray-500 hover:text-gray-700 px-3 py-2 rounded-md text-sm font-medium">
                    {t('home.whyUseThem')}
                  </Link>
                  <Link to="/what-are-digital-medals" className="text-gray-500 hover:text-gray-700 px-3 py-2 rounded-md text-sm font-medium">
                    {t('home.whatAreThey')}
                  </Link>
                  <Link to="/login" className="text-gray-500 hover:text-gray-700 px-3 py-2 rounded-md text-sm font-medium">
                    {t('Login')}
                  </Link>
                  <Link to="/register" className="text-gray-500 hover:text-gray-700 px-3 py-2 rounded-md text-sm font-medium">
                    {t('Register')}
                  </Link>
                  <div className="relative">
                    <select
                      onChange={(e) => changeLanguage(e.target.value)}
                      value={i18n.language}
                      className="block appearance-none bg-white border border-gray-300 text-gray-700 py-2 px-3 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    >
                      <option value="en">English</option>
                      <option value="es">Español</option>
                      <option value="fr">Français</option>
                      {/* <option value="pt">Português</option>
                      <option value="zh">Chinês</option>
                      <option value="tl">Tagalo</option>
                      <option value="de">Alemão</option>
                      <option value="it">Italiano</option>
                      <option value="ko">Coreano</option>
                      <option value="vi">Vietnamita</option> */}
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
                      </svg>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Mobile menu */}
        <div 
          className={`${isMobileMenuOpen ? 'block' : 'hidden'} sm:hidden`}
          id="mobile-menu"
        >
          <div className="pt-2 pb-3 space-y-1">
            {isAuthenticated && (
              <>
                {/* User info section */}
                <div className="px-4 py-3 border-b border-gray-200">
                  <p className="text-sm text-gray-500">{t('Signed in as')}</p>
                  <p className="truncate text-sm font-medium text-gray-900">
                    {user?.name || t('Guest')}
                  </p>
                </div>

                {/* Main navigation section */}
                <div className="border-b border-gray-200">
                  <Link
                    to="/dashboard"
                    className="flex items-center px-4 py-2 text-base text-gray-500 hover:bg-gray-50"
                    onClick={() => setIsMobileMenuOpen(false)}
                  >
                    <FaColumns className="mr-2" />
                    {t('Dashboard')}
                  </Link>
                  <Link
                    to="/profile"
                    className="flex items-center px-4 py-2 text-base text-gray-500 hover:bg-gray-50"
                    onClick={() => setIsMobileMenuOpen(false)}
                  >
                    <FaEdit className="mr-2" />
                    {t('Your Profile')}
                  </Link>
                  <Link
                    to="/settings"
                    className="flex items-center px-4 py-2 text-base text-gray-500 hover:bg-gray-50"
                    onClick={() => setIsMobileMenuOpen(false)}
                  >
                    <FaSlidersH className="mr-2" />
                    {t('Settings')}
                  </Link>
                </div>

                {/* Features section */}
                <div className="border-b border-gray-200">
                  <Link
                    to="/skills"
                    className="flex items-center px-4 py-2 text-base text-gray-500 hover:bg-gray-50"
                    onClick={() => setIsMobileMenuOpen(false)}
                  >
                    <FaCode className="mr-2" />
                    {t('Skills')}
                  </Link>
                  <Link
                    to="/technologies"
                    className="flex items-center px-4 py-2 text-base text-gray-500 hover:bg-gray-50"
                    onClick={() => setIsMobileMenuOpen(false)}
                  >
                    <FaLaptopCode className="mr-2" />
                    {t('Technologies')}
                  </Link>
                  <Link
                    to="/medal-wizard"
                    className="flex items-center px-4 py-2 text-base text-gray-500 hover:bg-gray-50"
                    onClick={() => setIsMobileMenuOpen(false)}
                  >
                    <FaPlus className="mr-2" />
                    {t('Add Medal')}
                  </Link>
                  <Link
                    to="/timeline"
                    className="flex items-center px-4 py-2 text-base text-gray-500 hover:bg-gray-50"
                    onClick={() => setIsMobileMenuOpen(false)}
                  >
                    <FaHistory className="mr-2" />
                    {t('Timeline')}
                  </Link>
                </div>

                {/* Sign out section */}
                <button
                  onClick={() => {
                    handleSignOut();
                    setIsMobileMenuOpen(false);
                  }}
                  className="flex items-center w-full px-4 py-2 text-base text-gray-500 hover:bg-gray-50"
                >
                  <FaSignOutAlt className="mr-2" />
                  {t('Sign out')}
                </button>
              </>
            )}
            {!isAuthenticated && (
              <>
                <Link
                  to="/login"
                  className="block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700"
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  {t('Login')}
                </Link>
                <Link
                  to="/register"
                  className="block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700"
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  {t('Register')}
                </Link>
                <Link
                  to="/what-are-digital-medals"
                  className="block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700"
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  {t('home.whatAreThey')}?
                </Link>
                <Link
                  to="/why-use-digital-medals"
                  className="block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700"
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  {t('home.whyUseThem')}?
                </Link>
              </>
            )}
            {/* Add Language Switcher to mobile menu */}
            <div className="pl-3 pr-4 py-2">
              <select
                onChange={(e) => changeLanguage(e.target.value)}
                value={i18n.language}
                className="w-full bg-white border border-gray-300 text-gray-700 py-2 px-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <option value="en">English</option>
                <option value="es">Español</option>
                <option value="fr">Français</option>
              </select>
            </div>
          </div>
        </div>
      </nav>

      {renderEmailVerificationBanner()}

      <main className="flex-grow">
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          {children}
        </div>
      </main>

      <footer className="bg-white">
        <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col sm:flex-row justify-center items-center space-y-2 sm:space-y-0 sm:space-x-4">
            <p className="text-sm text-gray-500">
              &copy; {new Date().getFullYear()} {t('Digital Medals')}. {t('All rights reserved')}.
            </p>
            <Link to="/privacy-policy" className="text-sm text-gray-500 hover:text-blue-700">
              {t('Privacy Policy')}
            </Link>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Layout;
