import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import { fetchCommonSkills, createSkill } from '../../api/routes/user/skills';
import { fetchCommonTechnologies, createTechnology } from '../../api/routes/user/technologies';
import { fetchUserProjects } from '../../api/routes/medals/projects';

const AddProjects = ({ initialData, onNext, onBack, medalData }) => {
  const { t } = useTranslation();
  const [projects, setProjects] = useState(initialData.projects || []);
  const [existingProjects, setExistingProjects] = useState([]);
  const [selectedExistingProjects, setSelectedExistingProjects] = useState([]);
  const [newProject, setNewProject] = useState({ 
    name: '', 
    role: '', 
    responsibilities: '', 
    achievements: '', 
    challenges: '',
    skills: [],
    technologies: []
  });
  const [availableSkills, setAvailableSkills] = useState([]);
  const [availableTechnologies, setAvailableTechnologies] = useState([]);

  useEffect(() => {
    const loadData = async () => {
      try {
        const [skillsResponse, technologiesResponse, projectsResponse] = await Promise.all([
          fetchCommonSkills(),
          fetchCommonTechnologies(),
          fetchUserProjects()
        ]);
        setAvailableSkills(skillsResponse);
        setAvailableTechnologies(technologiesResponse);
        setExistingProjects(projectsResponse);
      } catch (error) {
        console.error('Error loading data:', error);
      }
    };

    loadData();
  }, []);

  const handleExistingProjectsChange = (selectedOptions) => {
    const selectedProjects = selectedOptions || [];
    setSelectedExistingProjects(selectedProjects);
    
    // Add selected projects to the projects list if they're not already there
    const newProjects = selectedProjects.map(option => ({
      id: option.value.id,
      name: option.value.name,
      role: option.value.role,
      responsibilities: option.value.responsibilities,
      challenges: option.value.challenges,
      skills: option.value.skills.map(s => ({
        id: s.skill.id,
        name: s.skill.name
      })),
      technologies: option.value.technologies.map(t => ({
        id: t.technology.id,
        name: t.technology.name
      }))
    }));

    // Filter out any duplicates
    const uniqueProjects = [...projects];
    newProjects.forEach(newProj => {
      if (!uniqueProjects.some(p => p.id === newProj.id)) {
        uniqueProjects.push(newProj);
      }
    });

    setProjects(uniqueProjects);
  };

  const addProject = () => {
    const projectToAdd = {
      ...newProject,
      skills: newProject.skills.map(skill => ({ id: skill.value, name: skill.label })),
      technologies: newProject.technologies.map(tech => ({ id: tech.value, name: tech.label }))
    };
    setProjects([...projects, projectToAdd]);
    setNewProject({ 
      name: '', 
      role: '', 
      responsibilities: '', 
      achievements: '', 
      challenges: '',
      skills: [],
      technologies: []
    });
  };

  const deleteProject = (index) => {
    setProjects(projects.filter((_, i) => i !== index));
  };

  const handleNext = () => {
    const processedProjects = projects.map(project => ({
      id: project.id || null, // Include ID if it's an existing project
      name: project.name,
      role: project.role,
      responsibilities: project.responsibilities,
      challenges: project.challenges,
      skills: project.skills.map(skill => ({ id: skill.id, name: skill.name })),
      technologies: project.technologies.map(tech => ({ id: tech.id, name: tech.name }))
    }));
    onNext({ projects: processedProjects });
  };

  const handleSkillChange = async (newValue, actionMeta) => {
    if (actionMeta.action === 'create-option') {
      try {
        const newSkillName = actionMeta.option.label;
        const createdSkill = await createSkill(newSkillName);
        const newSkillOption = { value: createdSkill.id, label: createdSkill.name };
        setNewProject({ ...newProject, skills: [...newProject.skills, newSkillOption] });
        setAvailableSkills([...availableSkills, newSkillOption]);
      } catch (error) {
        console.error('Error creating skill:', error);
      }
    } else {
      setNewProject({ ...newProject, skills: newValue });
    }
  };

  const handleTechnologyChange = async (newValue, actionMeta) => {
    if (actionMeta.action === 'create-option') {
      try {
        const newTechnologyName = actionMeta.option.label;
        const createdTechnology = await createTechnology(newTechnologyName);
        const newTechOption = { value: createdTechnology.id, label: createdTechnology.name };
        setNewProject({ ...newProject, technologies: [...newProject.technologies, newTechOption] });
        setAvailableTechnologies([...availableTechnologies, newTechOption]);
      } catch (error) {
        console.error('Error creating technology:', error);
      }
    } else {
      setNewProject({ ...newProject, technologies: newValue });
    }
  };

  return (
    <div className="w-full min-h-screen flex items-start justify-center p-4">
      <div className="w-full max-w-2xl bg-white">
        <div className="flex justify-between items-center mb-8 p-4">    
          <button
            onClick={() => onBack({ projects })}
            className="bg-gray-500 text-white py-2 px-4 rounded-md hover:bg-gray-600 transition duration-300"
          >
            {t('projects.back')}
          </button>
          <div className="hidden md:block w-3/4 bg-white text-center">
            <p><strong>{medalData.title}</strong></p>
            <p>
              <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                {medalData.categoryName || ''}
              </span>
            </p>
          </div>
          <button
            onClick={handleNext}
            className={`py-2 px-4 rounded-md transition duration-300 ${
              projects.length === 0
                ? 'bg-yellow-500 hover:bg-yellow-600 text-black'
                : 'bg-blue-500 hover:bg-blue-600 text-white'
            }`}
          >
            {projects.length === 0 ? t('common.skip') : t('common.next')}
          </button>
        </div>

        <div className="text-center mb-6">
          <h2 className="text-4xl font-bold text-gray-900 mb-2">{t('projects.title')}</h2>
          <p className="text-sm text-gray-700 rounded-lg p-4 border border-blue-300 bg-blue-100">
            {t('projects.description')}
          </p>
        </div>
        <div className="space-y-4">
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              {t('projects.selectExisting')}
            </label>
            <Select
              isMulti
              value={selectedExistingProjects}
              onChange={handleExistingProjectsChange}
              options={existingProjects.map(project => ({
                value: project,
                label: project.name
              }))}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder={t('projects.selectExistingPlaceholder')}
            />
          </div>

          <div className="text-center mb-6">
            <h3 className="text-xl font-bold text-gray-900 mb-2">
              {t('projects.orAddNew')}
            </h3>
          </div>

          <input
            type="text"
            value={newProject.name}
            onChange={(e) => setNewProject({ ...newProject, name: e.target.value })}
            placeholder={t('projects.projectName')}
            className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <input
            type="text"
            value={newProject.role}
            onChange={(e) => setNewProject({ ...newProject, role: e.target.value })}
            placeholder={t('projects.role')}
            className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <textarea
            value={newProject.responsibilities}
            onChange={(e) => setNewProject({ ...newProject, responsibilities: e.target.value })}
            placeholder={t('projects.responsibilities')}
            className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          ></textarea>
          <textarea
            value={newProject.challenges}
            onChange={(e) => setNewProject({ ...newProject, challenges: e.target.value })}
            placeholder={t('projects.challengesFaced')}
            className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          ></textarea>
          <CreatableSelect
            isMulti
            onChange={handleSkillChange}
            options={availableSkills.map(skill => ({ value: skill.id, label: skill.name }))}
            value={newProject.skills}
            placeholder={t('skills.searchOrAdd')}
            className="mb-4"
          />
          <CreatableSelect
            isMulti
            onChange={handleTechnologyChange}
            options={availableTechnologies.map(tech => ({ value: tech.id, label: tech.name }))}
            value={newProject.technologies}
            placeholder={t('technologies.searchOrAdd')}
            className="mb-4"
          />
          <button
            onClick={addProject}
            disabled={!newProject.name}
            className={`w-full py-3 rounded-lg transition duration-300 ${
              newProject.name ? 'bg-blue-500 hover:bg-blue-600 text-white' : 'bg-gray-300 text-gray-500 cursor-not-allowed'
            }`}
          >
            {t('projects.addProject')}
          </button>
          
          <div className="list-none pl-0 space-y-4">
            {projects.map((proj, index) => (
              <div key={`project-${index}`} className="bg-blue-100 text-blue-800 rounded-lg p-4 text-sm font-semibold flex justify-between items-center">
                <div>
                  <h3 className="text-lg font-semibold">{proj.name}</h3>
                  <p><strong>{t('projects.details.role')}:</strong> {proj.role}</p>
                  <p><strong>{t('projects.details.responsibilities')}:</strong> {proj.responsibilities}</p>
                  <p><strong>{t('projects.details.challenges')}:</strong> {proj.challenges}</p>
                  <p><strong>{t('projects.details.skills')}:</strong> {proj.skills.map(skill => skill.name).join(', ')}</p>
                  <p><strong>{t('projects.details.technologies')}:</strong> {proj.technologies.map(tech => tech.name).join(', ')}</p>
                </div>
                <span
                  onClick={() => deleteProject(index)}
                  className="bg-white rounded px-1 text-xs uppercase font-bold text-red-500 border border-red-500"
                >
                  {t('medals.delete')}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddProjects;
