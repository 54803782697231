import instance from "../../axios";
import { toast } from 'react-toastify';
import { t } from 'i18next';

export const fetchInstitutions = async () => {
    const response = await instance.get('/institutions');
    return response.data.data;
};  
export const createInstitution = async (institution) => {
    const response = await instance.post('/institutions/store', institution);
    return response.data.data;
};