import axios from 'axios';
import * as employmentRoutes from './routes/user/employments';
import * as infoRoutes from './routes/user/info';
import * as skillRoutes from './routes/user/skills';
import * as technologyRoutes from './routes/user/technologies';
import * as socialLinkRoutes from './routes/user/social-links';
import * as apiRoutes from './routes/api';
import * as publicRoutes from './routes/public';
import * as medalRoutes from './routes/medals/medals';
import * as projectsRoutes from './routes/medals/projects';
import * as authRoutes from './routes/auth/auth';
import * as goalsRoutes from './routes/goals/goals';
import * as categoriesRoutes from './routes/medals/categories';
import * as institutionsRoutes from './routes/institutions/institutions';
import * as certificationsRoutes from './routes/certifications/certifications';
import * as resumeRoutes from './routes/user/resume';
// Create axios instance with the correct base URL
const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL || 'http://digitalmedals.local/api', // Update this to match your API URL
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    }
});

// Add request interceptor to handle authentication
instance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Add response interceptor to handle common errors
instance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response?.status === 401) {
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            window.location.href = '/login';
        }
        return Promise.reject(error);
    }
);

export const { getUserByUsername } = publicRoutes;
// Export the imported functions
export const {
  storeUserTechnology,
  fetchUserTechnologies,
  removeUserTechnology,
  createTechnology,
  fetchCommonTechnologies,
  updateUserTechnology
} = technologyRoutes;

export const {
  fetchLatestUserInfo,
  saveUserName,
  saveUserHeadline,
  saveUserDescription,
  saveUserSocialLinks,
  saveUserGeneral,
  getUserLanguage,
  saveUserProfile,
  saveUserUserName,
  updateUserProfilePicture,
} = infoRoutes;

export const {
  getResumeData
} = resumeRoutes;

export const {
  updateUserEmployment,
  deleteUserEmployment,
  fetchUserEmployments,
  storeUserEmployment,
  createEmployer,
  createTitle
} = employmentRoutes;

export const {
  fetchEmployers,
  fetchPositions,
  fetchSocialPlatforms,
  fetchRandomQuote,
  fetchTechnologies,
  fetchSkills
} = apiRoutes;

export const {
  storeUserSkill,
  fetchUserSkills,
  removeUserSkill,
  createSkill,
  fetchCommonSkills,
  updateUserSkill
} = skillRoutes;

export const {
  updateSocialLink,
  deleteSocialLink,
  addSocialLink,  
} = socialLinkRoutes;

export const {
  storeMedal,
  fetchMedals,
  fetchMedalById,
  updateMedal,
  deleteMedal
} = medalRoutes;

export const {
  fetchMedalCategories
} = categoriesRoutes;

export const {
  fetchUserProjects,
  fetchUserProjectsSkills,
  fetchUserProjectsTechnologies,
  createProject,
  updateProject,
  deleteProject,
} = projectsRoutes;

export const {
  loginUser,
  signOut,
  resetPassword,
  updateUserPassword,
  logout,
  checkAuthToken,
  register,
  resendVerificationEmail,
  verifyEmail
} = authRoutes;

export const {
  fetchGoals,
  createGoal,
  updateGoal,
  deleteGoal
} = goalsRoutes;

export const {
  fetchCertifications,
  createCertification,
  updateCertification,
  deleteCertification
} = certificationsRoutes;

export const {
  fetchInstitutions,
  createInstitution
} = institutionsRoutes;

export default instance;


