import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { fetchSkills, storeUserSkill, fetchUserSkills, removeUserSkill, createSkill, fetchCommonSkills, updateUserSkill } from '../../api/axios';
import { FaTimes, FaSortAlphaDown, FaSortAlphaUp, FaSortNumericDown, FaSortNumericUp, FaPlus, FaCode } from 'react-icons/fa';
import CreatableSelect from 'react-select/creatable';
import ProficiencyModal from '../Proficiency/ProficiencyModal';
import { useTranslation } from 'react-i18next';
import PageHero from '../Common/PageHero';
import { useNavigate } from 'react-router-dom';
import { getProficiencyColor, getProficiencyBadgeColor } from '../Proficiency/Proficiency';

function SkillsList() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [skills, setSkills] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedSkill, setSelectedSkill] = useState(null);
  const [userSkills, setUserSkills] = useState([]);
  const [commonSkills, setCommonSkills] = useState([]);
  const [userSkillsSortMethod, setUserSkillsSortMethod] = useState('proficiency');
  const [sortDirection, setSortDirection] = useState('asc');
  const [availableSkills, setAvailableSkills] = useState([]);

  useEffect(() => {
    const getSkills = async () => {
      try {
        const [skillsResponse, commonSkillsResponse, userSkillsResponse] = await Promise.all([
          fetchSkills(),
          fetchCommonSkills(),
          fetchUserSkills()
        ]);
       
        const fetchedSkills = skillsResponse.data || [];
        const fetchedCommonSkills = commonSkillsResponse || [];
        const fetchedUserSkills = Array.isArray(userSkillsResponse.data.data) 
          ? userSkillsResponse.data.data
          : [];
        setSkills(fetchedSkills);
        setCommonSkills(fetchedCommonSkills);
        setUserSkills(fetchedUserSkills);
        setAvailableSkills(fetchedCommonSkills);
        setLoading(false);

        // Sort user skills by proficiency desc initially
        const sortedSkills = sortUserSkills(fetchedUserSkills, 'proficiency', 'asc');
        setUserSkills(sortedSkills);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to fetch skills or user skills');
        setLoading(false);
      }
    };

    getSkills();
  }, []);

  const handleSkillClick = (skill) => {
    setSelectedSkill(skill);
  };

  const handleProficiencySubmit = async (proficiency) => {
    if (selectedSkill && proficiency) {
      try {
        // Check if this is an update or new skill
        if (selectedSkill.currentProficiency !== undefined) {
          // Update existing skill
          await updateUserSkill(selectedSkill.id, proficiency);
          
          setUserSkills(prevSkills => prevSkills.map(skill => {
            if (skill.skill_id === selectedSkill.id) {
              return {
                ...skill,
                proficiency_id: proficiency,
                proficiency_name: ['Beginner', 'Intermediate', 'Advanced', 'Expert'][proficiency - 1]
              };
            }
            return skill;
          }));
        } else {
          // Add new skill
          await storeUserSkill(selectedSkill.id, proficiency);
          
          setUserSkills(prevSkills => [
            ...prevSkills,
            {
              id: selectedSkill.id,
              skill_id: selectedSkill.id,
              skill_name: selectedSkill.name,
              proficiency_name: ['Beginner', 'Intermediate', 'Advanced', 'Expert'][proficiency - 1]
            }
          ]);
          
          setAvailableSkills(prevSkills => 
            prevSkills.filter(skill => skill.id !== selectedSkill.id)
          );
        }
        
        setSelectedSkill(null);
      } catch (error) {
        console.error('Error updating user skill:', error);
      }
    }
  };

  const handleRemoveSkill = async (skillId) => {
    try {
      await removeUserSkill(skillId);
      setUserSkills(prevSkills => prevSkills.filter(skill => skill.skill_id !== skillId));
    } catch (error) {
      console.error('Error removing user skill:', error);
    }
  };

  const toggleSort = (method) => {
    if (method === userSkillsSortMethod) {
      setSortDirection(prevDirection => prevDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setUserSkillsSortMethod(method);
      setSortDirection('desc');
    }
    setUserSkills(prevSkills => sortUserSkills(prevSkills, method, sortDirection === 'asc' ? 'desc' : 'asc'));
  };

  const sortUserSkills = (skills, method, direction) => {
    return [...skills].sort((a, b) => {
      let comparison = 0;
      if (method === 'alphabetical') {
        comparison = a.skill_name.localeCompare(b.skill_name);
      } else if (method === 'proficiency') {
        const proficiencyOrder = ['Beginner', 'Intermediate', 'Advanced', 'Expert'];
        comparison = proficiencyOrder.indexOf(b.proficiency_name) - proficiencyOrder.indexOf(a.proficiency_name);
      }
      return direction === 'asc' ? comparison : -comparison;
    });
  };

  const handleCreateSkill = async (inputValue) => {
    try {
      const createdSkill = await createSkill(inputValue);
      setSkills(prevSkills => [...prevSkills, createdSkill]);
      handleSkillClick(createdSkill);
    } catch (error) {
      console.error("Error creating skill:", error);
    }
  };

  const renderAvailableSkills = () => {
    return availableSkills.map(skill => (
      <button
        key={skill.id}
        className="flex items-center bg-gray-200 hover:bg-gray-300 text-gray-800 text-sm py-1 px-2 rounded transition-colors"
        onClick={() => handleSkillClick(skill)}
      >
        <FaPlus className="mr-1" />{skill.name}
      </button>
    ));
  };

  if (loading) return <div className="text-center text-2xl text-blue-600 mt-8">{t('skills.loading')}</div>;
  if (error) return <div className="text-center text-2xl text-red-600 mt-8">{error}</div>;
  if (skills.length === 0) return <div className="text-center text-2xl text-blue-600 mt-8">{t('skills.noSkills')}</div>;

  return (
    <div className="min-h-screen">
      <div className="container mx-auto px-4 py-8">
        <div className="bg-white rounded-lg overflow-hidden">
          <PageHero
            title={t('skills.title')}
            buttons={[
              {
                text: "My Profile",
                onClick: () => navigate('/profile')
              },
              {
                text: "My Technologies",
                onClick: () => navigate('/technologies')
              }
            ]}
          />
          <div className="flex flex-col md:flex-row gap-8 pb-8">
            <div className="bg-white shadow-md p-6 flex-1 order-2 md:order-1">
              <h2 className="text-2xl font-bold mb-4 text-gray-800">{t('skills.addSkills')}</h2>
              <CreatableSelect
                isClearable
                onChange={(newValue) => {
                  if (newValue && newValue.__isNew__) {
                    handleCreateSkill(newValue.label);
                  } else if (newValue) {
                    handleSkillClick(newValue.value);
                  }
                }}
                onCreateOption={handleCreateSkill}
                options={skills.map(skill => ({ value: skill, label: skill.name }))}
                placeholder={t('skills.searchOrAdd')}
                className="mb-4"
              />
              <hr className="my-4" />
              <div className="mt-4">
                <div className="flex flex-wrap justify-center gap-2">
                  {availableSkills.map(skill => (
                    <button
                      key={skill.id}
                      className="flex items-center bg-gray-200 hover:bg-gray-300 text-gray-800 text-sm py-1 px-2 rounded transition-colors"
                      onClick={() => handleSkillClick(skill)}
                    >
                      <FaPlus className="mr-1" />{skill.name}
                    </button>
                  ))}
                </div>
              </div>
            </div>
            <div className="bg-white shadow-md p-6 flex-1 order-1 md:order-2">
              <h2 className="text-2xl font-bold mb-4 text-gray-800">{t('skills.yourSkillArsenal')}</h2>
              <p className="text-gray-600 text-sm mb-4 ml-2">{t('skills.clickOnSkillToEdit')}</p>
              <div className="flex space-x-4 mb-4">
                <button
                  onClick={() => toggleSort('alphabetical')}
                  className={`py-2 px-4 rounded ${userSkillsSortMethod === 'alphabetical' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-800'} hover:bg-blue-500 hover:text-white transition-colors`}
                >
                  {userSkillsSortMethod === 'alphabetical' && sortDirection === 'asc' ? <FaSortAlphaDown className="inline mr-2" /> : <FaSortAlphaUp className="inline mr-2" />} {t('skills.sortAZ')}
                </button>
                <button
                  onClick={() => toggleSort('proficiency')}
                  className={`py-2 px-4 rounded ${userSkillsSortMethod === 'proficiency' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-800'} hover:bg-blue-500 hover:text-white transition-colors`}
                >
                  {userSkillsSortMethod === 'proficiency' && sortDirection === 'asc' ? <FaSortNumericDown className="inline mr-2" /> : <FaSortNumericUp className="inline mr-2" />} {t('skills.sortProficiency')}
                </button>
              </div>
              <hr className="my-4" />
              <div className="flex flex-wrap gap-3">
                {userSkills.map(skill => (
                  <div 
                    key={skill.id} 
                    className={`${getProficiencyColor(skill.proficiency_name)} px-3 py-2 rounded shadow hover:shadow-md transition duration-200 ease-in-out relative group cursor-pointer`}
                    onClick={() => handleSkillClick({
                      id: skill.skill_id,
                      name: skill.skill_name,
                      currentProficiency: skill.proficiency_id
                    })}
                  >
                    <span className="text-sm font-medium">{skill.skill_name}</span>
                    <span className={`${getProficiencyBadgeColor(skill.proficiency_name)} text-white text-xs px-1.5 py-0.5 rounded ml-2`}>
                      {skill.proficiency_name ? skill.proficiency_name.slice(0, 3).toUpperCase() : '---'}
                    </span>
                    <button 
                      className="absolute -top-1 -right-1 bg-red-500 text-white rounded p-0.5 opacity-0 group-hover:opacity-100 transition-opacity"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRemoveSkill(skill.skill_id);
                      }}
                    >
                      <FaTimes className="text-xs" />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
      {selectedSkill && (
        <ProficiencyModal
          skill={selectedSkill}
          onSubmit={handleProficiencySubmit}
          onClose={() => setSelectedSkill(null)}
        />
      )}
    </div>
    </div>
    </div>
  );
}

export default SkillsList;
