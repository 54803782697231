import React from 'react';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import Page from '../Page';

function Home() {
  const { t } = useTranslation();

  return (
    <>
      <Page 
        title="Track Your Professional Growth"
        description="Digital Medals helps you track and showcase your professional achievements, skills, and career growth"
        keywords="professional growth, career tracking, skills development, digital achievements"
      />
      <div className="bg-white flex flex-col items-center min-h-screen mt-24">
        <div className="text-center max-w-3xl px-4">
          <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
            <span className="block xl:inline">{t('home.welcomeTo')}</span>{' '}
            <span className="block text-blue-600 xl:inline mb-4">{process.env.REACT_APP_NAME}</span>
          </h1>
          
          <h1 className="text-4xl font-bold text-primary-500 mb-8">
            {t('home.elevatingAchievements')}
          </h1>
          
          <div className="bg-gradient-to-r from-yellow-200 via-green-200 via-blue-200 to-purple-200 text-white p-6 rounded-lg shadow-lg mb-12">
            <h2 className="text-3xl font-bold mb-2 flex items-center justify-center">
              <img 
                src="/images/logo192.png"
                alt="Digital Medals Logo"
                className="w-6 h-6 mr-3 hidden md:block"
              />
              {t('home.boostCareer')}
            </h2>
            <p className="text-xl">{t('home.unlockPotential')}</p>
            <Link
              to="/why-use-digital-medals"
              className="mt-4 mr-4 inline-block bg-white text-blue-500 font-bold py-2 px-6 rounded hover:bg-blue-100 transition duration-300"
            >
              {t('home.whatAreDigitalMedals')}
            </Link>
            <Link
              to="/register"
              className="mt-4 inline-block bg-white text-blue-500 font-bold py-2 px-6 rounded hover:bg-blue-100 transition duration-300"
            >
              {t('home.getStarted')}
            </Link>
          </div>
          
          <ul className="text-lg text-gray-600 mb-12 space-y-4">
            <li className="flex items-center bg-gray-50 rounded-lg p-4 shadow-sm transition duration-300 hover:shadow-md">
              <svg className="w-6 h-6 text-blue-500 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"></path>
              </svg>

              {t('home.trackThings')}

            </li>
            <li className="flex items-center bg-gray-50 rounded-lg p-4 shadow-sm transition duration-300 hover:shadow-md">
              <svg className="w-6 h-6 text-blue-500 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"></path>
              </svg>
              {t('home.improvePerformance')}
            </li>
            <li className="flex items-center bg-gray-50 rounded-lg p-4 shadow-sm transition duration-300 hover:shadow-md">
              <svg className="w-6 h-6 text-blue-500 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"></path>
              </svg>
              {t('home.improveResume')}
            </li>
            <li className="flex items-center bg-blue-100 rounded-lg p-4 shadow-sm transition duration-300 hover:shadow-md">
              <svg className="w-6 h-6 text-blue-600 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z"></path>
              </svg>
              <span className="text-blue-700 font-semibold">{t('home.improveYourself')}</span>
            </li>
          </ul>
          
          <div className="flex justify-center space-x-4">
            <Link
              to="/login"
              className="px-8 py-3 bg-blue-500 text-white font-semibold rounded-md hover:bg-blue-600 transition duration-300"
            >
              {t('home.login')}
            </Link>
            <Link
              to="/register"
              className="px-8 py-3 bg-white text-gray-800 font-semibold rounded-md border border-gray-300 hover:bg-blue-100 transition duration-300"
            >
              {t('home.register')}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}


export default Home;
