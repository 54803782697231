import React, { useState, useCallback, useRef } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import imageCompression from 'browser-image-compression';
import { updateUserProfilePicture } from '../../api/axios';
import { FaUpload, FaCheck, FaTimes, FaUser } from 'react-icons/fa';
import Button from '../Common/Button';
import { useTranslation } from 'react-i18next';

const ProfilePicture = ({ currentImage, onUpdate }) => {
  const { t } = useTranslation();
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [imageError, setImageError] = useState(false);
  const [crop, setCrop] = useState({
    unit: '%',
    width: 80,
    height: 80,
    x: 10,
    y: 10,
    aspect: 1,
  });
  const [completedCrop, setCompletedCrop] = useState(null);
  const imgRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Add a ref for the file input
  const fileInputRef = useRef(null);

  // Add a handler to trigger file input click
  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setPreviewUrl(reader.result);
        setImageError(false);
      });
      reader.readAsDataURL(e.target.files[0]);
      setSelectedFile(e.target.files[0]);
    }
  };

  const onLoad = useCallback((img) => {
    imgRef.current = img;
    
    // Create a centered circular crop
    const size = 80; // Size of the circle as percentage
    const x = (100 - size) / 2;
    const y = (100 - size) / 2;
    
    const initialCrop = {
      unit: '%',
      width: size,
      height: size,
      x,
      y,
      aspect: 1
    };

    setCrop(initialCrop);
    setCompletedCrop(initialCrop); // Set the initial crop as completed crop
  }, []);

  const handleImageError = () => {
    setImageError(true);
  };

  const getCroppedImg = async (image, crop) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve) => {
      canvas.toBlob(async (blob) => {
        const compressedFile = await imageCompression(blob, {
          maxSizeMB: 1,
          maxWidthOrHeight: 500,
          useWebWorker: true,
        });
        resolve(compressedFile);
      }, 'image/jpeg', 0.9);
    });
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      setError(null);

      if (!completedCrop || !imgRef.current) {
        throw new Error(t('Please select an area to crop'));
      }

      const croppedImage = await getCroppedImg(imgRef.current, completedCrop);
      const response = await updateUserProfilePicture(croppedImage);
      
      if (response && response.profile_picture_url) {
        onUpdate(response.profile_picture_url);
      } else {
        throw new Error(t('Invalid response from server'));
      }
    } catch (err) {
      setError(err.message || t('Failed to update profile picture'));
    } finally {
      setLoading(false);
    }
  };

  const sanitizeImageUrl = (url) => {
    if (!url) return null;
    // Replace escaped forward slashes with regular forward slashes
    return url.replace(/\\/g, '');
  };

  const renderDefaultAvatar = () => (
    <div className="w-32 h-32 rounded-full bg-gray-200 flex items-center justify-center">
      <FaUser className="w-16 h-16 text-gray-400" />
    </div>
  );

  const renderCurrentImage = () => {
    if (!currentImage || imageError) {
      return renderDefaultAvatar();
    }

    return (
      <img
        src={sanitizeImageUrl(currentImage)}
        alt="Current profile"
        className="w-32 h-32 rounded-full object-cover"
        onError={handleImageError}
      />
    );
  };

  // Add new function to check if device has camera
  const isMobile = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  };

  return (
    <div className="space-y-4">
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded">
          {error}
        </div>
      )}

      <div className="flex justify-center">
        {!previewUrl ? (
          <div className="text-center">
            <div className="mb-4">
              {renderCurrentImage()}
            </div>
            <input
              ref={fileInputRef}
              type="file"
              className="hidden"
              accept={isMobile() ? "image/*;capture=camera" : "image/*"}
              capture={isMobile() ? "environment" : undefined}
              onChange={onSelectFile}
            />
            <Button 
              color="blue" 
              icon={FaUpload}
              onClick={handleButtonClick}
            >
              {isMobile() ? t('Take Photo') : t('Select New Image')}
            </Button>
          </div>
        ) : (
          <div className="space-y-4">
            <ReactCrop
              crop={crop}
              onChange={(newCrop) => setCrop(newCrop)}
              onComplete={(c) => setCompletedCrop(c)}
              aspect={1}
              circularCrop
              minWidth={80} // Prevent crop from being too small
              minHeight={80}
            >
              <img
                ref={onLoad}
                alt="Crop me"
                src={previewUrl}
                className="max-h-[400px] max-w-full"
                style={{ maxWidth: '100%' }}
              />
            </ReactCrop>
            <div className="flex justify-end space-x-2">
              <Button
                onClick={handleSave}
                disabled={loading || !completedCrop}
                color="green"
                icon={FaCheck}
              >
                {loading ? t('Saving...') : t('Save')}
              </Button>
              <Button
                onClick={() => {
                  setPreviewUrl(null);
                  setSelectedFile(null);
                  setCompletedCrop(null);
                }}
                color="gray"
                icon={FaTimes}
              >
                {t('Cancel')}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfilePicture;
