import React, { useState, useEffect } from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { FaMedal, FaUser, FaUserTie, FaHouseUser, FaCode } from 'react-icons/fa';
import { fetchMedals } from '../../api/axios';
import { format, parseISO } from 'date-fns';
import { useUser } from '../../contexts/UserContext';

const VisualTimeline = () => {
    const { user } = useUser();
    const [medals, setMedals] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [expandedSections, setExpandedSections] = useState({});

    useEffect(() => {
        const loadMedals = async () => {
            try {
                if (!user) return;
                setIsLoading(true);
                const response = await fetchMedals();
                const sortedMedals = (response || []).sort((a, b) => 
                    new Date(b.startDate) - new Date(a.startDate)
                );
                setMedals(sortedMedals);
            } catch (error) {
                console.error('Failed to load medals:', error);
                setError(error);
            } finally {
                setIsLoading(false);
            }
        };

        loadMedals();
    }, [user]);

    // console.log('Medals data:', medals);

    const getCategoryIcon = (category) => {
        switch (category) {
            case 'Personal': return <FaUser />;
            case 'Professional': return <FaUserTie />;
            case 'Community': return <FaHouseUser />;
            case 'Education': return <FaCode />;
            default: return <FaMedal />;
        }
    };

    const getCategoryColor = (category) => {
        switch (category) {
            case 'Personal': return '#4299e1'; // blue
            case 'Professional': return '#48bb78'; // green
            case 'Community': return '#ed8936'; // orange
            case 'Education': return '#9f7aea'; // purple
            default: return '#f6b93b'; // default gold
        }
    };

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = parseISO(dateString);
        return format(date, 'MMM d, yyyy');
    };

    const toggleSection = (medalId, sectionName) => {
        setExpandedSections(prev => ({
            ...prev,
            [`${medalId}-${sectionName}`]: !prev[`${medalId}-${sectionName}`]
        }));
    };

    // Helper component for section headers
    const SectionHeader = ({ medalId, title, sectionName, itemCount }) => (
        <button
            onClick={() => toggleSection(medalId, sectionName)}
            className="w-full flex justify-between items-center py-2 text-left"
        >
            <h4 className="font-semibold text-lg">{title} ({itemCount})</h4>
            <svg
                className={`w-5 h-5 transform transition-transform ${
                    expandedSections[`${medalId}-${sectionName}`] ? 'rotate-180' : ''
                }`}
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
            >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
            </svg>
        </button>
    );

    const IconWithLabel = ({ category, date, icon }) => (
        <div className="flex flex-col items-center gap-1">
            <div className="text-xl">{icon}</div>
            <div className="absolute top-full left-1/2 transform -translate-x-1/2 mt-2 whitespace-nowrap">
                <span className="text-xs font-medium bg-gray-100 text-gray-800 px-2 py-1 rounded-full">
                    {category}
                </span>
            </div>
        </div>
    );

    if (isLoading) {
        return <div className="flex justify-center items-center min-h-screen">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
        </div>;
    }

    if (error) {
        return <div className="text-center text-red-500 p-4">
            Error loading medals: {error.message}
        </div>;
    }

    if (!medals || medals.length === 0) {
        return <div className="text-center text-gray-500 p-4">
            No medals found. Start adding some achievements!
        </div>;
    }

    return (
        <div className="min-h-screen bg-gray-50 py-8">
            <VerticalTimeline className="vertical-timeline-custom-line">
                {medals.map((medal, index) => (
                    <VerticalTimelineElement
                        key={medal.id}
                        className="vertical-timeline-element"
                        position={index % 2 === 0 ? 'left' : 'right'}
                        iconStyle={{ 
                            background: getCategoryColor(medal.category.category), 
                            color: '#fff',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '60px',
                            height: '60px',
                            margin: '0 auto',
                            borderRadius: '50%',
                            boxShadow: '0 3px 6px rgba(0,0,0,0.16)'
                        }}
                        icon={
                            <IconWithLabel 
                                category={medal.category.category}
                                date={`${formatDate(medal.startDate)} - ${medal.endDate ? formatDate(medal.endDate) : 'Present'}`}
                                icon={getCategoryIcon(medal.category.category)}
                            />
                        }
                        contentStyle={{
                            background: '#fff',
                            boxShadow: '0 3px 6px rgba(0,0,0,0.08)',
                            borderRadius: '0.5rem',
                            padding: '0'
                        }}
                        contentArrowStyle={{
                            borderRight: '7px solid #fff'
                        }}
                    >
                        <div className="text-center text-sm text-gray-600 mb-4 bg-gray-50 py-2 rounded-t-lg">
                            {formatDate(medal.startDate)} - {medal.endDate ? formatDate(medal.endDate) : 'Present'}
                        </div>
                        <div className="p-6">
                            <h3 className="text-xl font-bold mb-2">{medal.title}</h3>
                            <span className="inline-block px-3 py-1 rounded-full text-sm font-medium mb-4"
                                  style={{ backgroundColor: getCategoryColor(medal.category.category) + '20', color: getCategoryColor(medal.category.category) }}>
                                {medal.category.category}
                            </span>

                            {/* Projects Section */}
                            {medal.projects && medal.projects.length > 0 && (
                                <div className="mb-6">
                                    <SectionHeader
                                        medalId={medal.id}
                                        title="Projects"
                                        sectionName="projects"
                                        itemCount={medal.projects.length}
                                    />
                                    {expandedSections[`${medal.id}-projects`] && (
                                        <div className="mt-3">
                                            {medal.projects.map((project, index) => (
                                                <div key={index} className="bg-white p-4 rounded-lg shadow-sm mb-3">
                                                    <h5 className="font-medium text-gray-900">{project.name}</h5>
                                                    <p className="text-sm text-gray-600 mt-1"><span className="font-medium">Role:</span> {project.role}</p>
                                                    <p className="text-sm text-gray-600"><span className="font-medium">Responsibilities:</span> {project.responsibilities}</p>
                                                    <p className="text-sm text-gray-600"><span className="font-medium">Challenges:</span> {project.challenges}</p>
                                                    <div className="mt-2 flex flex-wrap gap-2">
                                                        {project.skills?.map((skill, i) => (
                                                            <span key={i} className="px-2 py-1 bg-purple-100 text-purple-800 text-xs rounded-full">
                                                                {skill.skill.name}
                                                            </span>
                                                        ))}
                                                        {project.technologies?.map((tech, i) => (
                                                            <span key={i} className="px-2 py-1 bg-blue-100 text-blue-800 text-xs rounded-full">
                                                                {tech.technology.name}
                                                            </span>
                                                        ))}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            )}

                            {/* Goals Section */}
                            {medal.goals && medal.goals.length > 0 && (
                                <div className="mb-6">
                                    <SectionHeader
                                        medalId={medal.id}
                                        title="Goals"
                                        sectionName="goals"
                                        itemCount={medal.goals.length}
                                    />
                                    {expandedSections[`${medal.id}-goals`] && (
                                        <div className="mt-3">
                                            {medal.goals.map((goalItem, index) => (
                                                <div key={index} className="bg-white p-4 rounded-lg shadow-sm mb-3">
                                                    <h5 className="font-medium text-gray-900">{goalItem.goal.description}</h5>
                                                    <p className="text-sm text-gray-600"><span className="font-medium">Objective:</span> {goalItem.goal.objective}</p>
                                                    <p className="text-sm text-gray-600"><span className="font-medium">Progress:</span> {goalItem.goal.progress}</p>
                                                    {goalItem.goal.target_date && (
                                                        <p className="text-sm text-gray-600">
                                                            <span className="font-medium">Target Date:</span> {formatDate(goalItem.goal.target_date)}
                                                        </p>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            )}

                            {/* Accomplishments Section */}
                            {medal.accomplishments && medal.accomplishments.length > 0 && (
                                <div className="mb-6">
                                    <SectionHeader
                                        medalId={medal.id}
                                        title="Accomplishments"
                                        sectionName="accomplishments"
                                        itemCount={medal.accomplishments.length}
                                    />
                                    {expandedSections[`${medal.id}-accomplishments`] && (
                                        <ul className="list-disc list-inside space-y-2 mt-3">
                                            {medal.accomplishments.map((acc, index) => (
                                                <li key={index} className="text-gray-700">{acc.accomplishment}</li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            )}

                            {/* Lessons Section */}
                            {medal.lessons && medal.lessons.length > 0 && (
                                <div className="mb-6">
                                    <SectionHeader
                                        medalId={medal.id}
                                        title="Lessons Learned"
                                        sectionName="lessons"
                                        itemCount={medal.lessons.length}
                                    />
                                    {expandedSections[`${medal.id}-lessons`] && (
                                        <ul className="list-disc list-inside space-y-2 mt-3">
                                            {medal.lessons.map((lesson, index) => (
                                                <li key={index} className="text-gray-700">{lesson.lesson}</li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            )}

                            {/* Feedback Section */}
                            {medal.feedbacks && medal.feedbacks.length > 0 && (
                                <div>
                                    <SectionHeader
                                        medalId={medal.id}
                                        title="Feedback"
                                        sectionName="feedback"
                                        itemCount={medal.feedbacks.length}
                                    />
                                    {expandedSections[`${medal.id}-feedback`] && (
                                        <div className="mt-3">
                                            {medal.feedbacks.map((feedback, index) => (
                                                <div key={index} className="bg-white p-4 rounded-lg shadow-sm mb-3">
                                                    <p className="text-green-600 mb-2">{feedback.positive}</p>
                                                    <p className="text-orange-600 mb-2">{feedback.constructive}</p>
                                                    <p className="text-sm text-gray-500">- {feedback.given_by}</p>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </VerticalTimelineElement>
                ))}
            </VerticalTimeline>
        </div>
    );
};

export default VisualTimeline;
