import instance from '../../axios';
import { toast } from 'react-toastify';
import { t } from 'i18next';

export const fetchUserProjects = async () => {
    try {
        const response = await instance.get('/projects/');
        return response.data.data;
    } catch (error) {
        console.error('Error fetching medals:', error);
        throw error;
    }
};

export const fetchUserProjectsSkills = async () => {
    try {
        const response = await instance.get('/projects/skills');
        return response.data.data;
    } catch (error) {
        console.error('Error fetching projects skills:', error);
        throw error;
    }
};
export const fetchUserProjectsTechnologies = async () => {
    try {
        const response = await instance.get('/projects/technologies');
        return response.data.data;
    } catch (error) {
        console.error('Error fetching projects technologies:', error);
        throw error;
    }
};

export const createProject = async (project) => {
    try {
        const response = await instance.post('/projects/store', project);
        if (response.data.data) {
            toast.success('Project created successfully');
            return response.data.data;
        }
    } catch (error) {
        if (error.response?.data?.errors) {
            // Convert validation errors object to array of messages
            const errorMessages = Object.values(error.response.data.errors)
                .flat()
                .join(', ');
            toast.error(errorMessages);
        } else if (error.response?.data?.message) {
            toast.error(error.response.data.message);
        } else {
            toast.error(t('userInfo.descriptionUpdateError'));
        }
        throw error;
    }
};

export const updateProject = async (project) => {
    try {
        const response = await instance.put(`/projects/update/${project.id}`, project);
        if (response.data.data) {
            toast.success('Project updated successfully');
            return response.data.data;
        }
    } catch (error) {
        if (error.response?.data?.errors) {
            // Convert validation errors object to array of messages
            const errorMessages = Object.values(error.response.data.errors)
                .flat()
                .join(', ');
            toast.error(errorMessages);
        } else if (error.response?.data?.message) {
            toast.error(error.response.data.message);
        } else {
            toast.error(t('userInfo.descriptionUpdateError'));
        }
        throw error;
    }
};

export const deleteProject = async (id) => {
    const response = await instance.delete(`/projects/destroy/${id}`);
    if (response.status === 200) {
        toast.success('Project deleted successfully');
    } else {
        toast.error('Error deleting project');
    }
    return response.data;
};
