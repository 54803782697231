const Button = ({ 
  onClick, 
  color = 'blue', 
  children, 
  className = '',
  icon: Icon,
  type = 'button',
  disabled = false
}) => {
  let colorClasses = '';
  
  if (color === 'link') {
    colorClasses = 'text-blue-500 hover:text-blue-700 shadow-none';
  } else if (color === 'none') {
    colorClasses = '';
  } else {
    colorClasses = `bg-${color}-50 text-${color}-500 hover:bg-${color}-100`;
  }

  const baseClasses = `w-full sm:w-auto font-semibold py-2 px-4 rounded flex items-center justify-center gap-2`;
  const shadowClass = color !== 'link' && color !== 'none' ? 'shadow-md' : '';
  const disabledClasses = disabled ? 'opacity-50 cursor-not-allowed' : '';
  
  return (
    <button 
      onClick={onClick}
      type={type}
      disabled={disabled}
      className={`${baseClasses} ${colorClasses} ${shadowClass} ${disabledClasses} ${className}`}
    >
      {Icon && <Icon className="" />}
      {children}
    </button>
  );
};

export default Button;
