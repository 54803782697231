const PROFICIENCY_CONFIG = {
  beginner: {
    badge: 'bg-yellow-100 text-yellow-800 border-yellow-300',
    color: 'bg-yellow-200 text-yellow-800',
    badgeColor: 'bg-yellow-500',
    order: 3
  },
  intermediate: {
    badge: 'bg-orange-100 text-orange-800 border-orange-300',
    color: 'bg-orange-200 text-orange-800',
    badgeColor: 'bg-orange-500',
    order: 2
  },
  advanced: {
    badge: 'bg-blue-100 text-blue-800 border-blue-300',
    color: 'bg-blue-200 text-blue-800',
    badgeColor: 'bg-blue-500',
    order: 1
  },
  expert: {
    badge: 'bg-purple-100 text-purple-800 border-purple-300',
    color: 'bg-purple-200 text-purple-800',
    badgeColor: 'bg-purple-500',
    order: 0
  }
};

export const getProficiencyBadge = (proficiency) => {
  const config = PROFICIENCY_CONFIG[proficiency.toLowerCase()] || {
    badge: 'bg-gray-100 text-gray-800 border-gray-300'
  };

  return (
    <span className={`text-xs px-1.5 py-0.5 rounded border ${config.badge}`}>
      {proficiency}
    </span>
  );
};

export const sortByProficiencyAndName = (items) => {
  return items.sort((a, b) => {
    const profA = a.proficiency_name.toLowerCase();
    const profB = b.proficiency_name.toLowerCase();
    const orderA = PROFICIENCY_CONFIG[profA]?.order ?? 999;
    const orderB = PROFICIENCY_CONFIG[profB]?.order ?? 999;
    
    if (orderA !== orderB) {
      return orderA - orderB;
    }
    return (a.skill_name || a.technology_name || a.name).localeCompare(b.skill_name || b.technology_name || b.name);
  });
};

export const getProficiencyColor = (proficiency) => {
  return PROFICIENCY_CONFIG[proficiency.toLowerCase()]?.color || 'bg-gray-200 text-gray-800';
};

export const getProficiencyBadgeColor = (proficiency) => {
  return PROFICIENCY_CONFIG[proficiency.toLowerCase()]?.badgeColor || 'bg-gray-500';
};

export const getProficiencyColors = (proficiencyName) => {
  switch (proficiencyName.toLowerCase()) {
    case 'expert':
      return {
        bgColor: 'bg-purple-100',
        fromColor: 'from-purple-50',
        toColor: 'to-purple-200',
        textColor: 'text-purple-800',
        proficiencyAbbr: 'EXP'
      };
    case 'advanced':
      return {
        bgColor: 'bg-blue-100',
        fromColor: 'from-blue-50',
        toColor: 'to-blue-200',
        textColor: 'text-blue-800',
        proficiencyAbbr: 'ADV'
      };
    case 'intermediate':
      return {
        bgColor: 'bg-green-100',
        fromColor: 'from-green-50',
        toColor: 'to-green-200',
        textColor: 'text-green-800',
        proficiencyAbbr: 'INT'
      };
    case 'beginner':
      return {
        bgColor: 'bg-yellow-100',
        fromColor: 'from-yellow-50',
        toColor: 'to-yellow-200',
        textColor: 'text-yellow-800',
        proficiencyAbbr: 'BEG'
      };
    default:
      return {
        bgColor: 'bg-gray-100',
        fromColor: 'from-gray-50',
        toColor: 'to-gray-200',
        textColor: 'text-gray-800',
        proficiencyAbbr: '---'
      };
  }
};
