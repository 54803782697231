import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SectionHeader from '../Common/SectionHeader';
import PageHero from '../Common/PageHero';
import { FaChartLine, FaComments, FaTasks, FaCheckDouble, FaEye, FaChess, FaCode, FaLaptopCode, FaComment, FaCheck, FaHammer, FaProjectDiagram } from 'react-icons/fa';
import { Link } from 'react-router-dom';

function What() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (
        <div className="bg-white flex flex-col items-center min-h-screen mt-12 mb-12">
            <div className="text-center max-w-3xl px-4">
                <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                    <span className="block xl:inline">{t('home.whatAre')} <span className="text-blue-600">{process.env.REACT_APP_NAME}</span></span>
                </h1>
                <h3 className="text-2xl font-bold text-primary-500 mb-8 mt-4">
                    {t('home.digitalMedalSubtitle')}
                </h3>
                
                {/* <p className="text-lg text-gray-600 mb-8 mt-4">
                    {t('home.digitalMedalDescription')}
                </p> */}
                <PageHero 
                    title={t('home.digitalMedalIs')} 
                    textSize="text-xl" 
                    subtext={t('home.digitalMedalDescription')} 
                    
                />
                
                <SectionHeader 
                    title={t('home.digitalMedalIncludes')} 
                    className="my-8 ml-4"
                    buttons={[
                        {
                            text: t('Start Tracking Your Medals'),
                            onClick: () => {
                                navigate('/register');
                            },
                            className: "w-full sm:w-auto bg-white text-blue-500 font-semibold py-2 px-4 rounded shadow-md hover:bg-gray-100 flex items-center justify-center gap-2"
                        }
                    ]} 
                />
                <ul className="text-lg text-gray-600 mb-12 grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                    <li className="bg-gray-50 p-4">
                        <div className="flex items-center md:flex-row flex-col">
                        <FaHammer className="w-6 h-6 text-gray-500 mr-3" />
                        {t('home.accomplishments')}
                        </div>
                        <p className="text-sm text-left text-gray-500 mt-2 border border-gray-200 bg-gray-200 p-4 rounded">
                        {t('home.accomplishmentsText')}
                        </p>
                    </li>
                    <li className="bg-gray-50 p-4">
                        <div className="flex items-center md:flex-row flex-col">
                        <FaProjectDiagram className="w-6 h-6 text-gray-500 mr-3" />
                        {t('home.projects')}
                        </div>
                        <p className="text-sm text-left text-gray-500 mt-2 border border-gray-200 bg-gray-200 p-4 rounded">
                        {t('home.projectsText')}
                        </p>
                    </li>
                    <li className="bg-gray-50 p-4">
                        <div className="flex items-center md:flex-row flex-col">
                        <FaCode className="w-6 h-6 text-gray-500 mr-3" />
                        {t('home.skillsDevelopment')}
                        </div>
                        <p className="text-sm text-left text-gray-500 mt-2 border border-gray-200 bg-gray-200 p-4 rounded">
                            {t('home.skillsDevelopmentText')}
                        </p>
                    </li>
                    <li className="bg-gray-50 p-4">
                        <div className="flex items-center md:flex-row flex-col">
                        <FaLaptopCode className="w-6 h-6 text-gray-500 mr-3" />
                        {t('home.technologyDevelopment')}
                        </div>
                        <p className="text-sm text-left text-gray-500 mt-2 border border-gray-200 bg-gray-200 p-4 rounded">
                            {t('home.technologyDevelopmentText')}
                        </p>
                    </li>
                    <li className="bg-gray-50 p-4">
                        <div className="flex items-center md:flex-row flex-col">
                        <FaComment className="w-6 h-6 text-gray-500 mr-3" />
                        {t('home.feedback')}
                        </div>
                        <p className="text-sm text-left text-gray-500 mt-2 border border-gray-200 bg-gray-200 p-4 rounded">
                        {t('home.feedbackText')}
                        </p>
                    </li>
                    <li className="bg-gray-50 p-4">
                        <div className="flex items-center md:flex-row flex-col">
                        <FaCheck className="w-6 h-6 text-gray-500 mr-3" />
                        {t('home.futureGoals')}
                        </div>
                        <p className="text-sm text-left text-gray-500 mt-2 border border-gray-200 bg-gray-200 p-4 rounded">
                            {t('home.futureGoalsText')}
                        </p>
                    </li>
                </ul>
                <div className="bg-gradient-to-r from-yellow-200 via-green-200 via-blue-200 to-purple-200 text-white p-6 rounded-lg shadow-lg mb-12">
          <h2 className="text-3xl font-bold mb-2">{t('home.boostCareer')}</h2>
          <p className="text-xl">{t('home.unlockPotential')}</p>

          <Link
                        to="/why-use-digital-medals"
                            className="mt-4 mr-4 inline-block bg-white text-blue-500 font-bold py-2 px-6 rounded hover:bg-blue-100 transition duration-300"
                    >
                        {t('home.whyUseDigitalMedals')}

                    </Link>
                    <Link
                        to="/register"
                        className="mt-4 inline-block bg-white text-blue-500 font-bold py-2 px-6 rounded hover:bg-blue-100 transition duration-300"
                    >
                        {t('home.getStarted')}

                    </Link>
                </div>


            </div>
        </div>
    );
}

export default What;
