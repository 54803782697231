import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

const ProficiencyModal = ({ skill, onSubmit, onClose }) => {
  const [proficiency, setProficiency] = useState(skill.currentProficiency || null);

  const proficiencyLevels = [
    { level: 1, label: 'Beginner', color: 'bg-yellow-200', textColor: 'text-yellow-800', hoverColor: 'hover:bg-yellow-600', selectedColor: 'bg-yellow-800' },
    { level: 2, label: 'Intermediate', color: 'bg-orange-200', textColor: 'text-orange-800', hoverColor: 'hover:bg-orange-600', selectedColor: 'bg-orange-800' },
    { level: 3, label: 'Advanced', color: 'bg-blue-200', textColor: 'text-blue-800', hoverColor: 'hover:bg-blue-600', selectedColor: 'bg-blue-800' },
    { level: 4, label: 'Expert', color: 'bg-purple-200', textColor: 'text-purple-800', hoverColor: 'hover:bg-purple-600', selectedColor: 'bg-purple-800' }
  ];

  return ReactDOM.createPortal(
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50" onClick={onClose}>
      <div className="bg-white p-8 rounded-lg max-w-md w-full" onClick={e => e.stopPropagation()}>
        <h2 className="text-2xl font-bold mb-4 text-gray-800">Select Proficiency</h2>
        <p className="mb-4">How would you rate your proficiency in: <br /><span className="font-semibold">{skill.name}</span>?</p>
        <div className="grid grid-cols-2 gap-4 mb-4">
          {proficiencyLevels.map(({ level, label, color, textColor, hoverColor, selectedColor }) => (
            <button
              key={level}
              onClick={() => setProficiency(level)}
              className={`py-2 px-4 rounded transition-colors ${
                proficiency === level
                  ? `${selectedColor} text-white`
                  : `${color} ${textColor} ${hoverColor} hover:text-white`
              }`}
            >
              {label}
            </button>
          ))}
        </div>
        <div className="flex justify-center space-x-4">
          <button 
            className={`py-2 px-4 rounded transition-colors ${
              proficiency 
                ? 'bg-blue-600 text-white hover:bg-blue-700' 
                : 'bg-gray-300 text-gray-500 cursor-not-allowed'
            }`} 
            onClick={() => proficiency && onSubmit(proficiency)} 
            disabled={!proficiency}
          >
            Submit
          </button>
          <button className="py-2 px-4 bg-red-600 text-white rounded hover:bg-red-700 transition-colors" onClick={onClose}>Cancel</button>
        </div>
      </div>
    </div>,
    document.body
  );
};

ProficiencyModal.propTypes = {
  skill: PropTypes.shape({
    name: PropTypes.string.isRequired,
    currentProficiency: PropTypes.number
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

export default ProficiencyModal;
