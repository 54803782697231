import React from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { useNavigate, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FaGoogle, FaGithub, FaLinkedin } from 'react-icons/fa';
import { useUser } from '../../contexts/UserContext';
import { useAuth } from '../../contexts/AuthContext';
import axios from '../../api/axios';
import { useTranslation } from 'react-i18next';
import { loginUser, socialLogin } from '../../api/axios';

const Login = () => {
    const { t } = useTranslation();
    const { updateUser } = useUser();
    const { login } = useAuth();
    const navigate = useNavigate();

    const formSchema = z.object({
        email: z.string().email(t('login.invalidEmail')),
        password: z.string().min(8, t('login.passwordMinLength')),
    });

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: zodResolver(formSchema),
        defaultValues: {
            email: '',
            password: '',
        },
    });

    const onSubmit = async (values) => {
        try {
            const { token, user } = await loginUser(values);
            
            localStorage.setItem('token', token);
            await login(token, user);
            await updateUser();
            
            window.location.href = '/dashboard';
        } catch (error) {
            // Error handling is now done in loginUser function
        }
    };

    const handleGoogleLogin = () => {
        const baseUrl = 'https://accounts.google.com/o/oauth2/v2/auth';
        const params = new URLSearchParams({
            client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
            redirect_uri: `${process.env.REACT_APP_API_URL}/auth/google/callback`,
            response_type: 'code',
            scope: 'email profile',
            access_type: 'offline',
            state: Math.random().toString(36).substring(7),
        });

        window.location.href = `${baseUrl}?${params.toString()}`;
    };

    const handleLinkedInLogin = () => {
        const baseUrl = 'https://www.linkedin.com/oauth/v2/authorization';
        // const baseUrl = 'https://api.linkedin.com/v2/userinfo';
        const params = new URLSearchParams({
            client_id: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
            client_secret: process.env.REACT_APP_LINKEDIN_CLIENT_SECRET,
            redirect_uri: `${process.env.REACT_APP_API_URL}/auth/linkedin/callback`,
            response_type: 'code',
            scope: 'openid profile email',
            state: Math.random().toString(36).substring(7),
        });

        window.location.href = `${baseUrl}?${params.toString()}`;
    };

    return (
        <div className="min-h-screen flex flex-col  px-4 sm:px-6 lg:px-8">
            <div className="w-full max-w-md mx-auto">
                <h2 className="mt-6 mb-8 text-2xl sm:text-3xl font-bold text-center text-gray-900">
                    {t('login.title')}
                </h2>
                <div className="bg-white py-8 px-4 sm:px-6 shadow rounded-lg">
                    <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                {t('login.emailLabel')}
                            </label>
                            <div className="mt-1">
                                <input
                                    id="email"
                                    type="email"
                                    {...register("email")}
                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                />
                                {errors.email && <p className="mt-2 text-sm text-red-600">{errors.email.message}</p>}
                            </div>
                        </div>

                        <div>
                            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                {t('login.passwordLabel')}
                            </label>
                            <div className="mt-1">
                                <input
                                    id="password"
                                    type="password"
                                    {...register("password")}
                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                />
                                {errors.password && <p className="mt-2 text-sm text-red-600">{errors.password.message}</p>}
                            </div>
                        </div>

                        <div className="flex items-center justify-between">
                            <div className="flex items-center">
                                <input
                                    id="remember-me"
                                    name="remember-me"
                                    type="checkbox"
                                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                                />
                                <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                                    {t('login.rememberMe')}
                                </label>
                            </div>
                            <Link to="/forgot-password" className="text-sm font-medium text-blue-600 hover:text-blue-500">
                                {t('login.forgotPassword')}
                            </Link>
                        </div>

                        <button
                            type="submit"
                            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        >
                            {t('login.signIn')}
                        </button>
                    </form>

                    <div className="mt-6">
                        <div className="relative">
                            <div className="absolute inset-0 flex items-center">
                                <div className="w-full border-t border-gray-300" />
                            </div>
                            <div className="relative flex justify-center text-sm">
                                <span className="px-2 bg-white text-gray-500">
                                    {t('login.orContinueWith')}
                                </span>
                            </div>
                        </div>

                        <div className="mt-6 space-y-2">
                            <button
                                onClick={handleGoogleLogin}
                                type="button"
                                className="w-full flex justify-center items-center gap-2 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                            >
                                <FaGoogle className="text-red-600" />
                                {t('login.loginWithGoogle')}
                            </button>
                            
                            {/* <button
                                onClick={handleLinkedInLogin}
                                type="button"
                                className="w-full flex justify-center items-center gap-2 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                            >
                                <FaLinkedin className="text-[#0A66C2]" />
                                {t('login.loginWithLinkedIn')}
                            </button> */}
                        </div>
                    </div>

                    <div className="mt-6">
                        <p className="text-center text-sm text-gray-600">
                            {t('login.notAMember')}{' '}
                            <Link to="/signup" className="font-medium text-blue-600 hover:text-blue-500">
                                {t('login.signUp')}
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
