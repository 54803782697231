import React, { useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { resetPassword } from '../../api/axios';


const ResetPassword = () => {
  const { t } = useTranslation();
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // Updated token extraction
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get('email');
  const token = queryParams.get('token') || location.pathname.split('/password-reset/').pop();

  // Invalid token/email check updated with matching style
  if (!token || !email) {
    return (
      <div className="min-h-screen flex flex-col px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md mx-auto">
          <div className="bg-white py-8 px-4 sm:px-6 shadow rounded-lg">
            <div className="text-red-600 text-center">
              {t('password.invalidResetLink')}
            </div>
            <div className="mt-4 text-center">
              <button
                onClick={() => navigate('/forgot-password')}
                className="font-medium text-blue-600 hover:text-blue-500"
              >
                {t('password.tryAgain')}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      await resetPassword(token, email, password, passwordConfirmation);
      setSuccess(true);
      setTimeout(() => {
        navigate('/login');
      }, 2000);
    } catch (err) {
      setError(err.response?.data?.message || t('password.resetFailed'));
    }
  };

  return (
    <div className="min-h-screen flex flex-col px-4 sm:px-6 lg:px-8">
      <div className="w-full max-w-md mx-auto">
        <h2 className="mt-6 mb-8 text-2xl sm:text-3xl font-bold text-center text-gray-900">
          {t('password.resetPassword')}
        </h2>
        <div className="bg-white py-8 px-4 sm:px-6 shadow rounded-lg">
          {success ? (
            <div className="text-green-600 text-center">
              {t('password.resetSuccess')}
            </div>
          ) : (
            <form onSubmit={handleSubmit} className="space-y-6">
              {error && (
                <div className="text-red-600 text-center">{error}</div>
              )}
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  {t('password.email')}
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    type="email"
                    disabled
                    value={email}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm text-gray-500 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  {t('password.newPassword')}
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    type="password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="password-confirmation" className="block text-sm font-medium text-gray-700">
                  {t('password.confirmPassword')}
                </label>
                <div className="mt-1">
                  <input
                    id="password-confirmation"
                    type="password"
                    required
                    value={passwordConfirmation}
                    onChange={(e) => setPasswordConfirmation(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                </div>
              </div>
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                {t('password.resetButton')}
              </button>
            </form>
          )}
          <div className="mt-6">
            <p className="text-center text-sm text-gray-600">
              {t('password.backTo')}{' '}
              <Link to="/login" className="font-medium text-blue-600 hover:text-blue-500">
                {t('password.signIn')}
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
