import instance from '../../axios';
// import { toast } from 'react-toastify';
// import { t } from 'i18next';

export const fetchMedalCategories = async () => {
    try {
      const response = await instance.get('/medals/categories');
      return response.data.data;
    } catch (error) {
      console.error('Error fetching medal categories:', error);
      throw error;
    }
  };

