import React, { useState } from 'react';
import axios from '../../api/axios';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setMessage('');

    try {
      const response = await axios.post('/forgot-password', { email });
      setMessage(response.data.message || t('forgotPassword.emailSent'));
    } catch (error) {
      setMessage(error.response?.data?.message || t('forgotPassword.error'));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col px-4 sm:px-6 lg:px-8">
      <div className="w-full max-w-md mx-auto">
        <h2 className="mt-6 mb-8 text-2xl sm:text-3xl font-bold text-center text-gray-900">
          {t('forgotPassword.title')}
        </h2>
        <div className="bg-white py-8 px-4 sm:px-6 shadow rounded-lg">
          <p className="text-center text-gray-600 mb-8">
            {t('forgotPassword.instruction')}
          </p>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
                {t('forgotPassword.emailLabel')}
              </label>
              <div className="mt-1">
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  placeholder={t('forgotPassword.emailPlaceholder')}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>

            <button
              type="submit"
              disabled={isLoading}
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              {isLoading ? t('forgotPassword.sending') : t('forgotPassword.sendResetLink')}
            </button>
          </form>

          {message && (
            <div className="mt-4 text-center text-sm">
              <p className={message.includes('error') ? 'text-red-600' : 'text-green-600'}>
                {message}
              </p>
            </div>
          )}

          <div className="mt-6">
            <p className="text-center text-sm text-gray-600">
              {t('forgotPassword.rememberPassword')}{' '}
              <Link to="/login" className="font-medium text-blue-600 hover:text-blue-500">
                {t('forgotPassword.signIn')}
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
