import instance from '../../axios';
import { toast } from 'react-toastify';
import { t } from 'i18next';

export const storeMedal = async (medalDataToSave) => {
    try {
      const response = await instance.post('/medals/store', medalDataToSave);
      if (response.status === 201) {
        toast.success(t('Medal added successfully'));
        return response;
      } else {
        toast.error(t('Unexpected response status'));
        throw new Error('Unexpected response status');
      }
    } catch (error) {
      console.error('Error storing medal category:', error);
      throw error;
    }
  };


  export const updateMedal = async (medalId, medalDataToUpdate) => {
    try {
        const response = await instance.put(`/medals/update/${medalId}`, medalDataToUpdate);
        return response.data.data;
    } catch (error) {
        console.error('Error updating medal:', error);
        throw error;
    }
  };

  export const fetchMedals = async () => {
    try {
        const response = await instance.get('/medals');
        return response.data.data;
    } catch (error) {
        console.error('Error fetching medals:', error);
        throw error;
    }
  };

  export const fetchMedalById = async (medalId) => {
    try {
        const response = await instance.get(`/medals/edit/${medalId}`);
        return response.data.data;
    } catch (error) {
        console.error('Error fetching medal by ID:', error);
        throw error;
    }
  };

  export const deleteMedal = async (medalId) => {
    try {
        const response = await instance.delete(`/medals/destroy/${medalId}`);
        return response.data.data;
    } catch (error) {
        console.error('Error deleting medal:', error);
        throw error;
    }
  };

  