import instance from '../../axios';
import { toast } from 'react-toastify';
import i18n from '../../../i18n';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';
import axios from 'axios';


// User Info functions
export const fetchLatestUserInfo = async () => {
  try {
    const response = await instance.get('/user');
    return response.data.data;
  } catch (error) {
    console.error('Error fetching latest user info:', error);
    throw error;
  }
};

export const saveUserName = async (newName) => {
  try {
    const response = await instance.post('/user/update/name', { name: newName });
    toast.success(t('userInfo.nameUpdateSuccess'));
    return response.data.data;
  } catch (error) {
    console.error('Error saving user name:', error);
    if (error.response && error.response.data) {
      const { message, errors } = error.response.data;
      if (errors && errors.name) {
        toast.error(errors.name[0]);
      } else if (message) {
        toast.error(message);
      } else {
        toast.error(t('userInfo.nameUpdateError'));
      }
    } else {
      toast.error(t('userInfo.nameUpdateError'));
    }
    throw error;
  }
};
export const saveUserUserName = async (username) => {
  try {
    const response = await instance.post('/user/update/username', { username: username });
    toast.success(t('userInfo.usernameUpdateSuccess'));
    return response.data.data;
  } catch (error) {
    console.error('Error saving user username:', error);
    if (error.response && error.response.data) {
      const { message, errors } = error.response.data;
      if (errors && errors.username) {
        toast.error(errors.username[0]);
      } else if (message) {
        toast.error(message);
      } else {
        toast.error(t('userInfo.usernameUpdateError'));
      }
    } else {
      toast.error(t('userInfo.usernameUpdateError'));
    }
    throw error;
  }
};

export const saveUserGeneral = async (name, email, timezone, language) => {
  try {
    const response = await instance.post('/user/update/info', { name, email, timezone, language });
    toast.success(t('userInfo.profileUpdateSuccess'));
    return response.data.data;
  } catch (error) {
    console.error('Error saving user profile:', error);
    if (error.response && error.response.data) {
      const { message, errors } = error.response.data;
      if (errors) {
        const errorField = Object.keys(errors)[0];
        toast.error(errors[errorField][0]);
      } else if (message) {
        toast.error(message);
      } else {
        toast.error(t('userInfo.profileUpdateError'));
      }
    } else {
      toast.error(t('userInfo.profileUpdateError'));
    }
    throw error;
  }
};

export const saveUserProfile = async (headline, description, username) => {
  try {
    const response = await instance.post('/user/update/profile', { headline, description, username });
    if (response.data && response.data.data) {
      toast.success(t('userInfo.profileUpdateSuccess'));
      return response.data.data;
    } else {
      throw new Error('Unexpected response structure');
    }
  } catch (error) {
    console.error('Error saving user profile:', error);
    if (error.response && error.response.data) {
      const { message, errors } = error.response.data;
      if (errors && errors.headline) {
        toast.error(errors.headline[0]);
      } else if (errors && errors.description) {
        toast.error(errors.description[0]);
      } else if (message) {
        toast.error(message);
      } else {
        toast.error(t('userInfo.profileUpdateError'));
      }
    } else {
      toast.error(t('userInfo.profileUpdateError'));
    }
    throw error;
  }
};

export const saveUserHeadline = async (newHeadline) => {
  try {
    const response = await instance.post('/user/update/headline', { headline: newHeadline });
    toast.success(t('userInfo.headlineUpdateSuccess'));
    return response.data.data;
  } catch (error) {
    console.error('Error saving user headline:', error);
    if (error.response && error.response.data) {
      const { message, errors } = error.response.data;
      if (errors && errors.headline) {
        toast.error(errors.headline[0]);
      } else if (message) {
        toast.error(message);
      } else {
        toast.error(t('userInfo.headlineUpdateError'));
      }
    } else {
      toast.error(t('userInfo.headlineUpdateError'));
    }
    throw error;
  }
};

export const saveUserDescription = async (newDescription) => {
  try {
    const response = await instance.post('/user/update/description', { description: newDescription });
    toast.success(t('userInfo.descriptionUpdateSuccess'));
    return response.data.data;
  } catch (error) {
    console.error('Error saving user description:', error);
    if (error.response && error.response.data) {
      const { message, errors } = error.response.data;
      if (errors && errors.description) {
        toast.error(errors.description[0]);
      } else if (message) {
        toast.error(message);
      } else {
        toast.error(t('userInfo.descriptionUpdateError'));
      }
    } else {
      toast.error(t('userInfo.descriptionUpdateError'));
    }
    throw error;
  }
};

export const updateUserProfilePicture = async (imageFile) => {
  const formData = new FormData();
  formData.append('profile_picture', imageFile);
  
  const response = await instance.post('/user/update/profile-picture', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  
  return response.data;
};