import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getUserByUsername, fetchUserSkills } from '../../api/axios';
import { getSocialIcon } from '../Common/SocialIcons';
import { getProficiencyBadge, sortByProficiencyAndName, getProficiencyColors } from '../Proficiency/Proficiency';
import { FaCode, FaLaptopCode, FaCalendar, FaCertificate } from 'react-icons/fa';
import { format, isValid, parseISO } from 'date-fns';
import { motion } from 'framer-motion';
import Page from '../Page';

const SkillOrTechItem = ({ item }) => {
  const { fromColor, toColor, textColor, proficiencyAbbr } = getProficiencyColors(item.proficiency_name);

  return (
    <motion.li
      whileHover={{ scale: 1.02 }}
      className="mb-2 w-full"
    >
      <div className={`bg-gradient-to-r ${fromColor} ${toColor} ${textColor} rounded-lg px-3 py-2 text-sm font-semibold transition-all duration-300 flex justify-between items-center`}>
        <span>{item.skill_name || item.technology_name || item.name}</span>
        <span className="bg-white rounded px-1 text-xs font-bold">{proficiencyAbbr}</span>
      </div>
    </motion.li>
  );
};

const CertificationItem = ({ cert }) => {
  return (
    <motion.li
      whileHover={{ scale: 1.02 }}
      className="mb-2 w-full"
    >
      <div className="bg-gradient-to-r from-blue-100 to-blue-200 text-blue-800 rounded-lg px-3 py-2 text-sm font-semibold transition-all duration-300 flex flex-col">
        <span className="font-bold">{cert.name}</span>
        <span className="text-xs">{cert.institution.name}</span>
        {cert.date_of_completion && (
          <span className="text-xs">Completed: {new Date(cert.date_of_completion).toLocaleDateString()}</span>
        )}
      </div>
    </motion.li>
  );
};

const ProfileShow = () => {
  const { username } = useParams();
  const { t } = useTranslation();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userSkills, setUserSkills] = useState([]);
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userData = await getUserByUsername(username);
        setUser(userData.data);
        setLoading(false);
      } catch (err) {
        setError(t('Error'));
        setLoading(false);
      }
    };

    fetchUserData();
  }, [username, t]);

  useEffect(() => {
    const fetchSkillsData = async () => {
      try {
        const response = await fetchUserSkills();
        setUserSkills(response.data.data);
      } catch (err) {
        console.error('Error fetching user skills:', err);
      }
    };
    fetchSkillsData();
  }, []);

  if (loading) {
    return <div className="flex justify-center items-center h-screen">
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
    </div>;
  }

  if (error) {
    return <div className="text-center text-red-500">{error}</div>;
  }

  if (!user) {
    return <div className="text-center">{t('User not found')}</div>;
  }

  const formatDate = (dateString) => {
    if (!dateString) return t('Not available');
    const date = parseISO(dateString);
    return isValid(date) ? format(date, 'MMMM yyyy') : t('Invalid Date');
  };

  const MedalItem = ({ medal }) => {
    return (
      <div className="bg-white p-4 rounded-lg shadow mb-4">
        <div className="flex justify-between items-center mb-2">
          <h3 className="text-xl font-semibold text-gray-800">{medal.title}</h3>
          <span className="text-sm text-gray-500">
            {formatDate(medal.startDate)} - {medal.endDate ? formatDate(medal.endDate) : t('Present')}
          </span>
        </div>
        {medal.accomplishments && medal.accomplishments.length > 0 && (
          <div className="mt-2">
            <h4 className="font-medium text-gray-700 mb-1">{t('Accomplishments')}:</h4>
            <ul className="list-disc list-inside text-gray-600">
              {medal.accomplishments.map((acc, index) => (
                <li key={acc.id}>{acc.accomplishment}</li>
              ))}
            </ul>
          </div>
        )}
      </div>
    );
  };

  return (
    <Page
      title={`${user?.name}'s Profile`}
      description={`View ${user?.name}'s professional achievements, skills, and career history on Digital Medals`}
      keywords={`${user?.name}, professional profile, ${userSkills.map(s => s.skill_name).join(', ')}`}
      ogImage={user?.profile_picture_url}
    >
      <div className="max-w-4xl mx-auto p-8">
      
        <div className="text-center max-w-5xl mx-auto px-4 py-12">
          <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 
          sm:text-5xl md:text-6xl mb-4">
            <span className="block xl:inline">{user?.name || t('profile. nameNotSet')}'s</span>{' '}
            <span className="block text-blue-600 xl:inline">{t('Digital Medals')}</span>
          </h1>
          
          <h2 className="text-2xl font-bold text-primary-500 mb-8">
            {t('Showcasing Achievements in the Digital Realm')}
          </h2>
          
          <div className="
              bg-gradient-to-r from-yellow-200 via-blue-200 via-blue-200 
              to-purple-200 
              text-white p-2 rounded-lg shadow-lg mb-12
              w-5/6 mx-auto
          ">
            <div className="flex items-center justify-center mt-2 mb-2">
              <h2 className="text-3xl font-bold ">@{user?.username || t('profile.usernameNotSet')}</h2>
            </div>
            <p className="text-xl mb-2" dangerouslySetInnerHTML={{ __html: user?.headline || t('Headline not set') }} />
          </div>
        </div>
        {/* Profile Header */}
        {/* <div className="flex items-center mb-8">
          <div className="w-32 h-32 bg-blue-200 rounded-full flex items-center justify-center text-4xl text-blue-600 mr-6">
            {user?.avatar_url ? (
              <img src={user.avatar_url} alt={user.name} className="w-full h-full rounded-full object-cover" />
            ) : (
              <span>{user?.name?.charAt(0) || 'J'}</span>
            )}
          </div>
          <div>
            <h1 className="text-3xl font-bold mb-1">{user?.name}</h1>
            <p className="text-xl text-gray-600 mb-2">@{user?.username}</p>
            <p className="text-gray-700" dangerouslySetInnerHTML={{ __html: user?.headline }} />
            <p className="text-gray-500 mt-2">
              <FaCalendar className="inline mr-2" />
              Joined {formatDate(user?.created_at)}
            </p>
          </div>
        </div> */}

        {/* Main Content */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Left Sidebar */}
          <div className="md:col-span-1">
            {/* Certifications Section */}
            <section className="mb-8">
              <h2 className="text-xl font-semibold mb-4 text-gray-700 flex items-center">
                <FaCertificate className="w-5 h-5 mr-2 text-blue-500" />
                {t('Certifications')}
              </h2>
              <ul className="w-full">
                {user?.certifications?.length > 0 ? (
                  user.certifications.map((cert) => (
                    <CertificationItem key={cert.id} cert={cert} />
                  ))
                ) : (
                  <p className="text-gray-500 italic">{t('No certifications added yet')}</p>
                )}
              </ul>
            </section>

            {/* Skills Section */}
            <section className="mb-8">
              <h2 className="text-xl font-semibold mb-4 text-gray-700 flex items-center">
                <FaCode className="w-5 h-5 mr-2 text-blue-500" />
                {t('Skills')}
              </h2>
              <ul className="w-full">
                {user?.skills?.length > 0 ? (
                  sortByProficiencyAndName(user.skills).map((skill) => (
                    <SkillOrTechItem key={skill.id} item={skill} />
                  ))
                ) : (
                  <p className="text-gray-500 italic">{t('No skills added yet')}</p>
                )}
              </ul>
            </section>

            {/* Technologies Section */}
            <section>
              <h2 className="text-xl font-semibold mb-4 text-gray-700 flex items-center">
                <FaLaptopCode className="w-5 h-5 mr-2 text-blue-500" />
                {t('Technologies')}
              </h2>
              <ul className="w-full">
                {user?.technologies?.length > 0 ? (
                  sortByProficiencyAndName(user.technologies).map((tech) => (
                    <SkillOrTechItem key={tech.id} item={tech} />
                  ))
                ) : (
                  <p className="text-gray-500 italic">{t('No technologies added yet')}</p>
                )}
              </ul>
            </section>
          </div>

          {/* Right Content */}
          <div className="md:col-span-2">
            {/* About Section */}
            <section className="mb-8">
              <h2 className="text-2xl font-bold mb-4 border-b pb-2">About {user?.name?.split(' ')[0]}</h2>
              <div className="prose max-w-none" dangerouslySetInnerHTML={{ __html: user?.description }} />
            </section>

            {/* Find On Section */}
            <section className="mb-8">
              <h2 className="text-2xl font-bold mb-4 border-b pb-2">Find {user?.name?.split(' ')[0]} on</h2>
              <div className="flex space-x-4">
                {user?.social_links?.filter(link => link?.platform)?.map((link, index) => (
                  <a
                    key={index}
                    href={link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-3xl text-gray-600 hover:text-blue-500"
                  >
                    {link?.platform ? getSocialIcon(link.platform) : null}
                  </a>
                ))}
              </div>
            </section>

            {/* Recent Achievements Section */}
            <section className="mb-8">
              <h2 className="text-2xl font-bold mb-4 border-b pb-2">Recent Achievements</h2>
              {user?.medals
                ?.sort((a, b) => new Date(b.startDate) - new Date(a.startDate))
                ?.map((medal) => (
                <div key={medal.id} className="mb-6">
                  <div className="flex justify-between items-baseline mb-2">
                    <h3 className="text-xl font-semibold">{medal.title}</h3>
                    <span className="text-gray-500">
                      {formatDate(medal.startDate)} - {medal.endDate ? formatDate(medal.endDate) : 'Present'}
                    </span>
                  </div>
                  {medal.accomplishments?.length > 0 && (
                    <div>
                      <h4 className="font-medium mb-2">Accomplishments:</h4>
                      <ul className="list-disc list-inside">
                        {medal.accomplishments.map((acc) => (
                          <li key={acc.id} className="text-gray-700">{acc.accomplishment}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              ))}
            </section>

            {/* Experience Section */}
            <section>
              <h2 className="text-2xl font-bold mb-4 border-b pb-2">Experience</h2>
              {user?.employments?.map((emp) => (
                <div key={emp.id} className="mb-6">
                  <h3 className="text-xl font-semibold">{emp.position.name}</h3>
                  <p className="text-gray-700">{emp.employer.name}</p>
                  <p className="text-gray-500">
                    {formatDate(emp.start_date)} - {emp.is_current ? 'Present' : formatDate(emp.end_date)}
                  </p>
                  <div className="mt-2 prose max-w-none" dangerouslySetInnerHTML={{ __html: emp.description }} />
                </div>
              ))}
            </section>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default ProfileShow;
