import React from 'react';

const SectionHeader = ({ 
  title,
  subtitle, 
  buttons = [],
  className = ""
}) => {
  return (
    <div className={`border-b border-gray-200 pb-5 ${className}`}>
      <div className="flex justify-between items-center">
        <div className="-ml-2 -mt-2 flex flex-wrap items-baseline">
          <h3 className="ml-2 mt-2 text-base font-semibold leading-6 text-gray-900">{title}</h3>
          <p className="ml-2 mt-1 truncate text-sm text-gray-500">{subtitle}</p>
        </div>
        {buttons.length > 0 && (
          <div className="flex flex-col sm:flex-row gap-4">
            {buttons.map((button, index) => (
              <button
                key={index}
                onClick={button.onClick}
                className={button.className || "text-blue-600 hover:text-blue-800 font-medium flex items-center gap-2"}
              >
                {button.icon && <span className="text-xl">{button.icon}</span>}
                {button.text}
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default SectionHeader;
