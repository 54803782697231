import React from 'react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

const ReviewAndSave = ({ data, onBack, onSave }) => {
  const { t } = useTranslation();
  
  const formatDate = (date) => {
    return date ? format(new Date(date), 'MMMM do, yyyy') : t('common.current');
  };

  return (
    <div className="w-full min-h-screen flex items-start justify-center p-4">
      <div className="w-full max-w-2xl bg-white">
        <div className="flex justify-between items-center mb-8 p-4">
          <button
            onClick={onBack}
            className="bg-gray-500 text-white py-2 px-4 rounded-md hover:bg-gray-600 transition duration-300"
          >
            {t('reviewAndSave.back')}
          </button>
          <div className="hidden md:block w-3/4 bg-white text-center">
            <p><strong>{data.title}</strong></p>
            <p>
              <span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                {data.categoryName || ''}
              </span>
            </p>
          </div>
          <button
            onClick={onSave}
            className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition duration-300"
          >
            {t('reviewAndSave.save')}
          </button>
        </div>

        <div className="text-center mb-6">
          <h2 className="text-4xl font-bold text-gray-900 mb-2">{t('reviewAndSave.title')}</h2>
          <p className="text-sm text-gray-700 rounded-lg p-4 border border-blue-300 bg-blue-100">
            {t('reviewAndSave.description')}
          </p>
        </div>

        <div className="space-y-8">
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold mb-4 border-b pb-2">{t('reviewAndSave.medalInfo.title')}</h3>
            <div className="space-y-2 bg-yellow-50 text-yellow-800 rounded-lg p-4">
              <p className="text-gray-700 mb-2">
                <strong>{t('reviewAndSave.medalInfo.title')}:</strong> {data.title}
              </p>
              <p className="text-gray-700 mb-2">
                <strong>{t('reviewAndSave.medalInfo.timeFrame')}:</strong> {formatDate(data.startDate)} - {formatDate(data.endDate)}
              </p>
              <p className="text-gray-700 mb-2">
                <strong>{t('reviewAndSave.medalInfo.category')}:</strong> {data.categoryName}
              </p>
              
              {data.goals && data.goals.length > 0 && (
                <div className="mt-4 pt-4">
                  <p className="text-gray-700 mb-2">
                    <strong>{t('reviewAndSave.medalInfo.associatedGoals')}:</strong>
                  </p>
                  <div className="flex flex-wrap gap-2">
                    {data.goals.map((goal, index) => (
                      <div
                        key={index}
                        className="inline-flex items-center px-3 py-1 rounded text-sm font-medium bg-blue-100 text-blue-800"
                      >
                        <span>{goal.description}</span>
                        <span className="ml-2 text-xs text-blue-600">
                          {goal.target_date && `(Due: ${new Date(goal.target_date).toLocaleDateString()})`}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold mb-4 border-b pb-2">{t('projects.title')}</h3>
            <div className="list-none pl-0 space-y-4">
              {data.projects.length > 0 ? (
                data.projects.map((proj, index) => (
                  <div key={index} className="bg-blue-100 text-blue-800 rounded-lg p-4 text-sm font-semibold flex justify-between items-center">
                    <div>
                      <h3 className="text-lg font-semibold">{proj.name}</h3>
                      <p><strong>{t('projects.role')}:</strong> {proj.role}</p>
                      <p><strong>{t('projects.responsibilities')}:</strong> {proj.responsibilities}</p>
                      <p><strong>{t('projects.challenges')}:</strong> {proj.challenges}</p>
                      <p><strong>{t('projects.skills')}:</strong> {proj.skills.map(skill => skill.name).join(', ')}</p>
                      <p><strong>{t('projects.technologies')}:</strong> {proj.technologies.map(tech => tech.name).join(', ')}</p>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-gray-500">{t('projects.noProjects')}</p>
              )}
            </div>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold mb-4 border-b pb-2">{t('accomplishments.title')}</h3>
            <div className="list-none pl-0 space-y-4">
              {data.accomplishments.length > 0 ? (
                data.accomplishments.map((acc, index) => (
                  <div key={index} className="bg-blue-100 text-blue-800 rounded-lg p-4 text-sm font-semibold flex justify-between items-center">
                    <div>
                      <h3 className="text-lg font-semibold">{acc}</h3>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-gray-500">{t('accomplishments.noAccomplishments')}</p>
              )}
            </div>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold mb-4 border-b pb-2">{t('lessonsLearned.title')}</h3>
            <div className="list-none pl-0 space-y-4">
              {data.lessons.length > 0 ? (
                data.lessons.map((lesson, index) => (
                  <div key={index} className="bg-blue-100 text-blue-800 rounded-lg p-4 text-sm font-semibold flex justify-between items-center">
                    <div>
                      <h3 className="text-lg font-semibold">{lesson}</h3>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-gray-500">{t('lessonsLearned.noLessons')}</p>
              )}
            </div>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold mb-4 border-b pb-2">{t('feedback.title')}</h3>
            <div className="list-none pl-0 space-y-4">
              {data.feedbacks.length > 0 ? (
                data.feedbacks.map((fb, index) => (
                  <div key={index} className="bg-purple-100 text-purple-800 rounded-lg p-4 text-sm font-semibold flex justify-between items-center">
                    <div>
                      <p>{fb.positive && <><strong>{t('feedback.positive')}:</strong> {fb.positive}<br /></>}</p>
                      <p>{fb.constructive && <><strong>{t('feedback.negative')}:</strong> {fb.constructive}<br /></>}</p>
                      <p><strong>{t('feedback.givenBy')}:</strong> {fb.givenBy || 'Myself'}</p>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-gray-500">{t('feedback.noFeedback')}</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewAndSave;
