import React from 'react';
import Button from './Button';
import { FaTimes, FaExclamationTriangle } from 'react-icons/fa';

function ConfirmationModal({ isOpen, onClose, onConfirm, title, message }) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen px-4">
        <div className="fixed inset-0 bg-black opacity-30"></div>
        <div className="relative bg-white rounded-lg p-8 max-w-md w-full">
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center">
              <FaExclamationTriangle className="text-red-500 mr-2" size={24} />
              <h3 className="text-xl font-semibold">{title}</h3>
            </div>
            <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
              <FaTimes size={20} />
            </button>
          </div>
          <p className="text-gray-600 mb-6">{message}</p>
          <div className="flex justify-end space-x-4">
            <Button onClick={onClose} color="gray">Cancel</Button>
            <Button onClick={onConfirm} color="red">Delete</Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfirmationModal;
