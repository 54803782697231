import instance from '../axios';

export const fetchRandomQuote = async () => {
    try {
      const response = await instance.get('/random-quote');
      return response.data;
    } catch (error) {
      console.error('Error fetching random quote:', error);
      throw error;
    }
  };
  
export const fetchTechnologies = async () => {
    try {
        const response = await instance.get('/technologies');
        return response.data;
} catch (error) {
        console.error('Error fetching technologies:', error);
        throw error;
    }
};

export const fetchSkills = async () => {
    try {
        const response = await instance.get('/skills');
        return response.data;
    } catch (error) {
        console.error('Error fetching skills:', error);
        throw error;
    }
};

export const fetchEmployers = async () => {
    try {
      const response = await instance.get('/employments/employers');
      return response.data.data;
    } catch (error) {
      console.error('Error fetching employers:', error);
      throw error;
    }
  };
  
  export const fetchPositions = async () => {
    try {
      const response = await instance.get('/employments/positions');
      return response.data.data;
    } catch (error) {
      console.error('Error fetching positions:', error);
      throw error;
    }
  };
  export const fetchSocialPlatforms = async () => {
    try {
      const response = await instance.get('/social-platforms');
      return response.data.data;
    } catch (error) {
      console.error('Error fetching social platforms:', error);
      throw error;
    }
  };