import { useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { verifyEmail } from '../../api/axios';
import { useAuth } from '../../contexts/AuthContext';
import LoadingSpinner from '../Common/LoadingSpinner';

const EmailVerification = () => {
  const countdownTime = 5;
  const [status, setStatus] = useState('verifying');
  const [verificationData, setVerificationData] = useState(null);
  const [countdown, setCountdown] = useState(countdownTime);
  const location = useLocation();
  const navigate = useNavigate();
  const { setUser } = useAuth();
  const reload = useCallback(() => window.location.reload(), []);

  const verifyEmailToken = useCallback(async () => {
    if (status !== 'verifying') return;
    
    try {
      const url = new URL(window.location.href);
      const params = {
        id: url.pathname.split('/')[1],
        expires: url.searchParams.get('expires'),
        signature: url.searchParams.get('signature'),
        hash: url.searchParams.get('hash')
      };

      if (!params.id || !params.expires || !params.signature) {
        throw new Error('Missing verification parameters');
      }

      setVerificationData(params);
      const response = await verifyEmail(params);
      
      if (response.success === true) {
        // Ensure user data is fully updated before proceeding
        if (response.user) {
          setUser(response.user);
          localStorage.setItem('user', JSON.stringify(response.user));
          
          // Set a small delay to ensure state updates are processed
          await new Promise(resolve => setTimeout(resolve, 100));
        }
        
        setStatus('success');
        const timer = setInterval(() => {
          setCountdown((prev) => {
            if (prev <= 1) {
              clearInterval(timer);
              reload();
              navigate('/dashboard', { replace: true, state: { verified: true } });
              return 0;
            }
            return prev - 1;
          });
        }, 1000);

        return () => clearInterval(timer);
      } else {
        setStatus('error');
      }
    } catch (error) {
      setStatus('error');
      console.error('Email verification failed:', error);
    }
  }, [navigate, status, setUser]);

  // Add immediate navigation option
  const handleImmediateNavigation = () => {
    navigate('/dashboard', { replace: true, state: { verified: true } });
  };

  useEffect(() => {
    let mounted = true;
    const abortController = new AbortController();

    const verify = async () => {
      if (mounted) {
        await verifyEmailToken();
      }
    };

    verify();

    return () => {
      mounted = false;
      abortController.abort();
    };
  }, [verifyEmailToken]);

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="p-8 bg-white rounded-lg shadow-md text-center">
        {verificationData && (
          <div className="mb-4 text-sm text-gray-500">
            {/* <p>Verification ID: {verificationData.id}</p> */}
            <p>Expires: {new Date(verificationData.expires * 1000).toLocaleString()}</p>
            <p>Signature: {verificationData.signature}</p>
          </div>
        )}
        {status === 'verifying' && (
          <div className="space-y-4">
            <LoadingSpinner />
            <p className="text-gray-600">Verifying your email address...</p>
          </div>
        )}
        {status === 'success' && (
          <div>
            <p className="text-green-600">Email verified successfully!</p>
            <p className="text-gray-500 mt-2">
              Redirecting to dashboard in {countdown} seconds...
            </p>
            <button
              onClick={handleImmediateNavigation}
              className="text-blue-600 hover:text-blue-800 underline mt-2"
            >
              Click here to go to dashboard now
            </button>
          </div>
        )}
        {status === 'error' && (
          <p className="text-red-600">
            Failed to verify email. The link may be expired or invalid.
          </p>
        )}
      </div>
    </div>
  );
};

export default EmailVerification;
