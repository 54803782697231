import React from 'react';
import { register, loginUser } from '../../api/axios';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaGoogle } from 'react-icons/fa';
import { useAuth } from '../../contexts/AuthContext';
import { useUser } from '../../contexts/UserContext';

const formSchema = z.object({
    name: z.string().min(2, 'Name must be at least 2 characters'),
    email: z.string().email('Invalid email address'),
    password: z.string().min(8, 'Password must be at least 8 characters'),
    password_confirmation: z.string(),
}).refine((data) => data.password === data.password_confirmation, {
    message: "Passwords don't match",
    path: ["password_confirmation"],
});

export const Register = () => {

  const { t } = useTranslation();
  const { login } = useAuth();
  const { updateUser } = useUser();
  const { register: registerField, handleSubmit, formState: { errors } } = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: '',
      email: '',
      password: '',
      password_confirmation: '',
    },
  });



    async function onSubmit(values) {
        try {
            // Register the user and get the response
            const registerResponse = await register(values);

            // After registration, perform login
            const loginResponse = await loginUser({
                email: values.email,
                password: values.password
            });

            // Check if loginResponse exists and has the expected structure
            if (loginResponse && loginResponse.token) {
                const token = loginResponse.token || '';
                const user = loginResponse.user;
                localStorage.setItem('token', token);
                
                await Promise.all([
                    login(token, user),
                    updateUser(user)
                ]);
                toast.success(t("register.registrationSuccessful"));
                
                // Use setTimeout to ensure all state updates are complete
                setTimeout(() => {
                    window.location.href = '/dashboard';
                }, 100);
            } else {
                throw new Error('Invalid login response structure');
            }
        } catch (error) {
            console.error('Registration error:', error);
            toast.error(error.response?.data?.message || t("register.registrationFailed"));
        }
    }

    const handleGoogleLogin = () => {
        const baseUrl = 'https://accounts.google.com/o/oauth2/v2/auth';
        const params = new URLSearchParams({
            client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
            redirect_uri: `${process.env.REACT_APP_API_URL}/auth/google/callback`,
            response_type: 'code',
            scope: 'email profile',
            access_type: 'offline',
            state: Math.random().toString(36).substring(7),
        });

        window.location.href = `${baseUrl}?${params.toString()}`;
    };

    const handleLinkedInLogin = () => {
        const baseUrl = 'https://www.linkedin.com/oauth/v2/authorization';
        const params = new URLSearchParams({
            client_id: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
            redirect_uri: `${process.env.REACT_APP_API_URL}/auth/linkedin/callback`,
            response_type: 'code',
            scope: 'profile email openid',
            state: Math.random().toString(36).substring(7),
        });

        window.location.href = `${baseUrl}?${params.toString()}`;
    };

    return (
        <div className="min-h-screen flex flex-col px-4 sm:px-6 lg:px-8">
            <div className="w-full max-w-md mx-auto">
                <h2 className="mt-6 mb-8 text-2xl sm:text-3xl font-bold text-center text-gray-900">
                    {t("register.createAccount")}
                </h2>
                <div className="bg-white py-8 px-4 sm:px-6 shadow rounded-lg">
                    <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                        <div>
                            <label className="block text-sm font-medium text-gray-700" htmlFor="name">
                                {t("register.name")}
                            </label>
                            <div className="mt-1">
                                <input
                                    type="text"
                                    {...registerField("name")}
                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                    placeholder={t("register.namePlaceholder")}
                                />
                                {errors.name && <p className="mt-2 text-sm text-red-600">{t(errors.name.message)}</p>}
                            </div>
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700" htmlFor="email">
                                {t("register.email")}
                            </label>
                            <div className="mt-1">
                                <input
                                    type="email"
                                    {...registerField("email")}
                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                    placeholder={t("register.emailPlaceholder")}
                                />
                                {errors.email && <p className="mt-2 text-sm text-red-600">{t(errors.email.message)}</p>}
                            </div>
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700" htmlFor="password">
                                {t("register.password")}
                            </label>
                            <div className="mt-1">
                                <input
                                    type="password"
                                    {...registerField("password")}
                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                    placeholder={t("register.passwordPlaceholder")}
                                />
                                {errors.password && <p className="mt-2 text-sm text-red-600">{t(errors.password.message)}</p>}
                            </div>
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700" htmlFor="password_confirmation">
                                {t("register.confirmPassword")}
                            </label>
                            <div className="mt-1">
                                <input
                                    type="password"
                                    {...registerField("password_confirmation")}
                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                    placeholder={t("register.confirmPasswordPlaceholder")}
                                />
                                {errors.password_confirmation && <p className="mt-2 text-sm text-red-600">{t(errors.password_confirmation.message)}</p>}
                            </div>
                        </div>

                        <button
                            type="submit"
                            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        >
                            {t("register.register")}
                        </button>
                    </form>

                    <div className="mt-6">
                        <div className="relative">
                            <div className="absolute inset-0 flex items-center">
                                <div className="w-full border-t border-gray-300" />
                            </div>
                            <div className="relative flex justify-center text-sm">
                                <span className="px-2 bg-white text-gray-500">
                                    {t('login.orRegisterWith')}
                                </span>
                            </div>
                        </div>

                        <div className="mt-6 space-y-2">
                            <button
                                onClick={handleGoogleLogin}
                                type="button"
                                className="w-full flex justify-center items-center gap-2 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                            >
                                <FaGoogle className="text-red-600" />
                                {t('login.registerWithGoogle')}
                            </button>
                            
                            {/* <button
                                onClick={handleLinkedInLogin}
                                type="button"
                                className="w-full flex justify-center items-center gap-2 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                            >
                                <FaLinkedin className="text-[#0A66C2]" />
                                {t('login.registerWithLinkedIn')}
                            </button> */}
                        </div>
                    </div>

                    <div className="mt-6">
                        <p className="text-center text-sm text-gray-600">
                            {t("register.alreadyHaveAccount")}{' '}
                            <Link to="/login" className="font-medium text-blue-600 hover:text-blue-500">
                                {t("register.signIn")}
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};
