import React from 'react';
import { useTranslation } from 'react-i18next';
import { setUserLanguage } from '../i18n';

function LanguageSelector() {
  const { t, i18n } = useTranslation();

  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value;
    setUserLanguage(newLanguage);
  };

  return (
    <div>
      <label htmlFor="language" className="block text-sm font-medium text-gray-700">
        {t('settings.language')}
      </label>
      <select
        name="language"
        id="language"
        value={i18n.language}
        onChange={handleLanguageChange}
        className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
      >
        <option value="en">{t('languages.english')}</option>
        <option value="es">{t('languages.spanish')}</option>
        <option value="fr">{t('languages.french')}</option>
      </select>
    </div>
  );
}

export default LanguageSelector;
