import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useUser } from '../../contexts/UserContext';
import StatCard from '../Common/StatCard';
import { fetchUserSkills, fetchUserTechnologies, fetchUserEmployments, fetchRandomQuote, fetchUserProjects, fetchUserProjectsSkills, fetchUserProjectsTechnologies, fetchMedals } from '../../api/axios';
import PageHero from '../Common/PageHero';
import SectionHeader from '../Common/SectionHeader';
import { getProficiencyColor, sortByProficiencyAndName } from '../Proficiency/Proficiency';
import { useNavigate } from 'react-router-dom';
import { FaUserCircle } from 'react-icons/fa';
// Add Tippy import
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import Page from '../Page';

function Dashboard() {
  const { t } = useTranslation();
  const { user } = useUser();
  const navigate = useNavigate();
  const [skillsCount, setSkillsCount] = useState(null);
  const [technologiesCount, setTechnologiesCount] = useState(null);
  const [employmentsCount, setEmploymentsCount] = useState(null);
  const [randomQuote, setRandomQuote] = useState('');
  const [projectsCount, setProjectsCount] = useState(null);
  const [projectsSkillsCount, setProjectsSkillsCount] = useState(null);
  const [projectsTechnologiesCount, setProjectsTechnologiesCount] = useState(null);
  const [medalsCount, setMedalsCount] = useState(null);
  const [userSkills, setUserSkills] = useState([]);
  const [userTechnologies, setUserTechnologies] = useState([]);
  const [userMedals, setUserMedals] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          skillsData, 
          technologiesData, 
          employmentsData, 
          quoteData, 
          projectsData, 
          projectsSkillsData, 
          projectsTechnologiesData, 
          medalsData
        ] = await Promise.all([
          fetchUserSkills(),
          fetchUserTechnologies(),
          fetchUserEmployments(),
          fetchRandomQuote(),
          fetchUserProjects(),
          fetchUserProjectsSkills(),
          fetchUserProjectsTechnologies(),
          fetchMedals()
        ]);

        // Set counts as before
        setSkillsCount(skillsData.data.data.length);
        setTechnologiesCount(technologiesData.data.data.length);
        setEmploymentsCount(employmentsData.data.data.length);
        setRandomQuote(quoteData.quote);
        setProjectsCount(projectsData.length);
        setProjectsSkillsCount(projectsSkillsData.length);
        setProjectsTechnologiesCount(projectsTechnologiesData.length);
        setMedalsCount(medalsData.length);

        // Set the detailed data with sorting
        setUserSkills(sortByProficiencyAndName(skillsData.data.data));
        setUserTechnologies(sortByProficiencyAndName(technologiesData.data.data));
        setUserMedals(medalsData.slice(0, 5)); // Get only the 5 most recent medals
      } catch (error) {
        console.error('Error fetching data:', error);
        setSkillsCount(0);
        setTechnologiesCount(0);
        setEmploymentsCount(0);
        setRandomQuote('Failed to load quote');
      }
    };

    fetchData();
  }, []);

  // Add this helper function inside the Dashboard component
  const allTasksCompleted = () => {
    return skillsCount > 0 && 
           technologiesCount > 0 && 
           medalsCount > 0 && 
           employmentsCount > 0;
  };

  if (!user) {
    return <div>{t('loading.userData')}</div>;
  }

  return (
    <>
      <Page 
        title="Dashboard"
        description={`Track your professional growth with ${skillsCount} skills, ${technologiesCount} technologies, and ${medalsCount} medals`}
      />
      <div className="container mx-auto px-4 py-8">
        <div className="bg-blue-50 rounded-lg p-8 mb-8">
          <div className="flex flex-col md:flex-row md:justify-between md:items-center">
            <div className="mb-4 md:mb-0">
              <h1 className="text-3xl font-bold mb-2">
                {t('dashboard.welcome', { name: user.name })}
              </h1>
              <p className="text-gray-600 text-lg">
                {new Date().toLocaleDateString(undefined, {
                  weekday: 'long',
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric'
                })}
              </p>
              {randomQuote && (
                <p className="text-gray-600 mt-2 italic">{randomQuote}</p>
              )}
            </div>
            <div className="text-left md:text-right">
              <Link 
                to="/profile" 
                className="inline-block w-full md:w-auto bg-white text-blue-500 font-semibold py-2 px-4 rounded shadow-md hover:bg-gray-100 flex items-center justify-center gap-2"
              >
                {t('dashboard.viewProfile')}
              </Link>
              <div className="text-sm text-gray-600 mb-1 mt-4">
                {t('dashboard.lastLogin')}:
              </div>
              <div className="text-gray-800">
                {new Date(user.last_login_at).toLocaleString(undefined, {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: true
                })}
              </div>
            </div>
          </div>
        </div>

        {/* Conditionally render checklist banner */}
        {!allTasksCompleted() && (
          <div className="bg-gray-100 rounded-lg shadow-sm p-6 mb-8">
            <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <svg className="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                    </svg>
                  </div>
                  <div className="ml-3">
                    <p className="text-sm text-yellow-700">
                      {t('Complete your profile to stand out! A complete profile increases the output of your profile. Take a few minutes to add your skills and achievements.')}
                    </p>
                  </div>
                </div>
              </div>
            <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-8"> 
              <div>
                <h3 className="text-lg font-semibold mb-4">{t('dashboard.checklistTitle')}</h3>
                <ul className="space-y-3">
                  <li className="flex items-center gap-3">
                    <div className={`p-1 rounded-full ${skillsCount > 0 ? 'bg-green-100 text-green-600' : 'bg-gray-100 text-gray-400'}`}>
                      <svg className="w-4 h-4" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"/>
                      </svg>
                    </div>
                    <span className={skillsCount > 0 ? 'text-gray-700' : 'text-gray-400'}>
                      {skillsCount > 0 ? (
                        t('dashboard.addSkillsChecklist')
                      ) : (
                        <Link to="/skills" className="hover:text-blue-600">
                          {t('dashboard.addSkillsChecklist')}
                        </Link>
                      )}
                    </span>
                  </li>
                  <li className="flex items-center gap-3">
                    <div className={`p-1 rounded-full ${technologiesCount > 0 ? 'bg-green-100 text-green-600' : 'bg-gray-100 text-gray-400'}`}>
                      <svg className="w-4 h-4" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"/>
                      </svg>
                    </div>
                    <span className={technologiesCount > 0 ? 'text-gray-700' : 'text-gray-400'}>
                      {technologiesCount > 0 ? (
                        t('dashboard.addTechnologiesChecklist')
                      ) : (
                        <Link to="/technologies" className="hover:text-blue-600">
                          {t('dashboard.addTechnologiesChecklist')}
                        </Link>
                      )}
                    </span>
                  </li>
                </ul>
              </div>
              <div>
                <h3 className="text-lg font-semibold mb-4">{t('dashboard.progressTitle')}</h3>
                <ul className="space-y-3">
                  <li className="flex items-center gap-3">
                    <div className={`p-1 rounded-full ${medalsCount > 0 ? 'bg-green-100 text-green-600' : 'bg-gray-100 text-gray-400'}`}>
                      <svg className="w-4 h-4" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"/>
                      </svg>
                    </div>
                    <span className={medalsCount > 0 ? 'text-gray-700' : 'text-gray-400'}>
                      {medalsCount > 0 ? (
                        t('dashboard.addMedalsChecklist')
                      ) : (
                        <Link to="/timeline" className="hover:text-blue-600">
                          {t('dashboard.addMedalsChecklist')}
                        </Link>
                      )}
                    </span>
                  </li>
                  <li className="flex items-center gap-3">
                    <div className={`p-1 rounded-full ${employmentsCount > 0 ? 'bg-green-100 text-green-600' : 'bg-gray-100 text-gray-400'}`}>
                      <svg className="w-4 h-4" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"/>
                      </svg>
                    </div>
                    <span className={employmentsCount > 0 ? 'text-gray-700' : 'text-gray-400'}>
                      {employmentsCount > 0 ? (
                        t('dashboard.addEmploymentsChecklist')
                      ) : (
                        <Link to="/settings?tab=employments" className="hover:text-blue-600">
                          {t('dashboard.addEmploymentsChecklist')}
                        </Link>
                      )}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )}

        <div className="bg-white py-6">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-6">
            <Link to="/timeline">
              <StatCard 
                title={t('dashboard.medals')}
                value={medalsCount !== null ? medalsCount.toString() : '..'}
                subtext={t('dashboard.manageMedals')}
                className="bg-gradient-to-r from-green-400 to-green-600"
              />
            </Link>
            <Link to="/skills">
              <StatCard 
                title={t('dashboard.skills')}
                value={skillsCount !== null ? skillsCount.toString() : '..'}
                subtext={t('dashboard.manageSkills')}
                className="bg-gradient-to-r from-blue-400 to-blue-600"
              />
            </Link>
            <Link to="/technologies">
              <StatCard 
                title={t('dashboard.technologies')}
                value={technologiesCount !== null ? technologiesCount : '...'}
                subtext={t('dashboard.manageTechnologies')}
                className="bg-gradient-to-r from-purple-400 to-purple-600"
              />
            </Link>
            <Link to="/settings?tab=employments">
              <StatCard 
                title={t('dashboard.employments')}
                value={employmentsCount !== null ? employmentsCount : '...'}
                subtext={t('dashboard.manageEmployments')}
                className="bg-gradient-to-r from-yellow-400 to-yellow-600"
              />
            </Link>
              <StatCard 
                title={t('dashboard.projects')}
                value={projectsCount !== null ? projectsCount : '...'}
                subtext="Projects you've worked on"
                className="bg-gradient-to-r from-red-400 to-red-600"
              />
              <StatCard 
                title={t('dashboard.projectsSkills')}
                value={projectsSkillsCount !== null ? projectsSkillsCount : '...'}
                subtext="Skills used in projects"
                className="bg-gradient-to-r from-orange-400 to-orange-600"
              />
              <StatCard 
                title={t('dashboard.projectsTechnologies')}
                value={projectsTechnologiesCount !== null ? projectsTechnologiesCount : '...'}
                subtext="Technologies used in projects"
                className="bg-gradient-to-r from-pink-400 to-pink-600"
              />
          </div>
        </div>
        <div>
        <SectionHeader 
            title={t('Highlights')}
            subtitle={t('for you')}
            buttons={[{
              text: t('dashboard.viewProfile'),
              onClick: () => navigate('/profile')
            }]}
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-6">
          
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-semibold mb-4">{t('dashboard.latestMedals')}</h2>
            <div className="space-y-3">
              {userMedals.length > 0 ? (
                userMedals.map((medal) => (
                  <div key={medal.id} className="flex items-center space-x-3 p-2 bg-gray-50 rounded">
                    <div className="w-10 h-10 flex items-center justify-center bg-yellow-100 rounded-full">
                      🏅
                    </div>
                    <div>
                      <div className="font-medium">{medal.title}</div>
                      <div className="text-sm text-gray-500">{medal.category.name}</div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="text-center py-4">
                  <p className="text-gray-500 mb-2">{t('dashboard.noMedals')}</p>
                  <Link
                    to="/timeline"
                    className="text-blue-500 hover:text-blue-600 font-medium"
                  >
                    {t('dashboard.addMedals')}
                  </Link>
                </div>
              )}
            </div>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-semibold mb-4">{t('dashboard.topSkills')}</h2>
            {userSkills.length > 0 ? (
              <div className="flex flex-wrap gap-2">
                {userSkills.slice(0, 10).map((skill) => (
                  <Tippy 
                    key={skill.id}
                    content={t(`proficiency.${skill.proficiency_name.toLowerCase()}`)}
                    placement="top"
                  >
                    <span
                      className={`px-3 py-1 rounded-full text-sm ${getProficiencyColor(skill.proficiency_name)} cursor-help`}
                    >
                      {skill.skill_name}
                    </span>
                  </Tippy>
                ))}
              </div>
            ) : (
              <div className="text-center py-4">
                <p className="text-gray-500 mb-2">{t('dashboard.noSkills')}</p>
                <Link
                  to="/skills"
                  className="text-blue-500 hover:text-blue-600 font-medium"
                >
                  {t('dashboard.addSkills')}
                </Link>
              </div>
            )}
          </div>

          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-semibold mb-4">{t('dashboard.topTechnologies')}</h2>
            {userTechnologies.length > 0 ? (
              <div className="flex flex-wrap gap-2">
                {userTechnologies.slice(0, 10).map((tech) => (
                  <Tippy
                    key={tech.id}
                    content={t(`proficiency.${tech.proficiency_name.toLowerCase()}`)}
                    placement="top"
                  >
                    <span
                      className={`px-3 py-1 rounded-full text-sm ${getProficiencyColor(tech.proficiency_name)} cursor-help`}
                    >
                      {tech.technology_name}
                    </span>
                  </Tippy>
                ))}
              </div>
            ) : (
              <div className="text-center py-4">
                <p className="text-gray-500 mb-2">{t('dashboard.noTechnologies')}</p>
                <Link
                  to="/technologies"
                  className="text-blue-500 hover:text-blue-600 font-medium"
                >
                  {t('dashboard.addTechnologies')}
                </Link>
              </div>
            )}
          </div>
        </div>

        
      </div>
    </>
  );
}

export default Dashboard;
