import React, { useState, useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { HelmetProvider } from 'react-helmet-async';
import 'react-toastify/dist/ReactToastify.css';
import './styles/toast.css'; // Add this line after the react-toastify import
import { UserProvider } from './contexts/UserContext';
import Layout from './components/Layout/Layout';
import Home from './components/Public/Home';
import Login from './components/Auth/Login';
import { Register } from './components/Auth/Register';
import Dashboard from './components/Dashboard/Dashboard';
import Profile from './components/Profile/Profile';
import Settings from './components/Settings/Settings';
import ForgotPassword from './components/Auth/ForgotPassword';
import Users from './components/Admin/Users';
import Skills from './components/Skills/Skills';
import { checkAuthToken } from './api/axios';
import TechnologiesList from './components/Technologies/Technologies';
import Technologies from './components/Technologies/Technologies';
import ErrorBoundary from './components/ErrorBoundary';
import Loading from './components/Loading/Loading';
import { AuthProvider } from './contexts/AuthContext';
import { initI18n } from './i18n';
import ProfileShow from './components/Public/ProfileShow';
import Page from './components/Page';
import Timeline from './components/Profile/Timeline';
import ResetPassword from './components/Auth/ResetPassword';
import MedalWizard from './components/MedalWizard/MedalWizard';
import { useTranslation } from 'react-i18next';
import { useResizeObserverHandler } from './hooks/useResizeObserverHandler';
import ResizeObserverErrorBoundary from './components/ErrorBoundary/ResizeObserverErrorBoundary';
import EmailVerification from './components/Auth/EmailVerification';
import Why from './components/Public/Why';
import What from './components/Public/What';
import SocialCallback from './components/Auth/SocialCallback';
import BasicResume from './components/Resume/BasicResume';
import PrivacyPolicy from './components/Public/PrivacyPolicy';
import VisualTimeline from './components/Profile/VisualTimeline';

// Initialize i18n
initI18n();

function App() {
  const { t } = useTranslation();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const verifyToken = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const isValid = await checkAuthToken(token);
          setIsAuthenticated(isValid);
        } catch (error) {
          console.error('Token verification failed:', error);
          setIsAuthenticated(false);
          localStorage.removeItem('token');
        }
      } else {
        setIsAuthenticated(false);
      }
      setIsLoading(false);
    };

    verifyToken();
  }, []);

  useEffect(() => {
    initI18n();
  }, []);

  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem('token');
  };

  useResizeObserverHandler();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <HelmetProvider>
      <div className="App">
        <ErrorBoundary>
          <UserProvider>
            <AuthProvider>
              <ToastContainer
                position="top-right"
                autoClose={1300}
                hideProgressBar={true}
                progressStyle={{
                  background: 'linear-gradient(to right, #4F46E5, #818CF8)', // Changed to indigo gradient
                  height: '5px',
                  borderRadius: '5px'
                }}
                newestOnTop={true}
                closeOnClick
                closeButton={true}
                rtl={false}
                pauseOnFocusLoss={false}
                theme="colored"
                toastClassName="custom-toast"
              />
              <Layout isAuthenticated={isAuthenticated} onLogout={handleLogout}>
                <Routes>
                  <Route
                    path="/"
                    element={
                      <Page title="Home">
                        {isAuthenticated ? <Navigate to="/dashboard" /> : <Home />}
                      </Page>
                    }
                  />
                  <Route
                    path="/login"
                    element={
                      <Page title="Login">
                        {isAuthenticated ? <Navigate to="/dashboard" /> : <Login onLogin={handleLogin} />}
                      </Page>
                    }
                  />
                  <Route
                    path="/register"
                    element={
                      <Page title="Register">
                        {isAuthenticated ? <Navigate to="/dashboard" /> : <Register />}
                      </Page>
                    }
                  />
                  <Route
                    path="/dashboard"
                    element={
                      <Page title="Dashboard">
                        {isAuthenticated ? <Dashboard /> : <Navigate to="/login" />}
                      </Page>
                    }
                  />
                  <Route
                    path="/profile"
                    element={
                      <Page title="Profile">
                        {isAuthenticated ? <Profile /> : <Navigate to="/login" />}
                      </Page>
                    }
                  />
                  <Route
                    path="/settings"
                    element={
                      <Page title="Settings">
                        {isAuthenticated ? <Settings /> : <Navigate to="/login" />}
                      </Page>
                    }
                  />
                  <Route
                    path="/forgot-password"
                    element={
                      <Page title="Forgot Password">
                        <ForgotPassword />
                      </Page>
                    }
                  />
                  <Route
                    path="/users"
                    element={
                      <Page title="Users">
                        {isAuthenticated ? <Users /> : <Navigate to="/login" />}
                      </Page>
                    }
                  />
                  <Route
                    path="/skills"
                    element={
                      <Page title="Skills">
                        {isAuthenticated ? <Skills /> : <Navigate to="/login" />}
                      </Page>
                    }
                  />
                  <Route
                    path="/technologies"
                    element={
                      <Page title="Technologies">
                        {isAuthenticated ? <Technologies /> : <Navigate to="/login" />}
                      </Page>
                    }
                  />
                  <Route
                    path="/u/:username"
                    element={
                      <Page title={`${decodeURIComponent(window.location.pathname.split('/').pop())}`}>
                        <ProfileShow />
                      </Page>
                    }
                  />
                  <Route
                    path="/medal-wizard"
                    element={
                      <Page title="Add Medal">
                        {isAuthenticated ? <MedalWizard /> : <Navigate to="/login" />}
                      </Page>
                    }
                  />
                  <Route
                    path="/timeline"
                    element={
                      <Page title="Timeline">
                        {isAuthenticated ? <Timeline /> : <Navigate to="/login" />}
                      </Page>
                    }
                  />
                  <Route
                    path="/reset-password"
                    element={
                      <Page title="Reset Password">
                        <ResetPassword />
                      </Page>
                    }
                  />
                  <Route
                    path="/why-use-digital-medals"
                    element={
                      <Page title="Why">
                        <Why />
                      </Page>
                    }
                  />
                  <Route
                    path="/what-are-digital-medals"
                    element={
                      <Page title="What">
                        <What />
                      </Page>
                    }
                  />
                  <Route path="/password-reset/:token" element={<ResetPassword />} />
                  <Route path="/:id/verify-email" element={<EmailVerification />} />
                  <Route path="/social-callback" element={<SocialCallback />} />
                  <Route
                    path="/resume"
                    element={
                      <Page title="Resume">
                        {isAuthenticated ? <BasicResume /> : <Navigate to="/login" />}
                      </Page>
                    }
                  />
                  <Route
                    path="/privacy-policy"
                    element={
                      <Page title="Privacy Policy">
                        <PrivacyPolicy />
                      </Page>
                    }
                  />
                  <Route
                    path="/visual-timeline"
                    element={
                      <Page title="Visual Timeline">
                        {isAuthenticated ? <VisualTimeline /> : <Navigate to="/login" />}
                      </Page>
                    }
                  />
                  {/* Add more routes as needed */}
                </Routes>
              </Layout>
            </AuthProvider>
          </UserProvider>
        </ErrorBoundary>
      </div>
    </HelmetProvider>
  );
}

export default App;
