// src/components/Timeline/Timeline.js
import React, { useEffect, useState } from 'react';
import { fetchMedals, updateMedal, fetchMedalCategories, fetchGoals, fetchSkills, fetchTechnologies, deleteMedal } from '../../api/axios';
import { useNavigate } from 'react-router-dom';
import { FaUser, FaUserTie, FaHouseUser, FaCode, FaChevronDown, FaChevronUp, FaEdit, FaSave, FaPlus, FaTrash, FaTimes, FaStream } from 'react-icons/fa';
import { format, isValid, parseISO } from 'date-fns';
import PageHero from '../Common/PageHero';
import Button from '../Common/Button';
import Select from 'react-select';
import ConfirmationModal from '../Common/ConfirmationModal';
import CreatableSelect from 'react-select/creatable';
import { fetchUserProjects, fetchUserSkills } from '../../api/axios';
import Page from '../Page';
import { useUser } from '../../contexts/UserContext';

const Timeline = () => {
  const { user } = useUser();
  const [medals, setMedals] = useState([]);
  const [expandedMedal, setExpandedMedal] = useState(null);
  const [editingMedal, setEditingMedal] = useState(null);
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [skills, setSkills] = useState([]);
  const [technologies, setTechnologies] = useState([]);
  const [goals, setGoals] = useState([]);
  const [allGoals, setAllGoals] = useState([]);
  const [medalToDelete, setMedalToDelete] = useState(null);
  const [existingProjects, setExistingProjects] = useState([]);
  const [userSkills, setUserSkills] = useState([]);

  useEffect(() => {
    const loadOptions = async () => {
      const [skills, technologies, goalsData, userSkills] = await Promise.all([
        fetchSkills(),
        fetchTechnologies(),
        fetchGoals(),
        fetchUserSkills()
      ]);
      setSkills(skills.data.map(skill => ({ value: skill.id, label: skill.name })));
      setTechnologies(technologies.data.map(tech => ({ value: tech.id, label: tech.name })));
      setAllGoals(goalsData.map(goal => ({ 
        value: goal.id, 
        label: goal.description,
        objective: goal.objective,
        target_date: goal.target_date,
        progress: goal.progress
      })));
      setUserSkills(userSkills.data.data);
    };
    loadOptions();
  }, []);

  useEffect(() => {
    const loadMedals = async () => {
      try {
        if (!user) return;
        const data = await fetchMedals();
        setMedals(Array.isArray(data) ? data : []);
      } catch (error) {
        console.error('Failed to load medals:', error);
      }
    };

    loadMedals();
  }, [user]);

  useEffect(() => {
    const loadCategories = async () => {
      try {
        const data = await fetchMedalCategories();
        setCategories(data);
      } catch (error) {
        console.error('Failed to load categories:', error);
      }
    };

    loadCategories();
  }, []);

  useEffect(() => {
    const loadExistingProjects = async () => {
      try {
        const projects = await fetchUserProjects();
        setExistingProjects(projects);
      } catch (error) {
        console.error('Failed to load existing projects:', error);
      }
    };
    loadExistingProjects();
  }, []);

  const toggleMedal = (index) => {
    setExpandedMedal(expandedMedal === index ? null : index);
    setEditingMedal(null);
  };

  const startEditing = (index) => {
    setEditingMedal(index);
  };

  const handleInputChange = (index, field, value) => {
    const updatedMedals = [...medals];
    updatedMedals[index][field] = value;
    setMedals(updatedMedals);
  };

  const handleProjectChange = (medalIndex, projectIndex, field, value) => {
    const updatedMedals = [...medals];
    updatedMedals[medalIndex].projects[projectIndex][field] = value;
    setMedals(updatedMedals);
  };

  const handleAccomplishmentChange = (medalIndex, accomplishmentIndex, value) => {
    const updatedMedals = [...medals];
    updatedMedals[medalIndex].accomplishments[accomplishmentIndex].accomplishment = value;
    setMedals(updatedMedals);
  };

  const handleLessonChange = (medalIndex, lessonIndex, value) => {
    const updatedMedals = [...medals];
    updatedMedals[medalIndex].lessons[lessonIndex].lesson = value;
    setMedals(updatedMedals);
  };

  const handleFeedbackChange = (medalIndex, feedbackIndex, field, value) => {
    const updatedMedals = [...medals];
    updatedMedals[medalIndex].feedbacks[feedbackIndex][field] = value;
    setMedals(updatedMedals);
  };

  const saveMedal = async (index) => {
    const medalToUpdate = medals[index];
    
    // Prepare the data for the API
    const updatedMedalData = {
      title: medalToUpdate.title,
      start_date: medalToUpdate.startDate,
      end_date: medalToUpdate.endDate || null,
      category_id: medalToUpdate.category.id,
      category_name: medalToUpdate.category.category,
      projects: medalToUpdate.projects.map(project => ({
        id: project.id || null,
        name: project.name,
        role: project.role,
        responsibilities: project.responsibilities,
        challenges: project.challenges,
        skills: project.skills.map(s => ({ id: s.skill.id })),
        technologies: project.technologies.map(t => ({ id: t.technology.id }))
      })),
      accomplishments: medalToUpdate.accomplishments.map(a => a.accomplishment),
      feedbacks: medalToUpdate.feedbacks.map(f => ({
        positive: f.positive,
        constructive: f.constructive,
        given_by: f.given_by
      })),
      lessons: medalToUpdate.lessons.map(l => l.lesson),
      goals: medalToUpdate.goals ? medalToUpdate.goals.map(g => ({
        id: g.goal.id,
        description: g.goal.description
      })) : []
    };

    try {
      const updatedMedal = await updateMedal(medalToUpdate.id, updatedMedalData);
      const updatedMedals = [...medals];
      updatedMedals[index] = updatedMedal;
      setMedals(updatedMedals);
      setEditingMedal(null);
    } catch (error) {
      console.error('Failed to update medal:', error);
    }
  };

  const getCategoryIcon = (category) => {
    switch (category) {
      case 'Personal': return <FaUser />;
      case 'Professional': return <FaUserTie />;
      case 'Community': return <FaHouseUser />;
      case 'Education': return <FaCode />;
      default: return <FaUser />;
    }
  };

  const formatDate = (dateString) => {
    const date = parseISO(dateString);
    return isValid(date) ? format(date, 'MMM d, yyyy') : dateString;
  };

  const getDateDisplay = (start_date, endDate) => {
    const formattedStartDate = formatDate(start_date);
    if (endDate) {
      const formattedEndDate = formatDate(endDate);
      return `${formattedStartDate} - ${formattedEndDate}`;
    }
    return `${formattedStartDate} - Current`;
  };

  const handleAddProject = (medalIndex) => {
    const updatedMedals = [...medals];
    updatedMedals[medalIndex].projects.push({
      name: '',
      role: '',
      responsibilities: '',
      challenges: '',
      skills: [],
      technologies: []
    });
    setMedals(updatedMedals);
  };

  const handleDeleteProject = (medalIndex, projectIndex) => {
    const updatedMedals = [...medals];
    updatedMedals[medalIndex].projects.splice(projectIndex, 1);
    setMedals(updatedMedals);
  };

  const handleAddAccomplishment = (medalIndex) => {
    const updatedMedals = [...medals];
    updatedMedals[medalIndex].accomplishments.push({ accomplishment: '' });
    setMedals(updatedMedals);
  };

  const handleDeleteAccomplishment = (medalIndex, accomplishmentIndex) => {
    const updatedMedals = [...medals];
    updatedMedals[medalIndex].accomplishments.splice(accomplishmentIndex, 1);
    setMedals(updatedMedals);
  };

  const handleAddLesson = (medalIndex) => {
    const updatedMedals = [...medals];
    updatedMedals[medalIndex].lessons.push({ lesson: '' });
    setMedals(updatedMedals);
  };

  const handleDeleteLesson = (medalIndex, lessonIndex) => {
    const updatedMedals = [...medals];
    updatedMedals[medalIndex].lessons.splice(lessonIndex, 1);
    setMedals(updatedMedals);
  };

  const handleAddFeedback = (medalIndex) => {
    const updatedMedals = [...medals];
    updatedMedals[medalIndex].feedbacks.push({
      positive: '',
      constructive: '',
      given_by: ''
    });
    setMedals(updatedMedals);
  };

  const handleDeleteFeedback = (medalIndex, feedbackIndex) => {
    const updatedMedals = [...medals];
    updatedMedals[medalIndex].feedbacks.splice(feedbackIndex, 1);
    setMedals(updatedMedals);
  };

  const cancelEditing = () => {
    setEditingMedal(null);
  };

  const handleProjectSkillsChange = (medalIndex, projectIndex, selectedOptions) => {
    const updatedMedals = [...medals];
    updatedMedals[medalIndex].projects[projectIndex].skills = selectedOptions.map(option => ({
      id: option.value,
      skill: { id: option.value, name: option.label }
    }));
    setMedals(updatedMedals);
  };

  const handleProjectTechnologiesChange = (medalIndex, projectIndex, selectedOptions) => {
    const updatedMedals = [...medals];
    updatedMedals[medalIndex].projects[projectIndex].technologies = selectedOptions.map(option => ({
      id: option.value,
      technology: { id: option.value, name: option.label }
    }));
    setMedals(updatedMedals);
  };

  const handleGoalsChange = (medalIndex, selectedOptions) => {
    const updatedMedals = [...medals];
    updatedMedals[medalIndex].goals = selectedOptions.map(option => ({
      id: option.value,
      goal: {
        id: option.value,
        description: option.label,
        objective: option.objective,
        target_date: option.target_date,
        progress: option.progress
      }
    }));
    setMedals(updatedMedals);
  };

  const handleAddGoal = (medalIndex) => {
    const updatedMedals = [...medals];
    updatedMedals[medalIndex].goals.push({ id: '' });
    setMedals(updatedMedals);
  };

  // Add this new function inside the Timeline component
  const handleDeleteMedal = async (medalId) => {
    try {
      await deleteMedal(medalId);
      const updatedMedals = medals.filter(medal => medal.id !== medalId);
      setMedals(updatedMedals);
      setEditingMedal(null);
      setMedalToDelete(null);
    } catch (error) {
      console.error('Failed to delete medal:', error);
    }
  };

  const handleExistingProjectSelect = (medalIndex, selectedOptions) => {
    const updatedMedals = [...medals];
    const selectedProjects = selectedOptions.map(option => ({
      id: option.value.id,
      name: option.value.name,
      role: option.value.role,
      responsibilities: option.value.responsibilities,
      challenges: option.value.challenges,
      skills: option.value.skills.map(s => ({
        skill: { id: s.skill.id, name: s.skill.name }
      })),
      technologies: option.value.technologies.map(t => ({
        technology: { id: t.technology.id, name: t.technology.name }
      }))
    }));

    // Filter out any duplicates
    const existingProjectIds = updatedMedals[medalIndex].projects.map(p => p.id);
    const newProjects = selectedProjects.filter(p => !existingProjectIds.includes(p.id));
    
    updatedMedals[medalIndex].projects = [
      ...updatedMedals[medalIndex].projects,
      ...newProjects
    ];
    
    setMedals(updatedMedals);
  };

  const renderEditForm = (medal, index) => {
    if (!medal) return null;

    return (
      <div className="space-y-6 bg-white p-6 rounded-lg shadow">
        <h4 className="text-xl font-semibold mb-4">Edit Medal Details</h4>
        
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Title</label>
          <input
            type="text"
            value={medal.title || ''}
            onChange={(e) => handleInputChange(index, 'title', e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Start Date</label>
            <input
              type="date"
              value={medal.startDate || ''}
              onChange={(e) => handleInputChange(index, 'startDate', e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">End Date</label>
            <input
              type="date"
              value={medal.endDate || ''}
              onChange={(e) => handleInputChange(index, 'endDate', e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Category</label>
          <select
            value={medal.category?.id || ''}
            onChange={(e) => handleInputChange(index, 'category', { id: parseInt(e.target.value), category: e.target.options[e.target.selectedIndex].text })}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          >
            <option value="">Select a category</option>
            {categories.map((category) => (
              <option key={category.id} value={category.id}>
                {category.category}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Goals</label>
          <div className="flex flex-col sm:flex-row gap-2">
            <div className="flex-grow">
              <Select
                isMulti
                options={allGoals}
                value={(medal.goals || []).map(goalItem => ({
                  value: goalItem.goal.id,
                  label: goalItem.goal.description,
                  objective: goalItem.goal.objective,
                  target_date: goalItem.goal.target_date,
                  progress: goalItem.goal.progress
                }))}
                onChange={(selectedOptions) => handleGoalsChange(index, selectedOptions)}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Select goals..."
              />
            </div>
            <Button
              onClick={() => navigate('/settings?tab=goals')}
              color="none"
              icon={FaEdit}
              className="whitespace-nowrap text-gray-500 w-full sm:w-auto"
            >
              Edit Goals
            </Button>
          </div>
        </div>

        <div>
          <div className="flex flex-col sm:flex-row justify-between items-center mb-2">
            <h5 className="font-semibold">Projects</h5>
            <div className="flex gap-2">
              <Button
                onClick={() => handleAddProject(index)}
                color="none"
                icon={FaPlus}
                className="text-sm px-2 py-1 text-gray-500"
              >
                Add New Project
              </Button>
            </div>
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Add Existing Projects
            </label>
            <Select
              isMulti
              options={existingProjects.map(project => ({
                value: project,
                label: project.name
              }))}
              onChange={(selectedOptions) => handleExistingProjectSelect(index, selectedOptions)}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Select existing projects..."
              value={[]} // Always empty to allow re-selecting
            />
          </div>

          {medal.projects && medal.projects.map((project, projectIndex) => (
            <div key={projectIndex} className="mb-4 p-4 bg-gray-50 rounded-md">
              <div className="flex justify-between items-start mb-2">
                <label className="block text-sm font-medium text-gray-700">
                  {project.id ? 'Existing Project' : 'New Project'}
                </label>
                <Button
                  onClick={() => handleDeleteProject(index, projectIndex)}
                  color="red"
                  icon={FaTrash}
                  className="text-sm px-2 py-1"
                >
                  Remove
                </Button>
              </div>
              
              <div className="mb-2">
                <label className="block text-sm font-medium text-gray-700 mb-1">Project Name</label>
                <input
                  type="text"
                  value={project.name}
                  onChange={(e) => handleProjectChange(index, projectIndex, 'name', e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  disabled={project.id} // Disable editing for existing projects
                />
              </div>

              <div className="mb-2">
                <label className="block text-sm font-medium text-gray-700 mb-1">Role</label>
                <input
                  type="text"
                  value={project.role}
                  onChange={(e) => handleProjectChange(index, projectIndex, 'role', e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  disabled={project.id}
                />
              </div>

              <div className="mb-2">
                <label className="block text-sm font-medium text-gray-700 mb-1">Responsibilities</label>
                <textarea
                  value={project.responsibilities}
                  onChange={(e) => handleProjectChange(index, projectIndex, 'responsibilities', e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  rows="3"
                  disabled={project.id}
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Challenges</label>
                <textarea
                  value={project.challenges}
                  onChange={(e) => handleProjectChange(index, projectIndex, 'challenges', e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  rows="3"
                  disabled={project.id}
                />
              </div>

              <div className="mb-2">
                <label className="block text-sm font-medium text-gray-700 mb-1">Skills</label>
                <Select
                  isMulti
                  options={skills}
                  value={project.skills.map(skill => ({ 
                    value: skill.skill.id, 
                    label: skill.skill.name 
                  }))}
                  onChange={(selectedOptions) => handleProjectSkillsChange(index, projectIndex, selectedOptions)}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  isDisabled={project.id}
                />
              </div>

              <div className="mb-2">
                <label className="block text-sm font-medium text-gray-700 mb-1">Technologies</label>
                <Select
                  isMulti
                  options={technologies}
                  value={project.technologies.map(tech => ({ 
                    value: tech.technology.id, 
                    label: tech.technology.name 
                  }))}
                  onChange={(selectedOptions) => handleProjectTechnologiesChange(index, projectIndex, selectedOptions)}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  isDisabled={project.id}
                />
              </div>
            </div>
          ))}
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <div className="flex flex-col sm:flex-row justify-between items-center mb-2">
              <h5 className="font-semibold">Accomplishments</h5>
              <Button
                onClick={() => handleAddAccomplishment(index)}
                color="none"
                icon={FaPlus}
                className="text-sm px-2 py-1 text-gray-500"
              >
                Add Accomplishment
              </Button>
            </div>
            <div className="space-y-4">
              {medal.accomplishments.map((accomplishment, accomplishmentIndex) => (
                <div key={accomplishmentIndex} className="bg-gray-50 rounded-md p-4">
                  <textarea
                    value={accomplishment.accomplishment}
                    onChange={(e) => handleAccomplishmentChange(index, accomplishmentIndex, e.target.value)}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 mb-2"
                    rows="3"
                  />
                  <div className="flex justify-end">
                    <Button
                      onClick={() => handleDeleteAccomplishment(index, accomplishmentIndex)}
                      color="red"
                      icon={FaTrash}
                      className="text-sm px-2 py-1"
                    >
                      Delete
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div>
            <div className="flex flex-col sm:flex-row justify-between items-center mb-2">
              <h5 className="font-semibold">Lessons Learned</h5>
              <Button
                onClick={() => handleAddLesson(index)}
                color="none"
                icon={FaPlus}
                className="text-sm px-2 py-1 text-gray-500"
              >
                Add Lesson
              </Button>
            </div>
            <div className="space-y-4">
              {medal.lessons.map((lesson, lessonIndex) => (
                <div key={lessonIndex} className="bg-gray-50 rounded-md p-4">
                  <textarea
                    value={lesson.lesson}
                    onChange={(e) => handleLessonChange(index, lessonIndex, e.target.value)}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 mb-2"
                    rows="3"
                  />
                  <div className="flex justify-end">
                    <Button
                      onClick={() => handleDeleteLesson(index, lessonIndex)}
                      color="red"
                      icon={FaTrash}
                      className="text-sm px-2 py-1"
                    >
                      Delete
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div>
          <div className="flex flex-col sm:flex-row justify-between items-center mb-2">
            <h5 className="font-semibold">Feedbacks</h5>
            <Button
              onClick={() => handleAddFeedback(index)}
              color="none"
              icon={FaPlus}
              className="text-sm px-2 py-1 text-gray-500"
            >
              Add Feedback
            </Button>
          </div>
          {medal.feedbacks.map((feedback, feedbackIndex) => (
            <div key={feedbackIndex} className="mb-4 p-4 bg-gray-50 rounded-md">
              <div className="mb-2">
                <label className="block text-sm font-medium text-gray-700 mb-1">Positive</label>
                <textarea
                  value={feedback.positive}
                  onChange={(e) => handleFeedbackChange(index, feedbackIndex, 'positive', e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  rows="2"
                />
              </div>
              <div className="mb-2">
                <label className="block text-sm font-medium text-gray-700 mb-1">Constructive</label>
                <textarea
                  value={feedback.constructive}
                  onChange={(e) => handleFeedbackChange(index, feedbackIndex, 'constructive', e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  rows="2"
                />
              </div>
              <div className="mb-2">
                <label className="block text-sm font-medium text-gray-700 mb-1">Given By</label>
                <input
                  type="text"
                  value={feedback.given_by}
                  onChange={(e) => handleFeedbackChange(index, feedbackIndex, 'given_by', e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              <div className="flex justify-end">
                <Button
                  onClick={() => handleDeleteFeedback(index, feedbackIndex)}
                  color="red"
                  icon={FaTrash}
                  className="text-sm px-2 py-1"
                >
                  Delete
                </Button>
              </div>
            </div>
          ))}
        </div>

        <div className="flex justify-end space-x-4">
          <Button
            onClick={() => setMedalToDelete(medal.id)}
            color="red"
            icon={FaTrash}
          >
            Delete
          </Button>
          <Button
            onClick={() => cancelEditing()}
            color="gray"
            icon={FaTimes}
            className="text-gray-500"
          >
            Cancel
          </Button>
          <Button
            onClick={() => saveMedal(index)}
            color="green"
            icon={FaSave}
          >
            Save
          </Button>
        </div>
      </div>
    );
  };

  return (
    <Page
      title={`${user?.name}'s Timeline of Achievements`}
      description={`View ${user?.name}'s professional achievements, skills, and career history on Digital Medals`}
      keywords={`${user?.name}, professional profile, ${userSkills.map(s => s.skill_name).join(', ')}`}
      ogImage={user?.profile_picture_url}
    >
      <div className="container mx-auto px-4 py-8">
      <PageHero
        title="Welcome to your Digital Timeline"
        buttons={[
          {
            icon: <FaStream />,
            text: "Visualize",
            onClick: () => navigate('/visual-timeline'),
          },
          {
            icon: <FaPlus />,
            text: "Add Medal",
            onClick: () => navigate('/medal-wizard')
          },
        ]}
      />
      
      {medals.length === 0 ? (
        <div className="text-center text-gray-500 mt-2">
          <p>No medals yet. Start adding your achievements!</p>
        </div>
      ) : (
        <div className="space-y-2">
          {medals.map((medal, index) => (
            <div key={index} className="bg-gray-50 rounded-lg shadow overflow-hidden">
              <div 
                className={`p-6 cursor-pointer ${expandedMedal === index ? 'bg-blue-50' : ''}`}
                onClick={() => toggleMedal(index)}
              >
                <div className="flex flex-col md:flex-row md:items-center md:justify-between">
                  <div className="flex items-center">
                    <div className="h-12 w-12 rounded-full bg-gradient-to-r from-yellow-200 via-blue-200 via-blue-200 to-purple-200 flex items-center justify-center mr-4">
                      {getCategoryIcon(medal.category.category)}
                    </div>
                    <div>
                      <h3 className="text-xl font-semibold">{medal.title}</h3>
                      <div className="text-sm text-gray-500 mt-1">
                        <span>{getDateDisplay(medal.startDate, medal.endDate)}</span>
                        <span className="ml-2 px-2 py-1 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                          {medal.category.category}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center mt-4 md:mt-0">
                    {expandedMedal === index && editingMedal !== index && (
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          startEditing(index);
                        }}
                        color="none"
                        icon={FaEdit}
                        className="w-full md:w-auto mr-4 text-gray-500 "
                      >
                        Edit
                      </Button>
                    )}
                    {editingMedal === index && (
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          saveMedal(index);
                        }}
                        color="none"
                        icon={FaSave}
                        className="w-full md:w-auto mr-4 text-green-500"
                      >
                        Save
                      </Button>
                    )}
                    {expandedMedal === index ? <FaChevronUp /> : <FaChevronDown />}
                  </div>
                </div>
              </div>
              
              {expandedMedal === index && (
                <div className="p-6 border-t border-gray-200">
                  {editingMedal === index ? renderEditForm(medal, index) : (
                    <div className="space-y-4">
                      {/* Projects and Goals Section - Side by Side */}
                      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                        {/* Projects Section */}
                        <div className="mb-6">
                          <h4 className="font-semibold mb-2">Projects</h4>
                          {medal.projects && medal.projects.length > 0 ? (
                            <div className="space-y-4">
                              {medal.projects.map((project, projIndex) => (
                                <div key={projIndex} className="bg-blue-50 p-4 rounded-lg">
                                  <h5 className="font-medium">{project.name}</h5>
                                  {project.role && <p className="text-sm text-gray-600"><span className="font-medium">Role:</span> {project.role}</p>}
                                  {project.responsibilities && <p className="text-sm text-gray-600"><span className="font-medium">Responsibilities:</span> {project.responsibilities}</p>}
                                  {project.challenges && <p className="text-sm text-gray-600"><span className="font-medium">Challenges:</span> {project.challenges}</p>}
                                  <div className="mt-2 flex flex-wrap gap-2">
                                    {project.skills && project.skills.map((skill, skillIndex) => (
                                      <span key={skillIndex} className="px-2 py-1 bg-purple-100 text-purple-800 text-xs font-medium rounded-full">
                                        {skill.skill.name}
                                      </span>
                                    ))}
                                    {project.technologies && project.technologies.map((tech, techIndex) => (
                                      <span key={techIndex} className="px-2 py-1 bg-orange-100 text-orange-800 text-xs font-medium rounded-full">
                                        {tech.technology.name}
                                      </span>
                                    ))}
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div className="bg-blue-50 p-4 rounded-lg text-center">
                              <p className="text-gray-600 italic">No projects added yet. <a onClick={(e) => {
                                  e.stopPropagation();
                                  startEditing(index);
                                }} className="text-blue-500 italic">Add one now!</a></p>
                            </div>
                          )}
                        </div>

                        {/* Goals Section */}
                        <div className="mb-6">
                          <h4 className="font-semibold mb-2">Goals</h4>
                          {medal.goals && medal.goals.length > 0 ? (
                            <div className="space-y-2">
                              {medal.goals.map((goalItem, goalIndex) => (
                                <div key={goalIndex} className="p-4 border-b border-gray-200 bg-purple-50 rounded-lg">
                                  <div className="flex justify-between items-center">
                                    <div>
                                      <h5 className="font-medium text-gray-900">{goalItem.goal.description}</h5>
                                      {goalItem.goal.objective && (
                                        <p className="text-sm text-gray-600">
                                          <span className="font-medium">Objective:</span> {goalItem.goal.objective}
                                        </p>
                                      )}
                                      <p className="text-sm text-gray-600">
                                        <span className="font-medium">Progress:</span> {goalItem.goal.progress}
                                      </p>
                                    </div>
                                    {goalItem.goal.target_date && (
                                      <span className="text-sm text-gray-600">
                                        Target: {new Date(goalItem.goal.target_date).toLocaleDateString()}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div className="bg-blue-50 p-4 rounded-lg text-center">
                              <p className="text-gray-600 italic">No goals linked yet. <a onClick={(e) => {
                                  e.stopPropagation();
                                  startEditing(index);
                                }} className="text-blue-500 italic">Link some now!</a></p>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        {/* Accomplishments Section */}
                        <div className="mb-6">
                          <h4 className="font-semibold mb-2">Accomplishments</h4>
                          {medal.accomplishments && medal.accomplishments.length > 0 ? (
                            <div className="space-y-2">
                              {medal.accomplishments.map((accomplishment, accIndex) => (
                                <div key={accIndex} className="bg-green-50 p-3 rounded">
                                  {accomplishment.accomplishment && <p className="text-sm">
                                  <span className="font-medium">
                                    #{accIndex + 1}:
                                  </span> {accomplishment.accomplishment}
                                  </p>}
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div className="bg-green-50 p-4 rounded-lg text-center">
                              <p className="text-gray-600 italic">No accomplishments added yet. <a onClick={(e) => {
                                  e.stopPropagation();
                                  startEditing(index);
                                }} className="text-blue-500 italic">Add some now!</a></p>
                            </div>
                          )}
                        </div>

                        {/* Lessons Section */}
                        <div className="mb-6">
                          <h4 className="font-semibold mb-2">Lessons Learned</h4>
                          {medal.lessons && medal.lessons.length > 0 ? (
                            <div className="space-y-2">
                              {medal.lessons.map((lesson, lessonIndex) => (
                                <div key={lessonIndex} className="bg-yellow-50 p-3 rounded">
                                  {lesson.lesson && <p className="text-sm">
                                  <span className="font-medium">
                                    #{lessonIndex + 1}:
                                  </span> {lesson.lesson}
                                  </p>}
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div className="bg-yellow-50 p-4 rounded-lg text-center">
                              <p className="text-gray-600 italic">No lessons added yet. <a onClick={(e) => {
                                  e.stopPropagation();
                                  startEditing(index);
                                }} className="text-blue-500 italic">Add some now!</a></p>
                            </div>
                          )}
                        </div>
                      </div>

                      {/* Feedback Section */}
                      <div className="mb-6">
                        <h4 className="font-semibold mb-2">Feedbacks</h4>
                        {medal.feedbacks && medal.feedbacks.length > 0 ? (
                          <div className="space-y-2">
                            {medal.feedbacks.map((feedback, fbIndex) => (
                              <div key={fbIndex} className="bg-red-50 p-3 rounded">
                                {feedback.positive && <p className="text-sm"><span className="font-medium">Positive:</span> {feedback.positive}</p>}
                                {feedback.constructive && <p className="text-sm"><span className="font-medium">Constructive:</span> {feedback.constructive}</p>}
                                <p className="text-xs text-gray-500 mt-1">Given by: {feedback.given_by || 'Myself'}</p>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div className="bg-red-50 p-4 rounded-lg text-center">
                            <p className="text-gray-600 italic">No feedback added yet. <a onClick={(e) => {
                                e.stopPropagation();
                                startEditing(index);
                              }} className="text-blue-500 italic">Add some now!</a></p>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
      
        <ConfirmationModal
          isOpen={medalToDelete !== null}
          onClose={() => setMedalToDelete(null)}
          onConfirm={() => handleDeleteMedal(medalToDelete)}
          title="Delete Medal"
          message="Are you sure you want to delete this medal? This action cannot be undone."
        />
      </div>
    </Page>
  );
};

export default Timeline;
