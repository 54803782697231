import React from 'react';
import { 
  FaFacebook, FaTwitter, FaLinkedin, FaInstagram, FaPinterest, 
  FaSnapchat, FaTiktok, FaYoutube, FaReddit, FaTumblr, FaFlickr, 
  FaVimeo, FaGithub, FaWhatsapp, FaTelegram, FaDiscord, FaMedium, 
  FaQuora, FaSpotify, FaGlobe
} from 'react-icons/fa';
import { SiX } from 'react-icons/si';

export const socialIcons = {
  Facebook: FaFacebook,
  X: SiX,
  LinkedIn: FaLinkedin,
  Instagram: FaInstagram,
  Pinterest: FaPinterest,
  Snapchat: FaSnapchat,
  TikTok: FaTiktok,
  YouTube: FaYoutube,
  Reddit: FaReddit,
  Tumblr: FaTumblr,
  Flickr: FaFlickr,
  Vimeo: FaVimeo,
  GitHub: FaGithub,
  WhatsApp: FaWhatsapp,
  Telegram: FaTelegram,
  Discord: FaDiscord,
  Medium: FaMedium,
  Quora: FaQuora,
  Spotify: FaSpotify,
};

export const getSocialIcon = (platform) => {
  const IconComponent = socialIcons[platform] || FaGlobe;
  return <IconComponent />;
};
