import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { fetchTechnologies, storeUserTechnology, fetchUserTechnologies, removeUserTechnology, createTechnology, fetchCommonTechnologies, updateUserTechnology } from '../../api/axios';
import { FaTimes, FaSortAlphaDown, FaSortAlphaUp, FaSortNumericDown, FaSortNumericUp, FaPlus, FaCode, FaLaptopCode } from 'react-icons/fa';
import CreatableSelect from 'react-select/creatable';
import ProficiencyModal from '../Proficiency/ProficiencyModal';
import PageHero from '../Common/PageHero';
import { useNavigate } from 'react-router-dom';
import { getProficiencyColor, getProficiencyBadgeColor } from '../Proficiency/Proficiency';

function TechnologiesList() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [technologies, setTechnologies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedTechnology, setSelectedTechnology] = useState(null);
  const [userTechnologies, setUserTechnologies] = useState([]);
  const [commonTechnologies, setCommonTechnologies] = useState([]);
  const [userTechnologiesSortMethod, setUserTechnologiesSortMethod] = useState('proficiency');
  const [sortDirection, setSortDirection] = useState('asc');
  const [availableTechnologies, setAvailableTechnologies] = useState([]);

  useEffect(() => {
    const getTechnologies = async () => {
      try {
        const [technologiesResponse, commonTechnologiesResponse, userTechnologiesResponse] = await Promise.all([
          fetchTechnologies(),
          fetchCommonTechnologies(),
          fetchUserTechnologies()
        ]);
       
        const fetchedTechnologies = technologiesResponse.data || [];
        const fetchedCommonTechnologies = commonTechnologiesResponse || [];
        const fetchedUserTechnologies = Array.isArray(userTechnologiesResponse.data.data) 
          ? userTechnologiesResponse.data.data
          : [];
        setTechnologies(fetchedTechnologies);
        setCommonTechnologies(fetchedCommonTechnologies);
        setUserTechnologies(fetchedUserTechnologies);
        setAvailableTechnologies(fetchedCommonTechnologies);
        setLoading(false);

        // Sort user technologies by proficiency desc initially
        const sortedTechnologies = sortUserTechnologies(fetchedUserTechnologies, 'proficiency', 'asc');
        setUserTechnologies(sortedTechnologies);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to fetch technologies or user technologies');
        setLoading(false);
      }
    };

    getTechnologies();
  }, []);

  const handleTechnologyClick = (technology) => {
    setSelectedTechnology({
      id: technology.technology_id || technology.id,
      name: technology.technology_name || technology.name,
      currentProficiency: technology.proficiency_id
    });
  };

  const handleProficiencySubmit = async (proficiency) => {
    if (selectedTechnology && proficiency) {
      try {
        // Check if this is an update or new technology
        const isUpdate = userTechnologies.some(tech => tech.technology_id === selectedTechnology.id);
        
        if (isUpdate) {
          // Update existing technology
          await updateUserTechnology(selectedTechnology.id, proficiency);
          
          setUserTechnologies(prevTechnologies => prevTechnologies.map(tech => {
            if (tech.technology_id === selectedTechnology.id) {
              return {
                ...tech,
                proficiency_id: proficiency,
                proficiency_name: ['Beginner', 'Intermediate', 'Advanced', 'Expert'][proficiency - 1]
              };
            }
            return tech;
          }));
        } else {
          // Add new technology
          await storeUserTechnology(selectedTechnology.id, proficiency);
          
          setUserTechnologies(prevTechnologies => [
            ...prevTechnologies,
            {
              id: selectedTechnology.id,
              technology_id: selectedTechnology.id,
              technology_name: selectedTechnology.name,
              proficiency_name: ['Beginner', 'Intermediate', 'Advanced', 'Expert'][proficiency - 1]
            }
          ]);
          
          setAvailableTechnologies(prevTechnologies => 
            prevTechnologies.filter(tech => tech.id !== selectedTechnology.id)
          );
        }
        
        setSelectedTechnology(null);
      } catch (error) {
        console.error('Error updating user technology:', error);
      }
    }
  };

  const handleRemoveTechnology = async (technologyId) => {
    try {
      await removeUserTechnology(technologyId);
      setUserTechnologies(prevTechnologies => prevTechnologies.filter(technology => technology.technology_id !== technologyId));
    } catch (error) {
      console.error('Error removing user technology:', error);
    }
  };

  const toggleSort = (method) => {
    if (method === userTechnologiesSortMethod) {
      setSortDirection(prevDirection => prevDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setUserTechnologiesSortMethod(method);
      setSortDirection('desc');
    }
    setUserTechnologies(prevTechnologies => sortUserTechnologies(prevTechnologies, method, sortDirection === 'asc' ? 'desc' : 'asc'));
  };

  const sortUserTechnologies = (technologies, method, direction) => {
    return [...technologies].sort((a, b) => {
      let comparison = 0;
      if (method === 'alphabetical') {
        comparison = a.technology_name.localeCompare(b.technology_name);
      } else if (method === 'proficiency') {
        const proficiencyOrder = ['Beginner', 'Intermediate', 'Advanced', 'Expert'];
        comparison = proficiencyOrder.indexOf(b.proficiency_name) - proficiencyOrder.indexOf(a.proficiency_name);
      }
      return direction === 'asc' ? comparison : -comparison;
    });
  };

  const handleCreateTechnology = async (inputValue) => {
    try {
      const createdTechnology = await createTechnology(inputValue);
      setTechnologies(prevTechnologies => [...prevTechnologies, createdTechnology]);
      handleTechnologyClick(createdTechnology);
    } catch (error) {
      console.error("Error creating technology:", error);
    }
  };

  if (loading) return <div className="text-center text-2xl text-blue-600 mt-8">{t('technologies.loading')}</div>;
  if (error) return <div className="text-center text-2xl text-red-600 mt-8">{error}</div>;
  if (technologies.length === 0) return <div className="text-center text-2xl text-blue-600 mt-8">{t('technologies.noTechnologies')}</div>;

  return (
    <div className="min-h-screen">
      <div className="container mx-auto px-4 py-8">
        <div className="bg-white rounded-lg overflow-hidden">
          <PageHero
            title={t('technologies.title')}
            buttons={[
              {
                text: "My Profile",
                onClick: () => navigate('/profile')
              },
              {
                text: "My Skills",
                onClick: () => navigate('/skills')
              }
            ]}
          />
          <div className="flex flex-col md:flex-row gap-8">
            <div className="bg-white shadow-md border border-gray-200 p-6 flex-1 order-2 md:order-1">
              <h2 className="text-2xl font-bold mb-4 text-gray-800">{t('technologies.addTechnologies')}</h2>
              <CreatableSelect
                isClearable
                onChange={(newValue) => {
                  if (newValue && newValue.__isNew__) {
                    handleCreateTechnology(newValue.label);
                  } else if (newValue) {
                    handleTechnologyClick(newValue.value);
                  }
                }}
                onCreateOption={handleCreateTechnology}
                options={technologies.map(tech => ({ value: tech, label: tech.name }))}
                placeholder={t('technologies.searchOrAdd')}
                className="mb-4"
              />
              <hr className="my-4" />
              <div className="mt-4">
                <div className="flex flex-wrap justify-center gap-2">
                  {availableTechnologies.map(tech => (
                    <button
                      key={tech.id}
                      className="flex items-center bg-gray-200 hover:bg-gray-300 text-gray-800 text-sm py-1 px-2 rounded transition-colors"
                      onClick={() => handleTechnologyClick(tech)}
                    >
                      <FaPlus className="mr-1" />{tech.name}
                    </button>
                  ))}
                </div>
              </div>
            </div>
            <div className="bg-white rounded-lg shadow-md border border-gray-200 p-6 flex-1 order-1 md:order-2">
              <h2 className="text-2xl font-bold mb-4 text-gray-800">{t('technologies.yourTechnologyToolkit')}</h2>
              <p className="text-gray-600 text-sm mb-4 ml-2">{t('technologies.clickOnTechnologyToEdit')}</p>
              <div className="flex space-x-4 mb-4">
                <button
                  onClick={() => toggleSort('alphabetical')}
                  className={`py-2 px-4 rounded ${userTechnologiesSortMethod === 'alphabetical' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-800'} hover:bg-blue-500 hover:text-white transition-colors`}
                >
                  {userTechnologiesSortMethod === 'alphabetical' && sortDirection === 'asc' ? <FaSortAlphaDown className="inline mr-2" /> : <FaSortAlphaUp className="inline mr-2" />} {t('technologies.sortAZ')}
                </button>
                <button
                  onClick={() => toggleSort('proficiency')}
                  className={`py-2 px-4 rounded ${userTechnologiesSortMethod === 'proficiency' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-800'} hover:bg-blue-500 hover:text-white transition-colors`}
                >
                  {userTechnologiesSortMethod === 'proficiency' && sortDirection === 'asc' ? <FaSortNumericDown className="inline mr-2" /> : <FaSortNumericUp className="inline mr-2" />} {t('technologies.sortProficiency')}
                </button>
              </div>
              <hr className="my-4" />
              <div className="flex flex-wrap gap-3">
                {userTechnologies.map(tech => (
                  <div 
                    key={tech.id} 
                    className={`${getProficiencyColor(tech.proficiency_name)} px-3 py-2 rounded shadow hover:shadow-md transition duration-200 ease-in-out relative group cursor-pointer`}
                    onClick={() => handleTechnologyClick(tech)}
                  >
                    <span className="text-sm font-medium">{tech.technology_name}</span>
                    <span className={`${getProficiencyBadgeColor(tech.proficiency_name)} text-white text-xs px-1.5 py-0.5 rounded ml-2`}>
                      {tech.proficiency_name.slice(0, 3).toUpperCase()}
                    </span>
                    <button 
                      className="absolute -top-1 -right-1 bg-red-500 text-white rounded p-0.5 opacity-0 group-hover:opacity-100 transition-opacity"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRemoveTechnology(tech.technology_id);
                      }}
                    >
                      <FaTimes className="text-xs" />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {selectedTechnology && (
            <ProficiencyModal
              skill={selectedTechnology}
              onSubmit={handleProficiencySubmit}
              onClose={() => setSelectedTechnology(null)}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default TechnologiesList;
