import React, { useState } from 'react';
import BasicInfo from './MedalInformation';
import AddAccomplishments from './AddAccomplishments';
import AddProjects from './AddProjects';
import AddFeedback from './AddFeedback';
import ReviewAndSave from './ReviewAndSave';
import LessonsLearned from './LessonsLearned';
import { FaInfoCircle, FaTrophy, FaProjectDiagram, FaComments, FaSave, FaCheck } from 'react-icons/fa';
import { storeMedal } from '../../api/axios';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const MedalWizard = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [step, setStep] = useState(1);
  const [medalData, setMedalData] = useState({
    title: '',
    startDate: '',
    endDate: '',
    category: '',
    categoryName: '',
    accomplishments: [],
    projects: [],
    feedbacks: [],
  });
  

  const handleNext = (data) => {
    setMedalData((prevData) => ({ ...prevData, ...data }));
    setStep(step + 1);
  };

  const handleBack = (data) => {
    setMedalData((prevData) => ({ ...prevData, ...data }));
    // setProjectData((prevData) => ({ ...prevData, ...data.projects }));
    // setFeedbackData((prevData) => ({ ...prevData, ...data.feedbacks }));
    setStep(step - 1);
  };

  const handleSave = async () => {
    try {
      const dataToSubmit = {
        title: medalData.title,
        start_date: medalData.startDate,
        end_date: medalData.endDate,
        category_id: medalData.category,
        category_name: medalData.categoryName,
        projects: medalData.projects,
        accomplishments: medalData.accomplishments,
        lessons: medalData.lessons,
        feedbacks: medalData.feedbacks,
        goals: medalData.goals ? medalData.goals.map(goal => ({
          id: goal.id,
          description: goal.description,
          target_date: goal.target_date,
          objective: goal.objective
        })) : []
      };

      const response = await storeMedal(dataToSubmit);
      if (response.status === 201) {
        navigate('/timeline');
      }
    } catch (error) {
      console.error('Error saving medal:', error);
      // Handle error appropriately
    }
  };

  const steps = [
    { id: 1, name: t('medals.basicInfo'), icon: <FaInfoCircle /> },
    { id: 2, name: t('medals.projects'), icon: <FaProjectDiagram /> },
    { id: 3, name: t('accomplishments.title'), icon: <FaTrophy /> },
    { id: 4, name: t('medals.lessonsLearned'), icon: <FaComments /> },
    { id: 5, name: t('medals.feedback'), icon: <FaComments /> },
    { id: 6, name: t('medals.reviewAndSave'), icon: <FaSave /> }
  ];

  return (
    <div className="h-full flex flex-col overflow-y-auto">
      <aside className="md:hidden w-full bg-blue-50 shadow rounded-lg p-4" style={{ height: '100%' }}>
        <ul className="space-y-4">
          {steps.map((s) => (
            <li key={s.id} className={`flex items-center p-2 rounded-lg ${step === s.id ? 'bg-blue-500 text-white' : 'text-gray-700'}`}>
              <span className="mr-2">
                {step > s.id ? <FaCheck className="text-blue-500" /> : s.icon}
              </span>
              {s.name}
            </li>
          ))}
        </ul>
        {step > 1 && (
          <div className="mt-4 p-2 bg-white rounded-lg shadow">
            <h3 className="text-lg font-bold mb-2">{t('medals.information')}</h3>
            <p><strong>{t('medals.title')}:</strong> {medalData.title}</p>
            <p><strong>{t('medals.startDate')}:</strong> {medalData.startDate}</p>
            {medalData.endDate && <p><strong>{t('medals.endDate')}:</strong> {medalData.endDate}</p>}
            <p><strong>{t('medals.category')}:</strong> {medalData.categoryName || t('common.notAvailable')}</p>
          </div>
        )}
      </aside>
      <header className="hidden md:flex w-full bg-blue-50 shadow rounded-lg p-4 justify-center">
        <ul className="flex space-x-4">
          {steps.map((s) => (
            <li key={s.id} className={`flex items-center p-2 rounded-lg ${step === s.id ? 'bg-blue-600 text-white' : 'text-blue-800'}`}>
              <span className="mr-2">
                {step > s.id ? <FaCheck className="text-blue-500" /> : s.icon}
              </span>
              {s.name}
            </li>
          ))}
        </ul>
      </header>
      <main className="w-full px-6 bg-white rounded-lg border-t border-gray-200" style={{ height: '100%' }}>
        {step === 1 && <BasicInfo initialData={medalData} onNext={handleNext} />}
        {step === 2 && <AddProjects 
          initialData={medalData} 
          onNext={handleNext} 
          onBack={handleBack} 
          medalData={medalData} // Pass medalData as a prop
        />}
        {step === 3 && <AddAccomplishments 
          initialData={medalData} 
          onNext={handleNext} 
          onBack={handleBack} 
          medalData={medalData} // Pass medalData as a prop
        />}
        {step === 4 && <LessonsLearned initialData={medalData} onNext={handleNext} onBack={handleBack} medalData={medalData} />}
        {step === 5 && <AddFeedback initialData={medalData} onNext={handleNext} onBack={handleBack} medalData={medalData}/>}
        {step === 6 && <ReviewAndSave data={medalData} onBack={handleBack} onSave={handleSave} />}
      </main>
    </div>
  );
};

export default MedalWizard;
