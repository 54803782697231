import React from 'react';
// import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { UserProvider } from './contexts/UserContext';
import App from './App';
import './index.css';
import './i18n';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { setupResizeObserverPolyfill } from './utils/resizeObserver';
const originalError = console.error;
console.error = (...args) => {
  if (args[0]?.includes?.('ResizeObserver') || 
      args[0]?.message?.includes?.('ResizeObserver') ||
      args.join(' ').includes('ResizeObserver')) {
    return;
  }
  originalError.apply(console, args);
};

// Setup ResizeObserver polyfill
if (process.env.NODE_ENV === 'development') {
  setupResizeObserverPolyfill();
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <AuthProvider>
        <UserProvider>
          <App />
        </UserProvider>
      </AuthProvider>
    </Router>
  </React.StrictMode>,
  // document.getElementById('root')
);

reportWebVitals();
