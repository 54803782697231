import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="container mx-auto px-4 py-8 max-w-4xl">
      <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>
      
      <div className="space-y-6">
        <section>
          <p className="mb-4">
            At Digital Medals (referred to as "we," "us," or "our"), we are committed to protecting your privacy. 
            This Privacy Policy explains how we collect, use, and protect your information when you visit our website 
            https://digitalmedals.io (the "Site").
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-3">Information We Collect</h2>
          <p>
            We may collect personal information that you provide to us directly, such as your name, 
            email address, and any other information you submit to us through forms on the Site.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-3">How We Use Your Information</h2>
          <p>We use the information we collect for the following purposes:</p>
          <ul className="list-disc ml-6 mt-2">
            <li>To provide and maintain our services</li>
            <li>To improve our website and services</li>
            <li>To communicate with you, including responding to your inquiries</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-3">Data Sharing and Disclosure</h2>
          <p>
            We do not sell, trade, or otherwise transfer your personal information to outside parties. 
            Your data is only shared with third parties if necessary to provide our services or comply with the law.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-3">Data Security</h2>
          <p>
            We implement a variety of security measures to maintain the safety of your personal information. 
            However, please remember that no method of transmission over the internet or method of 
            electronic storage is 100% secure.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-3">Changes to This Privacy Policy</h2>
          <p>
            We may update our Privacy Policy from time to time. We will notify you of any changes by 
            posting the new Privacy Policy on this page and updating the effective date at the top of this policy.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-3">Disclaimer</h2>
          <p>
            Please note that all terms, conditions, and policies outlined in this document are subject to change 
            at our discretion. While we strive to notify users of significant changes, it is your responsibility 
            to periodically review this Privacy Policy to stay informed of any updates.
          </p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-3">Contact Us</h2>
          <p>
            If you have any questions about this Privacy Policy, please contact us at{' '}
            <a href="mailto:support@digitalmedals.io" className="text-blue-600 hover:underline">
              support@digitalmedals.io
            </a>
          </p>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
