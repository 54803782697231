import React from 'react';
import { useTranslation } from 'react-i18next';
import CreatableSelect from 'react-select/creatable';
import Button from '../Common/Button';
import { FaPlus, FaCheck, FaTimes, FaTrash, FaEdit } from 'react-icons/fa';

const ProjectsSection = ({
  projects,
  isAddingProject,
  projectEditData,
  projectEditMode,
  onAddProject,
  onSaveNewProject,
  onCancelNewProject,
  onEditProject,
  onSaveProject,
  onCancelProject,
  onDeleteProject,
  setProjectEditData,
  availableSkills,
  availableTechnologies,
  handleSkillChange,
  handleTechnologyChange
}) => {
  const { t } = useTranslation();

  const ProjectForm = ({ isNew = false }) => {
    const [localData, setLocalData] = React.useState(projectEditData);

    React.useEffect(() => {
      setLocalData(projectEditData);
    }, []);

    const handleInputChange = (field, value) => {
      setLocalData(prev => ({ ...prev, [field]: value }));
    };

    const handleInputBlur = () => {
      setProjectEditData(localData);
    };

    return (
      <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Left Column */}
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                {t('projects.name')}
              </label>
              <input
                type="text"
                value={localData.name || ''}
                onChange={(e) => handleInputChange('name', e.target.value)}
                onBlur={handleInputBlur}
                className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                placeholder={t('projects.namePlaceholder')}
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                {t('projects.role')}
              </label>
              <input
                type="text"
                value={localData.role || ''}
                onChange={(e) => handleInputChange('role', e.target.value)}
                onBlur={handleInputBlur}
                className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                placeholder={t('projects.rolePlaceholder')}
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                {t('projects.responsibilities')}
              </label>
              <textarea
                value={localData.responsibilities || ''}
                onChange={(e) => handleInputChange('responsibilities', e.target.value)}
                onBlur={handleInputBlur}
                className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                rows="3"
                placeholder={t('projects.responsibilitiesPlaceholder')}
              />
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  {t('projects.startDate')}
                </label>
                <input
                  type="date"
                  value={localData.start_date || ''}
                  onChange={(e) => handleInputChange('start_date', e.target.value)}
                  onBlur={handleInputBlur}
                  className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  {t('projects.endDate')}
                </label>
                <input
                  type="date"
                  value={localData.end_date || ''}
                  onChange={(e) => handleInputChange('end_date', e.target.value)}
                  onBlur={handleInputBlur}
                  className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>
          </div>

          {/* Right Column */}
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                {t('projects.challengesFaced')}
              </label>
              <textarea
                value={localData.challenges || ''}
                onChange={(e) => handleInputChange('challenges', e.target.value)}
                onBlur={handleInputBlur}
                className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                rows="3"
                placeholder={t('projects.challengesPlaceholder')}
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                {t('projects.skills')}
              </label>
              <CreatableSelect
                isMulti
                value={localData.skills}
                onChange={handleSkillChange}
                options={availableSkills}
                className="w-full"
                placeholder={t('projects.skillsPlaceholder')}
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                {t('projects.technologies')}
              </label>
              <CreatableSelect
                isMulti
                value={localData.technologies}
                onChange={handleTechnologyChange}
                options={availableTechnologies}
                className="w-full"
                placeholder={t('projects.technologiesPlaceholder')}
              />
            </div>
          </div>
        </div>

        <div className="flex justify-end space-x-2 mt-6">
          {!isNew && (
            <Button
              onClick={() => onDeleteProject(localData.id)}
              color="red"
              icon={FaTrash}
            >
              {t('common.delete')}
            </Button>
          )}
          <Button
            onClick={isNew ? onCancelNewProject : onCancelProject}
            color="gray"
            icon={FaTimes}
          >
            {t('common.cancel')}
          </Button>
          <Button
            onClick={isNew ? onSaveNewProject : onSaveProject}
            color="green"
            icon={FaCheck}
          >
            {t('common.save')}
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center space-x-4">
        {!isAddingProject && !projectEditMode && (
          <Button onClick={onAddProject} color="blue" icon={FaPlus}>
            {t('projects.addNew')}
          </Button>
        )}
      </div>

      {isAddingProject && <ProjectForm isNew={true} />}

      <div className="space-y-4">
        {projects.map((project) => (
          <div
            key={project.id}
            className="bg-white rounded-lg shadow-sm border border-gray-200 p-6"
          >
            {projectEditMode === project.id ? (
              <ProjectForm />
            ) : (
              <div>
                <div className="flex justify-between items-start mb-4">
                  <div>
                    <h3 className="text-lg font-medium text-gray-900">{project.name}</h3>
                    <p className="text-sm text-gray-600">{project.role}</p>
                    <p className="text-sm text-gray-500 mt-1">
                        
                      {project.start_date && (
                        <>
                          {new Date(project.start_date).toLocaleDateString()} 
                          {project.end_date && ` - ${new Date(project.end_date).toLocaleDateString()}`}
                        </>
                      )}
                    </p>
                  </div>
                  <Button
                    onClick={() => onEditProject(project)}
                    color="blue"
                    icon={FaEdit}
                    size="sm"
                  >
                    {t('common.edit')}
                  </Button>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <h4 className="text-sm font-medium text-gray-700 mb-2">
                      {t('projects.responsibilities')}
                    </h4>
                    <p className="text-sm text-gray-600">{project.responsibilities}</p>
                  </div>

                  <div>
                    <h4 className="text-sm font-medium text-gray-700 mb-2">
                      {t('projects.challenges')}
                    </h4>
                    <p className="text-sm text-gray-600">{project.challenges}</p>
                  </div>
                </div>

                <div className="mt-4 space-y-2">
                  <div>
                    <h4 className="text-sm font-medium text-gray-700 mb-2">
                      {t('projects.skills')}
                    </h4>
                    <div className="flex flex-wrap gap-2">
                      {project.skills.map((skillItem) => (
                        <span
                          key={skillItem.id}
                          className="px-2 py-1 bg-purple-100 text-purple-800 text-xs font-medium rounded-full"
                        >
                          {skillItem.skill.name}
                        </span>
                      ))}
                    </div>
                  </div>

                  <div>
                    <h4 className="text-sm font-medium text-gray-700 mb-2">
                      {t('projects.technologies')}
                    </h4>
                    <div className="flex flex-wrap gap-2">
                      {project.technologies.map((techItem) => (
                        <span
                          key={techItem.id}
                          className="px-2 py-1 bg-orange-100 text-orange-800 text-xs font-medium rounded-full"
                        >
                          {techItem.technology.name}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProjectsSection;