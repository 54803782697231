import instance from '../../axios';
import { toast } from 'react-toastify';
import i18n from '../../../i18n';

// Employment functions
export const updateUserEmployment = async (employmentData) => {
    try {
      let endpoint = '/employments/update';
      const response = await instance.post(endpoint, { 
        employment_id:  employmentData.id,
        employer_id: employmentData.employer_id,
        position_id: employmentData.title,
        description: employmentData.description,
        start_date: employmentData.start_date,
        end_date: employmentData.end_date,
        is_current: employmentData.is_current
      });
      if (response.status === 200) {
        toast.success(i18n.t('employments.updateSuccess'));
        return response.data.data;
      } else {
        toast.error(i18n.t('employments.updateUnexpectedFailure'));
        throw new Error('Unexpected response status');
      }
    } catch (error) {
      toast.error(i18n.t('employments.updateFailure'));
      throw error;
    }
  };

  export const storeUserEmployment = async (employmentData) => {
    try {
      const response = await instance.post('/employments/store', { 
        employer_id: employmentData.employer_id,
        position_id: employmentData.title,
        description: employmentData.description,
        start_date: employmentData.start_date,
        end_date: employmentData.end_date,
        is_current: employmentData.is_current
      });
      if (response.status === 200) {
        toast.success(i18n.t('employments.addSuccess'));
        return response.data.data;
      } else {
        toast.error(i18n.t('employments.addUnexpectedFailure'));
        throw new Error('Unexpected response status');
      }
    } catch (error) {
      toast.error(i18n.t('employments.addFailure'));
      throw error;
    }
  };
  
  export const deleteUserEmployment = async (employmentId) => {
    try {
      const response = await instance.delete(`/employments/destroy/${employmentId}`);
      toast.success(i18n.t('employments.deleteSuccess'));
      return response.data.data;
    } catch (error) {
      console.error('Error deleting user employment:', error);
      throw error;
    }
  };
  
  export const fetchUserEmployments = () => {
    return instance.get('/employments');
  };

  export const createEmployer = async (name) => {
    try {
      const response = await instance.post('/employments/employer/store', { name });
      
      if (response.data && response.data.data) {
        toast.success(i18n.t('employments.createEmployerSuccess'));
        return response.data.data;
      } else {
        console.error("Unexpected response format:", response.data);
        toast.error(i18n.t('employments.createEmployerUnexpectedFormat'));
        throw new Error('Unexpected response format');
      }
    } catch (error) {
      console.error("API: Error creating employer:", error);
      toast.error(i18n.t('employments.createEmployerFailure', { error: error.response?.data?.message || error.message }));
      throw error;
    }
  };

  export const createTitle = async (name) => {
    try {
      const response = await instance.post('/employments/position/store', { title: name });
      if (response.data && response.data.data) {
        toast.success(i18n.t('employments.createTitleSuccess'));
        return response.data.data;
      } else {
        toast.error(i18n.t('employments.createTitleUnexpectedFailure'));
        throw new Error('Unexpected response status');
      }
    } catch (error) {
      console.error("API: Error creating title:", error);
      toast.error(i18n.t('employments.createTitleFailure'));
      throw error;
    }
  };
