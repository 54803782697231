import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { 
  saveUserGeneral, 
  updateUserPassword, 
  saveUserProfile, 
  fetchEmployers, 
  fetchPositions, 
  storeUserEmployment, 
  updateUserEmployment, 
  deleteUserEmployment, 
  fetchSocialPlatforms, 
  updateSocialLink, 
  deleteSocialLink, 
  addSocialLink, 
  resendVerificationEmail, 
  fetchGoals, 
  createGoal, 
  updateGoal, 
  deleteGoal, 
  fetchMedalCategories, 
  deleteCertification,
  fetchUserProjects, 
  createProject, 
  updateProject, 
  deleteProject,
  createSkill,
  createTechnology,
  fetchSkills,
  fetchTechnologies
} from '../../api/axios';
import TimezoneSelect, { allTimezones } from 'react-timezone-select';
import { useTranslation } from 'react-i18next';
import LanguageSelector from '../LanguageSelector';
import { FiUser, FiLock, FiEye, FiEyeOff,  FiFileText, FiBriefcase } from 'react-icons/fi';
import { FaCheckCircle, FaTrash, FaGlobe, FaLaptop, FaBullseye, FaFlag, FaCalendar, FaCheck, FaTimes, FaTimesCircle, FaGripLines, FaChevronUp, FaChevronDown, FaHourglassStart, FaTasks, FaMedal, FaGraduationCap } from 'react-icons/fa';
// import { FaSliders } from 'react-icons/fa6';
// import BouncingBallsHeader from '../Common/BouncingBallsHeader';
import QuillWrapper from '../Common/QuillWrapper';
import { EmploymentItem } from '../Employment/Employment';
import { FaPlus, FaEdit } from 'react-icons/fa';
import { socialIcons } from '../Common/SocialIcons';
import { Link } from 'react-router-dom';
// import { FaCode } from 'react-icons/fa';
import PageHero from '../Common/PageHero';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Button from '../Common/Button';
import CertificationsSection from '../Certifications/Certifications';
import ProjectsSection from './ProjectsSection';
import ConfirmationModal from '../Common/ConfirmationModal';
import Page from '../Page';

// Add this near the top of the file, before the Settings component
const SETTINGS_STEPS = [
  { id: 'general', name: 'General', icon: <FiUser /> },
  { id: 'profile', name: 'Profile', icon: <FiFileText /> },
  { id: 'employments', name: 'Employments', icon: <FiBriefcase /> },
  { id: 'social', name: 'Social', icon: <FaGlobe /> },
  { id: 'goals', name: 'Goals', icon: <FaBullseye /> },
  { id: 'projects', name: 'Projects', icon: <FaLaptop /> },
  { id: 'certifications', name: 'Certifications', icon: <FaGraduationCap /> },
  { id: 'security', name: 'Security', icon: <FiLock /> },
];

const Settings = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { user, setUser } = useAuth();
  const { t } = useTranslation();
  const [certifications, setCertifications] = useState([]); // Add this state
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    type: null,
    itemId: null,
  });

  // Update activeSection initialization to check URL params
  const [activeSection, setActiveSection] = useState(() => {
    const tabParam = searchParams.get('tab');
    return SETTINGS_STEPS.some(step => step.id === tabParam) ? tabParam : 'general';
  });

  // Add this function to handle tab changes
  const handleTabChange = (tabId) => {
    setActiveSection(tabId);
    setSearchParams({ tab: tabId });
  };

  const [formData, setFormData] = useState({
    name: user?.name || '',
    email: user?.email || '',
    language: user?.language || 'en',
    timezone: user?.timezone || 'UTC',
    password: '',
    confirm_password: '',
    headline: user?.headline || '',
    description: user?.description || '',
    username: user?.username || '', // Add this line
  });
  const [employers, setEmployers] = useState([]);
  const [titles, setTitles] = useState([]);
  const [isAddingEmployment, setIsAddingEmployment] = useState(false);
  const [editData, setEditData] = useState({
    newEmployment: null,
    experience: {},
    socialLinks: [] // Add this line to initialize socialLinks array
  });
  const [editMode, setEditMode] = useState({
    experience: null,
  });
  const [socialPlatforms, setSocialPlatforms] = useState([]);
  const [newSocialLink, setNewSocialLink] = useState({ platform_id: '', platform: '', url: '' });
  const [showNewLinkRow, setShowNewLinkRow] = useState(false);
  const [editedLinks, setEditedLinks] = useState({});
  const [goals, setGoals] = useState([]); // Add this line
  const [isAddingGoal, setIsAddingGoal] = useState(false); // Add this line
  const [goalEditData, setGoalEditData] = useState({}); // Add this line
  const [goalEditMode, setGoalEditMode] = useState(null); // Add this line
  const [projects, setProjects] = useState([]);
  const [isAddingProject, setIsAddingProject] = useState(false);
  const [projectEditData, setProjectEditData] = useState({});
  const [projectEditMode, setProjectEditMode] = useState(null);
  const [availableSkills, setAvailableSkills] = useState([]);
  const [availableTechnologies, setAvailableTechnologies] = useState([]);

  useEffect(() => {
    if (user) {
      setFormData({
        name: user.name || '',
        email: user.email || '',
        language: user.language || 'en',
        timezone: user.timezone || 'UTC',
        password: '',
        confirm_password: '',
        headline: user.headline || '',
        description: user.description || '',
        username: user.username || '', // Add this line
      });
    }
  }, [user]);

  useEffect(() => {
    const loadEmploymentData = async () => {
      try {
        const [employersData, titlesData] = await Promise.all([
          fetchEmployers(),
          fetchPositions(),
        ]);
        setEmployers(employersData);
        setTitles(titlesData);
      } catch (error) {
        console.error('Error loading employment data:', error);
      }
    };

    loadEmploymentData();
  }, []);

  useEffect(() => {
    const loadSocialPlatforms = async () => {
      try {
        const platforms = await fetchSocialPlatforms();
        setSocialPlatforms(platforms);
      } catch (error) {
        console.error('Error loading social platforms:', error);
      }
    };

    loadSocialPlatforms();
  }, []);

  useEffect(() => {
    const loadGoals = async () => {
      try {
        const goalsData = await fetchGoals();
        setGoals(goalsData);
      } catch (error) {
        console.error('Error loading goals:', error);
      }
    };

    loadGoals();
  }, []);

  useEffect(() => {
    const loadProjects = async () => {
      try {
        const projectsData = await fetchUserProjects();
        setProjects(projectsData);
      } catch (error) {
        console.error('Error loading projects:', error);
      }
    };

    loadProjects();
  }, []);
  // When fetching available skills and technologies
useEffect(() => {
  const fetchAvailableOptions = async () => {
    try {
      const [skillsResponse, techResponse] = await Promise.all([
        fetchSkills(),
        fetchTechnologies()
      ]);

      // Transform skills into the format CreatableSelect expects
      const formattedSkills = skillsResponse.data.map(skill => ({
        value: skill.id,
        label: skill.name
      }));

      // Transform technologies into the format CreatableSelect expects
      const formattedTechnologies = techResponse.data.map(tech => ({
        value: tech.id,
        label: tech.name
      }));

      setAvailableSkills(formattedSkills);
      setAvailableTechnologies(formattedTechnologies);
    } catch (error) {
      console.error('Error fetching available options:', error);
    }
  };

  fetchAvailableOptions();
}, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Strip spaces from username field
    const finalValue = name === 'username' ? value.replace(/\s+/g, '') : value;
    setFormData({ ...formData, [name]: finalValue });
    if (name === 'language') {
      t.changeLanguage(value);
    }
  };

  const handleTimezoneChange = (selectedTimezone) => {
    setFormData({ ...formData, timezone: selectedTimezone.value });
  };

  const handleGeneralSubmit = async (e) => {
    e.preventDefault();
    try {
      const updatedUser = await saveUserGeneral(
        formData.name,
        formData.email,
        formData.timezone,
        formData.language
      );
      setUser(updatedUser);
    } catch (error) {
      console.error('Failed to update profile:', error);
    }
  };

  const handleSecuritySubmit = async (e) => {
    e.preventDefault();
    if (formData.password !== formData.confirm_password) {
      // toast.error(t('settings.passwordsDoNotMatch'));
      return;
    }
    try {
      await updateUserPassword(formData.password, formData.confirm_password);
      // toast.success(t('settings.passwordUpdated'));
      setFormData({ 
        ...formData, 
        password: '', 
        confirm_password: ''
      });
    } catch (error) {
      console.error('Failed to update password:', error);
    }
  };

  const handleProfileSubmit = async (e) => {
    e.preventDefault();
    try {
      const updatedUser = await saveUserProfile(formData.headline, formData.description, formData.username);
      setUser(updatedUser);
    } catch (error) {
      console.error('Failed to update profile:', error);
    }
  };

  const handleDescriptionChange = (content) => {
    setFormData(prevData => ({
      ...prevData,
      description: content
    }));
  };

  const handleAddEmployment = () => {
    setIsAddingEmployment(true);
    setEditData({
      ...editData,
      newEmployment: {
        employer_id: '',
        title: '',
        start_date: '',
        end_date: '',
        is_current: false,
        description: '',
      }
    });
  };

  const handleSaveNewEmployment = async () => {
    try {
      const updatedUser = await storeUserEmployment(editData.newEmployment);
      setUser(updatedUser);
      setIsAddingEmployment(false);
      setEditData({ ...editData, newEmployment: null });
    } catch (error) {
      console.error('Error saving new employment:', error);
    }
  };

  const handleCancelNewEmployment = () => {
    setIsAddingEmployment(false);
    setEditData({ ...editData, newEmployment: null });
  };

  const handleEditExperience = (exp) => {
    setEditMode({ ...editMode, experience: exp.id });
    setEditData({ ...editData, experience: { ...exp } });
  };

  const handleSaveExperience = async () => {
    try {
      const updatedUser = await updateUserEmployment(editData.experience);
      setUser(updatedUser);
      setEditMode({ ...editMode, experience: null });
      setEditData({ ...editData, experience: {} });
    } catch (error) {
      console.error('Error saving experience:', error);
    }
  };

  const handleCancelExperience = () => {
    setEditMode({ ...editMode, experience: null });
    setEditData({ ...editData, experience: {} });
  };

  const handleDeleteEmployment = async (employmentId) => {
    try {
      const updatedUser = await deleteUserEmployment(employmentId);
      setUser(updatedUser);
    } catch (error) {
      console.error('Error deleting employment:', error);
    }
  };

  const handleSocialLinkChange = (linkId, newUrl) => {
    setEditedLinks(prev => ({ ...prev, [linkId]: true }));
  };

  const handleUpdateSocialLink = async (linkId, updatedUrl) => {
    try {
      await updateSocialLink(linkId, updatedUrl);
      setUser(prevUser => ({
        ...prevUser,
        social_links: prevUser.social_links.map(link =>
          link.id === linkId ? { ...link, url: updatedUrl } : link
        )
      }));
      setEditedLinks(prev => ({ ...prev, [linkId]: false }));
    } catch (error) {
      console.error('Error updating social link:', error);
    }
  };

  const handleDeleteSocialLink = async (linkId) => {
    setDeleteModal({
      isOpen: true,
      type: 'social',
      itemId: linkId
    });
  };

  const handleAddSocialLink = async () => {
    try {
      if (!newSocialLink.platform_id || !newSocialLink.url) {
        console.error('Platform ID or URL is missing');
        return;
      }
   
      const newLink = await addSocialLink({
        social_platform_id: newSocialLink.platform_id,
        url: newSocialLink.url
      });

      if (!newLink || !newLink.url) {
        throw new Error('Invalid response from addSocialLink');
      }

      setUser(prevUser => ({
        ...prevUser,
        social_links: [...(prevUser.social_links || []), newLink]
      }));

      setNewSocialLink({ platform_id: '', platform: '', url: '' });
      setShowNewLinkRow(false);
    } catch (error) {
      console.error('Error adding social link:', error);
    }
  };

  const handleResendVerification = async () => {
    await resendVerificationEmail(user.email);
  };

  const handleAddGoal = () => {
    setIsAddingGoal(true);
    setGoalEditData({
      description: '',
      objective: '',
      action_plan: '',
      relevance: '',
      target_date: '',
      progress: 'Not Started',
      success_criteria: '',
      priority: 'Low',
    });
  };

  const handleSaveNewGoal = async () => {
    try {
      const response = await createGoal(goalEditData);
      // Assuming the API returns the new goal in response.data
      const newGoal = response;
      
      // Make sure all required fields are present in the new goal
      const formattedGoal = {
        id: newGoal.id,
        description: newGoal.description || '',
        objective: newGoal.objective || '',
        action_plan: newGoal.action_plan || '',
        relevance: newGoal.relevance || '',
        target_date: newGoal.target_date || '',
        progress: newGoal.progress || 'Not Started',
        success_criteria: newGoal.success_criteria || '',
        priority: newGoal.priority || 'Low'
      };

      setGoals(currentGoals => [...currentGoals, formattedGoal]);
      setIsAddingGoal(false);
      setGoalEditData({});
    } catch (error) {
      console.error('Error saving new goal:', error);
    }
  };

  const handleCancelNewGoal = () => {
    setIsAddingGoal(false);
    setGoalEditData({});
  };

  const handleEditGoal = (goal) => {
    setGoalEditMode(goal.id);
    setGoalEditData(goal);
  };

  const handleSaveGoal = async () => {
    try {
      const updatedGoal = await updateGoal(goalEditData);
      setGoals(goals.map(g => g.id === updatedGoal.id ? updatedGoal : g));
      setGoalEditMode(null);
      setGoalEditData({});
    } catch (error) {
      console.error('Error saving goal:', error);
    }
  };

  const handleCancelGoal = () => {
    setGoalEditMode(null);
    setGoalEditData({});
  };

  const handleDeleteGoal = async (goalId) => {
    setDeleteModal({
      isOpen: true,
      type: 'goal',
      itemId: goalId
    });
  };

  const handleDeleteCertification = async (certificationId) => {
    setDeleteModal({
      isOpen: true,
      type: 'certification',
      itemId: certificationId
    });
  };

  // Update handleConfirmDelete
  const handleConfirmDelete = async () => {
    try {
      switch (deleteModal.type) {
        case 'social':
          await deleteSocialLink(deleteModal.itemId);
          setUser(prevUser => ({
            ...prevUser,
            social_links: prevUser.social_links.filter(link => link.id !== deleteModal.itemId)
          }));
          break;
        case 'goal':
          await deleteGoal(deleteModal.itemId);
          setGoals(currentGoals => currentGoals.filter(goal => goal.id !== deleteModal.itemId));
          break;
        case 'certification':
          await deleteCertification(deleteModal.itemId);
          setCertifications(prevCerts => 
            prevCerts.filter(cert => cert.id !== deleteModal.itemId)
          );
          break;
        case 'project':
          await deleteProject(deleteModal.itemId);
          setProjects(currentProjects => 
            currentProjects.filter(project => project.id !== deleteModal.itemId)
          );
          break;
      }
    } catch (error) {
      console.error(`Error deleting ${deleteModal.type}:`, error);
    } finally {
      setDeleteModal({ isOpen: false, type: null, itemId: null });
    }
  };

  const renderContent = () => {
    switch (activeSection) {
      case 'general':
        return <GeneralSection 
          formData={formData} 
          handleInputChange={handleInputChange} 
          handleTimezoneChange={handleTimezoneChange}
          handleSubmit={handleGeneralSubmit}
          user={user}
          handleResendVerification={handleResendVerification}  // Add this line
        />;
      case 'profile':
        return <ProfileSection 
          formData={formData} 
          handleInputChange={handleInputChange} 
          handleSubmit={handleProfileSubmit} 
          handleDescriptionChange={handleDescriptionChange}
          handleHeadlineChange={(content) => handleInputChange({ target: { name: 'headline', value: content } })}
        />;
      case 'security':
        return <SecuritySection formData={formData} handleInputChange={handleInputChange} handleSubmit={handleSecuritySubmit} />;
      case 'employments':
        return <EmploymentsSection 
          employments={user?.employments || []}  // Add null check with default empty array
          isAddingEmployment={isAddingEmployment}
          editData={editData}
          editMode={editMode}
          onAddEmployment={handleAddEmployment}
          onSaveNewEmployment={handleSaveNewEmployment}
          onCancelNewEmployment={handleCancelNewEmployment}
          onEditExperience={handleEditExperience}
          onSaveExperience={handleSaveExperience}
          onCancelExperience={handleCancelExperience}
          onDeleteEmployment={handleDeleteEmployment}
          setEditData={setEditData}
          employers={employers}
          titles={titles}
          setEmployers={setEmployers}
          setTitles={setTitles}
        />;
      case 'social':
        return <SocialSection 
          socialLinks={user?.social_links || []}  // Add null check with default empty array
          socialPlatforms={socialPlatforms}
          editedLinks={editedLinks}
          newSocialLink={newSocialLink}
          showNewLinkRow={showNewLinkRow}
          onSocialLinkChange={handleSocialLinkChange}
          onUpdateSocialLink={handleUpdateSocialLink}
          onDeleteSocialLink={handleDeleteSocialLink}
          onAddSocialLink={handleAddSocialLink}
          setNewSocialLink={setNewSocialLink}
          setShowNewLinkRow={setShowNewLinkRow}
        />;
      case 'goals':
        return <GoalsSection 
          goals={goals}
          isAddingGoal={isAddingGoal}
          goalEditData={goalEditData}
          goalEditMode={goalEditMode}
          onAddGoal={handleAddGoal}
          onSaveNewGoal={handleSaveNewGoal}
          onCancelNewGoal={handleCancelNewGoal}
          onEditGoal={handleEditGoal}
          onSaveGoal={handleSaveGoal}
          onCancelGoal={handleCancelGoal}
          onDeleteGoal={handleDeleteGoal}  // Pass the new function
          setGoalEditData={setGoalEditData}
          user={user}
        />;
      case 'projects':
        return <ProjectsSection 
          projects={projects}
          isAddingProject={isAddingProject}
          projectEditData={projectEditData}
          projectEditMode={projectEditMode}
          onAddProject={handleAddProject}
          onSaveNewProject={handleSaveNewProject}
          onCancelNewProject={handleCancelNewProject}
          onEditProject={handleEditProject}
          onSaveProject={handleSaveProject}
          onCancelProject={handleCancelProject}
          onDeleteProject={handleDeleteProject}
          setProjectEditData={setProjectEditData}
          availableSkills={availableSkills}
          availableTechnologies={availableTechnologies}
          handleSkillChange={handleSkillChange}
          handleTechnologyChange={handleTechnologyChange}
        />;
      case 'certifications':
        return <CertificationsSection 
          user={user}
          certifications={certifications}
          setCertifications={setCertifications}
          onDeleteCertification={handleDeleteCertification}
        />;
      default:
        return <GeneralSection 
          formData={formData} 
          handleInputChange={handleInputChange} 
          handleTimezoneChange={handleTimezoneChange}
          handleSubmit={handleGeneralSubmit} 
        />;
    }
  };

  const getDeleteModalConfig = () => {
    switch (deleteModal.type) {
      case 'social':
        return {
          title: t('socialLinks.deleteConfirmTitle'),
          message: t('socialLinks.deleteConfirmMessage')
        };
      case 'goal':
        return {
          title: t('goals.deleteConfirmTitle'),
          message: t('goals.deleteConfirmMessage')
        };
      case 'certification':
        return {
          title: t('certifications.deleteConfirmTitle'),
          message: t('certifications.deleteConfirmMessage')
        };
      case 'project':
        return {
          title: t('projects.deleteConfirmTitle'),
          message: t('projects.deleteConfirmMessage')
        };
      default:
        return { title: '', message: '' };
    }
  };

  const handleAddProject = () => {
    setIsAddingProject(true);
    setProjectEditData({
      name: '',
      role: '',
      responsibilities: '',
      challenges: '',
      skills: [],
      technologies: []
    });
  };

  const handleSaveNewProject = async () => {
    try {
      // Transform the project data to match the update payload format
      const apiFormattedProject = {
        ...projectEditData,
        skill_ids: projectEditData.skills.map(s => s.value),
        technology_ids: projectEditData.technologies.map(t => t.value)
      };
      
      const response = await createProject(apiFormattedProject);
      setProjects([...projects, response]);
      setIsAddingProject(false);
      setProjectEditData({});
    } catch (error) {
      console.error('Error saving new project:', error);
    }
  };

  const handleCancelNewProject = () => {
    setIsAddingProject(false);
    setProjectEditData({});
  };

  const handleEditProject = (project) => {
    const transformedProject = {
      ...project,
      skills: project.skills.map(s => ({
        value: s.skill.id,
        label: s.skill.name
      })),
      technologies: project.technologies.map(t => ({
        value: t.technology.id,
        label: t.technology.name
      }))
    };
    
    setProjectEditMode(project.id);
    setProjectEditData(transformedProject);
  };

  const handleSaveProject = async () => {
    try {
      const apiFormattedProject = {
        ...projectEditData,
        skill_ids: projectEditData.skills.map(s => s.value),
        technology_ids: projectEditData.technologies.map(t => t.value)
      };
      
      const updatedProject = await updateProject(apiFormattedProject);
      setProjects(projects.map(p => p.id === updatedProject.id ? updatedProject : p));
      setProjectEditMode(null);
      setProjectEditData({});
    } catch (error) {
      console.error('Error saving project:', error);
    }
  };

  const handleCancelProject = () => {
    setProjectEditMode(null);
    setProjectEditData({});
  };

  const handleDeleteProject = async (projectId) => {
    setDeleteModal({
      isOpen: true,
      type: 'project',
      itemId: projectId
    });
  };

  const handleSkillChange = async (newValue, actionMeta) => {
    if (actionMeta.action === 'create-option') {
      try {
        const newSkillName = actionMeta.option.label;
        const createdSkill = await createSkill(newSkillName);
        const newSkillOption = { 
          value: createdSkill.id, 
          label: createdSkill.name 
        };
        setProjectEditData({ 
          ...projectEditData, 
          skills: [...(projectEditData.skills || []), newSkillOption] 
        });
        setAvailableSkills([...availableSkills, newSkillOption]);
      } catch (error) {
        console.error('Error creating skill:', error);
      }
    } else {
      setProjectEditData({ 
        ...projectEditData, 
        skills: newValue || [] 
      });
    }
  };

  const handleTechnologyChange = async (newValue, actionMeta) => {
    if (actionMeta.action === 'create-option') {
      try {
        const newTechnologyName = actionMeta.option.label;
        const createdTechnology = await createTechnology(newTechnologyName);
        const newTechOption = { 
          value: createdTechnology.id, 
          label: createdTechnology.name 
        };
        setProjectEditData({ 
          ...projectEditData, 
          technologies: [...(projectEditData.technologies || []), newTechOption] 
        });
        setAvailableTechnologies([...availableTechnologies, newTechOption]);
      } catch (error) {
        console.error('Error creating technology:', error);
      }
    } else {
      setProjectEditData({ 
        ...projectEditData, 
        technologies: newValue || [] 
      });
    }
  };

  const getSettingsTitle = () => {
    const currentStep = SETTINGS_STEPS.find(step => step.id === activeSection);
    return currentStep ? currentStep.name : 'Settings';
  };

  return (
    <>
      <Page 
        title={`${getSettingsTitle()} Settings`}
        description="Manage your Digital Medals account settings, profile, and preferences"
      />
      <div className="min-h-screen">
        <div className="container mx-auto px-4 py-8">
          <div className="bg-white shadow-xl rounded-lg">
            <PageHero
              title={t('settings.title')}
              buttons={[
                {
                  text: "Skills",
                  icon: <FaEdit />,
                  onClick: () => navigate('/skills')
                },
                {
                  text: "Technologies",
                  icon: <FaEdit />,
                  onClick: () => navigate('/technologies')
                },
              ]}
            />
            <div className="bg-white rounded-lg shadow-xl">
              {/* Mobile Navigation */}
              <aside className="md:hidden w-full bg-blue-50 shadow rounded-lg p-4">
                <ul className="space-y-4">
                  {SETTINGS_STEPS.map((s) => (
                    <li
                      key={s.id}
                      onClick={() => handleTabChange(s.id)}
                      className={`flex items-center p-2 rounded-lg cursor-pointer ${
                        activeSection === s.id ? 'bg-blue-500 text-white' : 'text-gray-700'
                      }`}
                    >
                      <span className="mr-2">{s.icon}</span>
                      {s.name}
                    </li>
                  ))}
                </ul>
              </aside>

              {/* Desktop Navigation */}
              <header className="hidden md:flex w-full bg-blue-50 shadow rounded-lg p-4 justify-center">
                <ul className="flex space-x-4">
                  {SETTINGS_STEPS.map((s) => (
                    <li
                      key={s.id}
                      onClick={() => handleTabChange(s.id)}
                      className={`flex items-center p-2 rounded-lg cursor-pointer transition-colors duration-150 ease-in-out ${
                        activeSection === s.id 
                          ? 'bg-blue-600 text-white' 
                          : 'text-blue-800 hover:bg-blue-100'
                      }`}
                    >
                      <span className="mr-2">{s.icon}</span>
                      {s.name}
                    </li>
                  ))}
                </ul>
              </header>

              <div className="px-4 py-6 md:px-6 md:py-8">
                {renderContent()}
              </div>
            </div>
          </div>
        </div>
        <ConfirmationModal
          isOpen={deleteModal.isOpen}
          onClose={() => setDeleteModal({ isOpen: false, type: null, itemId: null })}
          onConfirm={handleConfirmDelete}
          {...getDeleteModalConfig()}
        />
      </div>
    </>
  );
};

const GeneralSection = ({ formData, handleInputChange, handleTimezoneChange, handleSubmit, user, handleResendVerification }) => {
  const { t } = useTranslation();
  const [verificationSent, setVerificationSent] = useState(false);

  const handleVerificationClick = async (e) => {
    e.preventDefault();
    try {
      await handleResendVerification();
      setVerificationSent(true);
    } catch (error) {
      console.error('Failed to resend verification email:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-8">
      <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-2">
        <div className="sm:col-span-2">
          <label htmlFor="name" className="block text-sm font-medium text-gray-700">
            {t('settings.fullName')}
          </label>
          <input
            type="text"
            name="name"
            id="name"
            value={formData.name}
            onChange={handleInputChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          />
        </div>
        <div className="sm:col-span-2">
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">
            {t('settings.emailAddress')} 
            {user?.email_verified_at ? (
              <span className="text-gray-500 text-xs ml-2">
                ({t('settings.emailVerified')})
              </span>
            ) : (
              <button
                onClick={handleVerificationClick}
                disabled={verificationSent}
                className="text-blue-500 text-xs hover:underline ml-2 disabled:text-gray-400"
              >
                ({verificationSent ? t('settings.verificationEmailSent') : t('settings.resendVerification')})
              </button>
            )}
          </label>
          <input
            type="email"
            name="email"
            id="email"
            value={formData.email}
            onChange={handleInputChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          />
        </div>
        <div className="sm:col-span-2">
          <label htmlFor="timezone" className="block text-sm font-medium text-gray-700">
            {t('settings.timezone')}
          </label>
          <TimezoneSelect
            value={formData.timezone}
            onChange={handleTimezoneChange}
            timezones={allTimezones}
            className="mt-1 block w-full"
            styles={{
              control: (provided) => ({
                ...provided,
                minHeight: '38px',
                borderColor: '#D1D5DB',
                '&:hover': {
                  borderColor: '#9CA3AF',
                },
              }),
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
              }),
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected ? '#4F46E5' : state.isFocused ? '#E0E7FF' : 'white',
                color: state.isSelected ? 'white' : '#111827',
                '&:active': {
                  backgroundColor: '#4F46E5',
                  color: 'white',
                },
              }),
            }}
          />
        </div>
        <div className="sm:col-span-2">
          
          <LanguageSelector
            value={formData.language}
            onChange={handleInputChange}
            className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          />
        </div>
      </div>
      <div>
        <Button
          type="submit"
          color="blue"
          className="w-full"
        >
          {t('settings.saveChanges')}
        </Button>
      </div>
    </form>
  );
};

const SecuritySection = ({ formData, handleInputChange, handleSubmit }) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [isValid, setIsValid] = useState(false);

  const validatePassword = (password) => {
    const minLength = password.length >= 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const hasNumber = /\d/.test(password);
    return minLength && hasUpperCase && hasSymbol && hasNumber;
  };

  useEffect(() => {
    const newErrors = {};
    if (formData.password) {
      if (!validatePassword(formData.password)) {
        newErrors.password = t('settings.passwordRequirements');
      }
    }
    if (formData.confirm_password && formData.password !== formData.confirm_password) {
      newErrors.confirm_password = t('settings.passwordsDoNotMatch');
    }
    setErrors(newErrors);
    setIsValid(Object.keys(newErrors).length === 0 && formData.password && formData.confirm_password);
  }, [formData.password, formData.confirm_password, t]);

  const togglePasswordVisibility = (field) => {
    if (field === 'password') {
      setShowPassword(!showPassword);
    } else {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-8">
      <div className="space-y-6">
        <div>
          <label htmlFor="password" className="block text-sm font-medium text-gray-700">
            {t('settings.newPassword')}
          </label>
          <div className="relative mt-1">
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              id="password"
              value={formData.password}
              onChange={handleInputChange}
              className={`block w-full border ${errors.password ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm py-2 px-3 pr-10 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm`}
            />
            <button
              type="button"
              className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 hover:text-gray-500"
              onClick={() => togglePasswordVisibility('password')}
            >
              {showPassword ? <FiEyeOff className="h-5 w-5" /> : <FiEye className="h-5 w-5" />}
            </button>
          </div>
          {errors.password && <p className="mt-2 text-sm text-red-600">{errors.password}</p>}
        </div>
        <div>
          <label htmlFor="confirm_password" className="block text-sm font-medium text-gray-700">
            {t('settings.confirmNewPassword')}
          </label>
          <div className="relative mt-1">
            <input
              type={showConfirmPassword ? "text" : "password"}
              name="confirm_password"
              id="confirm_password"
              value={formData.confirm_password}
              onChange={handleInputChange}
              className={`block w-full border ${errors.confirm_password ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm py-2 px-3 pr-10 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm`}
            />
            <button
              type="button"
              className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 hover:text-gray-500"
              onClick={() => togglePasswordVisibility('confirm_password')}
            >
              {showConfirmPassword ? <FiEyeOff className="h-5 w-5" /> : <FiEye className="h-5 w-5" />}
            </button>
          </div>
          {errors.confirm_password && <p className="mt-2 text-sm text-red-600">{errors.confirm_password}</p>}
        </div>
      </div>
      <div>
        <Button
          type="submit"
          color="blue"
          className="w-full"
          disabled={!isValid}
        >
          {t('settings.updatePassword')}
        </Button>
      </div>
    </form>
  );
};

const ProfileSection = ({ formData, handleInputChange, handleSubmit, handleDescriptionChange, handleHeadlineChange }) => {
  const { t } = useTranslation();

  return (
    <form onSubmit={handleSubmit} className="space-y-8">
      <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-2">
        <div className="sm:col-span-2">
          <label htmlFor="username" className="block text-sm font-medium text-gray-700">
            {t('settings.username')}
          </label>
          <input
            type="text"
            name="username"
            id="username"
            value={formData.username}
            onChange={handleInputChange}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            placeholder={t('settings.usernamePlaceholder')}
          />
        </div>
        <div className="sm:col-span-2">
          <label htmlFor="headline" className="block text-sm font-medium text-gray-700">
            {t('settings.headline')}
          </label>
          <QuillWrapper
            value={formData.headline}
            onChange={handleHeadlineChange}
            placeholder={t('settings.headlinePlaceholder')}
            className="mt-1"
          />
        </div>
        <div className="sm:col-span-2">
          <label htmlFor="description" className="block text-sm font-medium text-gray-700">
            {t('settings.description')}
          </label>
          <QuillWrapper
            value={formData.description}
            onChange={handleDescriptionChange}
            placeholder={t('settings.descriptionPlaceholder')}
            className="mt-1"
          />
        </div>
      </div>
      <div>
        <Button
          type="submit"
          color="blue"
          className="w-full"
        >
          {t('settings.saveChanges')}
        </Button>
      </div>
    </form>
  );
};

const EmploymentsSection = ({
  employments,
  isAddingEmployment,
  editData,
  editMode,
  onAddEmployment,
  onSaveNewEmployment,
  onCancelNewEmployment,
  onEditExperience,
  onSaveExperience,
  onCancelExperience,
  onDeleteEmployment,
  setEditData,
  employers,
  titles,
  setEmployers,
  setTitles
}) => {
  const { t } = useTranslation();
  const sortedEmployments = [...employments].sort((a, b) => new Date(b.start_date) - new Date(a.start_date));

  return (
    <div>
      <div className="flex flex-col sm:flex-row justify-between items-center mb-4 space-y-2 sm:space-y-0">
        {/* <h2 className="text-2xl font-bold text-gray-800">
          {t('settings.employments')}
        </h2> */}
        <Button
          onClick={onAddEmployment}
          color="blue"
          icon={FaPlus}
        >
          {t('employments.addNew')}
        </Button>
      </div>
      <div className="border-b border-blue-300 mb-4"></div>
      <div className="space-y-6">
        {isAddingEmployment && (
          <EmploymentItem
            isEditing={true}
            isNew={true}
            editData={editData.newEmployment}
            onSave={onSaveNewEmployment}
            onCancel={onCancelNewEmployment}
            setEditData={(data) => setEditData({ ...editData, newEmployment: data })}
            employers={employers}
            titles={titles}
            setEmployers={setEmployers}
            setTitles={setTitles}
          />
        )}
        {sortedEmployments.map((exp) => (
          <EmploymentItem
            key={exp.id}
            employment={exp}
            isEditing={editMode.experience === exp.id}
            editData={editData.experience}
            onEdit={() => onEditExperience(exp)}
            onSave={onSaveExperience}
            onCancel={onCancelExperience}
            onDelete={() => onDeleteEmployment(exp.id)}
            setEditData={(data) => setEditData({ ...editData, experience: data })}
            employers={employers}
            titles={titles}
            setEmployers={setEmployers}
            setTitles={setTitles}
          />
        ))}
        {sortedEmployments.length === 0 && !isAddingEmployment && (
          <p className="text-gray-500 italic">{t('employments.noEmployments')}</p>
        )}
      </div>
    </div>
  );
};

const SocialSection = ({ 
  socialLinks, 
  socialPlatforms, 
  editedLinks, 
  newSocialLink, 
  showNewLinkRow, 
  onSocialLinkChange, 
  onUpdateSocialLink, 
  onDeleteSocialLink, 
  onAddSocialLink, 
  setNewSocialLink, 
  setShowNewLinkRow 
}) => {
  const { t } = useTranslation();
  const [editedUrls, setEditedUrls] = useState(
    socialLinks.reduce((acc, link) => ({ ...acc, [link.id]: link.url }), {})
  );

  const handleUrlChange = (linkId, newUrl) => {
    setEditedUrls(prev => ({ ...prev, [linkId]: newUrl }));
    onSocialLinkChange(linkId, newUrl);
  };

  return (
    <div>
      <div className="flex flex-col sm:flex-row justify-between items-center mb-4 space-y-2 sm:space-y-0">
        <Button
          onClick={() => setShowNewLinkRow(true)}
          color="blue"
          icon={FaPlus}
        >
          {t('profile.addNewLink')}
        </Button>
      </div>
      <div className="border-b border-blue-300 mb-4"></div>
      <div className="space-y-6">
        {showNewLinkRow && (
          <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  {t('profile.platform')}
                </label>
                <select
                  value={newSocialLink.platform_id}
                  onChange={(e) => {
                    const selectedPlatform = socialPlatforms.find(p => p.id === parseInt(e.target.value));
                    setNewSocialLink({
                      ...newSocialLink,
                      platform_id: e.target.value,
                      platform: selectedPlatform ? selectedPlatform.name : ''
                    });
                  }}
                  className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="">{t('profile.selectPlatform')}</option>
                  {socialPlatforms.map((platform) => (
                    <option key={platform.id} value={platform.id}>{platform.name}</option>
                  ))}
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  {t('profile.url')}
                </label>
                <input
                  type="text"
                  value={newSocialLink.url}
                  onChange={(e) => setNewSocialLink({ ...newSocialLink, url: e.target.value })}
                  className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  placeholder={t('profile.urlPlaceholder')}
                />
              </div>
            </div>
            <div className="flex justify-end space-x-2 mt-4">
              <Button
                onClick={() => setShowNewLinkRow(false)}
                color="blue"
                icon={FaTimes}
              >
                {t('common.cancel')}
              </Button>
              <Button
                onClick={onAddSocialLink}
                color="green"
                icon={FaCheck}
                disabled={!newSocialLink.platform_id || !newSocialLink.url}
              >
                {t('common.save')}
              </Button>
            </div>
          </div>
        )}
        {socialLinks.map((link) => {
          const IconComponent = socialIcons[link.platform] || FaGlobe;
          return (
            <div key={link.id} className="bg-white rounded-lg shadow-sm border border-gray-200 p-6">
              <div className="flex flex-col sm:flex-row sm:items-center space-y-4 sm:space-y-0">
                <div className="flex items-center space-x-4 flex-grow">
                  <IconComponent className="text-2xl text-gray-600" />
                  <div className="flex-grow">
                    <input
                      type="text"
                      value={editedUrls[link.id] || link.url || ''}
                      onChange={(e) => handleUrlChange(link.id, e.target.value)}
                      className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                      placeholder="URL"
                    />
                  </div>
                </div>
                <div className="flex sm:flex-row flex-col space-y-2 sm:space-y-0 sm:space-x-2 w-full sm:w-auto">
                  <Button
                    onClick={() => onDeleteSocialLink(link.id)}
                    color="red"
                    icon={FaTrash}
                    className="w-full sm:w-auto"
                  >
                    {t('common.delete')}
                  </Button>
                  <Button
                    onClick={() => onUpdateSocialLink(link.id, editedUrls[link.id])}
                    color="green"
                    icon={FaCheck}
                    disabled={editedUrls[link.id] === link.url}
                    className="w-full sm:w-auto"
                  >
                    {t('common.save')}
                  </Button>
                </div>
              </div>
            </div>
          );
        })}
        {socialLinks.length === 0 && !showNewLinkRow && (
          <p className="text-gray-500 italic text-center py-4">{t('profile.noSocialLinks')}</p>
        )}
      </div>
    </div>
  );
};

// Add the GoalsSection component
const GoalsSection = ({
  goals,
  isAddingGoal,
  goalEditData,
  goalEditMode,
  onAddGoal,
  onSaveNewGoal,
  onCancelNewGoal,
  onEditGoal,
  onSaveGoal,
  onCancelGoal,
  onDeleteGoal,  // Make sure this prop is included
  setGoalEditData,
  user
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="flex flex-col sm:flex-row justify-between items-center mb-4 space-y-2 sm:space-y-0">
        <Button
          onClick={onAddGoal}
          color="blue"
          icon={FaPlus}
        >
          {t('goals.addNew')}
        </Button>
      </div>
      <div className="border-b border-blue-300 mb-4"></div>
      <div className="space-y-6">
        {isAddingGoal && (
          <GoalItem
            isEditing={true}
            isNew={true}
            editData={goalEditData}
            onSave={onSaveNewGoal}
            onCancel={onCancelNewGoal}
            setEditData={setGoalEditData}
          />
        )}
        {goals.map((goal) => (
          <GoalItem
            key={goal.id}
            goal={goal}
            isEditing={goalEditMode === goal.id}
            editData={goalEditData}
            onEdit={() => onEditGoal(goal)}
            onSave={onSaveGoal}
            onCancel={onCancelGoal}
            onDelete={onDeleteGoal}  // Pass it to GoalItem
            setEditData={setGoalEditData}
            userTimezone={user?.timezone || 'UTC'}
          />
        ))}
        {goals.length === 0 && !isAddingGoal && (
          <p className="text-gray-500 italic">{t('goals.noGoals')}</p>
        )}
      </div>
    </div>
  );
};

const formatDate = (dateString, timezone) => {
  if (!dateString) return '';
  
  const date = new Date(dateString + 'T00:00:00');
  const today = new Date();
  
  // Options for full date format
  const options = {
    timeZone: timezone,
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  };

  // Calculate days difference
  const timeDiff = date.getTime() - today.getTime();
  const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

  // Format the full date
  const fullDate = date.toLocaleDateString('en-US', options);

  // Add relative time in parentheses
  let relativeTime = '';
  if (daysDiff < 0) {
    relativeTime = `${Math.abs(daysDiff)} days overdue`;
  } else if (daysDiff === 0) {
    relativeTime = 'today';
  } else if (daysDiff === 1) {
    relativeTime = 'tomorrow';
  } else {
    relativeTime = `in ${daysDiff} days`;
  }

  return `${fullDate} (${relativeTime})`;
};

const getDateColor = (dateString) => {
  if (!dateString) return 'text-gray-900';
  
  const date = new Date(dateString + 'T00:00:00');
  const today = new Date();
  const timeDiff = date.getTime() - today.getTime();
  const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

  if (daysDiff < 0) {
    return 'text-red-600';
  } else if (daysDiff <= 7) {
    return 'text-yellow-600';
  } else {
    return 'text-green-600';
  }
};

// Implement the GoalItem component similar to EmploymentItem
const GoalItem = ({
  goal,
  isEditing,
  isNew,
  editData,
  onSave,
  onCancel,
  onDelete,
  onEdit,
  setEditData,
  userTimezone
}) => {
  const { t } = useTranslation();
  const [activePopover, setActivePopover] = useState(null);

  if (!goal && !isNew) return null;

  const renderMedalBadges = (medals) => {
    if (!medals || medals.length === 0) return null;

    const categoriesWithMedals = medals.reduce((acc, medal) => {
      const category = medal.category.category;
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(medal.title);
      return acc;
    }, {});
    
    return (
      <div className="flex flex-wrap gap-2">
        {Object.entries(categoriesWithMedals).map(([category, titles], index) => (
          <div key={index} className="relative">
            <span
              className="inline-flex items-center px-2 py-2 rounded text-xs font-medium bg-blue-100 text-blue-800 cursor-help"
              onMouseEnter={() => setActivePopover(category)}
              onMouseLeave={() => setActivePopover(null)}
            >
              <FaMedal className="mr-1" />
              {category}
            </span>
            {activePopover === category && (
              <div className="absolute z-10 w-64 px-4 py-2 text-sm bg-white border border-gray-200 rounded-lg shadow-lg top-full mt-1 right-0">
                <h4 className="font-semibold mb-2">{category} Medals:</h4>
                <ul className="list-disc pl-4">
                  {titles.map((title, idx) => (
                    <li key={idx}>{title}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="bg-white rounded-lg shadow-sm border border-gray-200 hover:shadow-md transition-shadow duration-200">
      {isEditing ? (
        <div className="p-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Left Column */}
            <div className="space-y-4">
              {/* Description */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  {t('goals.description')}
                </label>
                <input
                  type="text"
                  value={editData.description}
                  onChange={(e) => setEditData({ ...editData, description: e.target.value })}
                  placeholder={t('goals.descriptionPlaceholder')}
                  className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                />
              </div>

              {/* Objective */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  {t('goals.objective')}
                </label>
                <input
                  type="text"
                  value={editData.objective}
                  onChange={(e) => setEditData({ ...editData, objective: e.target.value })}
                  placeholder={t('goals.objectivePlaceholder')}
                  className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                />
              </div>

              {/* Action Plan */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  {t('goals.actionPlan')}
                </label>
                <textarea
                  value={editData.action_plan}
                  onChange={(e) => setEditData({ ...editData, action_plan: e.target.value })}
                  placeholder={t('goals.actionPlanPlaceholder')}
                  className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  rows="3"
                />
              </div>

              {/* Relevance */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  {t('goals.relevance')}
                </label>
                <textarea
                  value={editData.relevance}
                  onChange={(e) => setEditData({ ...editData, relevance: e.target.value })}
                  placeholder={t('goals.relevancePlaceholder')}
                  className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  rows="3"
                />
              </div>
            </div>

            {/* Right Column */}
            <div className="space-y-4">
              {/* Target Date */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  {t('goals.targetDate')}
                </label>
                <input
                  type="date"
                  value={editData.target_date}
                  onChange={(e) => setEditData({ ...editData, target_date: e.target.value })}
                  className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                />
              </div>

              {/* Progress */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  {t('goals.progress')}
                </label>
                <select
                  value={editData.progress}
                  onChange={(e) => setEditData({ ...editData, progress: e.target.value })}
                  className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="Not Started">{t('goals.notStarted')}</option>
                  <option value="In Progress">{t('goals.inProgress')}</option>
                  <option value="Completed">{t('goals.completed')}</option>
                </select>
              </div>

              {/* Success Criteria */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  {t('goals.successCriteria')}
                </label>
                <textarea
                  value={editData.success_criteria}
                  onChange={(e) => setEditData({ ...editData, success_criteria: e.target.value })}
                  placeholder={t('goals.successCriteriaPlaceholder')}
                  className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  rows="3"
                />
              </div>

              {/* Priority */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  {t('goals.priority')}
                </label>
                <select
                  value={editData.priority}
                  onChange={(e) => setEditData({ ...editData, priority: e.target.value })}
                  className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="Low">{t('goals.low')}</option>
                  <option value="Medium">{t('goals.medium')}</option>
                  <option value="High">{t('goals.high')}</option>
                </select>
              </div>
            </div>
          </div>

          {/* Action Buttons */}
          <div className="flex flex-col sm:flex-row justify-end space-y-2 sm:space-y-0 sm:space-x-2 mt-6">
            <Button 
              onClick={() => onDelete(goal.id)}
              color="red"
              icon={FaTrash}
            >
              {t('common.delete')}
            </Button>
            <Button 
              onClick={onCancel}
              color="blue"
              icon={FaTimes}
            >
              {t('common.cancel')}
            </Button>
            <Button 
              onClick={onSave}
              color="green"
              icon={FaCheck}
            >
              {t('common.save')}
            </Button>
          </div>
        </div>
      ) : (
        <div className="p-6">
          {/* Header Section with medal badges and edit button */}
          <div className="flex flex-col mb-4">
            {/* Title and Objective - with Edit button for desktop */}
            <div className="flex justify-between items-start mb-4">
              <div>
                <h3 className="text-xl font-bold text-gray-900 mb-2">{goal?.description}</h3>
                <p className="text-gray-600">{goal?.objective}</p>
              </div>
              {/* Desktop Edit Button */}
              <div className="hidden md:block">
                <Button 
                  onClick={() => onEdit(goal)} 
                  color="none"
                  icon={FaEdit}
                  className="text-gray-400 hover:text-gray-600"
                >
                  <span>{t('Edit')}</span>
                </Button>
              </div>
            </div>
            
            {/* Mobile Layout - Medals and Edit Button */}
            <div className="md:hidden space-y-3">
              <div className="flex flex-wrap gap-2">
                {renderMedalBadges(goal?.medals)}
              </div>
              <div className="flex justify-end">
                <Button 
                  onClick={() => onEdit(goal)} 
                  color="none"
                  icon={FaEdit}
                  className="text-gray-400 hover:text-gray-600"
                >
                  <span>{t('Edit')}</span>
                </Button>
              </div>
            </div>

            {/* Desktop Layout - Medals only */}
            <div className="hidden md:block">
              <div className="flex items-center space-x-4">
                {renderMedalBadges(goal?.medals)}
              </div>
            </div>
          </div>
          
          {/* Details Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-4">
            {/* Status Information */}
            <div className="bg-gray-50 rounded-lg p-4">
              <div className="space-y-3">
                {/* Progress */}
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    {goal?.progress === 'Completed' ? (
                      <FaCheck className="text-green-500 mr-2" />
                    ) : goal?.progress === 'In Progress' ? (
                      <FaTasks className="text-blue-500 mr-2" />
                    ) : (
                      <FaHourglassStart className="text-gray-500 mr-2" />
                    )}
                    <span className="text-sm font-medium text-gray-600">{t('goals.progress')}:</span>
                  </div>
                  <span className="text-sm text-gray-900">{goal?.progress}</span>
                </div>

                {/* Priority */}
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    {goal?.priority === 'High' ? (
                      <FaChevronUp className="text-red-500 mr-2" />
                    ) : goal?.priority === 'Medium' ? (
                      <FaGripLines className="text-yellow-500 mr-2" />
                    ) : (
                      <FaChevronDown className="text-blue-500 mr-2" />
                    )}
                    <span className="text-sm font-medium text-gray-600">{t('goals.priority')}:</span>
                  </div>
                  <span className="text-sm text-gray-900">{goal?.priority}</span>
                </div>

                {/* Target Date */}
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <FaCalendar className="text-gray-400 mr-2" />
                    <span className="text-sm font-medium text-gray-600">{t('goals.targetDate')}:</span>
                  </div>
                  <span className={`text-sm font-medium ${getDateColor(goal?.target_date)}`}>
                    {formatDate(goal?.target_date, userTimezone)}
                  </span>
                </div>
              </div>
            </div>
            {/* Action Plan */}
            <div className="bg-gray-50 rounded-lg p-4">
              <div className="flex items-center mb-2">
                <FaBullseye className="text-blue-500 mr-2" />
                <h4 className="font-semibold text-gray-700">{t('goals.actionPlan')}</h4>
              </div>
              <p className="text-gray-600">{goal?.action_plan}</p>
            </div>

            {/* Relevance */}
            <div className="bg-gray-50 rounded-lg p-4">
              <div className="flex items-center mb-2">
                <FaCheckCircle className="text-green-500 mr-2" />
                <h4 className="font-semibold text-gray-700">{t('goals.relevance')}</h4>
              </div>
              <p className="text-gray-600">{goal?.relevance}</p>
            </div>

            {/* Success Criteria */}
            <div className="bg-gray-50 rounded-lg p-4">
              <div className="flex items-center mb-2">
                <FaFlag className="text-yellow-500 mr-2" />
                <h4 className="font-semibold text-gray-700">{t('goals.successCriteria')}</h4>
              </div>
              <p className="text-gray-600">{goal?.success_criteria}</p>
            </div>

            
          </div>
        </div>
      )}
    </div>
  );
};

export default Settings;
