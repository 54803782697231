import instance from '../../axios';
import { toast } from 'react-toastify';
import i18n from '../../../i18n';

// Skills functions
  export const storeUserSkill = (skillId, proficiency) => {
    return instance.post('/skills/user/store', { skill_id: skillId, proficiency });
  };

  export const updateUserSkill = (skillId, proficiency) => {
    const response = instance.post(`/skills/user/update/${skillId}`, { proficiency });
    console.log("Update User Skill Response:", response);
    return response;
  };
  
  export const fetchUserSkills = () => instance.get('/skills/user');
  // export const fetchCommonSkills = () => instance.get('/skills/common');
  export const fetchCommonSkills = async () => {
    try {
      const response = await instance.get('/skills/common');
      if (response.data && response.data.data) {
        return response.data.data;
      } else {
        throw new Error('Unexpected response structure');
      }
    } catch (error) {
      console.error("API: Error fetching common skills:", error);
      throw error;
    }
  };
  
  export const removeUserSkill = (skillId) => instance.delete(`/skills/user/destroy/${skillId}`);

  export const createSkill = async (skill) => {
    try {
      const response = await instance.post('/skills/store', { name: skill });
      if (response.data && response.data.data) {
        toast.success(i18n.t('skills.createSuccess'));
        return response.data.data;
      } else {
        toast.error(i18n.t('skills.createUnexpectedFailure'));
        throw new Error('Unexpected response status');
      }
    } catch (error) {
      console.error("API: Error creating skill:", error);
      toast.error(i18n.t('skills.createFailure'));
      throw error;
    }
  };
