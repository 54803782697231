import React from 'react';

const PageHero = ({ 
  title, 
  subtext, // Optional subtext
  buttons = [],
  gradientClasses = "from-blue-50 to-blue-200",
  textSize = "text-4xl", // Default text size for title
  subtextSize = "text-sm", // Default text size for subtext
  textColor = "text-gray-600" // Default text color
}) => {
  return (
    <div className={`hero bg-gradient-to-r ${gradientClasses} text-white py-12 px-6 rounded-t-xl flex flex-col md:flex-row md:justify-between md:items-center gap-6 text-center md:text-left`}>
      <div>
        <h1 className={`${textSize} ${textColor} font-extrabold`}>{title}</h1>
        {subtext && (
          <p className={`${subtextSize} ${textColor} mt-2`}>{subtext}</p>
        )}
      </div>
      {buttons.length > 0 && (
        <div className="flex flex-col sm:flex-row gap-4 w-full md:w-auto justify-center md:justify-start md:items-center">
          {buttons.map((button, index) => (
            <button
              key={index}
              onClick={button.onClick}
              className={button.className || "w-full sm:w-auto bg-white text-blue-500 font-semibold py-2 px-4 rounded shadow-md hover:bg-gray-100 flex items-center justify-center gap-2"}
            >
              {button.icon && <span className="text-xl">{button.icon}</span>}
              {button.text}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default PageHero;
