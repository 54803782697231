import React, { useState, useEffect } from 'react';
import { fetchGoals, fetchMedalCategories } from '../../api/axios';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const BasicInfo = ({ onNext, onBack, initialData = {} }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [title, setTitle] = useState(initialData.title || '');
  const [startDate, setStartDate] = useState(initialData.startDate || new Date().toISOString().split('T')[0]); // Set default to today's date
  const [endDate, setEndDate] = useState(initialData.endDate || '');
  const [category, setCategory] = useState(initialData.category || '');
  const [categories, setCategories] = useState([]);
  const [categoryName, setCategoryName] = useState(initialData.categoryName || '');
  const [goals, setGoals] = useState([]);
  const [selectedGoals, setSelectedGoals] = useState(initialData.goals || []);

  useEffect(() => {
    const loadData = async () => {
      try {
        const [categoriesData, goalsData] = await Promise.all([
          fetchMedalCategories(),
          fetchGoals()
        ]);
        setCategories(categoriesData);
        setGoals(goalsData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    loadData();
  }, []);

  const handleCategoryChange = (e) => {
    const selectedCategoryId = e.target.value;
    const selectedCategory = categories.find(cat => String(cat.id) === selectedCategoryId);
    setCategory(selectedCategoryId);
    setCategoryName(selectedCategory ? selectedCategory.category : '');
  };

  const handleGoalChange = (e) => {
    const selectedOptions = Array.from(e.target.selectedOptions, option => {
      const goal = goals.find(g => g.id === parseInt(option.value));
      return {
        id: goal.id,
        title: goal.title,
        description: goal.description
      };
    });
    setSelectedGoals(selectedOptions);
  };

  const handleNext = async () => {
    try {
      onNext({ 
        title, 
        startDate, 
        endDate, 
        category, 
        categoryName,
        goals: selectedGoals 
      });
    } catch (error) {
      console.error('Error saving medal data:', error);
    }
  };

  const isFormValid = () => {
    return title && startDate && category;
  };

  const handleCreateGoal = () => {
    navigate('/settings?tab=goals&redirect=medal-wizard');
  };

  return (
    <div className="w-full min-h-screen flex items-start justify-center p-4">
      <div className="w-full max-w-2xl bg-white">
        <div className="flex justify-between mb-8 p-4">
          <button
            onClick={() => navigate('/timeline')}
            className="mt-1 py-2 px-4 rounded-md transition duration-300 bg-red-700 hover:bg-red-500 text-white"
          >
            {t('Cancel')}
          </button>
          <button
            onClick={handleNext}
            disabled={!isFormValid()}
            className={`mt-1 py-2 px-4 rounded-md transition duration-300 ${isFormValid() ? 'bg-blue-600 hover:bg-blue-600 text-white' : 'bg-gray-300 cursor-not-allowed'}`}
          >
            {t('Next')}
          </button>
        </div>
        <div className="text-center mb-6">
          <h2 className="text-4xl font-bold text-gray-800 mb-2">{t('medals.information')}</h2>
          <p className="text-sm text-gray-700 rounded-lg p-4 border border-blue-200 bg-blue-50">
            {t('medals.description')}
          </p>
        </div>
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              {t('medals.title')} <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder={t('medals.titlePlaceholder')}
              className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                {t('medals.startDate')} <span className="text-red-500">*</span>
              </label>
              <input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                {t('medals.endDate')}
              </label>
              <input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              {t('medals.category')} <span className="text-red-500">*</span>
            </label>
            <select
              value={category}
              onChange={handleCategoryChange}
              className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="">{t('medals.selectCategory')}</option>
              {categories.map((cat) => (
                <option key={cat.id} value={cat.id}>{cat.category}</option>
              ))}
            </select>
            <input type="hidden" value={categoryName} />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              {t('medals.associateGoal')} <span className="text-sm text-gray-500">{t('medals.optional')}</span>
            </label>
            {goals.length > 0 ? (
              <div className="border border-gray-300 rounded-lg overflow-hidden max-h-[400px] overflow-y-auto">
                {goals.map((goal) => (
                  <div 
                    key={goal.id}
                    className={`p-4 border-b border-gray-200 cursor-pointer hover:bg-blue-50 transition-colors ${
                      selectedGoals.some(g => g.id === goal.id) ? 'bg-blue-100' : 'bg-white'
                    }`}
                    onClick={() => {
                      const isSelected = selectedGoals.some(g => g.id === goal.id);
                      if (isSelected) {
                        setSelectedGoals(selectedGoals.filter(g => g.id !== goal.id));
                      } else {
                        setSelectedGoals([...selectedGoals, {
                          id: goal.id,
                          title: goal.title,
                          description: goal.description
                        }]);
                      }
                    }}
                  >
                    <div className="flex flex-col gap-2">
                      <div className="flex items-start gap-3 justify-between">
                        <div className="flex items-start gap-3 flex-1">
                          <input
                            type="checkbox"
                            checked={selectedGoals.some(g => g.id === goal.id)}
                            onChange={() => {}} // Handled by parent div onClick
                            className="mt-1 h-4 w-4 text-blue-600 rounded"
                          />
                          <div className="flex-1">
                            <h3 className="font-medium text-gray-900">{goal.description}</h3>
                          </div>
                        </div>
                        {goal.target_date && (
                          <span className="text-sm text-gray-600 whitespace-nowrap">
                            {new Date(goal.target_date).toLocaleDateString()}
                          </span>
                        )}
                      </div>
                      {goal.objective && (
                        <div className="text-sm pl-7">
                          <span className="font-medium text-gray-700">Objective:</span>
                          <span className="ml-2 text-gray-600">{goal.objective}</span>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center p-8 border border-gray-300 rounded-lg bg-gray-50">
                <p className="text-gray-600 mb-4 text-center">
                  {t('medals.noGoalsYet')}
                </p>
                <button
                  onClick={handleCreateGoal}
                  className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  <svg className="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
                  </svg>
                  {t('medals.createNew')}
                </button>
              </div>
            )}
            <p className="text-sm text-gray-500 mt-1">
              {t('medals.goalSelectionHelp')}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicInfo;
