import React, { useEffect, useState } from 'react';
import { FaPencilAlt, FaTrash, FaCheck, FaTimes, FaSave, FaEdit } from 'react-icons/fa';
import Select from 'react-select';
import QuillWrapper from '../Common/QuillWrapper';
import CreatableSelect from 'react-select/creatable';
import { createEmployer, createTitle } from '../../api/axios';
import { useTranslation } from 'react-i18next';
import { parseISO, isValid, format } from 'date-fns';
import Button from '../Common/Button';
import ConfirmationModal from '../Common/ConfirmationModal';

const EmploymentItem = ({
  employment,
  isEditing,
  editData,
  onEdit,
  onSave,
  onCancel,
  onDelete,
  setEditData,
  employers,
  titles,
  isNew = false,
  setEmployers,
  setTitles,
}) => {
  const { t } = useTranslation();
  const [newEmployer, setNewEmployer] = useState('');
  const [newTitle, setNewTitle] = useState('');
  // Add new state for delete confirmation
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  useEffect(() => {
    if (isEditing && !isNew && !editData.employer_id) {
      setEditData({
        ...editData,
        employer_id: employment.employer.id,
        title: employment.position.id
      });
    }
  }, [isEditing, editData, employment, setEditData, isNew]);

  const handleCreateEmployer = async (inputValue) => {
    try {
      const createdEmployer = await createEmployer(inputValue);
      setEditData({ ...editData, employer_id: createdEmployer.id });
      setEmployers(prevEmployers => [...prevEmployers, createdEmployer]);
    } catch (error) {
      console.error("Error creating employer:", error);
    }
  };

  const handleCreateTitle = async (inputValue) => {
    try {
      const createdTitle = await createTitle(inputValue);
      setEditData({ ...editData, title: createdTitle.id });
      setTitles(prevTitles => [...prevTitles, createdTitle]);
    } catch (error) {
      console.error("Error creating title:", error);
    }
  };

  // const formatDate = (dateString) => {
  //   if (!dateString) return '';
  //   const date = new Date(dateString);
  //   return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
  // };
  const formatDate = (dateString) => {
    const date = parseISO(dateString);
    return isValid(date) ? format(date, 'MMMM yyyy') : dateString;
  };

  if (isEditing || isNew) {
    const selectedEmployer = employers.find(emp => emp.id === editData.employer_id);
    const selectedTitle = titles.find(title => title.id === editData.title);

    return (
      <div className="mt-4 bg-gray-100 p-6 rounded-lg shadow-lg">
        <div className="mb-2">
          <label className="block text-sm font-medium text-gray-700">{t('employments.employer')}</label>
          <CreatableSelect
            value={selectedEmployer ? { value: selectedEmployer.id, label: selectedEmployer.name } : null}
            onChange={(selectedOption) => {
              if (selectedOption) {
                if (selectedOption.__isNew__) {
                  handleCreateEmployer(selectedOption.label);
                } else {
                  setEditData({ ...editData, employer_id: selectedOption.value });
                }
              } else {
                setEditData({ ...editData, employer_id: null });
              }
            }}
            onCreateOption={handleCreateEmployer}
            options={employers.map(emp => ({ value: emp.id, label: emp.name }))}
            className="mt-1"
            classNamePrefix="react-select"
            isClearable
            placeholder={t('employments.searchOrAddEmployer')}
          />
        </div>
        <div className="mb-2">
          <label className="block text-sm font-medium text-gray-700">{t('employments.title')}</label>
          <CreatableSelect
            value={selectedTitle ? { value: selectedTitle.id, label: selectedTitle.name } : null}
            onChange={(selectedOption) => {
              if (selectedOption) {
                if (selectedOption.__isNew__) {
                  handleCreateTitle(selectedOption.label);
                } else {
                  setEditData({ ...editData, title: selectedOption.value });
                }
              } else {
                setEditData({ ...editData, title: null });
              }
            }}
            onCreateOption={handleCreateTitle}
            options={titles.map(title => ({ value: title.id, label: title.name }))}
            className="mt-1"
            classNamePrefix="react-select"
            isClearable
            placeholder={t('employments.searchOrAddTitle')}
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-2">{t('employments.description')}</label>
          <QuillWrapper
            value={editData.description || ''}
            onChange={(content) => setEditData({ ...editData, description: content })}
            className="mt-1 bg-white"
          />
        </div>
        <div className="flex space-x-4 mb-4">
          <div className="flex-1">
            <label className="block text-sm font-medium text-gray-700 mb-2">{t('employments.startDate')}</label>
            <input
              type="date"
              value={editData.start_date || ''}
              onChange={(e) => setEditData({ ...editData, start_date: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm bg-gray-50 p-2"
            />
          </div>
          <div className="flex-1">
            <label className="block text-sm font-medium text-gray-700 mb-2">{t('employments.endDate')}</label>
            <input
              type="date"
              value={editData.end_date || ''}
              onChange={(e) => setEditData({ ...editData, end_date: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm bg-gray-50 p-2"
              disabled={editData.is_current}
            />
          </div>
        </div>
        <div className="mb-4">
          <label className="inline-flex items-center">
            <input
              type="checkbox"
              checked={editData.is_current || false}
              onChange={(e) => setEditData({ ...editData, is_current: e.target.checked })}
              className="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
            <span className="ml-2">{t('employments.currentPosition')}</span>
          </label>
        </div>
        <div className="mt-4 flex flex-wrap gap-2 justify-end">
          {!isNew && (
            <Button
              onClick={() => setShowDeleteConfirmation(true)}
              color="red"
              icon={FaTrash}
              className="w-full sm:w-auto"
            >
              {t('Delete')}
            </Button>
          )}
          <Button
            onClick={async () => {
              if (newEmployer) {
                const createdEmployer = await createEmployer(newEmployer);
                setEditData({ ...editData, employer_id: createdEmployer.id });
              }
              if (newTitle) {
                const createdTitle = await createTitle(newTitle);
                setEditData({ ...editData, title: createdTitle.id });
              }
              onSave();
            }}
            color="green"
            icon={FaSave}
            className="w-full sm:w-auto"
          >
            {t('Save')}
          </Button>
          <Button
            onClick={onCancel}
            color="gray"
            icon={FaTimes}
            className="w-full sm:w-auto"
          >
            {t('Cancel')}
          </Button>
        </div>

        {/* Add ConfirmationModal */}
        <ConfirmationModal
          isOpen={showDeleteConfirmation}
          onClose={() => setShowDeleteConfirmation(false)}
          onConfirm={() => {
            onDelete();
            setShowDeleteConfirmation(false);
          }}
          title={t('employments.deleteConfirmTitle')}
          message={t('employments.deleteConfirmMessage')}
        />
      </div>
    );
  }

  return (
    <div className="bg-white p-4 rounded-lg shadow-md">
      <div className="flex flex-col">
        <div className="flex justify-between items-center mb-2">
          <div>
            <h3 className="text-lg font-semibold">{employment.position.name}</h3>
            <p className="text-gray-600">{employment.employer.name}</p>
          </div>
          <div className="flex items-center">
            <p className="text-md text-gray-500 mr-2">
              {formatDate(employment.start_date)} - {employment.is_current ? t('employments.present') : formatDate(employment.end_date)}
            </p>
            <button
              onClick={onEdit}
              className="hidden sm:block text-gray-400 hover:text-gray-600 transition-colors duration-200"
            >
              <FaEdit className="text-gray-400 text-sm"/>
            </button>
          </div>
        </div>
        <p className="text-gray-600 text-sm leading-relaxed mt-2"
          dangerouslySetInnerHTML={{ __html: employment.description }}
        />
        <Button
          onClick={onEdit}
          color="blue"
          icon={FaEdit}
          className="sm:hidden mt-4"
        >
          {t('Edit')}
        </Button>
      </div>
    </div>
  );
};

export { EmploymentItem };
