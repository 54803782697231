import instance, { fetchLatestUserInfo } from '../../axios';
import { toast } from 'react-toastify';
import i18n from '../../../i18n';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';

export const register = async (data) => {
  try {
    const response = await instance.post('/register', data);
    return response.data;
  } catch (error) {
    console.error('Registration failed:', error);
    toast.error(t('registration.failed'));
    throw error;
  }
};

export const checkAuthToken = async (token) => {
  try {
    const response = await instance.get('/verify-token', {
      headers: { Authorization: `Bearer ${token}` }
    });
    return response.data.isValid;
  } catch (error) {
    console.error('Token verification failed:', error);
    return false;
  }
};

export const loginUser = async (values) => {
  try {
    const response = await instance.post('/login', values);
    if (response.data.token) {
      localStorage.setItem('token', response.data.token);
    }
    // toast.success(t('login.loginSuccessful'));
    return response.data;
  } catch (error) {
    console.error(t('login.loginFailedLog'), error);
    toast.error(t('login.loginFailed'));
    throw error;
  }
};

export const logout = async () => {
  try {
    const response = await instance.post('/logout');
    localStorage.removeItem('token');
    return response.data;
  } catch (error) {
    console.error('Logout failed', error);
    localStorage.removeItem('token');
    throw error;
  }
};

export const signOut = async () => {
  await logout();
};

export const updateUserPassword = async (newPassword, newPasswordConfirmation) => {
  try {
    const response = await instance.post('/user/update/password', {
      password: newPassword,
      password_confirmation: newPasswordConfirmation
    });

    toast.success(t('userInfo.passwordUpdateSuccess'));
    return response.data.data;
  } catch (error) {
    console.error('Error updating user password:', error);
    if (error.response && error.response.data) {
      const { message, errors } = error.response.data;
      if (errors && errors.password) {
        toast.error(errors.password[0]);
      } else if (message) {
        toast.error(message);
      } else {
        toast.error(t('userInfo.passwordUpdateError'));
      }
    } else {
      toast.error(t('userInfo.passwordUpdateError'));
    }
    throw error;
  }
};

export const resetPassword = async (token, email, password, password_confirmation) => {
  try {
    const response = await instance.post('/reset-password', {
      token,
      email,
      password,
      password_confirmation
    });
    
    toast.success(t('password.resetSuccess'));
    return response.data;
  } catch (error) {
    console.error('Password reset failed:', error);
    if (error.response?.data?.message) {
      toast.error(error.response.data.message);
    } else {
      toast.error(t('password.resetFailed'));
    }
    throw error;
  }
};

export const resendVerificationEmail = async (email) => {
  try {
    const response = await instance.post('/email/resend-verification-email', {
      email,
    });
    toast.success(t('email.resendVerificationSuccess'));
    return response.data;
  } catch (error) {
    console.error('Resend verification email failed:', error);
    if (error.response?.data?.message) {
      toast.error(error.response.data.message);
    } else {
      toast.error(t('email.resendVerificationFailed'));
    }
    throw error;
  }
};

export const verifyEmail = async (params) => {
  try {
    const response = await instance.get(`/email/verify/${params.id}`, {
      params: {
        expires: params.expires,
        hash: params.hash,
        signature: params.signature
      }
    });
    
    if (response.data.success) {
      // Fetch latest user info after successful verification
      const updatedUser = await fetchLatestUserInfo();
      return { success: true, user: updatedUser };
    }
    
    return response.data;
  } catch (error) {
    console.error('Email verification failed:', error);
    throw error;
  }
};
