import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SectionHeader from '../Common/SectionHeader';
import PageHero from '../Common/PageHero';
import { FaChartLine, FaComments, FaTasks, FaCheckDouble, FaEye, FaChess } from 'react-icons/fa';
function Why() {
    const { t } = useTranslation();
    return (
        <div className="bg-white flex flex-col items-center min-h-screen mt-12 mb-12">
            <div className="text-center max-w-3xl px-4">
                <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                    <span className="block xl:inline">{t('home.whyYouShouldKeep')}</span>{' '}
                    <span className="block text-blue-600 xl:inline mb-4">{process.env.REACT_APP_NAME}</span>
                </h1>
                
                <h3 className="text-2xl font-bold text-primary-500 mb-8 mt-4">
                    {t('home.whatIsIt')}
                </h3>
                
                <PageHero title={t('home.whatIsItUsedFor')} textSize="text-xl" subtext={t('home.whyItsImportant')} />
                <ul className="text-lg text-gray-600 mb-12 grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                    <li className="bg-gray-50 p-4">
                        <div className="flex items-center md:flex-row flex-col">
                        <FaChartLine className="w-6 h-6 text-gray-500 mr-3" />
                        {t('home.performanceReviews')}
                        </div>
                        <p className="text-sm text-left text-gray-500 mt-2 border border-gray-200 bg-gray-200 p-4 rounded">
                        {t('home.performanceReviewsText')}
                        </p>
                    </li>
                    <li className="bg-gray-50 p-4">
                        <div className="flex items-center md:flex-row flex-col">
                        <FaComments className="w-6 h-6 text-gray-500 mr-3" />
                        {t('home.jobInterviews')}
                        </div>
                        <p className="text-sm text-left text-gray-500 mt-2 border border-gray-200 bg-gray-200 p-4 rounded">
                        {t('home.jobInterviewsText')}
                        </p>
                    </li>
                    <li className="bg-gray-50 p-4">
                        <div className="flex items-center md:flex-row flex-col">
                        <FaTasks className="w-6 h-6 text-gray-500 mr-3" />
                        {t('home.careerProgression')}
                        </div>
                        <p className="text-sm text-left text-gray-500 mt-2 border border-gray-200 bg-gray-200 p-4 rounded">
                        {t('home.careerProgressionText')}
                        </p>
                    </li>
                    <li className="bg-gray-50 p-4">
                        <div className="flex items-center md:flex-row flex-col">
                        <FaCheckDouble className="w-6 h-6 text-gray-500 mr-3" />
                        {t('home.accurateReflection')}
                        </div>
                        <p className="text-sm text-left text-gray-500 mt-2 border border-gray-200 bg-gray-200 p-4 rounded">
                            {t('home.accurateReflectionText')}
                        </p>
                    </li>
                    <li className="bg-gray-50 p-4">
                        <div className="flex items-center md:flex-row flex-col">
                        <FaEye className="w-6 h-6 text-gray-500 mr-3" />
                        {t('home.visibility')}
                        </div>
                        <p className="text-sm text-left text-gray-500 mt-2 border border-gray-200 bg-gray-200 p-4 rounded">
                            {t('home.visibilityText')}
                        </p>
                    </li>
                    <li className="bg-gray-50 p-4">
                        <div className="flex items-center md:flex-row flex-col">
                        <FaChess className="w-6 h-6 text-gray-500 mr-3" />
                        {t('home.careerStrategy')}
                        </div>
                        <p className="text-sm text-left text-gray-500 mt-2 border border-gray-200 bg-gray-200 p-4 rounded">
                            {t('home.careerStrategyText')}
                        </p>
                    </li>
                </ul>
                <div className="bg-gradient-to-r from-yellow-200 via-green-200 via-blue-200 to-purple-200 text-white p-6 rounded-lg shadow-lg mb-12">
                    <h2 className="text-3xl font-bold mb-2">{t('home.boostCareer')}</h2>
                    <p className="text-xl">{t('home.unlockPotential')}</p>

                    <Link
                        to="/what-are-digital-medals"
                        className="mt-4 mr-4 inline-block bg-white text-blue-500 font-bold py-2 px-6 rounded hover:bg-blue-100 transition duration-300"
                    >
                        {t('home.whatAreDigitalMedals')}

                    </Link>
                    <Link
                        to="/register"
                        className="mt-4 inline-block bg-white text-blue-500 font-bold py-2 px-6 rounded hover:bg-blue-100 transition duration-300"
                    >
                        {t('home.getStarted')}

                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Why;
