import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // Add this import
import { format, isValid } from 'date-fns'; // Add isValid import
import PageHero from '../Common/PageHero';
import { 
  fetchUserSkills, 
  fetchUserTechnologies, 
  fetchLatestUserInfo, 
  fetchEmployers, 
  fetchPositions, 
  fetchSocialPlatforms, 
  deleteSocialLink, 
  addSocialLink, 
  updateSocialLink,
  saveUserName, 
  saveUserHeadline, 
  saveUserDescription, 
  updateUserEmployment, 
  deleteUserEmployment, 
  storeUserEmployment,
  saveUserUserName,
  fetchCertifications // Add this import
} from '../../api/axios';

import { useUser } from '../../contexts/UserContext';
import { getSocialIcon, socialIcons } from '../Common/SocialIcons';
import { EmploymentItem } from '../Employment/Employment';
import { sortByProficiencyAndName, getProficiencyColors } from '../Proficiency/Proficiency';
// Import the icons we'll be using
import { 
  FaCode, 
  FaLaptopCode, 
  FaTimes, 
  FaTrash, 
  FaPlus, 
  FaPencilAlt, 
  FaSave,
  FaCheckCircle,
  FaGlobe ,
  FaCalendar,
  FaChalkboardTeacher,
  FaEnvelope,
  FaFileAlt,
  FaCheck,
  FaCertificate, // Add FaCertificate
} from 'react-icons/fa';
import QuillWrapper from '../Common/QuillWrapper';
import 'react-quill/dist/quill.snow.css';
import { motion } from 'framer-motion';
import Button from '../Common/Button';
import ProfilePicture from './ProfilePicture';
import Page from '../Page';

const sortEmployments = (employments) => {
  return employments.sort((a, b) => {
    if (a.is_current && !b.is_current) return -1;
    if (!a.is_current && b.is_current) return 1;

    const dateA = a.is_current ? new Date(a.start_date) : new Date(a.end_date || a.start_date);
    const dateB = b.is_current ? new Date(b.start_date) : new Date(b.end_date || b.start_date);

    return dateB - dateA; // Sort by date descending
  });
};

const Profile = () => {
  const { t } = useTranslation();
  const [userSkills, setUserSkills] = useState([]);
  const [userTechnologies, setUserTechnologies] = useState([]);
  const [userCertifications, setUserCertifications] = useState([]); // Add this state
  const { user, setUser } = useUser();
  const [editMode, setEditMode] = useState({
    name: false,
    username: false,
    headline: false,
    description: false,
    socialLinks: false,
    experience: null,
    profile_picture_url: null,
  });
  const [editData, setEditData] = useState({});
  const [employers, setEmployers] = useState([]);
  const [titles, setTitles] = useState([]);
  const [socialPlatforms, setSocialPlatforms] = useState([]);
  const [newSocialLink, setNewSocialLink] = useState({ platform_id: '', platform: '', url: '' });
  const [showNewLinkRow, setShowNewLinkRow] = useState(false);
  const quillRef = useRef(null);
  const [isAddingEmployment, setIsAddingEmployment] = useState(false);
  const [newEmployment, setNewEmployment] = useState({
    employer_id: '',
    position_id: '',
    start_date: '',
    end_date: '',
    is_current: false,
    description: '',
  });

  // Add this new state to track edited links
  const [editedLinks, setEditedLinks] = useState({});

  const [saveError, setSaveError] = useState(null);

  const nameInputRef = useRef(null);
  const navigate = useNavigate();

  const handleSocialLinkChange = useCallback((index, newUrl) => {
    setEditData(prevEditData => {
      const newLinks = [...prevEditData.socialLinks];
      newLinks[index] = { ...newLinks[index], url: newUrl };
      return { ...prevEditData, socialLinks: newLinks };
    });
    setEditedLinks(prev => ({ ...prev, [index]: true }));
  }, []);

  const handleUpdateSocialLink = useCallback(async (linkId, updatedUrl) => {
    try {
      await updateSocialLink(linkId, updatedUrl);
      setUser(prevUser => ({
        ...prevUser,
        social_links: prevUser.social_links.map(link =>
          link.id === linkId ? { ...link, url: updatedUrl } : link
        )
      }));
      setEditedLinks(prev => ({ ...prev, [linkId]: false }));
    } catch (error) {
      console.error('Error updating social link:', error);
    }
  }, [setUser]);

  useEffect(() => {
    const loadUserData = async () => {
      try {
        const [skillsResponse, technologiesResponse, latestUserInfo, employersData, titlesData, certificationsResponse] = await Promise.all([
          fetchUserSkills(),
          fetchUserTechnologies(),
          fetchLatestUserInfo(),
          fetchEmployers(),
          fetchPositions(),
          fetchCertifications(),
        ]);

        const skills = Array.isArray(skillsResponse.data) ? skillsResponse.data : 
                       (skillsResponse.data && Array.isArray(skillsResponse.data.data) ? skillsResponse.data.data : []);
        setUserSkills(skills);

        const technologies = Array.isArray(technologiesResponse.data) ? technologiesResponse.data : 
                             (technologiesResponse.data && Array.isArray(technologiesResponse.data.data) ? technologiesResponse.data.data : []);
        setUserTechnologies(technologies);

        // Update this part to correctly set the certifications
        const certifications = certificationsResponse;
        setUserCertifications(certifications);

        // Update the user context with the latest user info
        if (setUser && typeof setUser === 'function') {
          setUser(latestUserInfo);
        } else {
          console.error('setUser is not a function or is undefined');
        }

        setEmployers(employersData);
        setTitles(titlesData);
      } catch (error) {
        console.error('Error loading user data:', error);
        setUserSkills([]);
        setUserTechnologies([]);
        setUserCertifications([]);
      }
    };

    loadUserData();
  }, [setUser]);

  useEffect(() => {
    const loadSocialPlatforms = async () => {
      try {
        const platforms = await fetchSocialPlatforms();
        setSocialPlatforms(platforms);
      } catch (error) {
        console.error('Error loading social platforms:', error);
      }
    };

    loadSocialPlatforms();
  }, []);


  useEffect(() => {
    if (editMode.name && nameInputRef.current) {
      adjustTextareaHeight(nameInputRef.current);
    }
  }, [editMode.name]);

  // Add this useEffect for cleanup
  useEffect(() => {
    return () => {
      // Cleanup any existing ResizeObserver instances
      if (quillRef.current) {
        const editor = quillRef.current.getEditor();
        if (editor) {
          editor.off('text-change');
        }
      }
    };
  }, []);

  const renderQuillWrapper = useCallback(({ value, onChange, modules, formats }) => {
    return (
      <div className="quill-wrapper-container">
        <QuillWrapper
          ref={quillRef}
          value={value}
          onChange={onChange}
          modules={{
            ...modules,
            clipboard: {
              matchVisual: false
            }
          }}
          formats={formats}
          className="bg-white max-w-full"
          style={{ maxWidth: '100%', overflowX: 'hidden' }}
          theme="snow"
        />
      </div>
    );
  }, []); // Empty dependency array since we're not using any external values

  if (!user) {
    return <div className="flex justify-center items-center h-screen">
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
    </div>;
  }

  const sortedEmployments = user.employments ? sortEmployments(user.employments) : [];

  const SkillOrTechItem = ({ item, type }) => {
    const handleSkillClick = () => {
      navigate('/skills');
    };

    const { bgColor, fromColor, toColor, textColor, proficiencyAbbr } = getProficiencyColors(item.proficiency_name);

    return (
      <motion.li
        whileHover={{ scale: 1.02 }}
        className="mb-2 w-full"
        onClick={handleSkillClick}
      >
        <div className={`bg-gradient-to-r ${fromColor} ${toColor} ${textColor} rounded-lg px-3 py-2 text-sm font-semibold cursor-pointer transition-all duration-300 flex justify-between items-center`}>
          <span>{item.skill_name || item.technology_name || item.name}</span>
          <span className="bg-white rounded px-1 text-xs font-bold">{proficiencyAbbr}</span>
        </div>
      </motion.li>
    );
  };

  const handleEdit = (section, data = {}) => {
    try {
      setEditMode({ ...editMode, [section]: true });
      if (section === 'socialLinks') {
        setEditData({ ...editData, [section]: Array.isArray(data) ? data : [] });
      } else {
        setEditData({ ...editData, [section]: { id: data.id, value: data.value } });
      }
    } catch (error) {
      console.error(`Error in handleEdit for ${section}:`, error);
    }
  };

  const handleSave = async (section) => {
    try {
      setSaveError(null);
      let updatedUser;
      switch (section) {
        case 'name':
          updatedUser = await saveUserName(editData.name.value);
          break;
        case 'username':
          updatedUser = await saveUserUserName(editData.username.value);
          break;
        case 'headline':
          updatedUser = await saveUserHeadline(editData.headline.value);
          break;
        case 'description':
          updatedUser = await saveUserDescription(editData.description.value);
          break;
        case 'experience':
          updatedUser = await updateUserEmployment(editData.experience);
          break;
        default:
          throw new Error('Invalid section');
      }
      if (updatedUser) {
        setUser(updatedUser);
        setEditMode({ ...editMode, [section]: false });
      } else {
        throw new Error('Failed to update user data');
      }
    } catch (error) {
      console.error(`Error saving ${section}:`, error.message);
      setSaveError(`Failed to save ${section}. Please try again.`);
      // Don't turn off edit mode here
    }
  };

  const handleCancel = (section) => {
    setEditMode({ ...editMode, [section]: null });
    setEditData({ ...editData, [section]: {} });
  };

  const handleDeleteEmployment = async (employmentId) => {
    try {
      const updatedUser = await deleteUserEmployment(employmentId);
      setUser(updatedUser);
    } catch (error) {
      console.error('Error deleting employment:', error);
    }
  };

  const handleAddEmployment = () => {
    setIsAddingEmployment(true);
    setEditData({
      ...editData,
      newEmployment: {
        employer_id: '',
        title: '',
        start_date: '',
        end_date: '',
        is_current: false,
        description: '',
      }
    });
  };

  const handleSaveNewEmployment = async () => {
    try {
      const updatedUser = await storeUserEmployment(editData.newEmployment);
      setUser(updatedUser);
      setIsAddingEmployment(false);
      setEditData({ ...editData, newEmployment: null });
    } catch (error) {
      console.error('Error saving new employment:', error);
    }
  };

  const handleCancelNewEmployment = () => {
    setIsAddingEmployment(false);
    setEditData({ ...editData, newEmployment: null });
  };

  const handleEditExperience = (exp) => {
    setEditMode({ ...editMode, experience: exp.id });
    setEditData({ ...editData, experience: { ...exp } });
  };

  const handleSaveExperience = async () => {
    try {
      const updatedUser = await updateUserEmployment(editData.experience);
      setUser(updatedUser);
      setEditMode({ ...editMode, experience: null });
      setEditData({ ...editData, experience: {} });
    } catch (error) {
      console.error('Error saving experience:', error);
    }
  };

  const handleCancelExperience = () => {
    setEditMode({ ...editMode, experience: null });
    setEditData({ ...editData, experience: {} });
  };

  // Update the handleQuillChange function
  const handleQuillChange = (content, field) => {
    setEditData(prevData => ({
      ...prevData,
      [field]: { ...prevData[field], value: content }
    }));
  };

  const handleDeleteSocialLink = async (linkId) => {
    try {
      await deleteSocialLink(linkId);
      // Update local state to remove the deleted link
      const updatedLinks = user.social_links.filter(link => link.id !== linkId);
      setUser({ ...user, social_links: updatedLinks });

      // Update editData if the deleted link is currently being edited
      if (editData.socialLinks) {
        const updatedEditLinks = editData.socialLinks.filter(link => link.id !== linkId);
        setEditData({ ...editData, socialLinks: updatedEditLinks });
      }
    } catch (error) {
      console.error('Error deleting social link:', error);
    }
  };

  const handleAddSocialLink = async () => {
    try {
      if (!newSocialLink.platform_id || !newSocialLink.url) {
        console.error('Platform ID or URL is missing');
        return;
      }
   
      const newLink = await addSocialLink({
        social_platform_id: newSocialLink.platform_id,
        url: newSocialLink.url
      });


      if (!newLink || !newLink.url) {
        throw new Error('Invalid response from addSocialLink');
      }

      // Update local state to include the new link
      setUser(prevUser => ({
        ...prevUser,
        social_links: [...(prevUser.social_links || []), newLink]
      }));

      // Update editData to include the new link if in edit mode
      if (editData.socialLinks) {
        setEditData(prevEditData => ({
          ...prevEditData,
          socialLinks: [...prevEditData.socialLinks, newLink]
        }));
      }

      setNewSocialLink({ platform_id: '', platform: '', url: '' });
      setShowNewLinkRow(false);
    } catch (error) {
      console.error('Error adding social link:', error);
      // Optionally, you can set an error state here to display to the user
      // setError('Failed to add social link. Please try again.');
    }
  };

  const handleCloseSocialLinksEdit = () => {
    setEditMode({ ...editMode, socialLinks: false });
    setEditData({ ...editData, socialLinks: [] });
    setShowNewLinkRow(false);
  };

  const handleNameChange = (e) => {
    setEditData({ ...editData, name: { ...editData.name, value: e.target.value } });
    adjustTextareaHeight(e.target);
  };

  const adjustTextareaHeight = (element) => {
    element.style.height = 'auto';
    element.style.height = `${element.scrollHeight}px`;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return isValid(date) ? format(date, 'MMMM d, yyyy') : 'Invalid Date';
  };

  const CertificationItem = ({ cert }) => {
    return (
      <motion.li
        whileHover={{ scale: 1.02 }}
        className="mb-2 w-full"
      >
        <div className="bg-gradient-to-r from-blue-100 to-blue-200 text-blue-800 rounded-lg px-3 py-2 text-sm font-semibold cursor-pointer transition-all duration-300 flex flex-col">
          <span className="font-bold">{cert.name}</span>
          <span className="text-xs">{cert.institution.name}</span>
          {cert.date_of_completion && (
            <span className="text-xs">Completed: {new Date(cert.date_of_completion).toLocaleDateString()}</span>
          )}
        </div>
      </motion.li>
    );
  };

  return (
    <Page 
      title={`${user?.name}'s Profile`}
      description={`View ${user?.name}'s professional achievements, skills, and career history on Digital Medals`}
      keywords={`${user?.name}, professional profile, ${userSkills.map(s => s.name).join(', ')}`}
      ogImage={user?.profile_picture_url}
    >
      <div className="container mx-auto px-4 py-8">
        <div className="bg-white shadow-xl rounded-lg">
        <PageHero
          title={t('settings.profile')}
          buttons={[
            {
                text: "My Public Profile",
                onClick: () => navigate(`/u/${user.username}`)
            },
            {
                text: "My Resume",
                onClick: () => navigate('/resume')
            },
            {
              text: "My Skills",
              onClick: () => navigate('/skills')
            },
            {
              text: "My Technologies",
              onClick: () => navigate('/technologies')
            }
          ]}
          />
          <div className="bg-white rounded-lg shadow-xl">
            <div className="flex flex-col lg:flex-row">
              {/* Left column */}
              <div className="lg:w-1/3 p-6 bg-gray-50">
                <div className="mb-6 flex flex-col items-center">
                  <div className="w-24 h-24 text-center sm:w-32 sm:h-32 bg-white rounded-full overflow-hidden mb-4 sm:mb-0 sm:mr-6 shadow-lg border-4 border-white flex-shrink-0 relative group">
                    {user?.profile_picture_url ? (
                      <>
                        <img 
                          src={user.profile_picture_url} 
                          alt={user.name} 
                          className="w-full h-full object-cover"
                        />
                        <div 
                          className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-40 transition-all duration-300 flex items-center justify-center cursor-pointer"
                          onClick={() => setEditMode(prev => ({ ...prev, avatar: true }))}
                        >
                          <FaPencilAlt className="text-white opacity-0 group-hover:opacity-100 transform scale-0 group-hover:scale-100 transition-all duration-300 text-xl" />
                        </div>
                      </>
                    ) : (
                      <div 
                        className="w-full h-full flex items-center justify-center text-3xl text-gray-600 bg-gray-100 relative group cursor-pointer"
                        onClick={() => setEditMode(prev => ({ ...prev, avatar: true }))}
                      >
                        {user?.name ? user.name.charAt(0).toUpperCase() : 'U'}
                        <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-40 transition-all duration-300 flex items-center justify-center">
                          <FaPencilAlt className="text-white opacity-0 group-hover:opacity-100 transform scale-0 group-hover:scale-100 transition-all duration-300 text-xl" />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="justify-center text-black flex-grow overflow-hidden mt-4 w-full">
                    {editMode.name ? (
                      <div className="flex flex-col mb-4 w-full"> {/* Increased bottom margin */}
                        <textarea
                          ref={nameInputRef}
                          value={editData.name?.value || ''}
                          onChange={handleNameChange}
                          className="text-2xl sm:text-3xl font-bold text-gray-800 bg-white rounded px-2 py-1 mb-2 w-full resize-none overflow-hidden border border-gray-300"
                          rows={1}
                          style={{ minHeight: '40px' }}
                        />
                        <div className="flex justify-end space-x-2 mt-2"> {/* Added top margin */}
                          <Button
                            onClick={() => handleSave('name')}
                            color="blue"
                            icon={FaSave}
                          >
                            {t('Save')}
                          </Button>
                          <Button
                            onClick={() => handleCancel('name')}
                            color="gray"
                            icon={FaTimes}
                          >
                            {t('Cancel')}
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <div className="flex items-start mb-1 w-full">
                        <h1 className="text-2xl sm:text-3xl font-bold text-black break-words pr-2 flex-grow">
                          {user.name || t('profile.nameNotSet')}
                        </h1>
                        <FaPencilAlt 
                          onClick={() => handleEdit('name', { id: 'name', value: user.name })} 
                          className="mt-1 cursor-pointer text-gray-400 hover:text-gray-600 text-xs flex-shrink-0" 
                        />
                      </div>
                    )}
                    
                    {/* Add username field */}
                    {editMode.username ? (
                      <div className="flex flex-col mb-4 w-full"> {/* Increased bottom margin */}
                        <input
                          value={editData.username?.value || ''}
                          onChange={(e) => setEditData({ 
                            ...editData, 
                            username: { 
                              ...editData.username, 
                              value: e.target.value.replace(/\s+/g, '') // Strip spaces
                            } 
                          })}
                          className="text-lg text-gray-600 bg-white rounded px-2 py-1 mb-2 w-full border border-gray-300"
                        />
                        <div className="flex justify-end space-x-2 mt-2"> {/* Added top margin */}
                          <Button
                            onClick={() => handleSave('username')}
                            color="blue"
                            icon={FaSave}
                          >
                            {t('Save')}
                          </Button>
                          <Button
                            onClick={() => handleCancel('username')}
                            color="gray"
                            icon={FaTimes}
                          >
                            {t('Cancel')}
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <div className="flex items-center mb-1 w-full">
                        <p className="text-lg text-gray-600 break-words pr-2 flex-grow">
                          @{user.username || t('profile.usernameNotSet')}
                        </p>
                        <FaPencilAlt 
                          onClick={() => handleEdit('username', { id: 'username', value: user.username })} 
                          className="cursor-pointer text-gray-400 hover:text-gray-600 text-xs flex-shrink-0" 
                        />
                      </div>
                    )}
                    
                    
                    {editMode.headline ? (
                      <div className="bg-white rounded-lg w-full mb-4"> {/* Added bottom margin */}
                        <QuillWrapper
                          value={editData.headline?.value || ''}
                          onChange={(content) => handleQuillChange(content, 'headline')}
                          modules={{
                            toolbar: [
                              ['bold', 'italic', 'underline'],
                              [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                              ['clean']
                            ],
                          }}
                          formats={['bold', 'italic', 'underline', 'list', 'bullet']}
                          className="bg-white text-gray-800 max-w-full"
                          style={{ maxWidth: '100%', overflowX: 'hidden' }}
                        />
                        <div className="flex justify-end mt-4 mb-2 space-x-2"> {/* Increased top and bottom margins */}
                          <Button
                            onClick={() => handleSave('headline')}
                            color="blue"
                            icon={FaSave}
                          >
                            {t('Save')}
                          </Button>
                          <Button
                            onClick={() => handleCancel('headline')}
                            color="gray"
                            icon={FaTimes}
                          >
                            {t('Cancel')}
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <div className="flex items-start mb-3 w-full">
                        <div className="text-sm text-gray-700 break-words pr-2 flex-grow" dangerouslySetInnerHTML={{ __html: user.headline || t('Headline not set') }} />
                        <FaPencilAlt 
                          onClick={() => handleEdit('headline', { id: 'headline', value: user.headline })} 
                          className="mt-1 cursor-pointer text-gray-400 hover:text-gray-600 text-xs flex-shrink-0" 
                        />
                      </div>
                    )}
                    {/* Add joined date */}
                    <p className="text-sm text-gray-500 mb-3 flex items-center">
                      <FaCalendar className="mr-2" />
                      <span>{t('profile.joined')} {formatDate(user.created_at)}</span>
                    </p>
                    <div className="w-full border-t border-gray-300 my-4"></div>
                    {/* <div className="flex items-center sm:justify-start space-x-3">
                      <button className="bg-blue-400 text-white px-4 py-1 rounded text-sm font-semibold hover:bg-blue-500 transition flex items-center">
                        <FaEnvelope className="w-4 h-4 mr-1" />
                        Contact
                      </button>
                      <button className="bg-blue-400 text-white px-4 py-1 rounded text-sm font-semibold hover:bg-blue-500 transition flex items-center">
                        <FaFileAlt className="w-4 h-4 mr-1" />
                        Resume
                      </button>
                    </div> */}
                  </div>
                </div>
                {/* Certifications Section */}
                <div className="mb-6">
                  <h2 className="text-xl font-semibold mb-4 text-gray-700 flex items-center">
                    <FaCertificate className="w-5 h-5 mr-2 text-blue-500" />
                    <Link to="/certifications" className="hover:text-blue-600 transition">{t('Certifications')}</Link>
                  </h2>
                  <ul className="w-full">
                    {Array.isArray(userCertifications) && userCertifications.length > 0 ? (
                      userCertifications.map((cert) => (
                        <CertificationItem key={cert.id} cert={cert} />
                      ))
                    ) : (
                      <p className="text-gray-500 italic">{t('No certifications added yet')}</p>
                    )}
                  </ul>
                </div>

                {/* Skills Section */}
                <div className="mb-6">
                  <h2 className="text-xl font-semibold mb-4 text-gray-700 flex items-center">
                    <FaCode className="w-5 h-5 mr-2 text-blue-500" />
                    <Link to="/skills" className="hover:text-blue-600 transition">{t('Skills')}</Link>
                  </h2>
                  <ul className="w-full">
                    {Array.isArray(userSkills) && sortByProficiencyAndName(userSkills).map((skill) => (
                      <SkillOrTechItem key={skill.id} item={skill} type="skill" />
                    ))}
                  </ul>
                </div>

                {/* Technologies Section */}
                <div>
                  <h2 className="text-xl font-semibold mb-4 text-gray-700 flex items-center">
                    <FaLaptopCode className="w-5 h-5 mr-2 text-blue-500" />
                    <Link to="/technologies" className="hover:text-blue-600 transition">{t('Technologies')}</Link>
                  </h2>
                  <ul className="space-y-2">
                    {Array.isArray(userTechnologies) && sortByProficiencyAndName(userTechnologies).map((tech) => (
                      <SkillOrTechItem key={tech.id} item={tech} type="technology" />
                    ))}
                  </ul>
                </div>
              </div>

              {/* Right column */}
              <div className="lg:w-2/3 p-6">
                {/* About Me Section */}
                <section className="mb-8">
                  <h2 className="text-2xl font-bold mb-4 text-gray-800 border-b border-blue-300 pb-2 flex items-center justify-between">
                    {t('About Me')}
                    {!editMode.description && (
                      <FaPencilAlt 
                        onClick={() => handleEdit('description', { id: 'description', value: user.description })} 
                        className="cursor-pointer text-gray-400 hover:text-gray-600 text-xs" 
                      />
                    )}
                  </h2>
                  {editMode.description ? (
                    <div className="bg-gray-100 p-4 rounded-lg shadow-lg mb-4"> {/* Added bottom margin */}
                      {renderQuillWrapper({
                        value: editData.description?.value || '',
                        onChange: (content) => handleQuillChange(content, 'description'),
                        modules: {
                          toolbar: [
                            [{ 'header': [1, 2, false] }],
                            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                            ['link'],
                            ['clean']
                          ],
                        },
                        formats: [
                          'header',
                          'bold', 'italic', 'underline', 'strike', 'blockquote',
                          'list', 'bullet', 'indent',
                          'link'
                        ]
                      })}
                      <div className="flex justify-end mt-4 space-x-2"> {/* Increased top margin */}
                        <Button
                          onClick={() => handleSave('description')}
                          color="blue"
                          icon={FaSave}
                        >
                          {t('Save')}
                        </Button>
                        <Button
                          onClick={() => handleCancel('description')}
                          color="gray"
                          icon={FaTimes}
                        >
                          {t('Cancel')}
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div 
                      className="text-gray-600 leading-relaxed"
                      dangerouslySetInnerHTML={{ __html: user.description }}
                    />
                  )}
                </section>

                <section className="mb-8">
                  <h2 className="text-2xl font-bold mb-4 text-gray-800 border-b border-blue-300 pb-2 flex items-center justify-between">
                    {t('Find me on')}
                    {!editMode.socialLinks && (
                      <FaPencilAlt onClick={() => handleEdit('socialLinks', user.social_links || [])} className="cursor-pointer text-gray-400 hover:text-gray-600 text-xs" />
                    )}
                  </h2>
                  {editMode.socialLinks ? (
                    <div className="bg-gray-100 p-4 rounded-lg">
                      {user.social_links && user.social_links.map((link) => {
                        const IconComponent = socialIcons[link.platform] || FaGlobe;
                        return (
                          <div key={link.id} className="bg-white rounded-lg shadow-sm border border-gray-200 p-6 mb-4">
                            <div className="flex flex-col sm:flex-row sm:items-center space-y-4 sm:space-y-0">
                              <div className="flex items-center space-x-4 flex-grow">
                                <IconComponent className="text-2xl text-gray-600" />
                                <div className="flex-grow">
                                  <input
                                    type="text"
                                    value={editData.socialLinks.find(l => l.id === link.id)?.url || link.url}
                                    onChange={(e) => handleSocialLinkChange(link.id, e.target.value)}
                                    className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                    placeholder="URL"
                                  />
                                </div>
                              </div>
                              <div className="flex sm:flex-row flex-col space-y-2 sm:space-y-0 sm:space-x-2 w-full sm:w-auto">
                                <Button
                                  onClick={() => handleUpdateSocialLink(link.id, editData.socialLinks.find(l => l.id === link.id)?.url || link.url)}
                                  color="green"
                                  icon={FaCheck}
                                  disabled={!editedLinks[link.id]}
                                  className="w-full sm:w-auto"
                                >
                                  {t('common.save')}
                                </Button>
                                <Button
                                  onClick={() => handleDeleteSocialLink(link.id)}
                                  color="red"
                                  icon={FaTrash}
                                  className="w-full sm:w-auto"
                                >
                                  {t('profile.delete')}
                                </Button>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      {showNewLinkRow && (
                        <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6">
                          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div>
                              <label className="block text-sm font-medium text-gray-700 mb-1">
                                {t('profile.platform')}
                              </label>
                              <select
                                value={newSocialLink.platform_id}
                                onChange={(e) => {
                                  const selectedPlatform = socialPlatforms.find(p => p.id === parseInt(e.target.value));
                                  setNewSocialLink({
                                    ...newSocialLink,
                                    platform_id: e.target.value,
                                    platform: selectedPlatform ? selectedPlatform.name : ''
                                  });
                                }}
                                className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                              >
                                <option value="">{t('profile.selectPlatform')}</option>
                                {socialPlatforms.map((platform) => (
                                  <option key={platform.id} value={platform.id}>{platform.name}</option>
                                ))}
                              </select>
                            </div>
                            <div>
                              <label className="block text-sm font-medium text-gray-700 mb-1">
                                {t('profile.url')}
                              </label>
                              <input
                                type="text"
                                value={newSocialLink.url}
                                onChange={(e) => setNewSocialLink({ ...newSocialLink, url: e.target.value })}
                                className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                                placeholder={t('profile.urlPlaceholder')}
                              />
                            </div>
                          </div>
                          <div className="flex justify-end space-x-2 mt-4">
                            <Button
                              onClick={handleAddSocialLink}
                              color="green"
                              icon={FaCheck}
                              disabled={!newSocialLink.platform_id || !newSocialLink.url}
                            >
                              {t('common.save')}
                            </Button>
                            <Button
                              onClick={() => setShowNewLinkRow(false)}
                              color="blue"
                              icon={FaTimes}
                            >
                              {t('common.cancel')}
                            </Button>
                          </div>
                        </div>
                      )}
                      <div className="mt-4 flex justify-end space-x-2">
                        <Button
                          onClick={() => setShowNewLinkRow(true)}
                          color="blue"
                          icon={FaPlus}
                        >
                          {t('profile.addNewLink')}
                        </Button>
                        <Button
                          onClick={handleCloseSocialLinksEdit}
                          color="gray"
                        >
                          {t('profile.doneEditing')}
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div className="flex flex-wrap gap-6">
                      {user.social_links && user.social_links.length > 0 ? (
                        user.social_links.map((link, index) => (
                          <a
                            key={index}
                            href={link.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-3xl text-gray-600 hover:text-blue-500 transition-colors duration-200"
                            title={link.platform}
                          >
                            {getSocialIcon(link.platform)}
                          </a>
                        ))
                      ) : (
                        <p className="text-gray-500 italic">{t('profile.noSocialLinks')}</p>
                      )}
                    </div>
                  )}
                </section>

                <section>
                  <h2 className="text-2xl font-bold mb-4 text-gray-800 border-b border-blue-300 pb-2 flex items-center justify-between">
                    {t('Experience')}
                    <Button
                      onClick={handleAddEmployment}
                      color="link"
                      icon={FaPlus}
                      className="text-gray-400 hover:text-gray-600 text-sm"
                      title={t('profile.addEmployment')}
                    />
                  </h2>
                  <div className="space-y-6">
                    {isAddingEmployment && (
                      <EmploymentItem
                        isEditing={true}
                        isNew={true}
                        editData={editData.newEmployment}
                        onSave={handleSaveNewEmployment}
                        onCancel={handleCancelNewEmployment}
                        setEditData={(data) => setEditData({ ...editData, newEmployment: data })}
                        employers={employers}
                        titles={titles}
                        setEmployers={setEmployers}
                        setTitles={setTitles}
                        formatDate={formatDate}
                      />
                    )}
                    {sortedEmployments.length > 0 ? (
                      sortedEmployments.map((exp) => (
                        <EmploymentItem
                          key={exp.id}
                          employment={exp}
                          isEditing={editMode.experience === exp.id}
                          editData={editData.experience}
                          onEdit={() => handleEditExperience(exp)}
                          onSave={handleSaveExperience}
                          onCancel={handleCancelExperience}
                          onDelete={() => handleDeleteEmployment(exp.id)}
                          setEditData={(data) => setEditData({ ...editData, experience: data })}
                          employers={employers}
                          titles={titles}
                          setEmployers={setEmployers}
                          setTitles={setTitles}
                          formatDate={formatDate}
                        />
                      ))
                    ) : (
                      <p className="text-gray-500 italic">{t('profile.noEmployments')}</p>
                    )}
                  </div>
                </section>
              </div>
            </div>
          </div>
          {saveError && (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
              <strong className="font-bold">{t('Error')}!</strong>
              <span className="block sm:inline"> {saveError}</span>
            </div>
          )}
          {editMode.avatar && (
            <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
              <div className="bg-white rounded-lg p-6 max-w-2xl w-full">
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-xl font-bold text-gray-800">{t('profile.editProfilePicture')}</h2>
                  <button
                    onClick={() => setEditMode(prev => ({ ...prev, avatar: false }))}
                    className="text-gray-500 hover:text-gray-700"
                  >
                    <FaTimes className="text-xl" />
                  </button>
                </div>
                <ProfilePicture
                  currentImage={user?.profile_picture_url}
                  onUpdate={(newImageUrl) => {
                    setUser(prev => ({ ...prev, profile_picture_url: newImageUrl }));
                    setEditMode(prev => ({ ...prev, avatar: false }));
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </Page>
  );
};

export default Profile;
