import { useLayoutEffect } from 'react';

export const useResizeObserverHandler = () => {
  useLayoutEffect(() => {
    // More comprehensive error message patterns
    const resizeObserverErrors = [
      "ResizeObserver loop completed with undelivered notifications",
      "ResizeObserver loop limit exceeded",
      "ResizeObserver loop completed",
      "Maximum call stack size exceeded"
    ];

    // Create a more robust error check function
    const isResizeObserverError = (error) => {
      return resizeObserverErrors.some(pattern => 
        error?.message?.includes(pattern) || 
        error?.toString().includes(pattern)
      );
    };

    // Override error handling at window level
    const errorHandler = (event) => {
      if (isResizeObserverError(event.error || event.reason)) {
        event.stopPropagation();
        event.preventDefault();
        return true; // Prevents the error from propagating
      }
    };

    // Add capture phase listeners
    window.addEventListener('error', errorHandler, { capture: true });
    window.addEventListener('unhandledrejection', errorHandler, { capture: true });

    // Create a more robust ResizeObserver wrapper
    if (window.ResizeObserver) {
      const OriginalResizeObserver = window.ResizeObserver;
      window.ResizeObserver = class extends OriginalResizeObserver {
        constructor(callback) {
          super((entries, observer) => {
            requestAnimationFrame(() => {
              try {
                callback(entries, observer);
              } catch (e) {
                if (!isResizeObserverError(e)) {
                  throw e;
                }
              }
            });
          });
        }
      };
    }

    return () => {
      window.removeEventListener('error', errorHandler, { capture: true });
      window.removeEventListener('unhandledrejection', errorHandler, { capture: true });
    };
  }, []);
};
